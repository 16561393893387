// Packages or third-party libraries
import React, { FC } from "react";
import { SerializedStyles } from "@emotion/react";

// Styles
import { tocTopStyles } from "./styles";

// Components
import Trigger from "../Trigger";

export type topProps = {
  isOpen: boolean;
  toggleTocMenu: () => void;
  children?: React.ReactNode;
};

const Top: FC<topProps> = ({ isOpen, toggleTocMenu, children }) => (
  <div css={(theme): SerializedStyles => tocTopStyles(theme, { isOpen })}>
    <Trigger isOpen={isOpen} toggleTocMenu={toggleTocMenu} />
    {children && <div className="children-wrapper">{children}</div>}
  </div>
);

export default Top;
