import React, { FC, MouseEvent } from "react";
import { CloseModalSVG } from "@epignosis_llc/gnosis/icons";

import ActionButton from "./ActionButton";

import { IconType } from "types/common";

import { useApplyTranslations } from "@hooks";

export type DeclineActionProps = {
  tooltipContent?: string;
  icon?: IconType;
  onClick: (e: MouseEvent) => void;
};

const DeclineAction: FC<DeclineActionProps> = ({
  tooltipContent,
  icon: Icon = CloseModalSVG,
  onClick,
  ...rest
}) => {
  const { t } = useApplyTranslations();

  return (
    <ActionButton
      tooltipContent={tooltipContent ?? t("general.decline")}
      onClick={onClick}
      data-testid="decline-action"
      aria-label={t("general.decline")}
      {...rest}
    >
      <Icon height={30} />
    </ActionButton>
  );
};

export default DeclineAction;
