import { css } from "@emotion/react";

export const tabWrapperStyles = () => css`
  padding: 2rem 0;
`;

export const tabFormFieldStyles = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;

  .form-field-note,
  .form-field-children {
    margin-inline-start: 2rem;
  }
`;

export const noteStyles = ({ courseEdit }) => css`
  color: ${courseEdit.note};
`;
