import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["customReport"] => {
  return {
    borderColor: colors.secondary.lighter,
    footerTextColor: colors.secondary.base,
    footerTextBorder: colors.secondary.light,
    titleIconColor: colors.primary.base,
  };
};
