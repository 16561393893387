import React, { FC, ReactNode } from "react";
import classNames from "classnames";
import { Button, Text } from "@epignosis_llc/gnosis";
import {
  ArrowChevronRightSVG,
  ArrowLeftChevronSVG,
  CloseModalSVG,
} from "@epignosis_llc/gnosis/icons";

import { feedbackBoxStyles, floatedButtonStyles, overlayStyles } from "./FeedbackBox.styles";

import FeedbackResult from "./FeedbackResult";
import { TextWithIcon } from "@components";

import { useApplyTranslations } from "@hooks";
import { IconType } from "types/common";

type FeedbackBoxProps = {
  title: string;
  isModalVisible: boolean;
  handleModalToggle: () => void;
  icon?: IconType;
  children?: ReactNode;
  status?: "success" | "error";
  shouldShowAgain: boolean;
};

const FeedbackBox: FC<FeedbackBoxProps> = ({
  title,
  isModalVisible,
  handleModalToggle,
  icon: Icon,
  children,
  status,
  shouldShowAgain,
}) => {
  const { t } = useApplyTranslations();

  const customClassNames = classNames("floated-feedback-button", {
    "hover-state": !isModalVisible,
  });

  if (!shouldShowAgain) return null;

  return (
    <>
      <div
        css={overlayStyles}
        className="overlay"
        onClick={handleModalToggle}
        data-testid="feedback-box-overlay"
      />
      <div
        css={feedbackBoxStyles}
        className={`feedback-box-container ${isModalVisible ? "show-modal" : ""}`}
        data-testid="feedback-box-container"
      >
        <div className="feedback-box-content">
          <div className="feedback-box-header">
            <TextWithIcon
              className="feedback-box-title"
              label={
                <Text as="p" weight="700" fontSize="md">
                  {title}
                </Text>
              }
              labelColor="white"
              icon={Icon && <Icon height={32} />}
            />

            <Button
              as="button"
              id="feedback-box-close-button"
              aria-label={t("a11y.feedbackMechanism.closeFeedback")}
              data-testid="feedback-box-close-button"
              onClick={handleModalToggle}
              className="close-button"
            >
              <CloseModalSVG height="32" className="close-icon" />
            </Button>
          </div>

          <FeedbackResult status={status}>{children}</FeedbackResult>
        </div>
        <div css={floatedButtonStyles}>
          <Button
            as="button"
            id="floated-feedback-button"
            aria-label={t("general.feedback")}
            data-testid="floated-feedback-button"
            onClick={handleModalToggle}
            className={customClassNames}
            iconAfter={isModalVisible ? ArrowChevronRightSVG : ArrowLeftChevronSVG}
            noGutters
          >
            {t("general.feedback")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default FeedbackBox;
