import React, { FC, MouseEvent } from "react";
import { IconType } from "types/common";
import ActionButton from "./ActionButton";
import { SynchronizeSVG } from "@epignosis_llc/gnosis/icons";
import { useApplyTranslations } from "@hooks";

export type SynchronizeActionProps = {
  tooltipContent?: string;
  icon?: IconType;
  iconHeight?: number;
  onClick: (e: MouseEvent) => void;
};

const SynchronizeAction: FC<SynchronizeActionProps> = ({
  tooltipContent,
  icon: Icon = SynchronizeSVG,
  iconHeight = 32,
  onClick,
}) => {
  const { t } = useApplyTranslations();

  return (
    <ActionButton
      tooltipContent={tooltipContent ?? t("general.synchronize")}
      onClick={onClick}
      data-testid="synchronize-action"
    >
      <Icon height={iconHeight} />
    </ActionButton>
  );
};

export default SynchronizeAction;
