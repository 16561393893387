import { yup } from "@utils/validation/validation";
import { t } from "@utils/i18n";

export const FeedbackFormValidationSchema = yup.object().shape({
  body: yup
    .string()
    .trim()
    .required()
    .max(500, () => t("feedbackMechanism.validationMessages.maxFeedbackLength", { number: 500 })),
  feedback_category: yup.string().trim().required(),
});
