import { t } from "@utils/i18n";
import { ScreenSizes } from "../breakpoints";
import { MainDrawerContent, MainDrawerContentEnum } from "types/common";

export const getDrawerWidth = ({ xs, sm }: Partial<ScreenSizes>): string => {
  if (sm) return "600px";
  if (xs) return "100%";

  return "550px";
};

export const getMainDrawerHeader = (content: MainDrawerContent): string | JSX.Element => {
  switch (content) {
    case MainDrawerContentEnum.HELP_CENTER:
      return t("general.help");
    default:
      return "";
  }
};

export const getProgressStatsWidth = ({ xxl }: Partial<ScreenSizes>): number => {
  if (xxl) return 125;

  return 85;
};
