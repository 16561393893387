// Packages or third-party libraries
import React, { FC, useState } from "react";
import { Button, Tooltip } from "@epignosis_llc/gnosis";
import { GamepadModernSVG } from "@epignosis_llc/gnosis/icons";

// Components
import GamificationDrawer from "@views/WidgetsDashboard/components/Widgets/Leaderboard/GamificationDrawer";

// Hooks
import { useApplyTranslations } from "@hooks";

export const GamificationMenu: FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { t } = useApplyTranslations();

  return (
    <div>
      <Tooltip content={t("general.gamification")}>
        <Button
          title={t("general.gamification")}
          data-testid="topbar-gamification"
          variant="ghost"
          color="primary"
          className="action-btn"
          noGutters
          onClick={(): void => setIsDrawerOpen(true)}
          aria-label={t("general.gamification")}
        >
          <GamepadModernSVG height={32} />
        </Button>
      </Tooltip>
      <GamificationDrawer
        isDrawerOpen={isDrawerOpen}
        onClose={(): void => setIsDrawerOpen(false)}
      />
    </div>
  );
};
