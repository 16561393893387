import { css } from "@emotion/react";

export const UserDetailsStyles = ({ colors }) => css`
  display: flex;
  position: relative;

  // Using the old absolute position method to avoid changing the DOM structure.
  .close-btn {
    position: absolute;
    inset-inline-end: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .user-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    padding: 0 0.5rem;
    line-height: 1;

    .user-name {
      word-break: break-all;
      /* padding-inline-end: 18px; */
    }

    .user-email {
      display: inline-block;
      max-width: 12.5rem;
      white-space: nowrap;
      overflow: hidden;
      line-height: 1.2;
      text-overflow: ellipsis;
      color: ${colors.secondary.light};
    }
  }
`;
