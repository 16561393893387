import React, { FC } from "react";
import { verticalDividerStyles } from "./styles";
import { SerializedStyles } from "@emotion/react";

export type VerticalDividerProps = {
  height?: number;
};

const VerticalDivider: FC<VerticalDividerProps> = ({ height = 60 }) => {
  return (
    <span css={(theme): SerializedStyles => verticalDividerStyles(theme, { height })}>
      <div className="line" />
    </span>
  );
};

export default VerticalDivider;
