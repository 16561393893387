import { css } from "@emotion/react";

export const toaster = ({ alert, gamificationNotification }) => css`
  padding: 1rem;
  z-index: 10001; // 10000 is the z-index of the modals overlay

  @media screen and (min-width: 481px) {
    width: 410px;
    padding: 0;
  }

  .Toastify__toast {
    align-items: self-start;
    min-height: 0;
    padding: 18px 8px;
    margin-bottom: 1rem;

    .Toastify__toast-body {
      padding: 0 8px;

      & > div:first-of-type {
        display: flex;
        align-items: self-start;
      }

      .prefix-icon {
        margin-inline-end: 1rem;
        height: 24px;

        svg {
          min-width: 24px;
        }
      }

      .gamification-notification-container {
        display: flex;
        align-items: center;

        .prefix-icon-badge {
          margin-inline-end: 1rem;
          height: 50px;
        }

        .gamification-notification-content {
          display: flex;
          flex-direction: column;

          .heading {
            margin-bottom: 0.5rem;
          }
        }
      }
    }

    .close-btn {
      color: #fff;
      min-width: 24px;
      margin: 4px 0;
    }
  }

  .Toastify__toast-theme--colored {
    &.Toastify__toast--info {
      background: ${alert.info.background};
      color: ${alert.info.color};
    }

    &.Toastify__toast--success {
      background: ${alert.success.background};
      color: ${alert.success.color};
    }

    &.Toastify__toast--warning {
      background: ${alert.warning.background};
      color: ${alert.warning.color};
    }

    &.Toastify__toast--error {
      background: ${alert.danger.background};
      color: ${alert.danger.color};
    }
  }

  .Toastify__toast-theme--light {
    &.Toastify__toast--success {
      background: ${gamificationNotification.success.background};
      color: ${gamificationNotification.success.color};
      border-left: solid 10px ${gamificationNotification.success.borderColor};
    }

    &.Toastify__toast--error {
      background: ${gamificationNotification.error.background};
      color: ${gamificationNotification.error.color};
      border-left: solid 10px ${gamificationNotification.error.borderColor};
    }
  }
`;
