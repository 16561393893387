import { useEffect } from "react";

const usePreventIframeEmbedding = (enabled: boolean): void => {
  useEffect(() => {
    const disableIframeEmbedding = (): void => {
      if (window && window.top && window !== window.top) {
        window.top.location = window.location;
      }
    };

    // Check if the current window is embedded in an iframe before executing
    if (!enabled && window !== window.top) {
      disableIframeEmbedding();
    }
  }, [enabled]);
};
export default usePreventIframeEmbedding;
