import { useConfigurationStore } from "@stores";
import i18n, { t as translate } from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import { config } from "@config";

type TranslationsVariables = {
  [key: string]: string | number | undefined | null;
};

const { getState: getConfigurationState } = useConfigurationStore;

// Locally on development environment we load local translation files from /public/locales.
// In production we load translations from a CDN.
const version = new Date().getTime(); // For cache busting purposes.
export const translationUrl = `${config.TRANSLATIONS_URL}/{{lng}}.json?v=${version}`;

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources: {},
    partialBundledLanguages: true,
    debug: config.isDev() ? true : false,
    lng: "en-US",
    fallbackLng: "en-US",
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: translationUrl,
      crossDomain: true,
      requestOptions: {
        cache: "no-store",
      },
    },
    load: "currentOnly",
  });

export { i18n };

export const t = (key: string, translationsVariables?: TranslationsVariables): string => {
  const currentTranslation = translate(key, translationsVariables);

  return applyOverrides(currentTranslation);
};

export const applyOverrides = (currentTranslation: string): string => {
  const { domainSettings } = getConfigurationState();

  const { language_overrides: languageOverrides } = domainSettings ?? {};
  // If there are more overrides for a word, we want to use the last one
  const matchingOverrides =
    languageOverrides && languageOverrides.filter((ov) => currentTranslation === ov.original);
  const override =
    matchingOverrides && matchingOverrides.length > 0
      ? matchingOverrides[matchingOverrides.length - 1]
      : undefined;

  return override ? override.overridden : currentTranslation;
};
