import { css } from "@emotion/react";

export const selectedFilesStyles = css`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 1rem;

  .attachments-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      min-height: 0;
      height: auto;
    }
  }

  .attachments {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 0.5rem;

    .attachment-tag {
      position: relative;
      word-break: break-word;
      font-style: italic;
    }
  }
`;
