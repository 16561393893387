import { css } from "@emotion/react";

export const courseCompletionProgressBar = (theme, { failed }) => css`
  background-color: ${failed
    ? theme.progressBar.backgroundFail
    : theme.progressBar.backgroundSuccess};
  color: ${theme.progressBar.fillSuccess};
  height: 0.35rem;
  min-width: 10rem;
  div {
    font-size: 0;
  }
`;
