import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";
import Color from "color";

export default (colors: PalletThemeColors): ThemePlusTypes["groups"] => {
  return {
    info: {
      backgroundColor: Color(colors.primary.lightest).alpha(0.25).string(),
      text: colors.primary.base,
    },
    courses: {
      info: {
        backgroundColor: colors.secondary.base,
        textColor: colors.white,
      },
    },
  };
};
