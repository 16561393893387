import { css } from "@emotion/react";

export const mainMenuInner = ({ colors, sidebarExtended }, { isCollapsed }) => css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 0%;

  // UL Menu containers
  .main-menu,
  .secondary-menu {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .main-menu {
  }

  .secondary-menu {
    .nav-item {
      border-radius: 5px;
      cursor: pointer;
      margin: 4px 12px;
      padding: ${!isCollapsed ? "4px 8px 4px 12px" : "4px 12px"};
      color: ${sidebarExtended.dynamicTextColor};
    }
  }

  a {
    // Override the default notification styles.
    .has-content {
      .content-container {
        border: none;
        font-size: 0.75rem;
        background-color: ${colors.red.base};
      }
    }
  }
`;
