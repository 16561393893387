import React, { FC, MouseEvent, useState } from "react";
import { useMutation } from "react-query";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import FeedbackBox from "@components/ReusableModals/FeedbackModal/components/FeedbackBox";
import FeedbackForm from "./FeedbackForm";

import { createFeedbackTicket } from "./api";

import permissions from "@utils/permissions";
import authService from "@utils/services/AuthService";
import { FeedbackFormValidationSchema } from "@utils/validation/feedbackSchemas";
import {
  openSupportTicket,
  resolveCurrentSection,
} from "@components/ReusableModals/FeedbackModal/helpers";

import queryKeys from "@constants/queryKeys";
import { supportTicketProcessSteps } from "./constants";

import { createFeedbackTicketPostData } from "./types";
import { useApplyTranslations } from "@hooks";

const FeedbackModal: FC = () => {
  const { t } = useApplyTranslations();
  const { canAccessProfile } = permissions.profilePermissions;
  const rolePasses = authService.isAdministrator() || authService.isInstructor();
  const shouldShowDialog = rolePasses && canAccessProfile();

  const [shouldShowAgain, setShouldShowAgain] = useState(true);
  const [statusResult, setStatusResult] = useState<"success" | "error" | undefined>();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleModalToggle = (): void => {
    setIsModalVisible((prev) => !prev);
  };

  const formMethods = useForm<createFeedbackTicketPostData>({
    defaultValues: {
      body: "",
      feedback_category: "",
      feedback_source: resolveCurrentSection(false),
      skip_feedback: false,
    },
    resolver: yupResolver(FeedbackFormValidationSchema),
    mode: "onChange",
  });

  const {
    formState: { isValid },
    reset,
    watch,
  } = formMethods;

  const skipFeedback = watch("skip_feedback");

  const closeModalAfterDelay = (): void => {
    setTimeout(() => {
      setStatusResult(undefined);
      handleModalToggle();
    }, 4000);
  };

  const { mutate: createFeedbackTicketMutation, isLoading } = useMutation(
    [queryKeys.help.feedback],
    (ticket: createFeedbackTicketPostData) => createFeedbackTicket(ticket),
    {
      onSuccess: () => {
        setStatusResult("success");
        setShouldShowAgain(!skipFeedback);
        reset();
      },
      onError: () => {
        setStatusResult("error");
      },
      onSettled: () => {
        closeModalAfterDelay();
      },
    },
  );

  const onSubmit = (data: createFeedbackTicketPostData): void => {
    createFeedbackTicketMutation(data);
  };

  const handleSupportTicketClick = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    openSupportTicket(supportTicketProcessSteps);
  };

  if (!shouldShowDialog) return null;

  return (
    <FeedbackBox
      isModalVisible={isModalVisible}
      handleModalToggle={handleModalToggle}
      title={t("feedbackMechanism.giveFeedback")}
      status={statusResult}
      shouldShowAgain={shouldShowAgain}
    >
      <FormProvider {...formMethods}>
        <FeedbackForm
          onSubmit={onSubmit}
          isLoading={isLoading}
          isValid={isValid}
          handleOnClick={handleSupportTicketClick}
        />
      </FormProvider>
    </FeedbackBox>
  );
};

export default FeedbackModal;
