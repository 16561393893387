import { css } from "@emotion/react";

export const MultiValueStyles = ({ autocompleteInput, base }) => ({
  ...base,
  margin: "0.25rem 0.5rem 0.25rem 0",
  backgroundColor: autocompleteInput.tagColor,
  flexDirection: "row-reverse",
  ".multi-value-label": {
    padding: 0,
  },
});

export const MultiValueLabelStyles = ({ base }) => ({
  ...base,
  padding: "0.25rem",
  paddingLeft: "0.25rem",
  paddingRight: "0.5rem",
});

export const MultiValueRemoveStyles = ({ autocompleteInput, base }) => ({
  ...base,
  ".multi-value-remove": {
    cursor: "pointer",
  },
  "&:hover": {
    backgroundColor: autocompleteInput.tagColor,
    color: "inherit",
  },
});

export const ValueContainerStyles = ({ base }) => ({
  ...base,
  padding: "0rem 0.75rem",
});

export const SelectContainerStyles = ({ base }) => ({
  ...base,
  zIndex: 1050,
  width: "100%",
});

export const MenuListStyles = ({ base }) => ({
  ...base,
  paddingTop: 0,
  paddingBottom: 0,
});

export const SingleValueStyles = ({ base }) => ({
  ...base,
  position: "absolute",
  maxWidth: "calc(100% - 35px)",
});

export const AutocompleteContainer = (autocompleteInput, { required }) => css`
  .label-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.25rem;
    margin-bottom: 0.5rem;

    label {
      margin-bottom: 0;
      ${required && `&::after { content: " *"; color: ${autocompleteInput.errorBorderColor}}`}
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      color: ${autocompleteInput.blueColor};

      &:hover {
        cursor: help;
      }
    }
  }
`;

export const IndicatorContainer = ({ base }) => ({
  ...base,
  alignSelf: "start",
  paddingTop: "0.6rem",
});

export const editableInputContainer = (autocompleteInput, { required }) => css`
  min-width: 2.5rem;
  border: none;

  .label-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.25rem;
    margin-bottom: 0.5rem;

    label {
      margin-bottom: 0;
      ${required && `&::after { content: " *"; color: ${autocompleteInput.errorBorderColor}}`}
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      color: ${autocompleteInput.blueColor};
      &:hover {
        cursor: help;
      }
    }
  }

  .edit-label-input {
    background: transparent;
    appearance: none;
    border: none;
    padding: 0.25rem;
    outline: none;
    font-size: 85%;
    line-height: 18.7px;
  }

  .input-wrapper {
    input {
      border: none;
    }
  }
`;

export const editableChipInputContainer = (
  { autocompleteInput, activeButton },
  { isRequired = false } = {},
) => css`
  width: 100%;

  .inputs-wrapper {
    display: flex;
    align-items: start;
    gap: 0.5rem;
  }

  .creatable-input-container {
    width: 100%;
  }

  .edit-button {
    min-height: 2.5rem;
    height: auto;

    &.is-on-edit-mode {
      background-color: ${activeButton.background};
      border-color: ${activeButton.borderColor};
      color: ${activeButton.color};
    }
  }

  .label-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.25rem;
    margin-bottom: 0.5rem;

    label {
      margin-bottom: 0;
      ${isRequired && `&::after { content: " *"; color: ${autocompleteInput.errorBorderColor}}`}
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      color: ${autocompleteInput.blueColor};
      &:hover {
        cursor: help;
      }
    }
  }
`;
