import React, { FC } from "react";
import { Text } from "@epignosis_llc/gnosis";

import { useApplyTranslations } from "@hooks";
import { ListItemProps } from "./types";

const ListItem: FC<ListItemProps> = ({ value, translationKey, type }) => {
  const { t } = useApplyTranslations();
  const baseTranslation =
    type === "points" ? "gamification.collectPoints.drawer" : "gamification.levelUp.drawer";

  const translationValues = {
    count: value,
    number: value,
  };

  return (
    <li className="list-item">
      <span className="points-badge">
        <Text as="span" fontSize="md" weight="700">
          {value}
        </Text>
        {type === "points"
          ? t(`${baseTranslation}.${type}`, { count: value })
          : t(`${baseTranslation}.${translationKey}`, { count: value })}
      </span>
      <Text as="span" fontSize="md">
        {type === "points"
          ? t(`${baseTranslation}.${translationKey}`, translationValues)
          : t(`${baseTranslation}.levelUpgrade`, translationValues)}
      </Text>
    </li>
  );
};

export default ListItem;
