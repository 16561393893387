import React, { FC } from "react";
import classNames from "classnames";
import { badgeFigure } from "./styles";
import { Badge, BadgeImage, BadgeCriteria, BadgeType } from "types/entities";
import { Tooltip } from "@epignosis_llc/gnosis";
import { t } from "@utils/i18n";

type BadgeFigureProps = Pick<Badge, "image" | "awarded" | "type" | "criteria" | "name"> & {
  showTooltip?: boolean;
};

const constructContent = (name: string, criteria: number, type: BadgeType): JSX.Element => {
  return (
    <>
      <span>{name}</span>
      <div
        className="reward-label"
        dangerouslySetInnerHTML={{
          __html: t(`badges.criteria.${type}`, {
            count: criteria,
          }),
        }}
      />
    </>
  );
};

const BadgeFigure: FC<BadgeFigureProps> = (props) => {
  const { image, awarded, type, name, criteria, showTooltip = false } = props;
  const { set, index, custom_url } = image as BadgeImage;

  const imageFigureClassname = classNames({
    [`set-${set}`]: true,
    [`badge-${type}-${index}`]: true,
    awarded,
  });

  const badgeFigureClassname = classNames({
    "custom-badge": true,
    awarded,
  });

  return (
    <Tooltip
      content={constructContent(name ?? "", (criteria as BadgeCriteria)?.count, type)}
      disabled={!showTooltip}
      parentProps={{ "aria-label": name }}
    >
      <figure css={badgeFigure}>
        {custom_url ? (
          <div
            className={badgeFigureClassname}
            style={{
              backgroundImage: `url(${custom_url})`,
            }}
          />
        ) : (
          <div className={imageFigureClassname} />
        )}
      </figure>
    </Tooltip>
  );
};

export default BadgeFigure;
