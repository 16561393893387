import { css } from "@emotion/react";

export const tocTopStyles = ({ unitsToC }, { isOpen }) => css`
  background-color: ${unitsToC.top.backgroundColor};
  height: 3.5rem;
  min-height: 3.5rem;
  padding: ${isOpen ? "0.5rem 1rem" : "0"};
  display: flex;
  align-items: center;
  gap: 1rem;

  .children-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 2.5rem;
    position: relative;

    .top-start {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    .top-end {
      display: flex;
      gap: 0.5rem;
    }
  }
`;

export const tocHeaderStyles = ({ unitsToC }, { isActive, hasLinkTitle }) => css`
  display: flex;
  flex-direction: column;
  align-items: start;
  border-bottom: 1px solid ${unitsToC.header.borderColor};

  .back-button-container {
    padding-inline: 1rem;
    width: 100%;

    button {
      padding-inline: 0;
      min-width: 0;
    }
  }

  .title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
    padding: 0.75rem 1rem;
    width: 100%;
    background-color: ${isActive ? unitsToC.header.backgroundColor : "initial"};
    position: relative;
    cursor: ${hasLinkTitle ? "pointer" : "initial"};

    &:hover {
      background-color: ${unitsToC.header.backgroundColor};
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      inset-inline-start: 0;
      width: ${isActive ? "5px" : "0"};
      height: 100%;
      background: ${unitsToC.header.activeColor};
    }

    .title-container {
      display: flex;
      gap: 0.5rem;
      align-items: center;

      .title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
      }
    }
  }

  .children-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    gap: 0.5rem;

    .actions {
      display: flex;
      gap: 0.5rem;

      div[role="tooltip"] {
        display: flex;
        height: fit-content;
      }
    }
  }
`;

export const tocListContainerStyles = css`
  overflow-y: auto;
  flex: 1;
  opacity: 1;
  transition: opacity 0.2s ease;

  &.hidden {
    opacity: 0;
    display: none;
    transition: opacity 0.1s ease;
  }

  dl {
    margin: 0;
    padding: 0;
  }
`;

export const tocListTitleStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.25rem;
  padding: 0.5rem;
  height: 3.5rem;
  visibility: visible;
  margin-inline-start: 2.5rem;

  .title-container {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;

    .title {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }
  }
`;

export const listItemStyles = ({ unitsToC: { listItem } }, { disabled, readonly }) => css`
  display: flex;
  align-items: center;
  margin-inline-start: 0rem;
  visibility: visible;
  position: relative;
  transition: background-color 0.1s ease-in;
  border-top: 1px solid transparent;

  &.is-active {
    background-color: ${listItem.hoverColor};
    border-top: 1px solid ${listItem.borderColor};

    &::before {
      content: "";
      position: absolute;
      top: 0;
      inset-inline-start: 0;
      width: 5px;
      height: 100%;
      background: ${listItem.activeColor};
    }

    .list-item-children {
      visibility: visible !important;
    }
  }

  &:hover {
    background-color: ${!disabled && !readonly ? listItem.hoverColor : "initial"};
    border-top: 1px solid ${listItem.borderColor};

    .list-item-children {
      visibility: visible !important;
    }
  }

  .list-item-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 0.5rem;
    height: 4rem;
    min-height: 4rem;
    padding: 0.5rem;
    cursor: ${!disabled ? "pointer" : "default"};

    .list-item-content {
      display: flex;
      align-items: center;
      width: 100%;
      color: ${disabled ? listItem.disabledColor : "initial"};

      .list-item-icon {
        margin-inline-end: 0.5rem;
        line-height: 0;

        svg {
          fill: ${disabled ? listItem.disabledColor : "initial"};
        }
      }

      .list-item {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .list-item-text {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
        }
      }
    }

    .list-item-children {
      visibility: hidden;
      width: fit-content;
    }
  }
`;
