import React, { FC } from "react";
import { Text } from "@epignosis_llc/gnosis";
import { LinkStyled } from "@components";
import { URLS } from "@constants/urls";
import localStorageKeys from "@constants/localStorageKeys";
import { useApplyTranslations } from "@hooks";

const ForgotPasswordLink: FC = () => {
  const { t } = useApplyTranslations();
  const handleOnClick = (): void => {
    // Reset local storage key
    localStorage.removeItem(localStorageKeys.EXTERNAL_SIGNIN_SIGNUP);
  };

  return (
    <section className="forgot-pswd">
      <LinkStyled to={URLS.passwordReset} onClick={handleOnClick}>
        <Text fontSize="sm" as="div">
          {t("signIn.forgotPassword")}
        </Text>
      </LinkStyled>
    </section>
  );
};

export default ForgotPasswordLink;
