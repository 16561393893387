import Color from "color";
import { PalletThemeColors, isShadeOfWhite } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["customLink"] => {
  const isLightTheme = isShadeOfWhite(colors.primary.base);

  return {
    primary: {
      color: isLightTheme ? colors.primary.darker : colors.primary.base,
      hoverColor: isLightTheme ? colors.primary.dark : colors.primary.lighter,
    },
    secondary: {
      color: isLightTheme ? colors.primary.darker : colors.black,
      hoverColor: isLightTheme ? colors.primary.dark : colors.primary.lighter,
    },
    defaultLink: {
      color: colors.blue.base,
      hoverColor: Color(colors.blue.base).alpha(0.8).string(),
    },
  };
};
