import { css } from "@emotion/react";

export const CountMassActionModalBody = () => css`
  display: flex;
  gap: 1rem;
  flex-direction: column;

  .certification-container {
    display: flex;
    gap: 0.5rem;
    width: 100%;

    .item-select-container {
      width: 60%;
    }
    .preview-button-container {
      display: flex;
      align-items: flex-end;
    }
  }
`;
