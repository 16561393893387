import { css } from "@emotion/react";
import { COLORS } from "@styles";

export const headerComponentStyles = ({ icon }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  .back-button {
    padding: 0;
    min-width: auto;
    height: auto;
  }

  .header-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .header-title {
      font-size: 1.375rem;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-all;
      cursor: default;
    }

    .article-link {
      line-height: 0;
    }

    .link-icon {
      color: ${icon.primary};
    }
  }
`;

export const articleContentStyles = () => css`
  height: 100%;
  max-width: 90%;
  padding-top: 1.5rem;
  margin: auto;
  font-size: 1rem;

  img {
    max-width: 100%;
  }
`;

export const supportFormContainer = (isSupportTicket) => css`
  display: grid;
  width: 100%;
  padding: ${isSupportTicket ? "0" : "0 2rem"};
  margin-top: ${isSupportTicket ? "0" : "1.5rem"};
  gap: 1.5rem;

  #body::placeholder {
    color: ${isSupportTicket ? COLORS.secondary : "initial"};
  }
`;
