import { LeaderboardStatistics, LeaderboardUser } from "types/entities";
import {
  GamificationSettings,
  LevelsSettings,
  PointsSettings,
} from "types/entities/GamificationSettings";
import { translationsMapping } from "./constants";
import { PermissionsType, TabName } from "./types";

export const filterValidEntries = (
  data: PointsSettings | LevelsSettings | null | undefined,
  type: "points" | "levels",
): {
  key: string;
  value: number;
  translationKey: string;
}[] => {
  if (!data) return [];

  const filteredData = Object.fromEntries(
    Object.entries(data).filter(([_, value]) => value !== null),
  );

  const mappingKeys = Object.keys(translationsMapping[type]);

  return mappingKeys
    .filter((key) => filteredData[key] !== undefined)
    .map((key) => ({
      key,
      value: filteredData[key] as number,
      translationKey: translationsMapping[type]?.[key] ?? "",
    }));
};

export const filterLeaderboardData = (
  leaderboard: LeaderboardStatistics | undefined,
  permissionsArray: PermissionsType,
): {
  [k: string]: {
    title: string;
    user: LeaderboardUser | null | undefined;
  };
} => {
  if (!leaderboard) return {};

  return Object.fromEntries(
    Object.entries(leaderboard)
      .filter(([key, value]) => permissionsArray[key] && value && "user" in value)
      .map(([key, value]) => [
        key,
        {
          title: key,
          user: value?.user,
        },
      ]),
  );
};

export const getProgress = (threshold: number, value: number): number | string => {
  const completionPercentage = value / threshold;
  const percentage =
    (completionPercentage < 1 ? Math.round(completionPercentage * 100) / 100 : 1) * 100;
  return percentage === 100 ? "general.progress.completed" : percentage;
};

const hasAnyPointsOrLevels = (data?: PointsSettings | LevelsSettings | null): boolean => {
  if (!data) return false;
  const { enabled, ...points } = data;
  return enabled && Object.values(points).some((value) => value !== null);
};

export const shouldShowFooterButton = (
  currentTab: TabName,
  gamificationSettings: GamificationSettings | null,
): boolean => {
  if (!currentTab) return false;

  const tabConditions: Record<TabName, boolean> = {
    courses: false,
    certificates: false,
    badges: true,
    points: hasAnyPointsOrLevels(gamificationSettings?.points),
    levels: hasAnyPointsOrLevels(gamificationSettings?.levels),
  };

  return tabConditions[currentTab] ?? false;
};
