import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const tableHeaderContainer = css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title-wrapper {
    display: flex;
    align-items: center;
    word-break: break-all;
    margin-bottom: 1rem;

    ${mq["md"]} {
      display: block;
    }

    .subtitle {
      width: 100%;
      margin-inline-start: 0.125rem;

      ${mq["sm"]} {
        padding-inline-start: 0.5rem;
        width: auto;
        margin-inline-start: 0;
      }
    }
  }

  .right-block {
    margin-inline-start: 1rem;
  }
`;
