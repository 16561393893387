import React, { FC } from "react";
import { DropdownItem } from "@epignosis_llc/gnosis";
import { getFormattedUserName } from "@utils/helpers";
import { CourseUser } from "@views/Course/CourseUsers/types";
import { resetProgress } from "@api/courses";
import { handleUserCoursesErrors } from "@errors/errors";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import { ButtonWithDropdown, ConfirmationModal } from "@components";
import { useApplyTranslations } from "@hooks";

export type ResetProgressModalProps = {
  selectedUser: CourseUser;
  courseId: string;
  handleInvalidateQuery: () => void;
  setIsResetProgressModalOpen: (open: boolean) => void;
  isResetProgressModalOpen: boolean;
};

const ResetProgressModal: FC<ResetProgressModalProps> = ({
  selectedUser,
  courseId,
  handleInvalidateQuery,
  setIsResetProgressModalOpen,
  isResetProgressModalOpen,
}) => {
  const { t } = useApplyTranslations();
  const { id, name, surname, login, has_certificate } = selectedUser;
  const username = getFormattedUserName({ name, surname, login });
  const userId = id.toString();
  const resetDropdown: DropdownItem[] = [
    {
      label: t("users.courses.resetAndRemoveCertificate"),
    },
  ];

  const list = has_certificate ? resetDropdown : [];

  const { mutate: resetProgressMutation } = useMutation(
    (removeCertificate: boolean | undefined) => resetProgress(courseId, userId, removeCertificate),
    {
      onError: (error: AxiosError) => {
        handleUserCoursesErrors(error);
      },
      onSettled: () => {
        handleInvalidateQuery();
      },
    },
  );

  const handleResetProgress = (removeCertificate: boolean | undefined): void => {
    resetProgressMutation(removeCertificate);
    setIsResetProgressModalOpen(false);
  };

  return (
    <ConfirmationModal
      id={id}
      header={t("users.courses.resetUserProgress")}
      isOpen={isResetProgressModalOpen}
      bodyTitle={
        <div
          dangerouslySetInnerHTML={{
            __html: t("users.courses.confirmResetUserProgressWithWarning", { username }),
          }}
        />
      }
      footerButton={
        <ButtonWithDropdown
          list={list}
          color="danger"
          onButtonClick={(): void => {
            handleResetProgress(false);
          }}
          onListItemClick={(): void => {
            handleResetProgress(true);
          }}
        >
          {t("general.reset")}
        </ButtonWithDropdown>
      }
      onClose={(): void => setIsResetProgressModalOpen(false)}
      closeOnOutsideClick={true}
    />
  );
};

export default ResetProgressModal;
