import React from "react";
import { CheckRegularSVG } from "@epignosis_llc/gnosis/icons";
import { Branch } from "types/entities";

type BranchOptions = {
  id: string;
  label: string;
  value: string;
  icon: JSX.Element | undefined;
};

// Transform branches to options
export const getFormattedBranches = (
  branches: Branch[],
  loggedInBranchId: number,
): BranchOptions[] => {
  const formattedBranches = branches.map((branch) => ({
    id: branch.id.toString(),
    label: branch.name,
    value: branch.id.toString(),
    icon: loggedInBranchId === branch.id ? <CheckRegularSVG height={32} /> : undefined,
  }));

  return formattedBranches;
};
