// Packages or third-party libraries
import React, { FC } from "react";
import { Button } from "@epignosis_llc/gnosis";
import { HamburgerSVG } from "@epignosis_llc/gnosis/icons";

// Styles
import { tocTriggerContainerStyles } from "./styles";

// Utils, hooks
import { useApplyTranslations } from "@hooks";
import classNames from "classnames";

type TriggerProps = {
  isOpen: boolean;
  toggleTocMenu: () => void;
};

const Trigger: FC<TriggerProps> = ({ isOpen, toggleTocMenu }) => {
  const { t } = useApplyTranslations();
  const triggerClassName = classNames({ closed: !isOpen });
  const ariaLabel = isOpen ? t("a11y.courses.closeToC") : t("a11y.courses.openToC");

  return (
    <div css={tocTriggerContainerStyles} className={triggerClassName}>
      <Button
        className="toc-trigger"
        noGutters
        aria-label={ariaLabel}
        color="primaryLight"
        onClick={toggleTocMenu}
      >
        <HamburgerSVG height={32} />
      </Button>
    </div>
  );
};

export default Trigger;
