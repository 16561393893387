export const calculate90DaysPassed = (timestamp: string): boolean | null => {
  if (!timestamp) return null;

  const ninetyDaysInMilliseconds = 90 * 24 * 60 * 60 * 1000;
  const givenDate = new Date(timestamp);
  const currentDate = new Date();
  const timeDifference = currentDate.getTime() - givenDate.getTime();

  return timeDifference >= ninetyDaysInMilliseconds;
};
