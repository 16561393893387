import { css } from "@emotion/react";

export const ActionGhostButtonStyles = ({ button }, { isPressed }) => css`
  ${isPressed &&
  `
    background-color: ${button.primary.ghost.background} !important;
    color: ${button.primary.ghost.hoverColor} !important;
    border-color: transparent !important;
  `}

  &:hover {
    background-color: ${button.primary.ghost.background} !important;
    color: ${button.primary.ghost.hoverColor} !important;
    border-color: transparent !important;
  }
`;
