/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useQuery,
  useMutation,
  useQueryClient,
  UseMutationResult,
  UseQueryResult,
  UseQueryOptions,
} from "react-query";
import { NavigateFunction, useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { generalNotification } from "@utils/helpers";
import { postCourseRating, deleteCourseRating, getCourse } from "@api/courses";
import { createCourse } from "@views/Courses/api";
import { CourseRatingRes, CourseRes } from "types/responses";
import queryKeys from "@constants/queryKeys";
import { URLS } from "@constants/urls";
import { deleteEnrollmentApproval, postEnrollmentApproval } from "@api/catalog";
import { t } from "@utils/i18n";

export const useGetMyCourse = <T = CourseRes>(
  courseId: string,
  options: UseQueryOptions<CourseRes, unknown, T>,
): UseQueryResult<T, unknown> =>
  useQuery<CourseRes, unknown, T>(
    [queryKeys.myCourse, courseId],
    () => getCourse(courseId),
    options,
  );

export const usePostCourseRating = (
  courseId: number,
): UseMutationResult<CourseRatingRes, unknown, number, void> => {
  const queryClient = useQueryClient();

  return useMutation(
    [queryKeys.rating, courseId],
    (rating: number) => postCourseRating(courseId.toString(), rating),
    {
      onMutate: (rating) => {
        const prevCourse = queryClient.getQueryData<CourseRes>([
          queryKeys.course,
          courseId.toString(),
        ]);

        if (prevCourse) {
          queryClient.setQueryData([queryKeys.course, courseId.toString()], () => ({
            _data: {
              ...prevCourse._data,
              rating: { average: prevCourse._data.rating?.average, user: rating },
            },
          }));
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries([queryKeys.course, courseId.toString()]);
      },
    },
  );
};

export const usePostCourseCreate = (navigate: NavigateFunction): UseMutationResult => {
  return useMutation([queryKeys.courses.create], () => createCourse(), {
    onSuccess: (res) => {
      const courseId = res._data.id.toString();
      navigate(URLS.courses.getCourseEditLink({ courseId }));
    },
    onError: (error: AxiosError) => {
      generalNotification("error", error?.response?.data._errors[0].title);
    },
  });
};

export const useResetCourseRating = (courseId: number): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation([queryKeys.rating, courseId], () => deleteCourseRating(courseId.toString()), {
    onMutate: () => {
      const prevCourse = queryClient.getQueryData<CourseRes>([
        queryKeys.course,
        courseId.toString(),
      ]);

      if (prevCourse) {
        queryClient.setQueryData([queryKeys.course, courseId.toString()], () => ({
          _data: {
            ...prevCourse._data,
            rating: { average: prevCourse._data.rating?.average, user: null },
          },
        }));
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.course, courseId.toString()]);
    },
  });
};

// Hook for Enrollment Approval
export const useEnrollmentApproval = (): {
  mutate: (courseId: string) => void;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { courseId } = useParams() as { courseId: string };

  const { mutate, isLoading } = useMutation(
    (courseId: string) => postEnrollmentApproval(courseId),
    {
      onSettled: () => {
        queryClient.invalidateQueries([queryKeys.course, courseId]);
        queryClient.invalidateQueries([queryKeys.catalog]);
        generalNotification("success", t("courses.courseApproval.requestedSuccessfully"));
      },
    },
  );

  return { mutate, isLoading };
};

// Hook for Canceling Enrollment Approval
export const useCancelEnrollmentApproval = (): {
  mutate: (courseId: string) => void;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { courseId } = useParams() as { courseId: string };

  const { mutate, isLoading } = useMutation(
    (courseId: string) => deleteEnrollmentApproval(courseId),
    {
      onSettled: () => {
        queryClient.invalidateQueries([queryKeys.course, courseId]);
        queryClient.invalidateQueries([queryKeys.catalog]);
        generalNotification("success", t("courses.courseApproval.canceledSuccessfully"));
      },
    },
  );

  return { mutate, isLoading };
};
