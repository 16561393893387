import { css } from "@emotion/react";

export const termsModalFooter = () => css`
  .terms-footer {
    button {
      margin-inline-end: 1rem;

      &:last-of-type {
        margin-inline-end: 0;
      }
    }
  }
`;

export const termsModalBody = () => css`
  .terms-body {
    max-height: 30rem;
    overflow-y: auto;
    height: calc(70vh - 150px);
  }
`;
