import React, { ReactNode } from "react";
import { SerializedStyles } from "@emotion/react";
import classNames from "classnames";
import { Text } from "@epignosis_llc/gnosis";
import { settingCard } from "./styles";
import LockedFeatureIcon from "@components/LockedFeature/LockedFeatureIcon";

type SettingCardProps = {
  title: string;
  placement?: string;
  lockedFeature?: boolean;
  description?: ReactNode;
  hasErrors?: boolean;
  children?: ReactNode;
  withSpaceLeft?: boolean;
  testId?: string;
  lockedFeatureTooltip?: ReactNode | string;
};

const SettingCard = ({
  title,
  description,
  hasErrors = false,
  placement = "center",
  lockedFeature = false,
  lockedFeatureTooltip,
  children,
  withSpaceLeft,
  testId,
}: SettingCardProps): JSX.Element => {
  const placementPosition = hasErrors ? "start" : placement;
  return (
    <div css={(theme): SerializedStyles => settingCard(theme, { placementPosition })}>
      <div className="settings-content">
        <Text id={testId} as="div" fontSize="sm" className="settings-heading" weight="700">
          {title}
          {lockedFeature && <LockedFeatureIcon tooltipContent={lockedFeatureTooltip} />}
        </Text>
        {description &&
          (typeof description === "string" ? (
            <Text
              as="div"
              fontSize="sm"
              className="settings-description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          ) : (
            <div className="settings-description">{description}</div>
          ))}
      </div>
      <div className="setting-actions" data-testid={testId}>
        <div className={classNames({ "space-left": withSpaceLeft })}>{children}</div>
      </div>
    </div>
  );
};

export default SettingCard;
