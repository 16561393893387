import create from "zustand";
import {
  DomainSettings,
  UserProfile,
  GamificationSettings,
  SsoDomainSettings,
  SocialDomainSettings,
  UserStatistics,
  Announcement,
  DomainTerms,
  UserIntegrations,
} from "types/entities";
import { CatalogSettings } from "types/entities/Catalog";
import { NextStepsSubOptions } from "@views/Onboarding/constants";
import { SkillsSettings } from "@views/Skills/types";

export type ConfigurationState = {
  domainSettings: DomainSettings | null;
  setDomainSettings: (domainSettings: DomainSettings) => void;
  domainTerms: DomainTerms | null;
  setDomainTerms: (domainTerms: DomainTerms) => void;
  ssoDomainSettings: SsoDomainSettings | null;
  setSsoDomainSettings: (ssoDomainSettings: SsoDomainSettings) => void;
  socialDomainSettings: SocialDomainSettings[] | null;
  setSocialDomainSettings: (socialDomainSettings: SocialDomainSettings[]) => void;
  userProfileData: UserProfile | null;
  setUserProfile: (userProfileData: UserProfile | null) => void;
  gamificationSettings: GamificationSettings | null;
  setGamificationSettings: (gamificationSettings: GamificationSettings | null) => void;
  userStatistics: UserStatistics | null;
  setUserStatistics: (userStatistics: UserStatistics | null) => void;
  catalogSettings: CatalogSettings | null;
  setCatalogSettings: (catalogSettings: CatalogSettings) => void;
  announcements: Announcement[] | null;
  setAnnouncements: (announcements: Announcement[]) => void;
  userIntegrations: UserIntegrations | null;
  setUserIntegrations: (userIntegrations: UserIntegrations) => void;
  onboardingStep: NextStepsSubOptions | null;
  setOnboardingStep: (onboardingStep: NextStepsSubOptions | null) => void;
  skillsSettings: SkillsSettings | null;
  setSkillsSettings: (skillsSettings: SkillsSettings | null) => void;
  isEditMode: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  isAddWidgetMode: boolean;
  setIsAddWidgetMode: (isAddWidgetMode: boolean) => void;
  requiredCustomFields: boolean;
  setRequiredCustomFields: (requiredCustomFields: boolean) => void;
};

const useConfigurationStore = create<ConfigurationState>((set) => ({
  domainSettings: null,
  setDomainSettings: (newDomainSettings: DomainSettings): void => {
    set((state) => ({ ...state, domainSettings: newDomainSettings }));
  },
  domainTerms: null,
  setDomainTerms: (newDomainTerms: DomainTerms): void => {
    set((state) => ({ ...state, domainTerms: newDomainTerms }));
  },
  ssoDomainSettings: null,
  setSsoDomainSettings: (newSsoDomainSettings: SsoDomainSettings): void => {
    set((state) => ({ ...state, ssoDomainSettings: newSsoDomainSettings }));
  },
  socialDomainSettings: null,
  setSocialDomainSettings: (newSocialDomainSettings: SocialDomainSettings[]): void => {
    set((state) => ({ ...state, socialDomainSettings: newSocialDomainSettings }));
  },
  userProfileData: null,
  setUserProfile: (newUserProfileData: UserProfile | null): void => {
    set((state) => ({ ...state, userProfileData: newUserProfileData }));
  },
  gamificationSettings: null,
  setGamificationSettings: (newGamificationSettings: GamificationSettings | null): void => {
    set((state) => ({ ...state, gamificationSettings: newGamificationSettings }));
  },
  userStatistics: null,
  setUserStatistics: (newUserStatistics: UserStatistics | null): void => {
    set((state) => ({ ...state, userStatistics: newUserStatistics }));
  },
  catalogSettings: null,
  setCatalogSettings: (newCatalogSettings: CatalogSettings): void => {
    set((state) => ({ ...state, catalogSettings: newCatalogSettings }));
  },
  announcements: null,
  setAnnouncements: (newAnnouncements: Announcement[]): void => {
    set((state) => ({ ...state, announcements: newAnnouncements }));
  },
  userIntegrations: null,
  setUserIntegrations: (newUserIntegrations: UserIntegrations): void => {
    set((state) => ({ ...state, userIntegrations: newUserIntegrations }));
  },
  onboardingStep: null,
  setOnboardingStep: (newOnboardingStep: NextStepsSubOptions | null): void => {
    set((state) => ({ ...state, onboardingStep: newOnboardingStep }));
  },
  skillsSettings: null,
  setSkillsSettings: (newSkillsSettings: SkillsSettings | null): void => {
    set((state) => ({ ...state, skillsSettings: newSkillsSettings }));
  },
  isEditMode: false,
  setIsEditMode: (isEditMode: boolean): void => {
    set((state) => ({ ...state, isEditMode: isEditMode }));
  },
  isAddWidgetMode: false,
  setIsAddWidgetMode: (isAddWidgetMode: boolean): void => {
    set((state) => ({ ...state, isAddWidgetMode: isAddWidgetMode }));
  },
  requiredCustomFields: false,
  setRequiredCustomFields: (newRequiredCustomFields: boolean): void => {
    set((state) => ({ ...state, requiredCustomFields: newRequiredCustomFields }));
  },
}));

export default useConfigurationStore;
