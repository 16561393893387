import { userHasRequiredPermissions } from "./index";
import useConfigurationStore from "@stores/configuration";
import authService from "@utils/services/AuthService";

// Map with all the available permissions
export const accountPermissions = {
  read: "account.read",
  update: "account.update",
  domain: "account.domain",
  subscription: "account.subscription",
};

const { read, update, domain, subscription } = accountPermissions;
const { getState } = useConfigurationStore;

export default {
  /**
   * Permission => message.read
   * message.read is required in order to have all the other messages related permissions
   *
   *  Allowed actions:
   *  GET recipients, singleMessage, unread, inbox, sent
   *  POST attachment, reply, read
   *  PUT unread
   *  DELETE delete
   *
   * @returns boolean
   */
  canAccessAccount: (): boolean => {
    // Temporarily we give admin permission to learner when comes to account permissions
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([read], getState().userProfileData?.permissions[userRole]);
  },
  /**
   * Permission => message.create
   *
   *  Allowed actions:
   *  POST messages, forward
   *
   * @returns boolean
   */
  canUpdateAccount: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [read, update],
      getState().userProfileData?.permissions[userRole],
    );
  },

  /**
   * Permission => message.create
   *
   *  Allowed actions:
   *  POST messages, forward
   *
   * @returns boolean
   */
  canUpdateDomain: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([domain], getState().userProfileData?.permissions[userRole]);
  },

  /**
   * Permission => account.subscription
   *
   *  Allowed actions:
   *  GET talentlibrary/courses
   *
   * @returns boolean
   */
  canAccessStoreWithSubscription: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [subscription],
      getState().userProfileData?.permissions[userRole],
    );
  },
  /**
   * Permission => account.subscription
   *
   *  Allowed actions:
   *  Subscription page
   *
   * @returns boolean
   */
  canAccessSubscription: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [subscription],
      getState().userProfileData?.permissions[userRole],
    );
  },
};
