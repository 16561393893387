import { palletColorsGenerator } from "../utils";
import { COLORS } from "../constants";
import { getGnosisTheme } from "./gnosis/";
import { getPlusTheme } from "./plus";

export const THEME_COLOURS = {
  ...COLORS,
};

const colors = palletColorsGenerator(THEME_COLOURS);
const defaultBlue = { ...getGnosisTheme(colors), ...getPlusTheme(colors) };

export type CustomTheme = typeof defaultBlue;

export default defaultBlue;
