import { css } from "@emotion/react";

export const courseUsersTableContainer = (canViewUser) => css`
  .link {
    cursor: ${!canViewUser ? "default" : "pointer"} !important;
  }

  .no-data-button-container {
    text-align: end;
  }

  .empty-cell {
    text-align: start;
  }

  .date-button {
    cursor: default !important;
    padding: 0;
    margin-inline-start: -5px;
  }

  .date-button-text {
    color: initial;
  }

  .progress-cell {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .user-role-text {
    padding: 0 0.75rem;
    cursor: default;
  }

  .user-role-select {
    border: none;
    cursor: pointer;
    max-width: 10rem;
    min-width: 9rem;
  }

  .select-input-wrapper {
    background-color: unset !important;
  }

  // This is the React-select control container that should have transparent background and on hover it should be white.
  // This functionality might be moved to the design system in the future if we need this coloring in another table.
  .control-md {
    background: transparent;

    &:hover {
      background-color: #fff;
    }

    &:not(&:hover) {
      border: none;
    }
  }

  .header-cell {
    min-width: 10rem;
  }

  .header-cell.score,
  .header-cell.training_time {
    min-width: 6rem;
  }

  .header-cell.role_in_course,
  .header-cell.formatted_name {
    min-width: 12rem;
  }

  .header-cell.role_in_course {
    padding-left: 2rem;
  }

  .user-actions-btns {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .requests-btn {
    .info-container {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
`;

export const courseUsersActions = (theme) => css`
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  .input-wrapper,
  .select-input-wrapper {
    min-width: 250px;
  }

  .filter-button {
    border: none;
    padding: 0;
  }

  .active-filters {
    display: flex;
    gap: 10px;
  }

  .active-chip {
    background-color: ${theme.chip.lightestBlue}!important;
  }
`;

export const expirationDateWrapper = (
  { icon: { danger, success } },
  { hasExpired, inProgress },
) => css`
  display: flex;
  align-items: center;

  svg {
    color: ${hasExpired ? danger : inProgress ? "inherit" : success};
  }

  .expiration-date-icon {
    line-height: 0;
  }

  .empty-cell {
    margin-inline-start: 0.5rem;
  }
`;
