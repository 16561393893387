import React, { FC, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { LazyMotion, domAnimation } from "framer-motion";
import { Drawer, Loader } from "@epignosis_llc/gnosis";
import { Helmet, HelmetProvider } from "react-helmet-async";

import CustomHomePageLayoutStyles from "./styles";

import { ScreenSizeAlert } from "@components/ReusableComponents";
import { useScreenSizeAlert, useApplyTranslations } from "@hooks";
import userRoles from "@constants/userRoles";

const CustomHomePageLayout: FC = () => {
  const shouldShowScreenSizeAlert = useScreenSizeAlert([userRoles.ADMINISTRATOR]);
  const { t } = useApplyTranslations();

  return (
    <HelmetProvider>
      <Helmet>
        <title> {t("customHomePage.addMenu")}</title>
      </Helmet>
      <LazyMotion features={domAnimation} strict>
        <Suspense fallback={<Loader fullScreen />}>
          <main css={CustomHomePageLayoutStyles} role="main">
            <LazyMotion features={domAnimation} strict>
              <Drawer.Root />
            </LazyMotion>
            <Suspense fallback={<Loader fullScreen />}>
              {shouldShowScreenSizeAlert ? <ScreenSizeAlert /> : <Outlet />}
            </Suspense>
          </main>
        </Suspense>
      </LazyMotion>
    </HelmetProvider>
  );
};

export default CustomHomePageLayout;
