import { userHasRequiredPermissions } from "./index";
import { useConfigurationStore } from "@stores";
import authService from "@utils/services/AuthService";

// Map with all the available permissions
export const usersPermissions = {
  read: "users.read",
  update: "user.update",
  create: "user.create",
  delete: "user.delete",
  reports: "reports.user",
};

const { read, update, reports, delete: deleteUser, create } = usersPermissions;
const { getState } = useConfigurationStore;

export default {
  /**
   * Permission => users.read
   *
   *  Allowed actions:
   *  GET courses.users
   *
   * @returns boolean
   */
  canAccessUsers: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([read], getState().userProfileData?.permissions[userRole]);
  },
  /**
   * Permission => users.update
   *
   *  Allowed actions:
   *  PATCH courses.user.edit
   *
   * @returns boolean
   */
  canUpdateUsers: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([update], getState().userProfileData?.permissions[userRole]);
  },

  /**
   * Permission => users.delete
   *
   *  Allowed actions:
   *
   *
   * @returns boolean
   */
  canDeleteUsers: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [deleteUser],
      getState().userProfileData?.permissions[userRole],
    );
  },

  /**
   * Permission => user.create
   * user.create is required in order to have all the other user related permissions
   *
   *  Allowed actions:
   *  POST user.create
   *
   * @returns boolean
   */
  canCreateUser: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([create], getState().userProfileData?.permissions[userRole]);
  },

  canGetReportUsers: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([reports], getState().userProfileData?.permissions[userRole]);
  },
};
