import { AIGenerateTextType } from "@components/FormElements/Editor/types";
import PubSub from "pubsub-js";
import { AI_EDITOR_ACTIONS } from "@components/FormElements/Editor/AI/AIActions";

export function performEditorAIAction(
  editorId: string,
  type: AIGenerateTextType,
  prompt?: string,
): void {
  PubSub.publish(AI_EDITOR_ACTIONS, {
    editorId: editorId,
    prompt: prompt,
    type: type,
  });
}
