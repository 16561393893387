import { css } from "@emotion/react";

export const progressBarContainer = ({ animatedProgressBar }, borderRadius, shouldAnimate) => css`
  .bar-wrapper {
    width: 100%;
    background-color: ${animatedProgressBar.barBackground};
    border-radius: ${borderRadius};
  }

  .bar {
    height: 1rem;
    background: ${shouldAnimate
      ? animatedProgressBar.animationBackground
      : animatedProgressBar.background};
    animation: ${shouldAnimate ? "gradient 2s infinite linear forwards" : "none"};

    background-size: 200% auto;
    background-position: 0 100%;
  }

  @keyframes gradient {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
`;
