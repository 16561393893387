import { yup } from "./validation";

export const DiscussionFormValidationSchema = yup.object().shape({
  title: yup.string().trim().required().max(255),
  description: yup.string().trim().required().max(5000),
  visibility: yup.object().shape({
    type: yup.string().trim().required(),
    limited_to: yup
      .object()
      .nullable()
      .when("type", {
        is: (value: string) => value === "limited",
        then: yup.object().nullable().required(),
      }),
  }),
});

export const DiscussionReplyFormValidationSchema = yup.object().shape({
  text: yup.string().trim().required().max(5000),
});
