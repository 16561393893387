import { ENDPOINTS } from "@api/endpoints";
import HttpClient from "@api/HttpClient";
import { UserCoursesRes, TableCSVExportRes } from "types/responses";

export const getUserCourses = async (userId: string, queryStr = ""): Promise<UserCoursesRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.users.courses(userId)}${queryStr}`);
  return res.data;
};

export const getAvailableCourses = async (
  userId: string,
  queryStr = "",
): Promise<UserCoursesRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.users.availableCourses(userId)}${queryStr}`);
  return res.data;
};

export const enrollUserToCourse = async (courseId: string, userId: string): Promise<void> => {
  await HttpClient.put(ENDPOINTS.courses.user.edit(courseId, userId));
};

export const userCoursesExport = async (
  userId: string,
  queryStr = "",
): Promise<TableCSVExportRes> => {
  const res = await HttpClient.post(`${ENDPOINTS.tableExports.userCourses(userId)}${queryStr}`);
  return res.data;
};
