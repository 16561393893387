import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const pageContainer = ({ signInPage }) => css`
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  background-color: ${signInPage.background};

  header {
    display: flex;
    justify-content: center;
    background-color: ${signInPage.headerBackground};
    width: 100%;
    align-items: center;
    height: 4rem;
    margin-bottom: 1.5rem;
    box-shadow: 0px 2px 3px ${signInPage.boxShadow};

    img {
      max-height: 38px;
    }

    ${mq["lg"]} {
      padding-inline-start: 2.5rem;
      justify-content: flex-start;
    }
  }

  .content-wrapper {
    max-width: 500px;
    margin: auto;

    .form-wrapper {
      background: transparent;
      overflow-y: auto;
      display: flex;
      justify-content: center;
      padding: 1rem;
    }

    .announcements-wrapper {
      padding: 1rem 1rem 3rem;
    }
  }
`;

export const signUpFormContainer = ({ signInPage }) => css`
  width: 100%;
  max-width: 500px;
  background: ${signInPage.formBackground};
  border-radius: 30px;

  h2 {
    text-align: center;
    padding: 1.25rem;
  }

  .form-content {
    display: flex;
    flex-direction: column;
    padding: 2.5rem 2.75rem;
    margin: auto;
    text-align: center;

    .icon {
      padding-bottom: 1.25rem;

      svg {
        height: 10rem;
      }
    }
  }
`;
