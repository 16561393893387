export type PdfViewerState = {
  numPages: number;
  pageNumber: number;
  isLoading: boolean;
  pageWidth: number;
  pageHeight?: number;
  scale: number;
};

export enum PdfViewerStateActions {
  SetNumPages = "SetNumPages",
  SetPageNumber = "setPageNumber",
  SetIsLoading = "setIsLoading",
  SetPageDimensions = "setPageDimensions",
  ZoomIn = "zoomIn",
  ZoomOut = "zoomOut",
}

export type PdfViewerStateAction =
  | { type: PdfViewerStateActions.SetNumPages; payload: { numPages: number } }
  | { type: PdfViewerStateActions.SetPageNumber; payload: { pageNumber: number } }
  | { type: PdfViewerStateActions.SetIsLoading; payload: { isLoading: boolean } }
  | {
      type: PdfViewerStateActions.SetPageDimensions;
      payload: { pageWidth: number; pageHeight?: number };
    }
  | { type: PdfViewerStateActions.ZoomIn }
  | { type: PdfViewerStateActions.ZoomOut };
