import { css } from "@emotion/react";

export const ActionGhostButtonStyles = css`
  &:not(.disabled) {
    svg {
      color: black;
    }
  }

  &:hover {
    svg {
      color: inherit;
    }
  }
`;
