import { MAX_DELAY } from "@constants/tableExports";

/**
 * Calculates an exponential backoff delay with a maximum cap.
 * Formula: Math.min(Math.pow(2, exportTaskPingCount) * 100 + 500, 4000)
 *
 * @param {number} exportTaskPingCount - How many times we pinged to get task info.
 * @returns {number} - The calculated delay in milliseconds.
 */
export const calculateExponentialBackoff = (exportTaskPingCount: number): number => {
  // Calculate an exponential backoff delay
  const baseDelay = Math.pow(2, exportTaskPingCount) * 100 + 500;
  // The Math.pow(2, exportTaskPingCount) part creates an exponential growth factor,
  // And multiplying by 100 provides a scaling factor to control the rate of growth.
  // Adding 500 serves as an additional fixed term in the delay calculation.

  // Set a maximum cap for the delay
  // Return the minimum value between the calculated delay and the maximum cap.
  // This ensures that the final delay does not exceed the specified maximum value.

  return Math.min(baseDelay, MAX_DELAY);
};

export const getCSVFile = (fileUrl: string): void => {
  const a = document.createElement("a");

  a.href = fileUrl;
  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
  window.URL.revokeObjectURL(fileUrl);
};
