import { PalletThemeColors, getContrastColor, getProtectedColor } from "@styles/utils";
import { ThemePlusTypes } from "../../types";
import Color from "color";

export default (colors: PalletThemeColors): ThemePlusTypes["units"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);
  const unitsCreationMenuBackgroundColor = protectColorContrast("darker", colors.primary.base);
  const uploadOptionsBackgroundHoverColor = protectColorContrast("darkest", colors.primary.light);

  return {
    assignment: {
      color: colors.primary.base,
      durationColor: colors.white,
      durationColorScreen: colors.black,
      grade: colors.green.base,
      hover: colors.primary.lighter,
      infoTag: {
        color: colors.black,
        expiredBackground: Color(colors.red.light).alpha(0.15).string(),
      },
      recordingButton: {
        color: colors.white,
        background: colors.orange.base,
      },
      textColor: colors.secondary.darker,
      reply: {
        backgroundColor: colors.secondary.lighter,
      },
    },
    completionScreen: {
      subtitleColor: colors.secondary.base,
      background: colors.secondary.lighter, // Check
      success: {
        backgroundColor: Color(colors.green.light).alpha(0.15).string(),
        textColor: colors.black,
      },
      fail: {
        backgroundColor: Color(colors.red.light).alpha(0.15).string(),
        textColor: colors.black,
      },
      modal: {
        textColor: colors.white,
      },
    },
    edit: {
      borderColor: colors.secondary.lighter,
      secondaryTextColor: colors.secondary.base,
      uploadOptions: {
        backgroundColor: colors.secondary.lighter,
        color: colors.secondary.darker,
        hoverBackgroundColor: uploadOptionsBackgroundHoverColor,
        hoverColor: getContrastColor(uploadOptionsBackgroundHoverColor),
      },
      versionHistory: {
        backgroundColor: colors.secondary.lighter,
        currentVersion: colors.secondary.base,
      },
    },
    ilt: {
      borderColor: colors.secondary.lighter,
      boxShadowColor: "rgba(0, 0, 0, 0.16)",
      startedColor: colors.primary.base,
      textColor: colors.secondary.darker,
    },
    popUp: {
      borderColor: colors.secondary.lighter,
      subtitleColor: colors.secondary.darker,
    },
    scorm: {
      attempt: {
        taglineColor: colors.secondary.base,
        rawDataBackground: colors.secondary.lighter,
      },
      analysis: {
        background: Color(colors.primary.lightest).alpha(0.25).string(),
        inputBorder: colors.primary.lightest,
        textColor: colors.secondary.base,
      },
    },
    startScreen: {
      buttonTextColor: colors.secondary.darker,
    },
    survey: {
      background: colors.secondary.lighter,
      border: colors.primary.base,
      disabled: colors.secondary.base,
      hoverColor: Color(colors.primary.lightest).alpha(0.25).string(),
      textColor: colors.black,
      textColorDisabled: colors.secondary.darker,
    },
    test: {
      analysis: {
        color: colors.secondary.base,
        iconColor: colors.primary.base,
        borderColor: colors.secondary.light,
      },
      attempt: {
        correct: colors.green.base,
        failed: colors.red.base,
        titleColor: colors.secondary.base,
        tagColor: colors.secondary.lighter,
        border: colors.secondary.light,
      },
      borderNotAnswered: colors.black,
      disabledColor: colors.secondary.darker,
      edit: {
        totalQuestionsColor: colors.secondary.darker,
      },
      errorColor: colors.red.base,
      errorColorLight: colors.red.lightest,
      hoverColor: Color(colors.primary.lightest).alpha(0.25).string(),
      matchPairs: {
        answeredBorder: "#d4d4d4",
        answeredBackground: "#e6f2ff",
        background: colors.white,
        backgroundActive: "#1978F3",
        borderColor: colors.black,
        borderHoverColor: colors.primary.base,
        color: colors.primary.base,
        colorActive: colors.white,
        hoverBackground: "#E6F2FF",
      },
      notAnsweredBackground: colors.secondary.lighter,
      notAnsweredColor: colors.black,
      previewShadowColor: colors.secondary.light,
      questionBackground: colors.secondary.lighter,
      questionBorder: colors.secondary.lighter,
      results: {
        success: {
          backgroundColor: Color(colors.green.light).alpha(0.15).string(),
          color: colors.green.base,
          textColor: colors.black,
        },
        fail: {
          textColor: colors.black,
          color: colors.red.base,
          backgroundColor: Color(colors.red.light).alpha(0.15).string(),
        },
      },
      successColor: colors.green.base,
      successColorLight: colors.green.lightest,
    },
    unitsCreationMenu: {
      background: colors.white,
      description: colors.secondary.base,
      hover: colors.secondary.lighter,
      tagBackground: unitsCreationMenuBackgroundColor,
      textColor: getContrastColor(unitsCreationMenuBackgroundColor),
    },
  };
};
