import { getLocale } from "@utils/helpers";
import {
  AiJobsStatus,
  AiJobStatusType,
  type AssignSkillsToUsersPayload,
  DurationUnit,
  RecommendSkillsToUsersPayload,
  Skill,
  SkillCourse,
  SkillLevel,
  UpgradeSkillsOfUsersPayload,
} from "./types";
import { images } from "@constants/assets";
import { Match } from "./MySkills/MyJobRole/constants";
import { MAX_ALLOWED_SKILLS_USERS_ASSOCIATIONS } from "./constants";

export const getFormattedPercentage = (num: number): string => {
  if (num < 0 || num > 1) {
    return num.toString();
  }

  const localeCode = getLocale().code;
  const options: Intl.NumberFormatOptions = {
    style: "percent",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  };
  return new Intl.NumberFormat(localeCode, options).format(num);
};

export const getCourseCoverImage = (course: SkillCourse): string => {
  return course.cover_image?.default ?? images.cover;
};

export const getCourseCoverImageAlt = (course: SkillCourse): string => {
  return `${course.name} cover image.`;
};

export const getSkillCoverImage = (skill: Skill): string => {
  return skill.cover_image?.default ?? getDefaultCoverImageUrl();
};

export const getSkillCoverImageAlt = (skill: Skill): string => {
  return `${skill.name} cover image.`;
};

export const getSkillsUsersAssignPayload = (
  userIds: number[],
  skillIds: number[],
): AssignSkillsToUsersPayload => {
  return userIds.flatMap((userId) =>
    skillIds.map((skillId) => ({
      user_id: userId,
      skill_id: skillId,
    })),
  );
};

export const getSkillsUsersRecommendPayload = (
  userIds: number[],
  skillIds: number[],
): RecommendSkillsToUsersPayload => getSkillsUsersAssignPayload(userIds, skillIds);

export const getUpgradeSkillsOfUsersPayload = (
  userIds: number[],
  skillIds: number[],
): UpgradeSkillsOfUsersPayload => getSkillsUsersAssignPayload(userIds, skillIds);

/**
 * Returns a literal key according to the given match (0-100).
 * @param match - The match score to evaluate.
 * @returns The corresponding string key.
 */
export const getStringKeyForMatch = (match: number): string => {
  if (match > Match.Excellent) {
    return "skills.match.excellent";
  } else if (match > Match.Good) {
    return "skills.match.good";
  } else if (match > Match.Fair) {
    return "skills.match.fair";
  } else {
    return "skills.match.poor";
  }
};

export const floatToPercentage = (progress = 0): number => Math.trunc(progress * 100);

export const getStatusFromJobs = (
  type: string,
  jobs: AiJobsStatus,
): AiJobStatusType | undefined => {
  return jobs.find((job) => job.type === type)?.status;
};

export const convertNumberToPercentage = (num: number): number => {
  return num / 100;
};

export const convertPercentageToInt = (num: number): number => {
  return Math.floor(num * 100);
};

export const getDurationInSeconds = (duration: number, durationUnit: DurationUnit): number => {
  switch (durationUnit) {
    case DurationUnit.Minutes:
      return duration * 60;
    case DurationUnit.Hours:
      return duration * 3600;
    case DurationUnit.Days:
      return duration * 86400;
  }
};

export const convertDurationToDurationUnit = (
  duration: number,
  durationUnit: DurationUnit,
): number => {
  switch (durationUnit) {
    case DurationUnit.Minutes:
      return Math.max(duration, 60) / 60;
    case DurationUnit.Hours:
      return Math.max(duration, 3600) / 3600;
    case DurationUnit.Days:
      return Math.max(duration, 86400) / 86400;
  }
};

export const getUnitOfDuration = (duration: number): DurationUnit => {
  if (duration > 86400) {
    return DurationUnit.Days;
  } else if (duration > 3600) {
    return DurationUnit.Hours;
  } else {
    return DurationUnit.Minutes;
  }
};

export const getDefaultCoverImageUrl = (): string => {
  return images.cover;
};

export const buildSuggestionsSearchQuery = (search: string): string => {
  let query = "";
  if (search) {
    query += `&filter[name][like]=${encodeURIComponent(search)}`;
  }
  return query;
};

export const getMaxEarnedLevel = (levels: SkillLevel[]): SkillLevel | null => {
  const earnedLevels = levels
    .filter((level) => level.earned_on !== null) // Keep earned levels
    .sort((a, b) => b.id - a.id); // Sort (descending)

  return earnedLevels.length > 0 ? earnedLevels[0] : null;
};

export const getMaxAllowedSelectedSkills = (selectedUsers: number): number => {
  if (selectedUsers <= 0 || selectedUsers == 1) {
    return MAX_ALLOWED_SKILLS_USERS_ASSOCIATIONS;
  }

  if (selectedUsers == MAX_ALLOWED_SKILLS_USERS_ASSOCIATIONS) {
    return 1;
  }

  return Math.floor(MAX_ALLOWED_SKILLS_USERS_ASSOCIATIONS / selectedUsers);
};

export const getMaxAllowedSelectedUsers = (selectedSkills: number): number => {
  if (selectedSkills <= 0 || selectedSkills == 1) {
    return MAX_ALLOWED_SKILLS_USERS_ASSOCIATIONS;
  }

  if (selectedSkills >= MAX_ALLOWED_SKILLS_USERS_ASSOCIATIONS) {
    return 1;
  }

  return Math.floor(MAX_ALLOWED_SKILLS_USERS_ASSOCIATIONS / selectedSkills);
};

export const encodeToURLSafeBase64 = (text: string): string => {
  const encoder = new TextEncoder();
  const byteArray = encoder.encode(text);
  const base64 = btoa(String.fromCharCode(...byteArray));

  // Make url-safe.
  return base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
};

export function sanitizeRoleNameForURL(roleName: string): string {
  const urlSafeBase64 = encodeToURLSafeBase64(roleName);
  return encodeURIComponent(urlSafeBase64);
}
