import { css } from "@emotion/react";

export const tocContainerStyles = ({ unitsToC }, { isOpen }) => css`
  width: 100%;
  height: 100vh;
  border-inline-end: ${isOpen ? "2px solid" : "none"};
  border-color: ${unitsToC.borderColor};
  background: ${unitsToC.backgroundColor};
`;

export const mobileTocContainerStyles = () => css`
  position: relative;
`;

export const mobileTocDrawerBodyContainer = ({ unitsToC }) => css`
  background: ${unitsToC.backgroundColor};
  height: 100%;
`;

export const tocStyles = () => css`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const tocTriggerContainerStyles = ({ unitsToC }) => css`
  position: relative;
  background-color: ${unitsToC.top.backgroundColor};
  height: 40px;
  border-radius: 5px;
  visibility: visible;

  &.closed {
    position: absolute;
    top: 0.5rem;
    inset-inline-start: 1rem;
    z-index: 9999;
  }
`;
