export type CertificateTemplate = {
  id: number;
  name: string;
};

export type CertificateTemplatePreview = {
  preview_url: string;
};

export type CertificatePreview = {
  preview_url: string;
};

export enum CertificateDuration {
  Forever = "forever",
  OneMonth = "1_month",
  ThreeMonths = "3_months",
  SixMonths = "6_months",
  OneYear = "1_year",
  TwoYears = "2_years",
  ThreeYears = "3_years",
  Custom = "custom",
  Date = "date",
}

export enum CertificateReassignWhen {
  OnExpiration = "on_expiration",
  OneWeekBeforeExpiration = "one_week_before_expiration",
  OneMonthBeforeExpiration = "one_month_before_expiration",
}
