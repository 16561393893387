import { useState } from "react";
import { useDeleteTaskMutation, useGetTaskMutation } from "@hooks";
import { TableCSVExportRes } from "types/responses";
import { useMutation } from "react-query";

type ExportToCSVProps = {
  queryKey: string;
  exportId?: string;
  searchQuery: string;
  handleExport(exportId?: string, searchQuery?: string): Promise<TableCSVExportRes>;
};

type ExportToCSVReturn = {
  exportProgress: number;
  isExportModalOpen: boolean;
  deleteTaskMutationLoading: boolean;
  handleExportToCSV: () => void;
  handleExportModalClose: () => void;
};
const useExportToCSV = ({
  queryKey,
  exportId,
  searchQuery,
  handleExport,
}: ExportToCSVProps): ExportToCSVReturn => {
  const [exportProgress, setExportProgress] = useState(0);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [taskId, setTaskId] = useState<string | null>(null);

  const { getTaskMutation, resetRefs } = useGetTaskMutation(
    setIsExportModalOpen,
    setExportProgress,
  );

  const { mutate: handleExportToCSV } = useMutation<TableCSVExportRes>(
    [queryKey],
    () => (exportId ? handleExport(exportId, searchQuery) : handleExport(searchQuery)),
    {
      onSuccess: (res) => {
        const { id } = res._data;
        setIsExportModalOpen(true);
        setTaskId(id);
        getTaskMutation(id);
      },
    },
  );

  const { mutate: deleteTaskMutation, isLoading: deleteTaskMutationLoading } =
    useDeleteTaskMutation({
      setIsExportModalOpen,
      setExportProgress,
      resetRefs,
    });

  const handleExportModalClose = (): void => {
    if (taskId) {
      deleteTaskMutation(taskId);
    }
  };

  return {
    exportProgress,
    isExportModalOpen,
    deleteTaskMutationLoading,
    handleExportToCSV,
    handleExportModalClose,
  };
};

export default useExportToCSV;
