import { Column, TableProps } from "@epignosis_llc/gnosis";
import { QueryFilter } from "types/common";
import { t } from "@utils/i18n";

export const SORTING: TableProps["sorting"] = { column: "name", isDescending: false };
export const DEFAULT_FILTERS: QueryFilter[] = [];

export const getColumns = (): Column[] => {
  return [
    {
      accessor: "name",
      cell: t("general.name"),
      classNames: ["name"],
    },
    {
      accessor: "created_on",
      cell: t("general.date"),
      classNames: ["creation_date"],
    },
  ];
};

export const sortingFilters = ["name", "created_on"];
