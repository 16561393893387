import React, { FC, memo } from "react";
import { Checkbox } from "@epignosis_llc/gnosis";
import { DraggableProvided } from "@hello-pangea/dnd";
import { unitIconWrapperStyles } from "./styles";
import { useApplyTranslations } from "@hooks";

type UnitListIconProps = {
  provided: DraggableProvided;
  unitId: string;
  name: string;
  isSelectedSectionUnit: boolean;
  isSectionReorderUnit: boolean;
  unitIcon: JSX.Element | null;
  onClick: () => void;
};

const UnitListIcon: FC<UnitListIconProps> = ({
  provided,
  unitId,
  name,
  isSelectedSectionUnit,
  isSectionReorderUnit,
  unitIcon,
  onClick,
}) => {
  const { t } = useApplyTranslations();
  const isDraggable = provided.dragHandleProps;

  return (
    <div css={unitIconWrapperStyles} {...provided.dragHandleProps}>
      {isSelectedSectionUnit ? (
        <Checkbox
          id={unitId}
          name={name ?? ""}
          value={unitId}
          defaultChecked={isSectionReorderUnit}
          containerAttrs={{ className: "unit-reorder-checkbox" }}
          onClick={onClick}
        />
      ) : (
        <>
          {isDraggable ? (
            <div aria-label={t("a11y.courseEdit.unitIcon", { unitName: name })}>{unitIcon}</div>
          ) : (
            unitIcon
          )}
        </>
      )}
    </div>
  );
};

export default memo(UnitListIcon);
