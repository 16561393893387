import { css } from "@emotion/react";

export const pathListStyles = css`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

export const pathItemStyles = css`
  list-style: none;
  display: flex;
  position: relative;
`;

export const pathSeparatorStyles = ({ courseEdit: { pathItem } }, { isEmpty }) => css`
  display: flex;
  flex-direction: column;
  flex: 0;
  align-items: center;

  .line-dot {
    display: flex;
    align-self: baseline;
    width: 1rem;
    height: 1rem;
    border: 1px solid ${pathItem.dotColor};
    border-radius: 50%;
    background-color: ${isEmpty ? pathItem.emptyDotColor : pathItem.dotBorderColor};
  }

  .line {
    width: 1px;
    background-color: ${pathItem.lineColor};
    flex-grow: 1;
  }
`;

export const pathContentStyles = css`
  flex: 1;
  padding: 0 1rem;
  position: relative;
  top: -0.75rem;
  margin-bottom: 1rem;
`;
