import Color from "color";
import { getProtectedColor, PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["feedbackBox"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);

  return {
    background: protectColorContrast("darker", colors.primary.darker),
    borderColor: colors.secondary.lighter,
    boxShadow: Color(colors.black).alpha(0.16).string(),
    messageColor: colors.secondary.darker,
    overlayBackground: Color(colors.black).alpha(0.5).string(),
  };
};
