import { userHasRequiredPermissions } from "./index";
import { useConfigurationStore } from "@stores";
import authService from "@utils/services/AuthService";

// Map with all the available permissions
export const usersTypesPermissions = {
  create: "usertype.create",
  read: "usertype.read",
  delete: "usertype.delete",
};

const { read, delete: deleteUserType, create } = usersTypesPermissions;
const { getState } = useConfigurationStore;

export default {
  /**
   * Permission => usertype.read
   *
   *  Allowed actions:
   *  GET userTypes
   *
   * @returns boolean
   */
  canAccessUserTypes: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([read], getState().userProfileData?.permissions[userRole]);
  },
  /**
   * Permission => usertype.delete
   *
   *  Allowed actions:
   *  DELETE userTypes
   *
   * @returns boolean
   */
  canDeleteUserTypes: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [deleteUserType],
      getState().userProfileData?.permissions[userRole],
    );
  },
  /**
   * Permission => usertype.create
   *
   *  Allowed actions:
   *  CREATE userTypes
   *
   * @returns boolean
   */
  canCreateUserTypes: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([create], getState().userProfileData?.permissions[userRole]);
  },
};
