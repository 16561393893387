import { PalletThemeColors, getProtectedColor } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["general"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);

  return {
    primary: protectColorContrast("darker", colors.primary.base),
  };
};
