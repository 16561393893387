export enum CustomIcon {
  InsertImage = "insertImages",
  UploadImage = "uploadImage",
  InsertSmartTag = "insertSmartTag",
  MagicWand = "magicWand",
  Sparkles = "sparkles",
}

export enum CustomDropdown {
  InsertImageDropdown = "insertImagesDropdown",
}

export enum CustomButton {
  UploadImageButton = "uploadImageButton",
  InsertSmartTagButton = "insertSmartTagButton",
  AIFeatures = "aiFeatures",
}

export enum CustomQuickInsertButton {
  UploadImageButton = "uploadImageButton",
  InsertSmartTagButton = "insertSmartTagButton",
  WriteAbout = "writeAbout",
}
