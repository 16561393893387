import { DropdownItem } from "@epignosis_llc/gnosis";
import { QueryFilter } from "types/common";

export const getActiveFilters = (
  allFilters: DropdownItem[],
  selectedFilters: QueryFilter[],
): DropdownItem[] => {
  return allFilters.reduce((array, filter): DropdownItem[] => {
    const foundFilter = selectedFilters.find((selectedFilter) => {
      return filter.category === selectedFilter.key && filter.value === selectedFilter.value;
    });

    if (foundFilter) {
      array.push(filter);
    }

    return array;
  }, [] as DropdownItem[]);
};
