export const CONTAINER_STYLES = {
  height: "550px",
  padding: "0.5rem",
  borderRadius: "5px",
};

export const TITLE_MAX_CHARS = 80;
export const CONTENT_MAX_CHARS = 200000;
export const CONTENT_SMALL_MAX_CHARS = 800;
export const PREVIEW_DEFAULT_HEIGHT = 550;

export const UNIT_EDIT_CONTENT_ID = "unit-edit-content";
