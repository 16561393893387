import React from "react";
import { useNavigate } from "react-router-dom";
import { Text, Button } from "@epignosis_llc/gnosis";
import { ScreenSizeAlertSVG } from "@assets/images";
import { ScreenSizeAlertStyles } from "./styles";
import { useApplyTranslations } from "@hooks";

const ScreenSizeAlert = (): JSX.Element => {
  const { t } = useApplyTranslations();
  const navigate = useNavigate();

  return (
    <div css={ScreenSizeAlertStyles}>
      <ScreenSizeAlertSVG />
      <Text fontSize="md" weight="700" as="p">
        {t("screenSizeAlert.title")}
      </Text>
      <Text fontSize="sm" as="span">
        {t("screenSizeAlert.subtitle")}
      </Text>

      <Button
        as="button"
        color="primary"
        variant="ghost"
        className="back-btn"
        onClick={(): void => navigate(-1)}
      >
        {t("screenSizeAlert.goBack")}
      </Button>
    </div>
  );
};

export default ScreenSizeAlert;
