import { PdfViewerState } from "./types";

export const MIN_ZOOM = 0.5;
export const MAX_ZOOM = 3;

export const documentOptions = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
  standardFontDataUrl: "standard_fonts/",
  isEvalSupported: false,
};

export const defaultState: PdfViewerState = {
  numPages: 0,
  pageNumber: 1,
  isLoading: false,
  pageWidth: 0,
  pageHeight: undefined,
  scale: 1,
};
