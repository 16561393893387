import { t } from "@utils/i18n";
import { yup } from "./validation";
import { isValidUrlWithProtocol } from "@utils/helpers";

const AutomationFilterSchema = yup.object().shape({
  branches: yup.array().of(yup.number()).nullable(),
  groups: yup.array().of(yup.number()).nullable(),
  user_types: yup.array().of(yup.number()).nullable(),
});

export const AutomationsSchemas = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(1, () => t("validationMessages.requiredField"))
    .max(100, () => t("validationMessages.maxCharacters", { number: 100 }))
    .required(() => t("validationMessages.requiredField")),
  action: yup
    .number()
    .min(1, () => t("validationMessages.requiredField"))
    .required(),
  hours: yup
    .number()
    .min(1, () => t("validationMessages.minNumber", { number: 1 }))
    .max(100000, () => t("validationMessages.maxNumber", { number: 100000 }))
    .when("action", {
      is: (val: number) => [1, 2, 3, 4, 5, 8, 9, 14, 15, 17, 18].includes(val),
      then: yup
        .number()
        .required()
        .typeError(() => t("validationMessages.requiredField")),
      otherwise: yup.number().nullable().notRequired(),
    }),

  courses_to_assign: yup
    .array()
    .of(yup.number())
    .when("action", {
      is: (val: number) => [1, 2, 3, 4, 5, 6, 7, 9, 12, 14, 15, 16].includes(val),
      then: yup
        .array()
        .of(yup.number())
        .required()
        .typeError(() => t("validationMessages.requiredField")),
      otherwise: yup.array().of(yup.number()).nullable().notRequired(),
    }),

  action_course: yup.number().when("action", {
    is: (val: number) => [1, 2, 3, 4, 5, 7, 10, 11, 12, 13, 14, 15, 18].includes(val),
    then: yup
      .number()
      .required()
      .typeError(() => t("validationMessages.requiredField")),
    otherwise: yup.number().nullable().notRequired(),
  }),

  action_courses: yup
    .array()
    .of(yup.number())
    .when("action", {
      is: (val: number) => val === 6,
      then: yup
        .array()
        .of(yup.number())
        .required()
        .typeError(() => t("validationMessages.requiredField")),
      otherwise: yup.array().of(yup.number()).nullable().notRequired(),
    }),

  url: yup
    .string()
    .max(80, () => t("validationMessages.maxCharacters", { number: 80 }))
    .test(
      "isValidUrl",
      () => t("validationMessages.invalidUrl"),
      (value) => !value || isValidUrlWithProtocol(value),
    )
    .when("action", {
      is: (val: number) => val === 10,
      then: yup
        .string()
        .required()
        .typeError(() => t("validationMessages.requiredField")),
      otherwise: yup.string().nullable().notRequired(),
    }),

  points: yup
    .number()
    .integer()
    .positive()
    .min(1, () => t("validationMessages.minNumber", { number: 1 }))
    .max(9999999999, () => t("validationMessages.maxNumber", { number: 9999999999 }))
    .when("action", {
      is: (val: number) => val === 11,
      then: yup
        .number()
        .required()
        .typeError(() => t("validationMessages.requiredField")),
      otherwise: yup.number().nullable().notRequired(),
    }),

  score_from: yup
    .number()
    .when("action", {
      is: (val: number) => [12, 14].includes(val),
      then: yup
        .number()
        .required()
        .typeError(() => t("validationMessages.requiredField")),
      otherwise: yup.number().nullable().notRequired(),
    })
    .min(0, () => t("validationMessages.validField"))
    .max(100, () => t("validationMessages.validField"))
    .test(
      "is-lower",
      () => t("errors.eventsEngine.automations.scoreLess"),
      function (value) {
        const score_to = this.parent?.score_to;

        if (value === undefined || value === null) {
          return true;
        }

        if (score_to === null || score_to === undefined) {
          return true;
        }

        return value < score_to;
      },
    ),

  score_to: yup
    .number()
    .when("action", {
      is: (val: number) => [12, 14].includes(val),
      then: yup
        .number()
        .required()
        .typeError(() => t("validationMessages.requiredField")),
      otherwise: yup.number().nullable().notRequired(),
    })
    .min(0, () => t("validationMessages.validField"))
    .max(100, () => t("validationMessages.validField"))
    .test(
      "is-greater",
      () => t("errors.eventsEngine.automations.scoreGreater"),
      function (value) {
        const score_from = this.parent?.score_from;

        if (value === undefined || value === null) {
          return true;
        }

        if (score_from === null || score_from === undefined) {
          return true;
        }

        return value > score_from;
      },
    ),

  courses_to_remove: yup
    .array()
    .of(yup.number())
    .when("action", {
      is: (val: number) => val === 13,
      then: yup
        .array()
        .of(yup.number())
        .required()
        .typeError(() => t("validationMessages.requiredField")),
      otherwise: yup.array().of(yup.number()).nullable().notRequired(),
    }),

  level: yup
    .number()
    .min(1, () => t("validationMessages.minNumber", { number: 1 }))
    .max(20, () => t("validationMessages.maxNumber", { number: 20 }))
    .when("action", {
      is: (val: number) => val === 16,
      then: yup
        .number()
        .required()
        .typeError(() => t("validationMessages.requiredField")),
      otherwise: yup.number().nullable().notRequired(),
    }),

  deactivate_or_delete: yup
    .string()
    .oneOf(["deactivate", "delete", null], () => t("validationMessages.requiredField"))
    .when("action", {
      is: (val: number) => val === 8,
      then: yup
        .string()
        .required()
        .typeError(() => t("validationMessages.requiredField")),
      otherwise: yup.string().nullable().notRequired(),
    }),

  reset_and_assign: yup
    .string()
    .oneOf(["reset", "assign", null], () => t("validationMessages.requiredField"))
    .when("action", {
      is: (val: number) => [3, 5, 7].includes(val),
      then: yup
        .string()
        .required()
        .typeError(() => t("validationMessages.requiredField")),
      otherwise: yup.string().nullable().notRequired(),
    }),

  reset_and_remove: yup
    .string()
    .oneOf(["reset", "remove", null], () => t("validationMessages.requiredField"))
    .when("action", {
      is: (val: number) => val === 13,
      then: yup
        .string()
        .required()
        .typeError(() => t("validationMessages.requiredField")),
      otherwise: yup.string().nullable().notRequired(),
    }),

  status: yup.string().trim().required().oneOf(["active", "inactive"]),

  filter_by: AutomationFilterSchema.when("action", {
    is: (val: number) => [1, 6, 8].includes(val),
    then: AutomationFilterSchema.nullable(),
    otherwise: AutomationFilterSchema.nullable().notRequired(),
  }),
});
