import { isEqual, sortBy } from "lodash";
import {
  AIEditorOptions,
  AllAIGenerateTextActions,
  ToolbarButton,
} from "@components/FormElements/Editor/types";
import { CourseFile } from "types/entities";
import { MORE_RICH_BUTTONS, MORE_TEXT_BUTTONS } from "./constants";
import { CustomButton } from "@components/FormElements/Editor";

type courseFileMapping = {
  [key: string]: string;
};

// Return previewable version of provided content
// Find all file ids in content and replace them with the file's url
export const builtContentForPreview = (content: string, courseFiles: CourseFile[]): string => {
  if (courseFiles.length === 0) return content;
  if (!content) return "";

  const regex = /\[File:#([0-9]{1,})#\]/gi;
  // Find all occurrences of files in the given
  const filesOccurrences: string[] = content.match(regex) ?? [];

  // Array with all files found in content
  const filesMappingObj = filesOccurrences.reduce((object, contentFile) => {
    const fileId = contentFile.match(/#(.*?)#/)?.[1];
    const courseFile = courseFiles.find(({ id }) => id === Number(fileId));

    if (!courseFile) return object;

    const { id, url } = courseFile;
    return { ...object, [id]: url };
  }, {} as courseFileMapping);

  // Replace all files occurrences in content with their url
  const newContent = content
    .split(regex)
    .map((str) => getFileUrlFromString(filesMappingObj, str))
    .join("");

  return newContent;
};

const getFileUrlFromString = (courseFileMapping: courseFileMapping, str: string): string => {
  const fileId = Number(str);

  // This part of content is not a file id, return the string
  if (!fileId) return str;

  const fileUrl = courseFileMapping[fileId];

  // File id not found in course files, return string with file id
  if (!fileUrl) return `[File:#${fileId}#]`;

  // Return the file's url
  return fileUrl;
};

// Return savable version of provided content
// Find all file urls in content and replace them with the file's id
export const builtContentForSave = (content: string, courseFiles: CourseFile[]): string => {
  // TODO: check early return order
  if (courseFiles.length === 0) return content;
  if (!content) return "";

  // Create element and append current content
  const newDiv = document.createElement("div");
  newDiv.innerHTML = content;

  // Find all images in the created element
  const images = newDiv.querySelectorAll("img");

  // Replace course images url with file id
  images.forEach((image) => {
    const imageUrl = image.src;
    const courseFile = courseFiles.find(({ url }) => url === imageUrl);

    if (courseFile) {
      image.src = `[File:#${courseFile.id}#]`;
    }
  });

  const newContent = newDiv.innerHTML;
  newDiv.remove();

  return newContent;
};

export const getToolbarButtons = (aiEditorOptions?: AIEditorOptions): string[] | ToolbarButton => {
  const { aiEnabled = false, disabledActions } = aiEditorOptions ?? {};

  // If all froala actions in the dropdown are disabled we need to hide some things
  const allAIPermissionsAreDisabled =
    disabledActions && isEqual(sortBy(AllAIGenerateTextActions), sortBy(disabledActions));

  if (!aiEnabled || allAIPermissionsAreDisabled) {
    return {
      moreText: {
        buttons: MORE_TEXT_BUTTONS,
        buttonsVisible: 12,
      },
      horizontalDivider: {
        buttons: ["horizontalDivider"],
        buttonsVisible: 1,
      },
      moreRich: {
        buttons: MORE_RICH_BUTTONS,
        buttonsVisible: 0,
      },
    };
  }

  return {
    moreText: {
      buttons: MORE_TEXT_BUTTONS,
      buttonsVisible: 12,
    },
    moreRich: {
      buttons: MORE_RICH_BUTTONS,
      buttonsVisible: 0,
    },
    horizontalDivider: {
      buttons: ["horizontalDivider"],
      buttonsVisible: 1,
    },
    aiFeatures: {
      buttons: [CustomButton.AIFeatures],
      buttonsVisible: 1,
    },
  };
};

export const getMinimalToolbarButtons = (aiEditorOptions?: AIEditorOptions): string[] => [
  "bold",
  "italic",
  "underline",
  "textColor",
  ...(aiEditorOptions?.aiEnabled ? [CustomButton.AIFeatures] : []),
];
