import React, { Modal } from "@epignosis_llc/gnosis";
import { FC } from "react";
import { modalBodyContainer } from "./styles";
import FormContainer from "@views/SignIn/FormContainer";
import { useUIStore } from "@stores";
import localStorageKeys from "@constants/localStorageKeys";

const SignInPopupModal: FC = () => {
  const { showSignInModal, setShowSignInModal } = useUIStore();

  const handleClose = (): void => {
    setShowSignInModal(false);

    // Reset local storage
    localStorage.removeItem(localStorageKeys.EXTERNAL_SIGNIN_SIGNUP);
  };

  return (
    <Modal isOpen={showSignInModal} onClose={handleClose}>
      <Modal.Body css={modalBodyContainer}>
        <FormContainer />
      </Modal.Body>
    </Modal>
  );
};

export default SignInPopupModal;
