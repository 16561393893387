import { UseMutateFunction, useMutation, MutationStatus } from "react-query";
import queryKeys from "@constants/queryKeys";
import { postZendeskConfigData } from "@api";
import { ZendeskTokenRes } from "types/responses";

type ZendeskConfigDataMutation = {
  zendeskConfigDataMutation: UseMutateFunction<ZendeskTokenRes, unknown, string, unknown>;
  zendeskConfigDataLoading: boolean;
  zendeskConfigDataStatus: MutationStatus;
};

const useZendeskConfigData = (): ZendeskConfigDataMutation => {
  const {
    mutate: zendeskConfigDataMutation,
    isLoading: zendeskConfigDataLoading,
    status: zendeskConfigDataStatus,
  } = useMutation<ZendeskTokenRes, unknown, string>([queryKeys.zendesk.token], (mode: string) =>
    postZendeskConfigData(mode),
  );

  return { zendeskConfigDataMutation, zendeskConfigDataLoading, zendeskConfigDataStatus };
};

export default useZendeskConfigData;
