import { css } from "@emotion/react";

export const signUpForm = ({ signInPage }, { showOnModal }) => css`
  padding: ${!showOnModal && "2.25rem 2rem"};

  h2 {
    text-align: ${showOnModal ? "left" : "center"};
    padding: ${showOnModal && "1.25rem"};
  }

  .form-content {
    display: flex;
    flex-direction: column;
    padding: ${showOnModal && "2.5rem 2.75rem"};

    .svg-text-wrapper {
      display: flex;
      flex-direction: column;

      .content-svg {
        margin: 1rem auto 1rem;
        width: 60%;
      }
    }

    .form-item {
      margin-bottom: ${!showOnModal && "1.25rem"};

      &.reCaptcha {
        div {
          margin: auto;
        }
      }
    }
  }

  .login-error-wrapper {
    margin-top: ${showOnModal && "1rem"};

    .login-error-container {
      margin: 0;
      text-align: center;
    }
  }

  .password-text {
    text-align: center;
    margin: 1rem 0;
  }

  .modal-title-container {
    border-bottom: 1px solid ${signInPage.borderColor};
  }

  .footer-container {
    padding: 1.25rem;
    border-top: 1px solid ${signInPage.borderColor};

    .btns-wrapper {
      text-align: start;

      .login-btn {
        margin-inline-start: 1rem;
      }
    }
  }

  .sign-in-or-up-link {
    text-align: center;
    margin-bottom: ${!showOnModal && "1.25rem"};
  }

  .sign-in-btn {
    margin-top: 1rem;
  }

  .input-wrapper {
    .psw-note {
      font-style: italic;
      margin-top: 1rem;
    }
    .psw-bar {
      margin-top: 0.5rem;
    }
  }
`;

export const signInModalBodyContainer = () => css`
  form {
    margin: 0;
    max-width: 100%;
    border-radius: 5px;
  }
`;
