import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["selectCard"] => {
  return {
    backgroundColor: colors.white,
    borderColor: colors.secondary.light,
    boxShadowColor: "rgba(0, 0, 0, 0.16)",
    hoverBackgroundColor: colors.secondary.lighter,
  };
};
