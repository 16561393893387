// Packages or third-party libraries
import React, { FC, memo, useCallback, useLayoutEffect, useReducer, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Loader } from "@epignosis_llc/gnosis";
import { SerializedStyles } from "@emotion/react";
import { useDebounceFn } from "ahooks";
import classNames from "classnames";

// Styles
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { PdfViewerStyles } from "./styles";

// Components
import Toolbar from "./components/Toolbar";

// Utils
import { i18n } from "@utils/i18n";
import { isElementVisible, mostVisiblePageReducer } from "./helpers";
import { pdfViewerStateReducer } from "./reducers";

// Other imports
import { defaultState, documentOptions } from "./constants";
import { PdfViewerStateActions } from "./types";
import { polyfillPromiseWithResolvers } from "@utils/helpers/promiseWithResolvers";

polyfillPromiseWithResolvers();

// The version of pdfjs-dist should be in sync with the version used in react-pdf
// See node_modules/react-pdf/package.json
// Use legacy build to solve "Promise.withResolvers is not a function" error
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/legacy/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();

type PdfViewerProps = {
  fileUrl: string;
  hasMaxHeight?: boolean;
};

const PdfViewer: FC<PdfViewerProps> = ({ fileUrl, hasMaxHeight = false }) => {
  const [{ numPages, pageNumber, isLoading, pageWidth, pageHeight, scale }, dispatch] = useReducer(
    pdfViewerStateReducer,
    defaultState,
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const isRtl = i18n.dir() === "rtl";
  const width = !hasMaxHeight ? pageWidth : undefined;
  const height = hasMaxHeight ? pageHeight : undefined;
  const documentClassNames = classNames("document-container", { "max-height": hasMaxHeight });

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }: { numPages: number }): void => {
    dispatch({
      type: PdfViewerStateActions.SetNumPages,
      payload: { numPages: nextNumPages },
    });
  };

  const onPageLoadSuccess = (): void => {
    const el = containerRef?.current;

    if (el && !pageWidth) {
      dispatch({
        type: PdfViewerStateActions.SetPageDimensions,
        payload: { pageWidth: el.clientWidth, pageHeight: el.clientHeight },
      });
    }

    dispatch({ type: PdfViewerStateActions.SetIsLoading, payload: { isLoading: false } });
  };

  const goToNextPage = (): void => goToPage(pageNumber + 1);
  const goToPreviousPage = (): void => goToPage(pageNumber - 1);

  const goToPage = (page: number): void => {
    if (page > 0 && page <= numPages) {
      dispatch({ type: PdfViewerStateActions.SetPageNumber, payload: { pageNumber: page } });
      document.querySelector(`#page-${page}`)?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const zoomIn = (): void => dispatch({ type: PdfViewerStateActions.ZoomIn });
  const zoomOut = (): void => dispatch({ type: PdfViewerStateActions.ZoomOut });

  const toggleFullScreen = (): void => {
    const el = containerRef?.current;

    if (el) {
      const isFullScreenEnabled = Boolean(document.fullscreenElement);
      !isFullScreenEnabled ? el.requestFullscreen() : document.exitFullscreen();
    }
  };

  const handleRenderError = (): void => {
    dispatch({ type: PdfViewerStateActions.SetIsLoading, payload: { isLoading: false } });
  };

  const { run: runHandleScroll } = useDebounceFn(
    () => {
      const el = containerRef?.current;
      const documentContainer = el?.querySelector(".document-container");

      if (!documentContainer) return;

      const pages = documentContainer.querySelectorAll(".page");
      const visiblePages = Array.from(pages).filter((page) =>
        isElementVisible(page, documentContainer),
      );

      if (visiblePages.length === 0) return;

      // Page that has the most visible height in the document container
      const mostVisiblePageNumber = visiblePages
        .reduce((prevPage, currentPage) =>
          mostVisiblePageReducer(prevPage, currentPage, documentContainer),
        )
        ?.getAttribute("data-page-number");

      if (!mostVisiblePageNumber) return;

      const newPageNumber = Number(mostVisiblePageNumber);

      //  Set the page number, ensuring it's within bounds
      if (newPageNumber !== pageNumber && newPageNumber <= numPages) {
        dispatch({
          type: PdfViewerStateActions.SetPageNumber,
          payload: { pageNumber: newPageNumber },
        });
      }
    },
    { wait: 250 },
  );

  const handleScroll = useCallback((): void => {
    runHandleScroll();
  }, [runHandleScroll]);

  // Set PDF width on window resize
  useLayoutEffect(() => {
    const el = containerRef?.current;

    const trigger = (): void => {
      if (el) {
        dispatch({
          type: PdfViewerStateActions.SetPageDimensions,
          payload: { pageWidth: el.clientWidth, pageHeight: el.clientHeight },
        });
      }
    };

    if (el && "ResizeObserver" in window) {
      new ResizeObserver(trigger).observe(el);
      trigger();
    }
  }, []);

  useLayoutEffect(() => {
    const el = containerRef?.current;
    const documentContainer = el?.querySelector(".document-container");

    if (documentContainer) {
      documentContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (documentContainer) {
        documentContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <div css={(theme): SerializedStyles => PdfViewerStyles(theme, isRtl)} ref={containerRef}>
      <div className="container">
        <div className={documentClassNames}>
          <Document
            file={fileUrl}
            options={documentOptions}
            renderMode="canvas"
            loading={<Loader />}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (_el, index) => {
              const pageNum = index + 1;

              return (
                <div
                  key={`page_${pageNum}`}
                  id={`page-${pageNum}`}
                  data-page-number={pageNum}
                  className="page"
                >
                  <Page
                    pageNumber={pageNum}
                    renderAnnotationLayer
                    renderTextLayer
                    width={width}
                    scale={scale}
                    height={height}
                    onLoadSuccess={onPageLoadSuccess}
                    onRenderError={handleRenderError}
                  />
                </div>
              );
            })}
          </Document>
        </div>

        {!isLoading && (
          <Toolbar
            pageNumber={pageNumber}
            numPages={numPages}
            goToPreviousPage={goToPreviousPage}
            goToNextPage={goToNextPage}
            onPageChange={goToPage}
            onZoomIn={zoomIn}
            onZoomOut={zoomOut}
            onFullScreen={toggleFullScreen}
          />
        )}
      </div>
    </div>
  );
};

export default memo(PdfViewer);
