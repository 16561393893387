import { css } from "@emotion/react";

export const userPagesStyles = ({ sidebarExtended, colors }) => css`
  // Items of the sub-menu content
  .menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    white-space: nowrap;

    svg {
      color: ${colors.black};
    }

    &:hover {
      display: flex;
      background: ${sidebarExtended.backgroundHover};
      border-radius: 0.3125rem;
    }
  }

  .menu-page-item {
    display: flex;
    align-items: center;
    color: ${colors.black};

    .menu-icon {
      display: flex;
      margin-inline-end: 0.5rem;
    }

    p {
      max-width: 230px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 0.889rem;
    }
  }
`;
