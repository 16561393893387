import { ReactNode } from "react";
import { ResponseEnvelope } from "types/responses";
import { File } from "types/entities";

export enum ContactSupportEnum {
  CONTACT_SUPPORT = "contact-support",
  SUBMIT_TICKET = "submit-ticket",
  LIVE_CHAT = "live-chat",
  PHONE_SUPPORT = "phone-support",
  ACCOUNT_MANAGER = "account-manager",
}

export type BasicSettings = {
  title: string;
  name: string;
  description?: string | ReactNode;
  placeholder: string;
  type?: string;
  testId?: string;
  tooltipText?: string;
  tooltipEnabled?: boolean;
};

export type Article = {
  id: string;
  url: string;
  title: string;
  snippet: string;
  body: string;
};

export type ArticleFilters = {
  section_id: number | null;
};

export type SearchQueryOptionsWithFilters = {
  filters?: ArticleFilters;
  search: string;
};

export type SupportFormData = {
  subject: string;
  body: string;
};

export type Ticket = {
  id: number;
  subject: string;
  body: string;
  attachment: File | null;
};

export type createTicketPostData = {
  subject: string;
  body: string;
  attachment_hash?: string;
};

export type SupportServices = {
  submit_ticket: boolean;
  live_chat: boolean;
  phone_support: boolean;
  account_manager: boolean;
  user_type: {
    id: number;
    name: string;
  };
  are_any_phone_agents_available: boolean;
};

export type VoiceLines = string[] | null;

export type SupportHours = { opening: number; closing: number };

export type TicketField = {
  id: number;
  title?: string;
  value: string | boolean | null;
};

export type ArticlesRes = ResponseEnvelope<Article[]>;
export type TicketRes = ResponseEnvelope<Ticket>;
export type SupportServicesRes = ResponseEnvelope<SupportServices>;
export type VoiceLinesRes = ResponseEnvelope<VoiceLines>;
export type TicketFieldRes = ResponseEnvelope<TicketField[]>;
