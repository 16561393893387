import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const gamificationContainer = ({ gamificationDrawer }) => css`
  height: 100%;
  overflow-y: auto;

  .skeleton-wrapper {
    padding: 2rem;

    ${mq["md"]} {
      padding: 1.5rem 2rem;
    }
  }

  nav {
    padding: 0 1rem;
  }

  header {
    display: flex;
    align-items: center;
    padding: 1rem;
    height: 4.5rem;

    ${mq["sm"]} {
      padding: 1rem 2rem;
    }

    h1 {
      margin: 0;
    }
  }

  .achievements-container {
    padding: 0 1rem 1rem;

    .details {
      display: flex;
      width: fit-content;

      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-inline-end: 0.5rem;
        background: ${gamificationDrawer.background};
        padding: 0.5rem;
        border-radius: 10px;

        &:last-of-type {
          margin: 0;
        }

        ${mq["sm"]} {
          height: 96px;
          width: 96px;
        }
      }
    }
  }

  .stats-container {
    padding: 2rem;
  }
`;

export const gamificationInfoContainer = ({ gamificationDrawer }) => css`
  margin-top: 2rem;

  li {
    margin-bottom: 1rem;

    .info-item {
      span {
        margin-inline-start: 0.25rem;
        top: 5px;
        position: relative;
      }
    }
  }

  .info-header {
    padding: 0 1rem 0.5rem;

    ${mq["sm"]} {
      padding: 0 2rem 0.5rem;
    }
  }

  .back-btn {
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    color: ${gamificationDrawer.color};
  }
`;
