// Packages or third-party libraries
import React, { FC, useState } from "react";
import { ButtonWithDropdown, FileModal } from "@components";
import { Button, DropdownItem } from "@epignosis_llc/gnosis";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import { useToggle } from "ahooks";

// Components
import { MassActionType } from "@components/ReusableComponents";
import MassAction from "@components/ReusableComponents/MassActions/MassActions";

// Utils, hooks
import { downloadFile } from "@utils/helpers";
import { useApplyTranslations } from "@hooks";
import { handleUserCoursesErrors } from "@errors/errors";

// Other imports
import { countMassUpdateCertificate, massUpdateCertificate, updateCertificate } from "@api/courses";
import { CountMassActionResponse, MassActionResultResponse } from "types/responses";
import { MassActionEnum } from "@components/ReusableComponents/MassActions/types";
import { CourseUser } from "../types";

export type CertificateModalProps = {
  selectedUser: CourseUser;
  previewUrl: string | null;
  isModalOpen: boolean;
  courseId: string;
  hideUpdateCertificate: boolean;
  setModalOpen: (open: boolean) => void;
  setPreviewUrl: (url: string | null) => void;
  handleInvalidateQuery: () => void;
  certificatePreviewMutation: (userId: string) => void;
  setHideUpdateCertificate: (hide: boolean) => void;
};

const CertificateModal: FC<CertificateModalProps> = ({
  selectedUser,
  previewUrl,
  isModalOpen,
  courseId,
  hideUpdateCertificate,
  setModalOpen,
  setPreviewUrl,
  handleInvalidateQuery,
  certificatePreviewMutation,
  setHideUpdateCertificate,
}) => {
  const { t } = useApplyTranslations();
  const { download_certificate_url, policies, id } = selectedUser;
  const { can_update_certificate = false } = policies;
  const downloadUrl = download_certificate_url as string;
  const canUpdateCertificate = can_update_certificate;
  const userId = id.toString();
  const [isMassActionModalOpen, { toggle: toggleMassActionModalOpen }] = useToggle(false);
  const [_massActionType, setMassActionType] = useState<MassActionType | null>(null);

  const { mutateAsync: updateCertificateMutation, isLoading: updateCertificateIsLoading } =
    useMutation((userId: string) => updateCertificate(courseId, userId), {
      onSuccess: () => {
        setHideUpdateCertificate(true);
      },
      onError: (error: AxiosError) => {
        handleUserCoursesErrors(error);
      },
      onSettled: () => {
        handleInvalidateQuery();
      },
    });

  const handleCertificateUpdate = async (userId: string): Promise<void> => {
    setPreviewUrl(null);
    await updateCertificateMutation(userId);
    certificatePreviewMutation(userId);
  };

  const handleDownloadFile = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    await downloadFile({ fileUrl: downloadUrl, fileName: "certificate" });
  };

  const handleMassAction = (): void => {
    toggleMassActionModalOpen();
    setModalOpen(false);
  };

  const updateCertificateDropdown: DropdownItem[] = [
    {
      label: t("certificates.updateAllCertificatesForCourse"),
    },
  ];

  const handleClose = (): void => {
    setModalOpen(false);
  };

  return (
    <>
      <MassAction
        toggleMassActionModalOpen={toggleMassActionModalOpen}
        resetMassActionType={(): void => setMassActionType(null)}
        type={MassActionEnum.update_certificates}
        isOpen={isMassActionModalOpen}
        originTableName={"courseUsers"}
        countRequest={(): Promise<CountMassActionResponse> => countMassUpdateCertificate(courseId)}
        massActionRequest={(): Promise<MassActionResultResponse> => massUpdateCertificate(courseId)}
      />

      <FileModal
        id={0}
        name="certificate"
        url={previewUrl ?? ""}
        mime_type={"application/pdf"}
        preview_url={previewUrl ?? ""}
        isOpen={isModalOpen}
        hasMaxHeight
        header={t("certificates.certificate")}
        onClose={handleClose}
        isLoading={!previewUrl || updateCertificateIsLoading}
        customFooter={
          <>
            <Button color="primary" onClick={handleDownloadFile} className="download-btn">
              {t("general.download")}
            </Button>
            {canUpdateCertificate && !hideUpdateCertificate && (
              <ButtonWithDropdown
                color="primary"
                onButtonClick={(): Promise<void> => handleCertificateUpdate(userId)}
                disabled={updateCertificateIsLoading}
                list={updateCertificateDropdown}
                onListItemClick={handleMassAction}
              >
                {t("general.update")}
              </ButtonWithDropdown>
            )}
            <Button
              color="secondary"
              onClick={(): void => setModalOpen(false)}
              className="cancel-btn"
            >
              {t("general.cancel")}
            </Button>
          </>
        }
      />
    </>
  );
};

export default CertificateModal;
