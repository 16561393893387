// Packages or third-party libraries
import React, { FC, useRef, useState } from "react";
import { useResponsive } from "ahooks";
import { Text, Heading, Tooltip } from "@epignosis_llc/gnosis";

// Components
import { UserAvatar } from "@components/ReusableComponents";
import { PreviewAction } from "@components";
import UserBadgesModal from "./UserBadgesModal";
import BadgesInfoDrawer from "@views/WidgetsDashboard/components/Widgets/Leaderboard/BadgesInfoDrawer";

// Hooks
import { useIsOverflow } from "@hooks";

// Utils
import { getFormattedUserName } from "@utils/helpers";

// Others
import { LeaderboardUser } from "types/entities";

// Assets
import { FirstPlaceSVG, SecondPlaceSVG, ThirdPlaceSVG } from "@assets/images";

type UserItemProps = {
  leaderboardUser: LeaderboardUser;
  index: number;
  title?: string;
  isGamificationDrawer?: boolean;
};

const InfoContainer = (name: string, surname: string, login: string): JSX.Element => {
  const ref = useRef<HTMLSpanElement | null>(null);
  const overflowActive = useIsOverflow(ref);
  const formattedName = getFormattedUserName({ name, surname, login });

  return (
    <div className="info">
      <Tooltip content={formattedName} disabled={!overflowActive}>
        <Text as="div" fontSize="sm" weight="700">
          <span ref={ref}>{formattedName}</span>
        </Text>
      </Tooltip>
    </div>
  );
};

const tooltipContainer = (name?: string, surname?: string): JSX.Element => (
  <div className="tooltip">
    {name} {surname}
  </div>
);

const UserItem: FC<UserItemProps> = ({
  leaderboardUser,
  title,
  index,
  isGamificationDrawer = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { name, score, avatar, surname, login, id } = leaderboardUser;
  const [activeUserId, setActiveUserId] = useState<string | null>(null);
  const [isBadgesInfoDrawerOpen, setIsBadgesInfoDrawerOpen] = useState(false);
  const { sm } = useResponsive();
  const isBadgesTab = title === "badges";

  const handleShowClick = (id: string): void => {
    setActiveUserId(id);
    setIsOpen(true);
  };

  const handleClickClose = (): void => {
    setActiveUserId(null);
    setIsOpen(false);
  };

  const handleBadgeDrawerToggle = (id: string, isOpen: boolean): void => {
    setActiveUserId(id);
    setIsBadgesInfoDrawerOpen(isOpen);
  };

  const renderPlaceIcon = (): JSX.Element => {
    if (index === 0) return <FirstPlaceSVG />;
    if (index === 1) return <SecondPlaceSVG />;
    if (index === 2) return <ThirdPlaceSVG />;
    return (
      <Text fontSize="md" weight="700">
        {index + 1}
      </Text>
    );
  };

  return (
    <>
      <div
        className="container"
        onClick={(): void | null => (!sm && isBadgesTab ? handleShowClick(id.toString()) : null)}
      >
        <div className="image-container">
          <span className="ranking-number">{renderPlaceIcon()}</span>

          <span className="avatar-container">
            <Tooltip content={tooltipContainer(name, surname)}>
              <UserAvatar
                avatar={avatar}
                imageSize="sm"
                avatarSize="md"
                name={name}
                alt={`${name} ${surname}`}
              />
            </Tooltip>
          </span>
        </div>

        <div className="info-value-container">
          {InfoContainer(name, surname, login)}
          <Heading size="lg" className="stats">
            {score}
            {isBadgesTab && sm && (
              <PreviewAction
                onClick={(): void =>
                  isGamificationDrawer
                    ? handleBadgeDrawerToggle(id.toString(), true)
                    : handleShowClick(id.toString())
                }
              />
            )}
          </Heading>
        </div>
      </div>

      {activeUserId && (
        <>
          <UserBadgesModal
            userId={activeUserId.toString()}
            leaderboardUser={leaderboardUser}
            index={index}
            isOpen={isOpen}
            onClose={handleClickClose}
          />
          <BadgesInfoDrawer
            isOpen={isBadgesInfoDrawerOpen}
            onClose={(): void => handleBadgeDrawerToggle(id.toString(), false)}
            userId={activeUserId.toString()}
            leaderboardUser={leaderboardUser}
            index={index}
          />
        </>
      )}
    </>
  );
};

export default UserItem;
