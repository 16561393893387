import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["pdfPreview"] => {
  return {
    toolbar: {
      background: colors.black,
      color: colors.white,
    },
  };
};
