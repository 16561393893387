import { config } from "@config/index";

const ZENDESK_API_KEY_DEV = "66268865-26f5-47ff-9210-f172682194cc";
const ZENDESK_API_KEY_PROD = "828cf2a9-186a-417a-bfba-db0c508cfacc";

const ZENDESK_API_KEY = config.isProd() ? ZENDESK_API_KEY_PROD : ZENDESK_API_KEY_DEV;

const integrations = {
  ZENDESK_API_KEY,
};

export default integrations;
