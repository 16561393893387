import Color from "color";
import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["customHomePage"] => {
  return {
    baseGrey: colors.secondary.base,
    boxShadow: colors.secondary.light,
    emptyBackgroundColor: colors.white,
    occupiedBackgroundColor: colors.secondary.lighter,
    svgHoverColor: colors.primary.base,
    tagColor: colors.black,
    customNavigationMenu: {
      inactiveText: colors.secondary.darker,
      linkText: colors.black,
    },
    editMenuDrawer: {
      backgroundColor: colors.white,
      backgroundHoverColor: colors.secondary.lighter,
      grayTagBackgroundColor: colors.secondary.lighter,
      grayTagColor: colors.black,
      pathColor: colors.secondary.darker,
      tagTextColor: colors.black,
    },
    editSectionDrawer: {
      buttonHoverColor: Color(colors.primary.lightest).alpha(0.25).string(),
      leftContainerBackground: colors.primary.base,
      outlineButtonBorder: colors.secondary.lighter,
      outlineButtonDisabled: colors.secondary.base,
      outlineButtonPlacementBackground: colors.secondary.base,
      subtitleText: colors.secondary.darker,
      svgColor: colors.white,
      textColor: colors.black,
    },
    sectionsMenu: {
      borderSectionColor: colors.secondary.light,
      borderTopColor: colors.secondary.base,
      sectionHoverColor: colors.secondary.lighter,
      svgActionsColor: colors.black,
      menuSectionBar: colors.white,
      deleteOption: colors.red.base,
    },
    topHeaderCustomHomepage: {
      iconBorderColor: colors.black,
      borderBottomColor: colors.secondary.light,
      topBackgroundColor: colors.primary.darker,
      secondaryHeaderBackground: colors.white,
    },
  };
};
