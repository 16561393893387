// Packages or third-party libraries
import React, { FC } from "react";
import { SerializedStyles } from "@emotion/react";
import classNames from "classnames";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

// Styles
import { link } from "./styles";

export type LabelColor = "primary" | "secondary" | "defaultLink";

export type LinkProps = RouterLinkProps & {
  color?: LabelColor;
  readonly?: boolean;
  isUnderlined?: boolean;
};

const linkContainer = (readonly: boolean, isUnderlined: boolean): string =>
  classNames({
    readonly: Boolean(readonly),
    underlined: isUnderlined,
  });

const LinkStyled: FC<LinkProps> = ({
  color = "primary",
  readonly = false,
  isUnderlined = false,
  children,
  ...rest
}) => {
  return (
    <RouterLink
      css={(theme): SerializedStyles => link(theme, { color })}
      className={linkContainer(readonly, isUnderlined)}
      {...rest}
    >
      {children}
    </RouterLink>
  );
};

export default LinkStyled;
