import { config } from "@config";
import { MetricsType } from "./types";

const PRODUCTION_KEY = "IGtlFuB8EkuWSV2Cf6tO5g==";
const DEVELOPMENT_KEY = "iiZylpTPiwWo4D3JVGEIAg==";

export const keyBase64 = config.isProd() ? PRODUCTION_KEY : DEVELOPMENT_KEY;
export const logDebugger = false;

// Feature flags for metrics.
export const enabledMetrics = {
  device: true,
};

// Log Device Metrics Settings.
export const metrics: MetricsType = {
  device: () => ({
    screen: {
      width: screen.width,
      height: screen.height,
    },
  }),
};
