export const INTERCOM_SCRIPT_BASE = "https://widget.intercom.io/widget/";

export const getScriptSnippet = (
  appID: string | null,
  userEmail: string,
  userHash: string | null,
): string => `
    window.intercomSettings = {
      api_base: "https://api-iam.intercom.io",
      app_id: "${appID}",
      email: "${userEmail}",
      user_hash: "${userHash}"
    };
    
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',intercomSettings);}else{var
  d=document;var i=function(){i.c(arguments)};i.q=[];i.c=function(args){i.q.push(args)};w.Intercom=i;function
  l(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${appID}';var
  x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);}if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})()
`;
