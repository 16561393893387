// Import { DropdownItem } from "@epignosis_llc/gnosis";
// Import { Dispatch, SetStateAction, MutableRefObject } from "react";
import {
  BranchesByUsersRes,
  CountMassActionResponse,
  GroupsByUsersRes,
  MassActionResultResponse,
} from "types/responses";
import { EnrollModeType } from "@views/Group/components/GroupUsers/types";
import { SubmissionStatus } from "types/entities/Assignments";

export type WithIdAndName = {
  id: number | string;
  name: string;
};

export type MassActionParam = {
  course_id?: number;
  category_id?: number;
  branch_ids?: number[];
  group_ids?: number[];
  user_ids?: number[];
  certification_id?: number;
  remove_certificates?: boolean;
  prevent_completed?: boolean;
  chunk_index?: number;
  unit_progress_ids?: number[];
  sessionId?: string;
  status?: SubmissionStatus;
  score?: number;
  comments?: string;
  expiration_date?: string;
  enrollment_date?: string;
  completion_date?: string;
};

export enum MassActionEnum {
  activate = "activate",
  deactivate = "deactivate",
  add_to_branches = "add_to_branches",
  remove_from_branches = "remove_from_branches",
  add_to_groups = "add_to_groups",
  remove_from_groups = "remove_from_groups",
  add_to_category = "add_to_category",
  show_in_catalog = "show_in_catalog",
  hide_from_catalog = "hide_from_catalog",
  delete = "delete",
  addCourseToAllBranches = "add_course_to_all_branches",
  removeCourseFromAllBranches = "remove_course_from_all_branches",
  addCourseToAllGroups = "add_course_to_all_groups",
  removeCourseFromAllGroups = "remove_course_from_all_groups",
  unenroll = "unenroll",
  enroll = "enroll",
  reset = "reset",
  sync = "sync",
  setExpirationDate = "set_expiration_date",
  complete = "complete",
  send_message = "send_message",
  update_certificates = "update_certificates",
  complete_units = "complete_units",
  reset_units = "reset_units",
  add_users_to_group_courses = "add_users_to_group_courses",
  remove_users_from_group_courses = "remove_users_from_group_courses",
  grade = "grade",
  course_store_mass_acquire = "course_store_mass_acquire",
  add_to_branch = "add_to_branch",
  remove_from_branch = "remove_from_branch",
  add_to_group = "add_to_group",
  remove_from_group = "remove_from_group",
  add_to_course = "add_to_course",
  remove_from_course = "remove_from_course",
  custom_reports_reset_progress = "custom_reports_reset_progress",
  custom_reports_delete_users = "custom_reports_delete_users",
  remove_from_session = "remove_from_session",
  add_to_session = "add_to_session",
  assign_certification = "assign_certification",
  assign_skills = "assign_skills",
  unassign_skills = "unassign_skills",
  approve = "approve",
  decline = "decline",
}

export type MassActionType = MassActionEnum;

export type MassActionModalProps = {
  type: MassActionType;
  isOpen: boolean;
  originTableName?: string; // To add type
  showOnCatalogState?: boolean;
  preventSyncCompletedState?: boolean;
  itemNameLabel?: string;
  handleInvalidateQueryMassActions?: () => void;
  countRequest: (type: MassActionType, data: MassActionParam) => Promise<CountMassActionResponse>;
  massActionRequest: (
    type: MassActionType,
    data: MassActionParam,
  ) => Promise<MassActionResultResponse>;
  getGroupsByUsersRequest?: () => Promise<GroupsByUsersRes>;
  getBranchesByUsersRequest?: () => Promise<BranchesByUsersRes>;
  toggleMassActionModalOpen: () => void;
  resetMassActionType: () => void;
  cleanState?: () => void;
  handleShowOnCatalog?: () => boolean;
  handlePreventSyncCompleted?: () => boolean;
  onSelectedItemsChange?: (items: number | number[] | null) => void;
  enrollMode?: React.MutableRefObject<EnrollModeType>;
};

export type InternalStateType = {
  total: number;
  chunks: number;
  processed: number;
  currentChunk: number;
};

export type GroupUsersCountType = {
  total_unique_users: number;
  total_unique_courses: number;
};
