export const talentLibraryTrademark = `TalentLibrary${String.fromCodePoint(0x02122)}`;

export const courseStoreMassActionsWordings = {
  course_store_mass_acquire: {
    confirmationBodyText: "courseStore.massActions.activateWarning",
    confirmationBodyTitle: "courseStore.massActions.activateWarningTitle",
    confirmationButton: "general.getCourses",
    confirmationHeader: "general.getCourses",
    progressHeader: "general.getCourses",
    progressMessage: "courseStore.massActions.acquireProgress",
    successMessage: "courseStore.massActions.acquireCourseSuccess",
    showOnCatalog: "general.showOnCatalog",
    limitReachedMessage: "courses.massActions.limitReachedMessage",
  },
};

export const searchQueryForCategories =
  "?page[number]=1&page[size]=10&sort=name&filter[language]=english";

export enum CourseStoreTabs {
  TALENT_LIBRARY = "talentLibrary",
  OTHER_PROVIDERS = "otherProviders",
  OPEN_SESAME = "openSesame",
}

export const SEARCH_KEY = "[keyword][like]";
