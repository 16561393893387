import { Column, TableProps } from "@epignosis_llc/gnosis";
import { Pagination as IPagination } from "types/responses/index";
import { QueryFilter } from "types/common";
import { buildPaginatedSearchQuery } from "@utils/helpers";
import { URLS } from "@constants/urls";
import { t } from "@utils/i18n";

export enum UnitTypes {
  ILT = "ilt",
  ASSIGNMENT = "assignment",
  SCORM = "scorm",
  TEST = "test",
  SURVEY = "survey",
}

export const SORTING: TableProps["sorting"] = { column: "name", isDescending: false };
export const PAGINATION: IPagination = { number: 1, size: 10 };
export const DEFAULT_FILTERS: QueryFilter[] = [];

export const DEFAULT_STATE_UNITS = {
  sorting: SORTING,
  filters: [],
  pagination: PAGINATION,
  url: URLS.reports.learningActivities,
};

// Fetch 1000 filter items by default.
export const filtersSearchQuery = buildPaginatedSearchQuery({
  pagination: { number: 1, size: 10000 },
  sorting: ["name"],
});

export const getUnitReportsColumns = (): Column[] => {
  return [
    {
      accessor: "name",
      cell: t("general.learningActivity", { count: 1 }),
      headerWidth: 250,
    },
    {
      accessor: "type",
      cell: t("general.type"),
    },
    {
      accessor: "course_name",
      cell: t("general.courseName"),
      maxWidth: 200,
    },
    {
      accessor: "completed",
      cell: t("general.progress.completed"),
      sortOrder: "desc",
    },
    {
      accessor: "in_progress",
      cell: t("general.progress.inProgress"),
      sortOrder: "desc",
    },
    {
      accessor: "not_passed",
      cell: t("general.progress.notPassed"),
      sortOrder: "desc",
    },
    {
      accessor: "not_attempted",
      cell: t("general.progress.notAttempted"),
      sortOrder: "desc",
    },
    {
      accessor: "average_score",
      cell: t("general.averageScore"),
      sortOrder: "desc",
    },
    {
      accessor: "actions",
      cell: "",
      classNames: ["hidden"],
      sortableHeader: false,
    },
  ];
};
