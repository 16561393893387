import { css } from "@emotion/react";

export const DiscussionsMenuStyles = ({ colors }) => css`
  .discussions {
    display: relative;

    .unread-message {
      position: absolute;
      width: 0.5rem;
      height: 0.5rem;
      top: 0.5625rem;
      right: 0.6875rem;
      border-radius: 50%;
      background-color: ${colors.red.base};
    }
  }
`;
