import localStorageKeys from "@constants/localStorageKeys";

export const shouldShowTrialEndingModal = (daysLeft: number): boolean => {
  const hasAlreadySeenModal =
    localStorage.getItem(localStorageKeys.SHOW_TRIAL_ENDING_MODAL) === "true";

  const shouldShowModal = daysLeft === 1 && !hasAlreadySeenModal;

  return shouldShowModal;
};

export const dismissTrialEndingModal = (): void => {
  localStorage.setItem(localStorageKeys.SHOW_TRIAL_ENDING_MODAL, "true");
};

export const clearTrialModalKeys = (): void => {
  localStorage.removeItem(localStorageKeys.SHOW_TRIAL_ENDING_MODAL);
};
