import { css } from "@emotion/react";

export const TopBarMenuStyles = ({ colors }) => css`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .action-btn {
    color: black;
  }

  .branches-dropdown {
    .main-branch-switch {
      margin: -0.75rem -0.75rem 0.25rem -0.75rem;
      text-align: center;
      border-bottom: 1px solid ${colors.secondary.light};
    }

    .switch-label {
      padding-bottom: 0.25rem;
    }

    // We are overriding the dropdown list item styles.
    // Dropdown doesn't give us any specific class to target the list items.
    .dropdown-list-item {
      svg {
        order: 2;
        width: 1.125rem;
      }

      span {
        display: block;
        width: 100%;

        div {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }
`;
