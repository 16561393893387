import {
  CoursesSVG_duplicate,
  CertificateSVG_duplicate,
  BadgesSVG,
  LevelSVG,
  PointsSVG,
} from "@epignosis_llc/gnosis/icons";

export const TAB_TITLES = {
  courses: "dashboard.leaderboardTabs.courses",
  points: "general.points",
  levels: "general.levels",
  certificates: "general.certificates",
  badges: "general.badges",
  rewards: "dashboard.achievementsTab.myRewards",
};

export const footerButtonTranslations = {
  badges: "dashboard.badgeGallery",
  points: "gamification.collectMorePoints",
  levels: "gamification.howToLevelUp",
};

export const translationsMapping = {
  points: {
    login_points: "loginPoints",
    unit_completion_points: "unitCompletionPoints",
    course_completion_points: "courseCompletionPoints",
    certification_points: "certificationPoints",
    test_completion_points: "testCompletionPoints",
    assignment_completion_points: "assignmentCompletionPoints",
    ilt_completion_points: "iltCompletionPoints",
    discussion_topic_comment_points: "discussionTopicCommentPoints",
    discussion_upvote_comment_points: "discussionUpvoteCommentPoints",
  },
  levels: {
    upgrade_level_points: "upgradeLevelPoints",
    upgrade_level_completed_courses: "upgradeLevelCompletedCourses",
    upgrade_level_badges: "upgradeLevelBadges",
  },
};

export const badgeCategories = [
  "activity",
  "assignment",
  "certification",
  "communication",
  "learning",
  "perfectionism",
  "survey",
  "test",
];

export const rewardTranslations = {
  points: "courseDiscountMoreThan",
  badges: "courseDiscountMoreThanBadges",
  level: "courseDiscountOnLevel",
};

export const icons = {
  badges: BadgesSVG,
  points: PointsSVG,
  levels: LevelSVG,
  courses: CoursesSVG_duplicate,
  certificates: CertificateSVG_duplicate,
};
