// Packages or third-party libraries
import React, { FC, useState } from "react";
import { Button, Grid, Input, Text, ToggleSwitch } from "@epignosis_llc/gnosis";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

// Styles
import { shareOptionStyles } from "./styles";

// Components
import { CopyToClipboardButton, Skeletons } from "@components";
import InvitationsDrawer from "../InvitationsDrawer/InvitationsDrawer";

// Utils, hooks
import { yup } from "@utils/validation";
import { useApplyTranslations } from "@hooks";
import { handleCourseErrors } from "@errors";

// Other imports

import { Course } from "types/entities";
import { shareCourse } from "@views/CourseEdit/api";
import { getCoursePublicUrl } from "@views/CourseEdit";
import queryKeys from "@constants/queryKeys";
import { URLS } from "@constants/urls";

type ShareSchema = { shared: boolean };

const ShareValidationSchema = yup.object().shape({
  shared: yup.boolean().required(),
});

type ShareOptionProps = {
  course: Course;
};

const ShareOption: FC<ShareOptionProps> = ({ course }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useApplyTranslations();

  const [isInvitationsDrawerOpen, setIsInvitationsDrawerOpen] = useState(false);

  const { id, policies, public_key, name } = course;
  const { can_invite_public_course = false } = policies ?? {};
  const courseId = id.toString();

  const publicUrl = getCoursePublicUrl(public_key as string);

  const { control, watch } = useForm<ShareSchema>({
    mode: "onChange",
    resolver: yupResolver(ShareValidationSchema),
    defaultValues: {
      shared: !!public_key,
    },
  });

  const isShared = watch("shared");

  const {
    mutate: shareCourseMutation,
    isLoading: shareCourseIsLoading,
    status: shareCourseStatus,
    error: shareCourseError,
  } = useMutation([queryKeys.courses.share], (shared: boolean) => shareCourse(courseId, shared), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([queryKeys.myCourse, courseId]);
    },
    onError: (error: AxiosError) => {
      const handleError = (): void => navigate(URLS.courses.courses);
      handleCourseErrors(error, false, handleError);
    },
  });

  const handleOpenInvitationsDrawer = (): void => {
    if (isShared) {
      setIsInvitationsDrawerOpen(true);
    }
  };

  const handleCloseInvitationsDrawer = (): void => {
    setIsInvitationsDrawerOpen(false);
  };

  return (
    <>
      <Grid templateColumns={1} gap={1} css={shareOptionStyles}>
        <Grid.Item colSpan={1}>
          <Controller
            name="shared"
            control={control}
            render={({ field: { onChange, value } }): JSX.Element => (
              <ToggleSwitch
                id="shared"
                labelAfter={t("courseEdit.enablePublicSharing")}
                defaultChecked={value}
                isDisabled={shareCourseIsLoading}
                onChange={(): void => {
                  const shared = !value;
                  onChange(!value);
                  shareCourseMutation(shared);
                }}
              />
            )}
          />
        </Grid.Item>

        {isShared && (
          <>
            <Skeletons.Loader status={shareCourseStatus} error={shareCourseError}>
              <Grid.Item colSpan={1}>
                <Grid templateColumns={1} gap={0.5}>
                  <Grid.Item colSpan={1} className="copy-link-container">
                    <Input
                      key={public_key}
                      id="course-public-link"
                      defaultValue={publicUrl}
                      label={t("general.link")}
                      readOnly
                    />
                    <CopyToClipboardButton textToCopy={publicUrl} buttonColor="primary" />
                  </Grid.Item>
                  <Grid.Item colSpan={1}>
                    <Text fontSize="xs" as="div" className="note">
                      {t("courseEdit.publicLinkText")}
                    </Text>
                  </Grid.Item>
                </Grid>
              </Grid.Item>
            </Skeletons.Loader>

            {can_invite_public_course && (
              <Grid.Item colSpan={1}>
                <Button
                  variant="outline"
                  disabled={shareCourseIsLoading}
                  onClick={handleOpenInvitationsDrawer}
                >
                  {t("general.sendInvitations")}
                </Button>
              </Grid.Item>
            )}
          </>
        )}
      </Grid>

      {isInvitationsDrawerOpen && public_key && (
        <InvitationsDrawer
          isOpen={isInvitationsDrawerOpen}
          courseId={courseId}
          publicKey={public_key}
          courseName={name}
          onClose={handleCloseInvitationsDrawer}
        />
      )}
    </>
  );
};

export default ShareOption;
