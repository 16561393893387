import React, { FC } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CloseSVG } from "@epignosis_llc/gnosis/icons";
import { toaster } from "./styles";
import { i18n } from "@utils/i18n";

const Toaster: FC = () => {
  const isRtl = i18n.dir() === "rtl";
  const placement = isRtl ? "top-left" : "top-right";

  return (
    <ToastContainer
      position={placement}
      css={toaster}
      closeButton={(): JSX.Element => <CloseSVG height={16} className="close-btn" />}
      icon={false}
      rtl={isRtl}
    />
  );
};

export default Toaster;
