import { useConfigurationStore } from "@stores";
import authService from "@utils/services/AuthService";
import { userHasRequiredPermissions } from "./index";
import { accountPermissions } from "@utils/permissions/account";
import userRoles from "@constants/userRoles";

// Map with all the available permissions
export const skillsPermissions = {
  create: "skill.create",
  read: "skill.read",
  update: "skill.update",
  delete: "skill.delete",
  assign: "skill.assign",
  approve: "skill.approve",
  recommend: "skill.recommend",
  accountSkills: "account.skills",
};

const {
  create,
  read,
  update: updateSkill,
  delete: deleteSkill,
  assign,
  approve,
  recommend,
  accountSkills: viewSettings,
} = skillsPermissions;

const { update: updateSettings } = accountPermissions;
const { getState } = useConfigurationStore;

const canViewSkills = (): boolean => {
  return getState().userProfileData?.policies?.can_view_skills ?? false;
};

const isAllowed = (permissions: string[], requireAll = true, asUserRole?: string): boolean => {
  const userRole = asUserRole ?? authService.getDefaultRole();
  return userHasRequiredPermissions(
    permissions,
    getState().userProfileData?.permissions[userRole],
    requireAll,
  );
};

const getRoleToUse = (): string | undefined => {
  const currentRole = authService.getDefaultRole();
  // Allow both learners AND instructors to view skills as learners!
  // (if the relevant permission is granted within the learner skills permission tree)
  return [userRoles.INSTRUCTOR, userRoles.LEARNER].includes(currentRole)
    ? userRoles.LEARNER
    : undefined;
};

export default {
  canViewSkills,
  canAccessSkills: (): boolean => isAllowed([read], true, getRoleToUse()),
  canCreateSkills: (): boolean => isAllowed([create]),
  canUpdateSkills: (): boolean => isAllowed([updateSkill]),
  canDeleteSkills: (): boolean => isAllowed([deleteSkill]),
  canAssignSkills: (): boolean => isAllowed([assign]),
  canApproveSkills: (): boolean => isAllowed([approve]),
  canAddCoursesToSkill: (): boolean => isAllowed([updateSkill]),
  canDeleteCoursesFromSkill: (): boolean => isAllowed([updateSkill]),
  canGetSkillSettings: (): boolean =>
    isAllowed([read, viewSettings], false, userRoles.ADMINISTRATOR),
  canAccessSkillSettings: (): boolean => isAllowed([viewSettings], true),
  canUpdateSkillSettings: (): boolean => isAllowed([viewSettings, updateSettings], true),
  canRecommendUsersForSkill: (): boolean => isAllowed([read, recommend], true, getRoleToUse()),
};
