import { css } from "@emotion/react";
import { inputBaseStyles, inputContainerBaseStyles } from "../styles";
const TIME_ONLY_WIDTH = "85px";

export const dateInput = ({ formFields, multiSelect, dateInput: dateInputTheme }, { size }) => css`
  ${inputContainerBaseStyles({ block: true })};

  &.inline {
    width: auto;
  }

  &.error {
    .input-wrapper input {
      border-color: red;
    }
  }

  input {
    ${inputBaseStyles({ multiSelect }, { block: true, size })};
  }

  .required .label-container label {
    &::after {
      content: " *";
      color: ${formFields.requiredFieldAsterisk};
    }
  }

  .branded-datepicker {
    width: 100%;
    cursor: pointer;

    input {
      cursor: pointer;
    }
  }

  .custom-header {
    display: flex;
    justify-content: space-between;
    padding: 0.38rem 0.5rem;

    .date-label,
    .icon-btn {
      padding: 0;
      background-color: transparent;
      border: 0;
    }

    .icon-btn {
      svg {
        color: ${dateInputTheme.textColor};
        transition: color 0.2s ease;
      }

      &:hover {
        svg {
          color: ${dateInputTheme.iconColorHover};
        }
      }
    }
  }

  .react-datepicker {
    border: 1px solid ${dateInputTheme.borderColor};
    box-shadow: 0 5px 10px 0 ${dateInputTheme.boxShadowColor};
    font-family: "Mulish", Arial, sans-serif;
  }

  /* desktop view */
  .react-datepicker-popper {
    width: 100vw;
    max-width: 19rem;
    z-index: 9999 !important;

    .react-datepicker,
    .react-datepicker__month-container {
      width: 100%;
    }
  }

  /* mobile view */
  .react-datepicker__portal {
    .react-datepicker__month-container,
    .react-datepicker__year--container {
      width: 100vw;
      max-width: 19rem;
    }
  }

  .react-datepicker__header--custom {
    background-color: ${dateInputTheme.headerBackgroundColor};
    border-bottom: 1px solid ${dateInputTheme.borderColor};
    padding: 0;

    .react-datepicker__day-names {
      margin: 0 0.5rem 0.125rem;
      font-size: 0.75rem;
      font-weight: 700;
      color: ${dateInputTheme.textColor};
      text-transform: uppercase;

      .react-datepicker__day-name {
        margin: 0;
        width: 100%;
        max-width: 2.5rem;
      }
    }
  }

  .react-datepicker__month {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    font-size: 0.75rem;
    font-weight: 700;
    color: ${dateInputTheme.textColor};

    &.react-datepicker__monthPicker {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 12.5rem;
      font-size: 0.875rem;

      .react-datepicker__month-wrapper {
        display: flex;
        justify-content: center;
      }
    }

    .react-datepicker__week {
      .react-datepicker__day {
        margin: 0;
        width: 100%;
        max-width: 2.5rem;
        line-height: 2.5rem;
      }

      .react-datepicker__day--outside-month {
        color: ${dateInputTheme.contentInactiveColor};
      }

      .react-datepicker__day--keyboard-selected,
      .react-datepicker__day--selected {
        background-color: ${dateInputTheme.activeBackgroundColor};
        color: ${dateInputTheme.contentActiveColor};
      }
    }

    .react-datepicker__month-wrapper {
      .react-datepicker__month-text {
        padding: 0.5rem;
        width: 5rem;
      }

      .react-datepicker__month-text--keyboard-selected {
        background-color: ${dateInputTheme.activeBackgroundColor};
        color: ${dateInputTheme.contentActiveColor};
      }
    }
  }

  .react-datepicker__year {
    display: flex;
    min-height: 12.5rem;
    font-size: 0.875rem;
    color: ${dateInputTheme.textColor};
    font-weight: 700;

    .react-datepicker__year-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: auto;
      max-width: 100%;
    }

    .react-datepicker__year-text {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 5rem;
      height: 2.5rem;

      &.react-datepicker__year-text--keyboard-selected,
      &.react-datepicker__year-text--selected {
        background-color: ${dateInputTheme.activeBackgroundColor};
        color: ${dateInputTheme.contentActiveColor};
      }
    }
  }

  /* Time input styles  */

  &.time-only {
    .react-datepicker-popper {
      width: ${TIME_ONLY_WIDTH};
    }
  }

  .react-datepicker.react-datepicker--time-only {
    width: ${TIME_ONLY_WIDTH};

    .react-datepicker__header {
      background-color: ${dateInputTheme.headerBackgroundColor};
      border-bottom: 1px solid ${dateInputTheme.borderColor};
    }
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: ${dateInputTheme.contentActiveColor};
  }
`;
