import React, { FC } from "react";
import { Modal } from "@epignosis_llc/gnosis";
import { signInModalBodyContainer } from "./styles";
import SignInForm from "./SignInForm";
import { useUIStore } from "@stores";

const signInModalBodyStyles = {
  padding: 0,
};

const SignInModal: FC = () => {
  const showSessionExpirationModal = useUIStore((state) => state.showSessionExpirationModal);

  return (
    <Modal isOpen={showSessionExpirationModal}>
      <Modal.Body style={signInModalBodyStyles}>
        <div css={signInModalBodyContainer}>
          <SignInForm showOnModal />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SignInModal;
