import { PdfViewerState, PdfViewerStateAction, PdfViewerStateActions } from "./types";
import { MAX_ZOOM, MIN_ZOOM } from "./constants";

// Reducer for full reordering of sections
export const pdfViewerStateReducer = (
  state: PdfViewerState,
  action: PdfViewerStateAction,
): PdfViewerState => {
  const { type } = action;

  switch (type) {
    case PdfViewerStateActions.SetNumPages: {
      return { ...state, numPages: action.payload.numPages };
    }
    case PdfViewerStateActions.SetPageNumber: {
      return { ...state, pageNumber: action.payload.pageNumber };
    }
    case PdfViewerStateActions.SetIsLoading: {
      return { ...state, isLoading: action.payload.isLoading };
    }
    case PdfViewerStateActions.SetPageDimensions: {
      return {
        ...state,
        pageWidth: action.payload.pageWidth,
        pageHeight: action.payload.pageHeight,
      };
    }
    case PdfViewerStateActions.ZoomIn: {
      return { ...state, scale: Math.min(state.scale + 0.2, MAX_ZOOM) };
    }
    case PdfViewerStateActions.ZoomOut: {
      return { ...state, scale: Math.max(state.scale - 0.2, MIN_ZOOM) };
    }
    default: {
      return state;
    }
  }
};
