import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import qs, { ParsedQs } from "qs";

const useSearchQuery = (): ParsedQs => {
  const { search } = useLocation();
  const cleanSearchQuery = search.replace("?", "");

  return useMemo(() => qs.parse(cleanSearchQuery), [cleanSearchQuery]);
};

export default useSearchQuery;
