import React, { FC } from "react";
import { courseOverviewStyles } from "./styles";
import Header, { HeaderProps } from "./compoundComponents/Header";
import Content, { ContentProps } from "./compoundComponents/Content";

// Type for props passed to CourseOverview component
type CourseOverviewComponentProps = {
  children?: React.ReactNode;
};

// Type for CourseOverview static properties
type CourseOverviewProps = {
  Header: FC<HeaderProps>;
  Content: FC<ContentProps>;
};

// The main CourseOverview component
const CourseOverview: FC<CourseOverviewComponentProps> & CourseOverviewProps = ({ children }) => (
  <div css={courseOverviewStyles} id="course-overview">
    {children}
  </div>
);

// Assign Header and Content to the CourseOverview component
CourseOverview.Header = Header;
CourseOverview.Content = Content;

export default CourseOverview;
