import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["recording"] => {
  return {
    audio: {
      backgroundColor: colors.secondary.lighter,
      color: colors.secondary.light,
    },
  };
};
