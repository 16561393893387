import { TableCSVExportRes } from "types/responses";
import { ENDPOINTS } from "./endpoints";
import HttpClient from "./HttpClient";

export const getTask = async (taskId: string): Promise<TableCSVExportRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.tableExports.task(taskId)}`);
  return res.data;
};

export const deleteTask = async (taskId: string): Promise<TableCSVExportRes> => {
  const res = await HttpClient.delete(`${ENDPOINTS.tableExports.task(taskId)}`);
  return res.data;
};
