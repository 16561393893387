import { getMyCourses } from "@api/courses";
import queryKeys from "@constants/queryKeys";
import { useState } from "react";
import { useQuery } from "react-query";

const useIsUserEnrolledInCourse = (
  courseId: string,
  isLearner: boolean,
): {
  hasCourseAccess: boolean;
  coursesStatus: "idle" | "error" | "loading" | "success";
} => {
  const [hasCourseAccess, setHasCourseAccess] = useState(false);

  const { status: coursesStatus } = useQuery([queryKeys.myCourses], () => getMyCourses(), {
    select: (res) => res._data,
    onSuccess: (data) => {
      const userHasCourseAccess = data?.some((course) => course.id.toString() === courseId);
      setHasCourseAccess(userHasCourseAccess);
    },
    enabled: isLearner,
  });
  return {
    hasCourseAccess,
    coursesStatus,
  };
};

export default useIsUserEnrolledInCourse;
