// Packages or third-party libraries
import React, { FC, useRef } from "react";
import { Button, Heading, Tooltip } from "@epignosis_llc/gnosis";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

// Styles
import { tocHeaderStyles } from "./styles";

// Hooks
import { useApplyTranslations, useIsOverflow } from "@hooks";
import { SerializedStyles } from "@emotion/react";

export type HeaderProps = {
  isOpen: boolean;
  isActive?: boolean;
  headerIcon?: JSX.Element;
  title: string;
  titleLink?: string;
  onBackClick?: () => void;
  children?: React.ReactNode;
};

const className = (isOpen: boolean): string => classNames({ hidden: !isOpen });

const Header: FC<HeaderProps> = ({
  isOpen,
  isActive = false,
  headerIcon,
  title,
  titleLink,
  onBackClick,
  children,
}) => {
  const { t } = useApplyTranslations();
  const navigate = useNavigate();
  const titleRef = useRef<HTMLDivElement | null>(null);
  const isOverflow = useIsOverflow(titleRef);

  const handleTitleCLick = (): void => {
    if (titleLink) navigate(titleLink);
  };

  return (
    <div
      css={(theme): SerializedStyles =>
        tocHeaderStyles(theme, { isActive, hasLinkTitle: !!titleLink })
      }
      className={className(isOpen)}
    >
      {onBackClick && (
        <div className="back-button-container">
          <Button variant="link" noGutters onClick={onBackClick} data-testid="course-back-button">
            {t("general.back")}
          </Button>
        </div>
      )}

      <div id="toc-header" className="title-wrapper" onClick={handleTitleCLick}>
        <div id="title-container" className="title-container">
          <Tooltip content={title} disabled={!isOverflow}>
            <div className="title" ref={titleRef}>
              <Heading size="lg">{title}</Heading>
            </div>
          </Tooltip>

          {headerIcon}
        </div>

        {children && <div className="children-wrapper">{children}</div>}
      </div>
    </div>
  );
};

export default Header;
