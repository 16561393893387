// React and Third party imports
import React from "react";
import { Link } from "react-router-dom";

import { Text, Button, TableProps } from "@epignosis_llc/gnosis";
import { IconEmptyStateSVG } from "@epignosis_llc/gnosis/icons";

// Styles
import { emptyStateContainer } from "./styles";

// Utils, Assets
import { t } from "@utils/i18n";
import classNames from "classnames";
import {
  NoUsers,
  NoResults,
  EmptyMatrix,
  NoAutomations,
  NoCategories,
  NoBranches,
  NoCourses,
  NoFiles,
  NoGroups,
  NoNotifications,
  NoPayments,
  NoLanguageOverrides,
  NoUnits,
  NoQuestions,
  NoCertificates,
  NoCustomUserFields,
  NoScheduledReports,
  NoTimelineEvents,
  NoReports,
  NoSubmissionsToGrade,
  NoIltSessionsReportsSVG,
  NoInvoicesSVG,
  NoCouponsSVG,
  NoConferencesSVG,
  NoSkillRecommendations,
} from "@assets/images";

// Other imports
import { EmptyStateProps, EmptyStateType, EmptyStatePropType } from "./types";
import { URLS } from "@constants/urls";

export const buildEmptyStateProps = ({
  filters,
  tableType,
  isDrawer,
  opensDrawer,
  permission,
  omitFilterText = false,
  isButtonLoading,
  onClick,
  handleRestoreDefault,
}: EmptyStatePropType): EmptyStateType => {
  const emptyStateProps = {
    title: t("search.noResults"),
    info: omitFilterText ? t("search.differentKeyword") : t("search.differentKeywordOrFilter"),
    permission: true,
    icon: NoResults,
  };

  if (filters.length > 0) {
    return isDrawer ? emptyStateProps : { ...emptyStateProps, handleRestoreDefault };
  }
  switch (tableType) {
    case "users":
      return isDrawer
        ? {
            title: t("groups.users.noUsersToAdd"),
            info: "",
            icon: NoUsers,
          }
        : opensDrawer
          ? {
              title: t("groups.users.noUsersYet"),
              info: t("groups.users.addSomeUsers"),
              addItemText: t("users.addNew"),
              onClick: onClick,
              opensDrawer: opensDrawer,
              icon: NoUsers,
              permission,
            }
          : {
              title: t("groups.users.noUsersYet"),
              info: t("groups.users.addSomeUsers"),
              addItemText: t("users.addNew"),
              opensDrawer: opensDrawer,
              redirectionUrl: URLS.users.create,
              icon: NoUsers,
              permission,
            };
    case "courses":
      return isDrawer
        ? {
            title: t("groups.courses.noCoursesToAdd"),
            info: "",
            icon: NoCourses,
          }
        : opensDrawer
          ? {
              title: t("groups.courses.noCoursesYet"),
              info: t("groups.courses.addNewCourse"),
              addItemText: t("myCourses.instructor.addNew"),
              opensDrawer: opensDrawer,
              isButtonLoading,
              onClick: onClick,
              icon: NoCourses,
              permission,
            }
          : {
              title: t("groups.courses.noCoursesYet"),
              info: t("groups.courses.createNewCourse"),
              addItemText: t("myCourses.instructor.addNew"),
              opensDrawer: true, // This one has to return true, because it's a special page which doesn't open a drawer but does not redirect the user either
              onClick: onClick,
              isButtonLoading,
              icon: NoCourses,
              permission,
            };
    case "branches":
      return isDrawer
        ? {
            title: t("branches.noBranchesToAdd"),
            info: "",
            icon: NoBranches,
          }
        : opensDrawer
          ? {
              title: t("branches.noBranchesYet"),
              info: t("branches.addNewBranch"),
              addItemText: t("branches.courses.addToBranch"),
              opensDrawer: opensDrawer,
              onClick: onClick,
              icon: NoBranches,
              permission,
            }
          : {
              title: t("branches.noBranchesYet"),
              info: t("branches.addNewBranch"),
              addItemText: t("branches.newBranch"),
              redirectionUrl: URLS.branches.create,
              opensDrawer: opensDrawer,
              icon: NoBranches,
              permission,
            };
    case "groups":
      return isDrawer
        ? {
            title: t("groups.noGroupsToAdd"),
            info: "",
            icon: NoGroups,
          }
        : opensDrawer
          ? {
              title: t("groups.noGroupsYet"),
              info: t("groups.addNewGroup"),
              addItemText: t("groups.courses.addToGroup"),
              opensDrawer: opensDrawer,
              onClick: onClick,
              icon: NoGroups,
              permission,
            }
          : {
              title: t("groups.noGroupsYet"),
              info: t("groups.addNewGroup"),
              addItemText: t("groups.addGroup"),
              opensDrawer: opensDrawer,
              redirectionUrl: URLS.groups.create,
              icon: NoGroups,
              permission,
            };
    case "payments":
      return {
        title: t("payments.noPaymentsYet"),
        info: "",
        icon: NoPayments,
      };
    case "myPayments":
      return {
        title: t("payments.emptyStateTitle"),
        info: "",
        icon: NoPayments,
      };
    case "categories":
      return {
        title: t("categories.emptyStateTitle"),
        info: t("categories.addNewCategory"),
        addItemText: t("categories.addCategory"),
        opensDrawer: opensDrawer,
        onClick: onClick,
        icon: NoCategories,
        permission,
      };
    case "automations":
      return isDrawer
        ? {
            title: t("eventsEngine.automations.noAutomationsYet"),
            info: "",
            icon: NoAutomations,
          }
        : opensDrawer
          ? {
              title: t("eventsEngine.automations.noAutomationsYet"),
              info: t("eventsEngine.automations.addNewAutomation"),
              addItemText: t("eventsEngine.automations.addAutomation"),
              opensDrawer: opensDrawer,
              onClick: onClick,
              icon: NoAutomations,
              permission,
            }
          : {
              title: t("eventsEngine.automations.noAutomationsYet"),
              info: t("eventsEngine.automations.addNewAutomation"),
              opensDrawer: opensDrawer,
              icon: NoAutomations,
              permission,
            };
    case "notifications":
      return isDrawer
        ? {
            title: t("eventsEngine.notifications.noNotificationsYet"),
            info: "",
            icon: NoNotifications,
          }
        : opensDrawer
          ? {
              title: t("eventsEngine.notifications.noNotificationsYet"),
              info: t("eventsEngine.notifications.addNewNotification"),
              addItemText: t("eventsEngine.notifications.addNotification"),
              opensDrawer: opensDrawer,
              onClick: onClick,
              icon: NoNotifications,
              permission,
            }
          : {
              title: t("eventsEngine.notifications.noNotificationsYet"),
              info: t("eventsEngine.notifications.addNewNotification"),
              opensDrawer: opensDrawer,
              icon: NoNotifications,
              permission,
            };
    case "notifications-history":
      return {
        title: t("eventsEngine.notifications.noSentNotificationsYet"),
        info: "",
        icon: NoNotifications,
      };
    case "notifications-pending":
      return {
        title: t("eventsEngine.notifications.noNotificationsPendingYet"),
        info: "",
        icon: NoNotifications,
      };
    case "files":
      return isDrawer
        ? {
            title: t("files.noFilesYet"),
            info: t("files.addNewFile"),
            icon: NoFiles,
          }
        : {
            title: t("files.noFilesYet"),
            info: t("files.addNewFile"),
            addItemText: t("files.uploadFiles"),
            opensDrawer: opensDrawer,
            onClick: onClick,
            icon: NoFiles,
            permission,
          };
    case "matrix":
      return {
        title: t("trainingMatrix.noDataYet"),
        info: t("trainingMatrix.addData"),
        icon: EmptyMatrix,
      };
    case "language_overrides":
      return {
        title: t("accountAndSettings.usersSettings.emptyStateTitle"),
        info: "",
        addItemText: t("accountAndSettings.usersSettings.addOverrides"),
        opensDrawer: opensDrawer,
        onClick: onClick,
        icon: NoLanguageOverrides,
        permission,
      };
    case "custom_user_fields":
      return {
        title: t("accountAndSettings.usersSettings.emptyStateCustomUserFieldsTitle"),
        info: "",
        addItemText: t("general.addField"),
        opensDrawer: opensDrawer,
        onClick: onClick,
        icon: NoCustomUserFields,
        permission,
      };
    case "custom_course_fields":
      return {
        title: t("accountAndSettings.courseSettings.emptyStateCustomCourseFieldsTitle"),
        info: "",
        addItemText: t("general.addField"),
        opensDrawer: opensDrawer,
        onClick: onClick,
        icon: NoCustomUserFields,
        permission,
      };
    case "customReports":
      return {
        title: t("general.noCustomReportsYet"),
        info: t("reports.customReports.addNewCustomReports"),
        addItemText: t("reports.customReports.addReport"),
        icon: NoReports,
        opensDrawer,
        onClick,
        permission,
      };
    case "customReport":
      return {
        title: t("general.noCustomReportData"),
        info: t("reports.customReports.editCustomReport"),
        addItemText: t("reports.customReports.editReport"),
        icon: NoReports,
        opensDrawer,
        onClick,
        permission,
      };
    case "units": {
      return {
        title: t("general.noUnitsYet"),
        info: "",
        icon: NoUnits,
      };
    }
    case "learningActivities": {
      return {
        title: t("general.noLearningActivitiesYet"),
        info: "",
        icon: NoUnits,
      };
    }
    case "question-weights":
      return {
        title: t("unitEdit.addQuestionsShort"),
        info: "",
        icon: NoQuestions,
      };
    case "questions":
      return {
        title: t("questions.noQuestionsFromOtherCourses"),
        info: "",
        icon: NoQuestions,
      };
    case "randomizedQuestions":
      return {
        title: t("questions.noQuestionsForPool"),
        info: "",
        icon: NoQuestions,
      };
    case "certificates": {
      return {
        title: t("certificates.emptyStateTitle"),
        info: "",
        icon: NoCertificates,
      };
    }
    case "timeline":
      return {
        title: t("general.noTimelineEventsYet"),
        info: "",
        icon: NoTimelineEvents,
      };
    case "userTypes":
    case "system-attributes":
      return emptyStateProps;
    case "scheduledReports":
      return {
        title: t("reports.scheduledReports.noScheduledReportsYet"),
        info: "",
        icon: NoScheduledReports,
      };
    case "submissions":
    case "iltSessions":
      return {
        title: t("gradingHub.nothingToGradeYet"),
        info: ``,
        icon: NoSubmissionsToGrade,
      };
    case "iltSessionsUsers":
      return {
        title: t("groups.users.noUsersYet"),
        info: opensDrawer ? t("ilt.addSessionUsers") : t("ilt.addMultiSessionUsers"),
        icon: NoUsers,
        opensDrawer: opensDrawer,
        permission,
        addItemText: t("users.addNew"),
        onClick: onClick,
      };
    case "iltCourseUsers":
      return {
        title: t("groups.users.noUsersYet"),
        info: t("courseEdit.addUsers"),
        icon: NoUsers,
        permission,
      };
    case "iltSessionsReports":
      return {
        title: t("ilt.thereAreNoPlannedSessionsYet"),
        info: ``,
        icon: NoIltSessionsReportsSVG,
      };
    case "invoices":
      return {
        title: t("billingAndSubscription.optionsPage.noInvoicesYet"),
        info: "",
        icon: NoInvoicesSVG,
      };
    case "skillUsers":
      return {
        title: t("groups.users.noUsersYet"),
        info: t("groups.users.addSomeUsers"),
        addItemText: t("skills.actions.assignUsers"),
        onClick: onClick,
        opensDrawer: true,
        icon: NoUsers,
        permission,
      };
    case "myGroups":
      return {
        title: t("myGroups.emptyState.title"),
        info: "",
        addItemText: t("myGroups.joinGroup"),
        opensDrawer: opensDrawer,
        onClick: onClick,
        icon: NoGroups,
        permission,
      };
    case "couponsList":
      return {
        title: t("accountAndSettings.ecommerce.coupons.noCouponsYet"),
        info: "",
        addItemText: t("accountAndSettings.ecommerce.coupons.addCoupon"),
        opensDrawer: opensDrawer,
        onClick: onClick,
        icon: NoCouponsSVG,
        permission,
      };
    case "conferences":
      return {
        title: t("conferences.noConferencesYet"),
        info: t("conferences.createNew"),
        addItemText: t("conferences.add"),
        opensDrawer: opensDrawer,
        onClick: onClick,
        icon: NoConferencesSVG,
        permission,
      };
    case "skillCourses":
      return {
        title: t("groups.courses.noCoursesToAdd"),
        info: "",
        opensDrawer: false,
        icon: NoCourses,
      };
    case "skillRecommendations":
      return {
        title: t("skills.noRecommendationsYet"),
        info: "",
        opensDrawer: false,
        icon: NoSkillRecommendations,
      };
    case "skillUpgrades":
      return {
        title: t("skills.noUpgradesYet"),
        info: "",
        opensDrawer: false,
        icon: NoSkillRecommendations,
      };
    case "enrollmentRequests":
      return {
        title: t("courses.courseApproval.noEnrollmentRequests"),
        info: "",
        icon: NoUsers,
      };
    default:
      return emptyStateProps;
  }
};

export default buildEmptyStateProps;

export const emptyState = ({
  title,
  info = "",
  icon = IconEmptyStateSVG,
  addItemText,
  isButtonLoading,
  opensDrawer,
  permission,
  redirectionUrl,
  onClick,
  hideInfo = false,
}: EmptyStateProps): TableProps["emptyState"] => {
  return {
    title,
    info: !permission ? "" : info,
    icon,
    footer: (
      <div css={emptyStateContainer}>
        <div className="body">
          <br />
          {permission &&
            addItemText &&
            (opensDrawer
              ? onClick && (
                  <Button
                    isLoading={isButtonLoading}
                    onClick={onClick}
                    data-testid="add-item-button"
                  >
                    <Text fontSize="md">{addItemText}</Text>
                  </Button>
                )
              : redirectionUrl && (
                  <Button as={Link} to={redirectionUrl} data-testid="add-item-button">
                    <Text fontSize="md">{addItemText}</Text>
                  </Button>
                ))}
        </div>
      </div>
    ),
    hideInfo,
  };
};

export const actionsClassNames = (isHovered: boolean): string =>
  classNames("hover-actions", {
    "is-visible": isHovered,
  });

export const actionIconClassNames = (isHovered: boolean): string =>
  classNames("actions-icon", {
    "is-visible": !isHovered,
  });
