// More details -> https://gist.github.com/joduplessis/7b3b4340353760e945f972a69e855d11
export function setCookie(name: string, value: string, path = "", domain = ""): void {
  const myDate = new Date();
  myDate.setMonth(myDate.getMonth() + 12);

  let newCookie = `${name}=${value};expires=${myDate};`;
  if (domain) newCookie += `domain=${domain};`;
  if (path) newCookie += `path=${path};`;

  document.cookie = newCookie;
}

export function getCookie(name: string): string {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  return (parts.length === 2 && parts.pop()?.split(";").shift()) || "";
}

export function deleteCookie(name: string): void {
  const date = new Date();
  // Set it expire in -1 days
  date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);

  // Delete it
  document.cookie = name + "=; expires=" + date.toUTCString() + "; path=/";
}
