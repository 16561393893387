import { css } from "@emotion/react";

export const branchesStylesMobile = ({ sidebarExtended, colors }) => css`
  .branches-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 -2rem; // Offset the padding of the parent container for full width border line.
    padding-bottom: 1.5rem;
    border-bottom: 0.0625rem solid ${colors.secondary.lighter};
  }

  .switch-label {
    margin: 0.5rem 0;
  }

  .branch-option {
    margin-bottom: 1.5rem;
    padding: 0.375rem;
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: space-between;
    align-items: center;

    .branch-option-text {
      width: 100%;
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      display: flex;
      background: ${sidebarExtended.backgroundHover};
      border-radius: 0.3125rem;
      cursor: pointer;
    }
  }

  .checked-icon svg {
    width: 1.125rem;
  }

  .branch-search {
    margin-bottom: 1rem;
  }
`;
