import { PalletThemeColors, isShadeOfWhite } from "@styles/utils";
import { ThemePlusTypes } from "@styles/types";

export default (colors: PalletThemeColors): ThemePlusTypes["themeCard"] => {
  const isLightTheme = isShadeOfWhite(colors.primary.base);

  return {
    color: isLightTheme ? colors.black : colors.white,
  };
};
