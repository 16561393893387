import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const searchContainer = ({ customLink }) => css`
  position: relative;
  display: none;
  margin-inline: 0 1rem;
  margin-block: 0;

  ${mq["md"]} {
    display: block;
  }

  .spotlight-result {
    display: flex;
    align-items: center;

    &.detailed-results-link {
      justify-content: center;

      button {
        height: auto;
      }
    }

    .spotlight-image,
    .file-tag,
    .spotlight-option-icon {
      margin-inline-end: 0.5rem;
      border-radius: 3px;
    }

    .spotlight-image {
      height: 40px;
      width: 53px;
    }

    .spotlight-option-icon {
      height: 40px;
      width: 53px;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        height: 40px;
        width: auto;
      }
    }

    .file-tag {
      width: 53px;
    }

    .highlighted {
      color: ${customLink.primary.color} !important;
      text-decoration: underline;
    }

    .subtitle {
      color: #7a7a7a;
    }

    &:hover {
      cursor: pointer;

      .highlighted,
      .title,
      .subtitle {
        color: ${customLink.primary.hoverColor} !important;
      }

      .spotlight-option-icon {
        button {
          background-color: ${customLink.primary.color} !important;
          color: #fff;

          svg {
            fill: #fff;
          }
        }
      }
    }
  }

  .no-results-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;

    svg {
      margin-bottom: 2rem;
    }

    .title {
      color: initial;
    }
  }

  .spotlight-avatar {
    svg {
      height: 32px !important;
    }
  }
`;

export const MainSearchMenuListStyles = ({ base }) => ({
  ...base,
  paddingTop: 0,
  paddingBottom: 0,
  maxHeight: "80vh",
  height: "auto",
});

export const mobileSearch = css`
  .searchContainer {
    display: flex;
    padding-inline: 1rem 0.5rem;
    padding-block: 1rem;

    div {
      margin-inline-end: 0;
    }

    .close-btn {
      margin-inline-start: 0.5rem;
    }
  }
`;

export const userCardContainer = css`
  &:hover {
    cursor: pointer;
  }

  .card-wrapper {
    margin: 0;
    background: transparent;
    width: 100%;
    border: 0;
  }

  section {
    display: flex;
    padding: 0.5rem;

    .user-text-container {
      text-align: start;
      min-width: 0;
      margin-inline-start: 0.5rem;
      word-break: break-all;
    }
  }
`;

export const searchUnitCardContainer = css`
  .unit-content {
    &:hover {
      cursor: pointer;
    }

    section {
      display: flex;
      padding: 0.5rem;

      .unit-image {
        max-height: 40px;
        max-width: 53px;
        width: auto;
        height: auto;
        margin-inline-end: 0.5rem;
      }

      .unit-text-container {
        min-width: 0;

        div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
`;

export const searchOptiontCardContainer = ({ customLink }) => css`
  .option-content {
    &:hover {
      cursor: pointer;

      .spotlight-option-icon {
        button {
          background-color: ${customLink.primary.color};
          color: #fff;

          svg {
            fill: #fff;
          }
        }
      }
    }

    .option-btn-wrapper {
      margin: 0;
      background: transparent;
      width: 100%;
      border: 0;
    }

    section {
      display: flex;
      padding: 0.5rem;
      align-items: center;

      .spotlight-option-icon {
        height: 40px;
        width: 53px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-inline-end: 0.5rem;

        button {
          height: 40px;
          width: auto;
        }
      }

      .option-text-container {
        min-width: 0;

        div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
`;

export const searchCardContainer = css`
  &:hover {
    cursor: pointer;
  }

  .card-wrapper {
    margin: 0;
    background: transparent;
    width: 100%;
    border: 0;
  }

  section {
    display: flex;
    padding: 0.5rem;
    align-items: center;
    gap: 0.5rem;

    .text-container {
      min-width: 0;
      margin-inline-start: 0.5rem;
      word-break: break-all;
    }
  }

  svg {
    height: 32px !important;
  }
`;
