import { userHasRequiredPermissions } from "./index";
import { useConfigurationStore } from "@stores";
import authService from "@utils/services/AuthService";

// Map with all the available permissions
export const conferencePermissions = {
  read: "conference.read",
  create: "conference.create",
};

const { read, create } = conferencePermissions;
const { getState } = useConfigurationStore;

export default {
  /**
   * Permission => instructor.conference.read
   *
   * Allowed actions:
   *  GET conferences
   *
   * @returns boolean
   */
  canAccessConferences: (): boolean => {
    const userRole = authService.getDefaultRole();

    return userHasRequiredPermissions([read], getState().userProfileData?.permissions[userRole]);
  },
  /**
   * Permission => instructor.conference.create
   *
   * Allowed actions:
   *  POST conferences
   *
   * @returns boolean
   */
  canCreateConferences: (): boolean => {
    const userRole = authService.getDefaultRole();

    return userHasRequiredPermissions([create], getState().userProfileData?.permissions[userRole]);
  },
};
