import { css } from "@emotion/react";
import { typeScale } from "@epignosis_llc/gnosis";

export const betaTagStyles = (theme) => css`
  color: ${theme.colors.primary.base};
  background-color: ${theme.colors.white};
  line-height: ${typeScale["2xs"]};
  font-size: ${typeScale["2xs"]};
  padding: 2px 5px;
`;

export const betaTagStylesForMainMenu = () => css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 8px;
`;

export const betaTagStylesForHeader = (theme) => css`
  position: absolute;
  background-color: ${theme.button.disabled.background};
  top: -7px;
  left: 81%;
`;
