import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["timeLimitBar"] => {
  return {
    background: colors.primary.base,
    sucessBackground: colors.green.base,
    dangerBackground: colors.red.base,
    barBackground: colors.secondary.lighter,
  };
};
