import { t } from "@utils/i18n";
import ReactFroalaEditor from "froala-editor";

export const insertImageBadLInkMessage = (): string => `
  <div>
    <span> ${t("editor.imageCannotBeLoaded")} </span>
    <a
      target="_blank"
      rel="noreferrer"
      href="https://help.talentlms.com/hc/en-us/articles/360014572654-How-to-upload-files-from-Dropbox-to-TalentLMS"
    >
      Dropbox
    </a>
    <span> ${t("editor.or")} </span>
    <a
      target="_blank"
      rel="noreferrer"
      href="https://help.talentlms.com/hc/en-us/articles/360014657713-How-to-upload-files-from-Google-Drive-to-your-courses"
    >
      Google Drive
    </a>
    <span> ${t("editor.toLoadFiles")} </span>
  </div>
`;

/*
 Utility class, a replacement for "editor.html.insert".
 Useful for pages with multiple froala editors, where we want to allow users to use one editor
 while another one is being populated by AI.

 "editor.html.insert" would constantly steal focus, and make all other editors inside the page unusable.
 */
export class FroalaInserter {
  private range: Range | null = null;

  constructor(private editor: ReactFroalaEditor) {}

  insertPlainText(textToInsert: string): void {
    if (this.range === null) {
      const selection: Selection | undefined = this.editor.selection.get() as Selection;
      this.range = selection.getRangeAt(0);
    }

    if (!this.editor.el || !this.range.intersectsNode(this.editor.el)) {
      return;
    }

    this.range.collapse(false);
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = textToInsert;

    const fragment = document.createDocumentFragment();
    Array.from(tempDiv.childNodes).forEach((node) => {
      fragment.appendChild(node.cloneNode(true));
    });
    this.range.insertNode(fragment);
    this.range.collapse(false);
  }
}

export function editorSelectionContainsInvalidElements(
  editor: ReactFroalaEditor | undefined,
): boolean {
  const allowedTags = [
    "a",
    "br",
    "p",
    "em",
    "strong",
    "b",
    "i",
    "u",
    "sup",
    "sub",
    "mark",
    "span",
    "code",
    "kbd",
    "s",
    "del",
    "blockquote",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "ul",
    "ol",
    "li",
    "hr",
  ];

  const selection: Selection | undefined = editor?.selection?.get() as Selection;

  if (selection && selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);
    const clonedContent = range.cloneContents();

    for (const node of clonedContent.childNodes) {
      if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node as HTMLElement;
        if (!allowedTags.includes(element.tagName.toLowerCase())) {
          return true;
        }
      } else if (node.nodeType === Node.TEXT_NODE) {
        // Continue
      } else {
        return true;
      }
    }
  }

  return false;
}
