import Color from "color";
import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["widgetsDashboard"] => {
  return {
    background: colors.secondary.lighter,
    droppableBackground: Color(colors.primary.lightest).alpha(0.25).string(),
    previewBackground: colors.white,
    widgetBorder: colors.secondary.light,
  };
};
