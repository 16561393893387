import { css } from "@emotion/react";

export const container = css`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .error-msg {
    margin-top: 3rem;
    text-align: center;
  }

  svg {
    max-width: 820px !important;
    padding: 0 1rem;
  }
`;
