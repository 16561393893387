import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["rating"] => {
  return {
    color: colors.orange.base,
    background: colors.secondary.light,
    hover: colors.orange.base,
  };
};
