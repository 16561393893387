import React, { FC, MouseEvent } from "react";
import { IconType } from "types/common";
import ActionButton from "./ActionButton";
import { ResetSVG } from "@epignosis_llc/gnosis/icons";
import { useApplyTranslations } from "@hooks";

export type ResetActionProps = {
  tooltipContent?: string;
  icon?: IconType;
  iconHeight?: number;
  onClick: (e: MouseEvent) => void;
};

const ResetAction: FC<ResetActionProps> = ({
  tooltipContent,
  icon: Icon = ResetSVG,
  iconHeight = 32,
  onClick,
}) => {
  const { t } = useApplyTranslations();

  return (
    <ActionButton
      tooltipContent={tooltipContent ?? t("general.reset")}
      onClick={onClick}
      data-testid="reset-action"
    >
      <Icon height={iconHeight} />
    </ActionButton>
  );
};

export default ResetAction;
