import { useMutation } from "react-query";
import { AxiosError } from "axios";

// Hooks, stores, utils
import { stopUserImpersonation } from "@views/User/api";
import { useUIStore, useConfigurationStore } from "@stores";
import { handleImpersonationErrors } from "@errors/errors";
import { useGetAutologinURL } from "@hooks";

// Constants
import queryKeys from "@constants/queryKeys";
import { URLS } from "@constants/urls";

type UseReturnToLegacyProps = {
  handleReturnToLegacy: () => void;
};
// This functionality is taken from the AutoLogin component.
const useReturnToLegacy = (): UseReturnToLegacyProps => {
  const { userProfileData } = useConfigurationStore();

  const setShowRedirectLoading = useUIStore((state) => state.setShowRedirectLoading);
  const { mutate: switchFromPlusToTalentMutation } = useGetAutologinURL();
  const isImpersonation = userProfileData?.impersonated;

  const handleReturnToLegacy = (): void => {
    if (isImpersonation) {
      stopImpersonationMutation();
    } else {
      setShowRedirectLoading(true);
      switchFromPlusToTalentMutation();
    }
  };

  const { mutate: stopImpersonationMutation } = useMutation(
    [queryKeys.users.stopImpersonation],
    () => stopUserImpersonation(),
    {
      onSuccess: (res) => {
        const response = res._data;
        const { token, domain } = response;
        window.location.replace(
          `https://${domain}/plus${URLS.autologin}?token=${token}&role=administrator&redirect_path=${URLS.users.users}`,
        );
      },

      onError: (error: AxiosError) => {
        handleImpersonationErrors(error);
      },
    },
  );

  return { handleReturnToLegacy };
};

export default useReturnToLegacy;
