import React, { FC, MouseEvent } from "react";
import { CheckRegularSVG } from "@epignosis_llc/gnosis/icons";

import ActionButton from "./ActionButton";

import { IconType } from "types/common";
import { useApplyTranslations } from "@hooks";

export type ApproveActionProps = {
  tooltipContent?: string;
  icon?: IconType;
  onClick: (e: MouseEvent) => void;
};

const ApproveAction: FC<ApproveActionProps> = ({
  tooltipContent,
  icon: Icon = CheckRegularSVG,
  onClick,
}) => {
  const { t } = useApplyTranslations();

  return (
    <ActionButton
      tooltipContent={tooltipContent ?? t("general.approve")}
      onClick={onClick}
      data-testid="approve-action"
      aria-label={t("general.approve")}
    >
      <Icon height={20} />
    </ActionButton>
  );
};

export default ApproveAction;
