import React, { FC } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "@hooks";
import { URLS } from "@constants/urls";

const RootPage: FC = () => {
  const { isAuthenticated } = useAuth();

  // Check if the user is authenticated and redirect to the dashboard else redirect to the login page
  return isAuthenticated ? <Navigate to={URLS.dashboard} /> : <Navigate to={URLS.login} />;
};

export default RootPage;
