/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
  interface PromiseConstructor {
    withResolvers<T>(): PromiseWithResolvers<T>;
  }
}

type PromiseWithResolvers<T> = {
  promise: Promise<T>;
  resolve: (value: T | PromiseLike<T>) => void;
  reject: (reason?: any) => void;
};

// Solution based on this comment https://github.com/wojtekmaj/react-pdf/issues/1811#issuecomment-2157866061
export const polyfillPromiseWithResolvers = (): void => {
  if (typeof Promise.withResolvers === "undefined") {
    if (window) {
      window.Promise.withResolvers = function <T>(): PromiseWithResolvers<T> {
        let resolve!: (value: T | PromiseLike<T>) => void;
        let reject!: (reason?: any) => void;

        const promise = new Promise<T>((res, rej) => {
          resolve = res;
          reject = rej;
        });

        return { promise, resolve, reject };
      };
    } else {
      global.Promise.withResolvers = function <T>(): PromiseWithResolvers<T> {
        let resolve!: (value: T | PromiseLike<T>) => void;
        let reject!: (reason?: any) => void;

        const promise = new Promise<T>((res, rej) => {
          resolve = res;
          reject = rej;
        });

        return { promise, resolve, reject };
      };
    }
  }
};
