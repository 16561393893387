import { css } from "@emotion/react";

export const ScreenSizeAlertStyles = ({ screenSizeAlert }) => css`
  // Height is 100vh - 88px (header height + padding).
  height: calc(100vh - 88px);
  display: flex;
  margin-top: 5rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  span:not(".back-btn") {
    color: ${screenSizeAlert.subtitle};
  }
`;
