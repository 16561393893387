import { gamificationNotification } from "@utils/helpers";
import { GamificationBadge, BadgeCriteria } from "types/entities";
import { GamificationRes } from "types/responses";
import { t } from "@utils/i18n";

type Events = Record<
  GamificationRes["type"],
  {
    pointsContent: (points: number) => string;
    badgeContent: (name: string, criteria: BadgeCriteria) => string;
  }
>;

type BadgeNotificationProps = {
  content: string;
  badge: GamificationBadge;
};

// Mapping to determine dynamic content in the different gamification events
const events: Events = {
  "user.login": {
    pointsContent: (points: number): string =>
      t("notifications.gamification.userLogin", { points: points }),
    badgeContent: (name: string, criteria: BadgeCriteria): string =>
      t("notifications.gamification.loginBadgeUnlock", { name: name, count: criteria.count }),
  },
  "discussion.create": {
    pointsContent: (points: number): string =>
      t("notifications.gamification.discussionCreate", { points: points }),
    badgeContent: (name: string, criteria: BadgeCriteria): string =>
      t("notifications.gamification.discussionBadgeUnlock", { name: name, count: criteria.count }),
  },
  "discussion.reply": {
    pointsContent: (points: number): string =>
      t("notifications.gamification.discussionReply", { points: points }),
    badgeContent: (name: string, criteria: BadgeCriteria): string =>
      t("notifications.gamification.discussionBadgeUnlock", { name: name, count: criteria.count }),
  },
  "unit.completion": {
    pointsContent: (points: number): string =>
      t("notifications.gamification.unitCompletion", { points: points }),
    badgeContent: (name: string, criteria: BadgeCriteria): string =>
      t("notifications.gamification.unitCompletionBadgeUnlock", {
        name: name,
        count: criteria.count,
      }),
  },

  "course.completion": {
    pointsContent: (points: number): string =>
      t("notifications.gamification.courseCompletion", { points: points }),
    badgeContent: (name: string, criteria: BadgeCriteria): string =>
      t("notifications.gamification.courseCompletionBadgeUnlock", {
        name: name,
        count: criteria.count,
      }),
  },
  "unit.test.completion": {
    pointsContent: (points: number): string =>
      t("notifications.gamification.testCompletion", { points: points }),
    badgeContent: (name: string, criteria: BadgeCriteria): string =>
      t("notifications.gamification.testCompletionBadgeUnlock", {
        name: name,
        count: criteria.count,
      }),
  },
  "course.certificate.acquisition": {
    pointsContent: (points: number): string =>
      t("notifications.gamification.certificateAcquisition", { points: points }),
    badgeContent: (name: string, criteria: BadgeCriteria): string =>
      t("notifications.gamification.certificateAcquisitionBadgeUnlock", {
        name: name,
        count: criteria.count,
      }),
  },
  "unit.assignment.completion": {
    pointsContent: (points: number): string =>
      t("notifications.gamification.unitAssignmentCompletion", { points: points }),
    badgeContent: (name: string, criteria: BadgeCriteria): string =>
      t("notifications.gamification.unitAssignmentCompletionBadgeUnlock", {
        name: name,
        count: criteria.count,
      }),
  },
  "unit.survey.completion": {
    pointsContent: (points: number): string =>
      t("notifications.gamification.unitSurveyCompletion", { points: points }),
    badgeContent: (name: string, criteria: BadgeCriteria): string =>
      t("notifications.gamification.unitSurveyCompletionBadgeUnlock", {
        name: name,
        count: criteria.count,
      }),
  },
  "unit.ilt.completion": {
    pointsContent: (points: number): string =>
      t("notifications.gamification.unitIltCompletion", { points: points }),
    badgeContent: (name: string, criteria: BadgeCriteria): string =>
      t("notifications.gamification.unitIltCompletionBadgeUnlock", {
        name: name,
        count: criteria.count,
      }),
  },
};

const pointsNotification = (content: string): void => {
  gamificationNotification({
    type: "success",
    iconType: "points",
    content: content,
  });
};

const levelNotification = (content: string): void => {
  gamificationNotification({
    type: "success",
    iconType: "level",
    content: content,
  });
};

const badgeNotification = ({ content, badge }: BadgeNotificationProps): void => {
  gamificationNotification({
    type: "success",
    iconType: "badge",
    content: content,
    heading: t("general.congratulations"),
    badge: badge,
  });
};

export const showGamificationNotification = (gamification: GamificationRes[]): void => {
  gamification.forEach((gamificationRes) => {
    const eventType = events[gamificationRes.type];
    const points = gamificationRes.points;
    const badges = gamificationRes.badges;
    const level = gamificationRes.new_level;

    if (points) {
      pointsNotification(eventType.pointsContent(points));
    }

    if (level) {
      levelNotification(t("notifications.gamification.levelUpContent", { level: level }));
    }

    if (badges?.length > 0) {
      badges.map((badge) => {
        badgeNotification({
          content: eventType.badgeContent(badge.name, badge.criteria as BadgeCriteria),
          badge: badge,
        });
      });
    }
  });
};
