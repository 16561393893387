import { Column, DropdownItem, TableProps, Tooltip } from "@epignosis_llc/gnosis";
import { t } from "@utils/i18n";
import { QueryFilter } from "types/common";
import React from "react";
import { InfoButtonSVG } from "@epignosis_llc/gnosis/icons";
import { talbleHeaderWithInfoTooltipStyles } from "./styles";
import { MassActionEnum } from "@components/ReusableComponents/MassActions/types";
import { Filters } from "@utils/helpers/filters";

export const getUsersTableColumns = (hasSkillFilters: boolean): Column[] => {
  return [
    { accessor: "formatted_name", cell: t("general.name"), sortableHeader: true },
    {
      accessor: "skills_progress",
      cell: hasSkillFilters ? t("skills.column.progress") : t("skills.skills"),
      sortableHeader: true,
      headerWidth: 180,
      sortOrder: "desc",
    },
    {
      accessor: "requests",
      cell: (): JSX.Element => {
        return (
          <div css={talbleHeaderWithInfoTooltipStyles}>
            <span>{t("skills.column.requests")}</span>
            <Tooltip placement="top" content={t("skills.tooltip.requestsHeaderTooltip")}>
              <InfoButtonSVG height={16} id="tooltip-icon" />
            </Tooltip>
          </div>
        );
      },
      sortableHeader: true,
      sortOrder: "desc",
    },
  ];
};

export const getSkillUsersMassActionsOptions = (): DropdownItem[] => {
  return [
    {
      label: t("skills.actions.unassignSkills"),
      value: MassActionEnum.unassign_skills,
    },
  ];
};

export const getSelectedSkillIds = (filters: QueryFilter[]): number[] => {
  return filters.filter((it) => it.key === Filters.Skill).map((filter) => Number(filter.value));
};

export const unassignSkillsToUsersWordings = {
  unassign_skills: {
    progressHeader: "skills.modal.unassignSkillsHeader",
    progressMessage: "skills.modal.unassignSkillsProgress",
    confirmationHeader: "skills.modal.unassignSkillsHeader",
    confirmationButton: "skills.actions.unassign",
    successMessage: "skills.modal.unassignSkillsSuccess",
    confirmationBodyTitle: "skills.modal.unassignSkillsWarning",
    confirmationBodyText: "skills.modal.unassignSkillOccurences",
  },
};

export const DEFAULT_FILTERS: QueryFilter[] = [];
export const SORTING: TableProps["sorting"] = { column: "formatted_name", isDescending: false };
