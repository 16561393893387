import { css } from "@emotion/react";

export const tabs = () => css`
  .nav-wrapper {
    margin-bottom: 2rem;
  }
`;

export const header = (withBottomMargin) => css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: ${withBottomMargin ? 1 : 0}rem;

  .actions-container {
    margin-bottom: 0;
  }

  button.settingsButton {
    align-self: center;

    .btn-text {
      align-items: center;
      gap: 0.5rem;
    }
  }
`;

export const roleDescriptionIcon = () => css`
  vertical-align: middle;
`;

export const topNavButtons = () => css`
  position: absolute;
  top: 1rem;
  inset-inline-start: 1rem;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  flex-direction: column;

  @media screen and (min-width: 1240px) {
    flex-direction: row;
  }
`;

export const headerTitle = () => css`
  position: relative;
`;

export const pathItemContentStyles = () => css`
  line-height: 1;
  top: unset;
  padding-left: 2rem;
  margin-bottom: 2rem;
`;

export const pathItemLabelStyles = ({ button, icon }, disabled) => css`
  display: flex;
  gap: ${distanceFromTooltip};
  align-items: center;

  ${disabled &&
  css`
    color: ${button.disabled.color};
  `}

  ${fixTooltipHeight()}

  #tooltip-icon {
    cursor: help;
    color: ${icon.primary};
  }
`;

export const distanceFromTooltip = "0.313rem";

export const fixTooltipHeight = () => css`
  [role="tooltip"] {
    display: inline-flex; // To ignore any additional height introduced by line-height
  }
`;

export const headerButtonsContainer = () => css`
  display: flex;
  gap: 1rem;

  #skills-admin-tour-btn {
    svg {
      height: 16px;
    }
  }
`;
