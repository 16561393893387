// Packages or third-party libraries
import React, { FC } from "react";
import { SerializedStyles } from "@emotion/react";

// Styles
import { rewardProgressBarContainer } from "./styles";

// Components
import { Text } from "@epignosis_llc/gnosis";
import { UnitCompleteSVG } from "@epignosis_llc/gnosis/icons";

// Hooks
import { useApplyTranslations } from "@hooks";

export type RewardProgressBarProps = {
  percent: number | string;
};

const RewardProgressBar: FC<RewardProgressBarProps> = ({ percent }) => {
  const { t } = useApplyTranslations();

  const isProgressNumber = typeof percent === "number";

  return (
    <div
      css={(theme): SerializedStyles =>
        rewardProgressBarContainer(theme, { percent, isProgressNumber })
      }
    >
      {isProgressNumber && <div className="percentage-bar" />}
      <div className="completion-container">
        {!isProgressNumber && <UnitCompleteSVG height={24} />}
        <Text
          dangerouslySetInnerHTML={{
            __html: isProgressNumber ? `${percent}&rlm;%` : t(percent),
          }}
          fontSize="xs"
          className="label"
          data-testid="percentage"
        />
      </div>
    </div>
  );
};

export default RewardProgressBar;
