import { Column, DropdownItem, TableProps } from "@epignosis_llc/gnosis";
import {
  AssignmentPendingFilter,
  AssignmentPassedFilter,
  AssignmentNotPassedFilter,
} from "@epignosis_llc/gnosis/icons";
import { t } from "@utils/i18n";
import { URLS } from "@constants/urls";
import { QueryFilter } from "types/common";
import { Pagination as IPagination } from "types/responses/index";
import { IltTable } from "./types";

const PAGINATION: IPagination = { number: 1, size: 10 };
const SORTING: TableProps["sorting"] = { column: "session_date", isDescending: false };
export const DEFAULT_FILTERS: QueryFilter[] = [];

export enum IltSessionsFilters {
  status = "[status]",
}

export const DEFAULT_STATE = {
  pagination: PAGINATION,
  sorting: SORTING,
  filters: DEFAULT_FILTERS,
  url: URLS.gradingHub.gradingHub,
};

export const USERS_DEFAULT_STATE = {
  pagination: PAGINATION,
  sorting: SORTING,
  filters: DEFAULT_FILTERS,
  url: "",
};

export const getIltSessionsColumns = (
  type: IltTable,
  isSelectedSessionLinked = false,
): Column[] => {
  const isUsersTable = type === "iltSessionsUsers";
  const isSessionsTable = type === "iltSessions";

  return [
    {
      accessor: "user",
      cell: t("general.user"),
      classNames: ["users"],
    },
    {
      accessor: "course",
      cell: t("general.course"),
      classNames: ["courseName"],
      hidden: isUsersTable || isSelectedSessionLinked,
    },
    {
      accessor: "unit",
      cell: t("general.unit"),
      classNames: ["unitName"],
      hidden: isUsersTable || isSelectedSessionLinked,
    },
    {
      accessor: "session_date",
      cell: t("gradingHub.sessionDate"),
      classNames: ["submissionDate"],
      hidden: isUsersTable || isSelectedSessionLinked,
    },
    {
      accessor: "grade_date",
      cell: t("gradingHub.gradeDate"),
      classNames: ["gradeDate"],
      hidden: isSelectedSessionLinked,
    },
    {
      accessor: "grade",
      cell: t("general.actions.grade"),
      classNames: ["grade"],
      hidden: isSelectedSessionLinked,
    },
    {
      accessor: "status",
      cell: t("general.status"),
      classNames: ["status"],
      hidden: isSelectedSessionLinked,
    },
    {
      accessor: "attendance",
      cell: t("general.attendance"),
      classNames: ["attendance"],
      hidden: isSessionsTable,
      sortableHeader: false,
    },
  ];
};

export const actionsColumn = {
  accessor: "actions",
  cell: "",
  classNames: ["hidden"],
  sortableHeader: false,
};

export const getIltSessionsFilterOptions = (): DropdownItem[] => [
  {
    label: t("general.progress.pending"),
    value: "pending",
    category: IltSessionsFilters.status,
    icon: AssignmentPendingFilter,
  },
  {
    label: t("general.progress.passed"),
    value: "passed",
    category: IltSessionsFilters.status,
    icon: AssignmentPassedFilter,
  },
  {
    label: t("general.progress.notPassed"),
    value: "not-passed",
    category: IltSessionsFilters.status,
    icon: AssignmentNotPassedFilter,
  },
];

export const NAME_COL_MAX_WIDTH = 200;
