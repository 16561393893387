import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const main = () => {
  return css`
    .main-content-container {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;

      .main-content-wrapper {
        width: 100%;
        max-width: 100%;
        min-height: 100vh;
        height: 100%;

        .announcements {
          margin-bottom: 1rem;

          ${mq["lg"]} {
            margin-bottom: 2rem;
          }
        }
      }
    }
  `;
};
