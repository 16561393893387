import { css } from "@emotion/react";

export const passwordStrengthBarContainer = ({ passwordStrengthBar }) => css`
  text-align: end;

  .bar-wrapper {
    background: ${passwordStrengthBar.background};
    width: 100%;
  }

  .bar {
    height: 4px;
    border-radius: 5px;
  }
`;
