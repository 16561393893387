import {
  differenceInSeconds,
  format,
  formatDistanceToNowStrict,
  parseISO,
  intervalToDuration,
  addSeconds,
  getYear,
  startOfMinute,
  addMinutes,
  isPast,
  setMilliseconds,
  setSeconds,
  setMinutes,
  setHours,
  isWithinInterval,
  getDay,
} from "date-fns";
import { utcToZonedTime, toDate, formatInTimeZone, zonedTimeToUtc } from "date-fns-tz";
import localStorageKeys from "@constants/localStorageKeys";
import { useConfigurationStore } from "@stores";
import { t } from "@utils/i18n";
import { Availability } from "types/entities";
import locales from "@constants/locales";
import { CourseUser } from "@views/Course/CourseUsers/types";

const { getState } = useConfigurationStore;
const JANUARY = 0;
const JULY = 6;

export const secondsToTime = (
  seconds: number,
  isHumanReadable = false,
  showSeconds = true,
  padZeroes = 2, // Adds zeroes to the left, so we reach max length(padZeroes). eg. padZeroes = 2, 5s -> 05s
): string => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
  const { years, months, days, hours, minutes, seconds: secs } = duration;
  const showNoSeconds = !isHumanReadable && !secs ? "00" : "";
  const showNoMinutes = !isHumanReadable && !minutes && hours ? "00:" : "";

  if (!seconds) return "";

  const yearsText = years ? `${years}${isHumanReadable ? "y " : ":"}` : "";
  const monthsText = months ? `${months}${isHumanReadable ? "m " : ":"}` : "";
  const daysText = days ? `${days}${isHumanReadable ? "d " : ":"}` : "";
  const hoursText = hours ? `${hours}${isHumanReadable ? "h " : ":"}` : "";
  const minutesText = minutes
    ? `${minutes.toString().padStart(padZeroes, "0")}${
        isHumanReadable ? "m " : showSeconds ? ":" : ""
      }`
    : "";
  const secondsText =
    showSeconds && secs
      ? `${secs.toString().padStart(padZeroes, "0")}${isHumanReadable ? "s " : ""}`
      : "";
  return `${yearsText}${monthsText}${daysText}${hoursText}${minutesText}${showNoMinutes}${secondsText}${showNoSeconds}`;
};

export const datesDifferenceInSeconds = (startDate: string, endDate = new Date()): number => {
  return differenceInSeconds(parseISO(startDate), endDate);
};

export const getLocale = (): Locale => {
  const localStorageLanguage = localStorage.getItem(localStorageKeys.LANGUAGE_LOCALE) as
    | keyof typeof locales
    | null;

  return locales[localStorageLanguage ?? "en-US"];
};

type DateFormatOption = "date" | "time" | "datetime" | "datetimeWithSeconds";

export const dateFormatsMapping = {
  DDMMYYYY: "dd/MM/yyyy",
  MMDDYYYY: "MM/dd/yyyy",
  YYYYMMDD: "yyyy/MM/dd",
};

export const timeFormatsMapping = {
  hh: "general.timeFormats.hh",
  HH: "general.timeFormats.HH",
};

export const formatUtcDate = (
  dateInUtc: string,
  dateFormatOption: DateFormatOption = "date",
): string => {
  const { userProfileData, domainSettings } = getState();

  if (domainSettings) {
    const { timezone: userTimezone } = userProfileData ?? {};
    const { date_format, time_format, timezone: domainTimezone } = domainSettings;

    // If the user is not authenticated use the domain timezone
    const timezone = userTimezone ?? domainTimezone;
    const dateWithTimezone = timeZonedDate(dateInUtc, timezone).toISOString();
    const formatTemplate = dateFormatsMapping[date_format as keyof typeof dateFormatsMapping];

    if (dateFormatOption === "date") {
      return format(parseISO(dateWithTimezone), formatTemplate, { locale: getLocale() });
    }

    if (dateFormatOption === "datetimeWithSeconds") {
      const timeFormatInSeconds = `${time_format}:mm:ss ${time_format === "hh" ? "a" : ""}`;
      const formatString = `${formatTemplate}, ${timeFormatInSeconds}`;

      return format(parseISO(dateWithTimezone), formatString, { locale: getLocale() });
    }

    const timeFormat = `${time_format}:mm ${time_format === "hh" ? "a" : ""}`;
    const formatString =
      dateFormatOption === "time" ? timeFormat : `${formatTemplate}, ${timeFormat}`;

    return format(parseISO(dateWithTimezone), formatString, { locale: getLocale() });
  }
  return dateInUtc;
};

export const getDistanceFromNowInSeconds = (date: Date): number => {
  const distance = formatDistanceToNowStrict(date, { unit: "second" });
  return Number(distance.replace("seconds", "").trim());
};

export const getDurationDateFromSeconds = (seconds: number, date: Date = new Date()): Duration => {
  const startDate = timeZonedDate(date);
  const endDate = timeZonedDate(addSeconds(date, seconds));
  const duration = intervalToDuration({ start: startDate, end: endDate });

  return duration;
};

export const timeZonedDate = (date: string | Date, timezone = "UTC"): Date => {
  return parseISO(utcToZonedTime(date, timezone).toISOString());
};

export const userTimeZonedDate = (date: string | Date): Date => {
  const { userProfileData, domainSettings } = getState();
  const { timezone: userTimezone } = userProfileData ?? {};
  const { timezone: domainTimezone } = domainSettings ?? {};
  const timezone = userTimezone ?? domainTimezone;

  return parseISO(utcToZonedTime(date, timezone ?? "UTC").toISOString());
};

export const getDistanceFromNow = (date: string): string => {
  const inputDate = new Date(date);
  const now = new Date();
  const isInTheFuture = inputDate.getTime() > now.getTime();

  const today = Date.parse(new Date().toISOString());
  const {
    years = 0,
    months = 0,
    days = 0,
    hours = 0,
    minutes = 0,
    seconds = 0,
  } = intervalToDuration({ start: Date.parse(date), end: today });

  if (years > 0 || months > 0 || isInTheFuture) {
    return formatUtcDate(date);
  }

  const displayJustNow = days === 0 && hours === 0 && minutes === 0 && seconds <= 59;
  const displayMinutesAgo = days === 0 && hours === 0 && minutes <= 59;
  const displayHoursAgo = days === 0 && hours <= 23 && minutes <= 59;
  const displayYesterday = days === 1 && hours <= 23;

  if (displayJustNow) {
    return t("general.justNow");
  } else if (displayMinutesAgo) {
    return t("general.minute", { count: minutes });
  } else if (displayHoursAgo) {
    return t("general.hour", { count: hours });
  } else if (displayYesterday) {
    return t("general.yesterday");
  }

  return formatUtcDate(date);
};

export const getYearsPickerRange = (date: Date, yearItemNumber = 12): string => {
  const endPeriod = Math.ceil(getYear(date) / yearItemNumber) * yearItemNumber;
  const startPeriod = endPeriod - (yearItemNumber - 1);
  return `${startPeriod} - ${endPeriod}`;
};

export const getDelayInSeconds = (availability: Availability | null): number => {
  // (not enrolled OR enrolled) course without delayed availability
  if (!availability || !availability.delay) return 0;

  // Enrolled course with delayed availability
  if (availability.available_on) {
    return Number(getDistanceFromNowInSeconds(toDate(availability.available_on)));
  }

  // Not enrolled course with delayed availability
  return availability.delay;
};

export const getDomainDateFormat = (): string => {
  const { domainSettings } = getState();
  const domainDateFormat = domainSettings?.date_format ?? "DDMMYYYY";

  return dateFormatsMapping[domainDateFormat as keyof typeof dateFormatsMapping];
};

export const formatDateToUTCstring = (date: Date): string => {
  return formatInTimeZone(date, "Etc/UTC", "yyyy-MM-dd'T'HH:mm:ssxxx");
};

export const formatToISOstring = (date: Date): string => {
  return format(date, "yyyy-MM-dd'T'HH:mm:ssxxx");
};

export const getDomainTimeFormat = (): string => {
  const { domainSettings } = getState();
  const domainTimeFormat = domainSettings?.time_format ?? "HH";

  return `${domainTimeFormat}:mm ${domainTimeFormat === "hh" ? "a" : ""}`;
};

export const getDomainDateFormatWithoutYears = (): string => {
  const { domainSettings } = getState();
  const domainDateFormat = domainSettings?.date_format ?? "DDMMYYYY";

  return domainDateFormat === "DDMMYYYY" ? "dd/MM" : "MM/dd";
};

export const getCurrentTimestampString = (): string => {
  return Math.floor(Date.now() / 1000).toString();
};

// Gets date in browsers timezone and converts it to users timezone and then converts it to UTC string
export const formatSelectedDate = (date: Date | null, timezone: string): string | null => {
  if (!date) return null;
  return formatDateToUTCstring(zonedTimeToUtc(date, timezone));
};

export const calculateCompletionDate = (
  selectedUser: CourseUser,
  enrolled_date: string,
  timezone: string,
): string | null => {
  const { completion_date } = selectedUser;
  if (completion_date !== null) return completion_date;

  const today = formatSelectedDate(new Date(), timezone) as string;
  return enrolled_date > today ? enrolled_date : today;
};

export const convertDaysToWeeks = (days: number): number => {
  return Math.ceil(days / 7);
};

export const roundToNearestQuarterHour = (date: Date): Date => {
  const minutes = date.getMinutes();
  const remainder = 15 - (minutes % 15);
  const roundedDate =
    remainder === 15 ? startOfMinute(date) : addMinutes(startOfMinute(date), remainder);
  return roundedDate;
};

export const areSameDay = (date1: Date, date2: Date): boolean => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const hasDatePassed = (dateTime: string): boolean => {
  const date = parseISO(dateTime);
  return isPast(date);
};

export const getCurrentDateTime = (): string => {
  const now = new Date();
  const formattedDateTime = format(now, "dd/MM/yyyy, HH:mm:ss");
  return formattedDateTime;
};

export const getTimeInterval = (
  date: Date,
  startHour: number,
  endHour: number,
): { start: Date; end: Date } => ({
  start: setMilliseconds(setSeconds(setMinutes(setHours(date, startHour), 0), 0), 0),
  end: setMilliseconds(setSeconds(setMinutes(setHours(date, endHour), 0), 0), 0),
});

// Function to check if the given date is within the interval and Monday to Friday
export const isWithinTimeIntervalOnWeekdays = (
  date: Date,
  startHour: number,
  endHour: number,
): boolean => {
  const utcDate = timeZonedDate(date); // Convert the date to UTC
  const dayOfWeek = getDay(utcDate); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
  const isWeekday = dayOfWeek >= 1 && dayOfWeek <= 5;
  const interval = getTimeInterval(utcDate, startHour, endHour);

  return isWeekday && isWithinInterval(utcDate, interval);
};

/**
 * Checks if the given date is within daylight saving time.
 * @param date - The date to check.
 * @param timezone - The timezone to check daylight saving time for.
 * @returns True if the date is within daylight saving time, false otherwise.
 */
export const isDaylightSavingTime = (date: Date, timezone = "America/New_York"): boolean => {
  const zonedDate = utcToZonedTime(date, timezone);

  const standardOffset = new Date(date.getFullYear(), JANUARY, 1).getTimezoneOffset();
  const dstOffset = new Date(date.getFullYear(), JULY, 1).getTimezoneOffset();

  return zonedDate.getTimezoneOffset() < Math.max(standardOffset, dstOffset);
};

/**
 * Formats a date string or Date object according to the specified timezone and format.
 *
 * @param date - The date to be formatted (string or Date).
 * @param timezone - The timezone in which to format the date.
 * @param format - The format string to use (default: ISO 8601 with timezone offset).
 * @returns The formatted date string.
 */
export const formatDateForTimezone = (
  date: string | Date,
  timezone: string,
  format = "yyyy-MM-dd'T'HH:mm:ssxxx",
): string => {
  return formatInTimeZone(date, timezone, format);
};

export const formatDateWithEndOfTheDayTime = (
  date: Date | null,
  timezone: string,
): string | null => {
  if (!date) return null;

  const endOfTheDay = new Date(date.setHours(23, 59, 59, 999));
  return formatSelectedDate(endOfTheDay, timezone);
};
