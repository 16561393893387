import Color from "color";
import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["uploadArea"] => {
  return {
    grayBase: colors.secondary.base,
    uploadFile: {
      borderColor: Color(colors.primary.lightest).alpha(0.25).string(),
      textColor: colors.primary.light,
      textHoverColor: colors.primary.lighter,
    },
  };
};
