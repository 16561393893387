import React, { FC, useCallback, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useToggle } from "ahooks";
import { SerializedStyles } from "@emotion/utils";

import { Sidebar, Tooltip } from "@epignosis_llc/gnosis";
import { ArrowLeftSVG, ArrowRightChevronSVG } from "@epignosis_llc/gnosis/icons";

import { optionMenuItem } from "./styles";

import { HelpCenterDrawer } from "@components/Drawers/HelpCenter/Drawers";

import { useApplyTranslations } from "@hooks";
import { MainNavData } from "../MainNav";
import { useUIStore } from "@stores";
import { MainDrawerContent } from "types/common";

type MenuItemProps = {
  title: string;
  url?: string;
  drawerKey: string;
  icon: JSX.Element | null;
  isActive: boolean;
  navState?: MainNavData;
  isCollapsed?: boolean;
  handleClick?: (
    e: React.MouseEvent<HTMLAnchorElement | HTMLLIElement, MouseEvent>,
    drawerKey: MainDrawerContent,
  ) => void;
};

const navItemClassNames = (isActiveTab: boolean): string =>
  classNames("help-center", {
    active: isActiveTab,
  });

const HelpMenuItem: FC<MenuItemProps> = ({
  title,
  url = "#",
  drawerKey,
  icon,
  isCollapsed = false,
  isActive,
  handleClick,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const tabValue = searchParams.get("tab");
  const pathname = location.pathname;

  const { t, i18n } = useApplyTranslations();
  const dir = i18n.dir();
  const isRTL = dir === "rtl";
  const { mainNavState, hideMainDrawer } = useUIStore((state) => state);
  const [isHelpDrawer, { set, setLeft }] = useToggle(false);

  const handleMenuItemClick = (e: React.MouseEvent<HTMLLIElement | HTMLAnchorElement>): void => {
    e.stopPropagation();
    handleClick && handleClick(e, drawerKey as MainDrawerContent);

    // Preserve URL parameters
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    set(!isHelpDrawer);
  };

  const handleCloseDrawer = useCallback(() => {
    setLeft();
  }, [setLeft]);

  useEffect(() => {
    handleCloseDrawer();
  }, [mainNavState, pathname, tabValue, handleCloseDrawer]);

  return (
    <>
      <li
        css={(theme): SerializedStyles => optionMenuItem(theme, { isCollapsed })}
        key={title}
        id={drawerKey ?? ""}
        className={navItemClassNames(isActive)}
        data-testid={`${drawerKey}-menu-item`}
        onClick={handleMenuItemClick}
        role="listitem"
        aria-label={t(title)}
      >
        <Tooltip as="div" content={t(title)} disabled={!isCollapsed} placement="right">
          <div className="main-nav-item">
            <Link to={url} title={t(title)} className="nav-link">
              <Sidebar.Item
                icon={icon}
                label={t(title)}
                isExpanded={!isCollapsed}
                isActive={isActive}
              />
            </Link>

            {!isCollapsed && (
              <div className="nav-arrow-link arrow-right">
                {isRTL ? <ArrowLeftSVG height={32} /> : <ArrowRightChevronSVG height={32} />}
              </div>
            )}
          </div>
        </Tooltip>
      </li>

      {isHelpDrawer && (
        <HelpCenterDrawer
          isOpen={isHelpDrawer}
          toggleDrawer={(): void => set(!isHelpDrawer)}
          hideMainDrawer={hideMainDrawer}
        />
      )}
    </>
  );
};

export default HelpMenuItem;
