import React, { FC } from "react";
import { ConfirmationModal, DateInput } from "@components";
import { useConfigurationStore } from "@stores";
import { dateFormatsMapping } from "@utils/helpers/date-time";
import { confirmationModalWithDateContent } from "./styles";
import { useApplyTranslations } from "@hooks";

type ExpirationModalProps = {
  modalId: number;
  selectedDate?: Date;
  title: string;
  bodyText: string;
  inputLabel: string;
  minDate?: Date;
  isConfirmationModalOpen: boolean;
  onDateChange: (date: Date | null) => void;
  onConfirm: () => void;
  toggleConfirmationModal: () => void;
};

const ConfirmationWithDateModal: FC<ExpirationModalProps> = ({
  modalId,
  selectedDate,
  isConfirmationModalOpen,
  title,
  bodyText,
  inputLabel,
  minDate,
  onDateChange,
  onConfirm,
  toggleConfirmationModal,
}) => {
  const { t } = useApplyTranslations();
  const { domainSettings } = useConfigurationStore();
  const dateFormat = domainSettings ? dateFormatsMapping[domainSettings.date_format] : undefined;

  return (
    <ConfirmationModal
      id={modalId}
      confirmationButtonColor="primary"
      closeOnOutsideClick={true}
      header={title}
      bodyTitle={
        <div css={confirmationModalWithDateContent}>
          <div dangerouslySetInnerHTML={{ __html: bodyText }}></div>
          <div className="expiration-date-input-wrapper">
            <DateInput
              id="expiration-date-input"
              className="expiration-date-input"
              value={selectedDate}
              label={inputLabel}
              placeholderText={t("messages.filters.pickDate")}
              dateFormat={dateFormat}
              onChange={onDateChange}
              minDate={minDate}
            />
          </div>
        </div>
      }
      footerButton={t("general.save")}
      isOpen={isConfirmationModalOpen}
      onClose={toggleConfirmationModal}
      onConfirm={onConfirm}
    />
  );
};

export default ConfirmationWithDateModal;
