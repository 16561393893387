// Packages or third-party libraries
import React, { FC, useMemo } from "react";

// Components
import { ActionDrawer } from "@components";
import BadgesInfo from "./BadgesInfo";

// Utils
import { getFormattedUserName } from "@utils/helpers";

// Other imports
import { BadgesInfoDrawerProps } from "./types";

const BadgesInfoDrawer: FC<BadgesInfoDrawerProps> = ({
  isOpen,
  onClose,
  userId,
  leaderboardUser,
  index,
}) => {
  const userName = useMemo(
    () =>
      getFormattedUserName({
        name: leaderboardUser.name ?? "",
        surname: leaderboardUser.surname ?? "",
        login: leaderboardUser.login ?? "",
      }),
    [leaderboardUser],
  );

  return (
    <ActionDrawer
      isOpen={isOpen}
      headerTitle={userName}
      onClose={onClose}
      size="md"
      hasBackButton
      showFooter={false}
    >
      <BadgesInfo userId={userId} leaderboardUser={leaderboardUser} index={index} />
    </ActionDrawer>
  );
};

export default BadgesInfoDrawer;
