import { palletColorsGenerator } from "../utils";
import { COLORS } from "../constants";
import { getGnosisTheme } from "./gnosis";
import { getPlusTheme } from "./plus";

export const THEME_COLOURS = {
  ...COLORS,
  primary: "#F3F5F7",
};

const colors = palletColorsGenerator(THEME_COLOURS);
const defaultGray = { ...getGnosisTheme(colors), ...getPlusTheme(colors) };

export default defaultGray;
