import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["arrowButton"] => {
  return {
    text: {
      color: colors.black,
    },
  };
};
