import defaultBlack from "./themes/defaultBlack";
import defaultBlue from "./themes/defaultBlue";
import defaultGray from "./themes/defaultGray";
import defaultGreen from "./themes/defaultGreen";
import defaultOrange from "./themes/defaultOrange";
import defaultRed from "./themes/defaultRed";
import defaultTurquoise from "./themes/defaultTurquoise";

export { GlobalStyleVariables } from "./global";

export const themes = {
  defaultBlack,
  defaultBlue,
  defaultGray,
  defaultGreen,
  defaultOrange,
  defaultRed,
  defaultTurquoise,
};

export { COLORS } from "./constants";
