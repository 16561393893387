export default {
  redirect: "redirect",
  show: "show",
  addUserType: "addUserType",
  addAutomation: "addAutomation",
  addCategory: "addCategory",
  addNotification: "addNotification",
  addCalendarEvent: "addCalendarEvent",
};

export const marketoQueryParam = "view=1";
export const onboardingQueryParam = "onboarding=1";
