import { Image, RichText, TabObject } from "types/entities/Common";
import { UserProfile } from "types/entities/User";
import { ResponseEnvelope } from "types/responses";
import { Course } from "types/entities";
import { AIJob } from "types/entities/AIJobs";

export type SkillPathParams = {
  skillId: string;
};

export type SkillAssessmentQuestionsPathParams = {
  skillId: string;
  questionNumber: string;
};

export type JobRolePathParams = {
  jobRole: string;
};

export enum SkillsActiveTab {
  Skills = "skills",
  Users = "users",
  TalentPool = "talentPool",
}

export enum MySkillsActiveTab {
  All = "all",
  MySkills = "mySkills",
  RoleSkills = "roleSkills",
}

export enum SingleSkillTab {
  Users = "users",
  RelatedCourses = "relatedCourses",
}

export enum SkillQuestionAction {
  Edit = "edit",
  Delete = "delete",
}

export enum ReviewDrawerTab {
  Skills = "skills",
  Recommendations = "recommendations",
  Upgrades = "upgrades",
}

export enum DurationUnit {
  Minutes = "minutes",
  Hours = "hours",
  Days = "days",
}

export type DurationRange = {
  min: number;
  max: number;
  step: number;
};

type TabObjectInfo = TabObject & {
  key?: SkillsActiveTab;
};

export type AvailableTabsType = {
  info: TabObjectInfo;
  permission: boolean;
};

export type SkillStatus = "active" | "inactive";

export type Skill = JobRoleSkill & {
  created_on?: string;
  last_update_on?: string;
  related_content?: Content[];
  assessment_content?: Content[];
  progress?: number;
  levels?: SkillLevel[];
  is_suggested?: boolean;
  ai_jobs?: AIJob[];
  status: SkillStatus;
};

export type JobRoleSkill = JobRoleBaseSkill & {
  cover_image: Image | null;
  description?: string;
  progress: number;
  user_level?: SkillLevel;
  show_ext_resources?: boolean;
};

export type JobRoleBaseSkill = {
  id: number;
  name: string;
  is_ignored: boolean;
};

export type NewSkill = {
  name: string;
};

export type ExtResource = {
  id: number;
  name: string;
  url: string;
};

export type Content = {
  id: string;
  name: string;
  description: string;
  thumbnail: string;
  progress: number;
};

export type AvailableSkill = {
  id: number;
  name: string;
  requested_on: string;
};

export type User = Pick<UserProfile, "id" | "name" | "surname" | "login">;

export type JobRoleCandidate = {
  user: {
    id: number;
    name: string;
    surname: string;
    login: string;
  };
  skills: JobRoleSkill[];
  match: number;
};

export type UserWithProgress = Pick<UserProfile, "id" | "name" | "surname" | "login"> & {
  progress: number;
  levels: SkillLevel[];
};

export type UserWithSkillStats = Pick<UserProfile, "id" | "name" | "surname" | "login"> & {
  user_skills_count: number;
  total_skills_count: number;
  skills_progress: number;
  number_of_recommendations: number;
  number_of_upgrades: number;
};

export type JobRoleBase = {
  name: string;
  description?: string;
  skills: JobRoleBaseSkill[];
  similar_role_names?: string[];
  status: JobRoleStatus;
  role_state: string;
  ai_jobs: object;
};

export type JobRoleInfo = {
  name: string;
  description: string;
  role_match: number;
  skills: JobRoleSkill[];
  similar_role_names?: string[];
  status: JobRoleStatus;
  role_state: string;
  ai_jobs: object;
};

export type JobRoleStatus = {
  is_found: boolean | null;
};

export type MyJobRoleType = {
  title: string;
  description: string;
  role_match: number;
  skills: JobRoleSkill[];
  similar_role_names: string[];
};

export type JobRoleSuggestion = {
  name: string;
};

export type SkillNameSuggestion = {
  name: string;
};

export type SkillCourse = Pick<
  Course,
  "id" | "name" | "category" | "code" | "last_updated_on" | "description" | "cover_image" | "status"
>;

export type SkillsRecommendation = {
  skills: AvailableSkill[];
  from_user: User;
};

export type SkillsUpgrade = {
  id: number;
  skill_name: string;
  level?: SkillLevel;
  requested_on: string;
};

export type SkillLevel = {
  id: number;
  name: string;
  earned_on: string | null;
};

export type SkillLevelConfiguration = {
  id: number;
  name: string;
  description: string;
  is_active?: boolean;
};

export type SkillsAssessmentSettings = {
  expiration?: number;
  question_count: number;
  pass_mark: number;
  retry_after_interval: number;
  max_time_allowed: number;
};

export type SkillsSettings = {
  enabled: boolean;
  activate_skills_for_learners: boolean;
  activate_skill_recommendations_for_learners: boolean;
  levels: SkillLevelConfiguration[];
  assessments: SkillsAssessmentSettings;
  ai_context?: string;
};

// TODO: Remove once we get rid for the frontend mock data
export type MockSkillAssessment = {
  skillId: number;
  questions: Array<unknown>;
};

export type InitializedSkillAssessmentMeta = {
  remaining_time: number;
  max_time_allowed: number;

  question_count: number;
  previous_question_number: number;
  next_question_number: number;
};

export type SkillAssessment = {
  question_count: number;
  retry_after_time?: number;
  remaining_time: number;
  max_time_allowed: number;
  completion_status?: SkillAssessmentCompletionStatus;
  is_allowed_to_start_assessment?: boolean;
  is_assessment_ready?: boolean;
  level_description?: string;
};

export type SkillAssessmentStartResult = {
  id: number;
  question_count: number;
  current_question_number: number;
  remaining_time: number;
  max_time_allowed: number;
};

export type SkillAssessmentQuestionSubmit = {
  // Empty type
};

export type SkillAssessmentQuestionSubmissionPayload = SkillQuestionUserAnswers;

export type UserSkillLink = {
  user_id: number;
  skill_id: number;
};

export type AssignSkillsToUsersPayload = UserSkillLink[];

export type RecommendSkillsToUsersPayload = UserSkillLink[];

export type UpgradeSkillsOfUsersPayload = UserSkillLink[];

export type CourseSkillLink = {
  course_id: number;
  skill_id: number;
};

export type LinkCoursesToSkillsPayload = CourseSkillLink[];

export type JobRoleSkillsPayload = { deselected?: number[] };

export type AiJobField =
  | "description"
  | "image"
  | "assessment"
  | "ext_resources"
  | "courses"
  | "course_thumbnail";
export type JobRoleAiJobField = "description" | "skills";
export type AiJobEntityType = "Skill" | "JobRole" | "Assessment" | "SkillThumbnail";
export type AiJobFieldMapping<T extends AiJobEntityType> = T extends "JobRole"
  ? JobRoleAiJobField
  : T extends "Skill"
    ? AiJobField
    : T extends "Assessment"
      ? AiJobField
      : never;

export type AiJobStatusType = "working" | "completed" | "failed";
export type AiJobStatus = {
  type: string;
  status: AiJobStatusType;
};

export type AiJobsStatus = AiJobStatus[];

export enum SkillQuestionType {
  MultipleChoice = "multiple_choice",
}

export type SkillQuestion = {
  id: number;
  type: SkillQuestionType;
  multiple_choice?: SkillMultipleChoiceQuestion;
  is_active?: boolean;
};

export type SkillMultipleChoiceQuestion = {
  text: RichText;
  answers: SkillMultipleChoiceAnswer[];
  is_single_answer_only?: boolean;
};

export type SkillMultipleChoiceAnswer = {
  id: number;
  text: string;
  is_correct: boolean;
};

export type SkillQuestionUserAnswers = {
  type: SkillQuestionType;
  multiple_choice?: {
    user_answers: SkillMultipleChoiceUserAnswer[];
  };
};

export type SkillMultipleChoiceUserAnswer = number;

// This is required because the request body for creating/updating a question
// Is expected to have a string text field (instead of a RichText one).
export type SkillAssessmentQuestionData = Omit<SkillQuestion, "id" | "multiple_choice"> & {
  multiple_choice?: {
    text: string;
    answers: SkillMultipleChoiceAnswer[];
  };
};

export type JobRoleSkillsAndCandidates = {
  job_role: JobRoleBase;
  candidates: JobRoleCandidate[];
};

export type SkillAssessmentCompletionStatus = "passed" | "failed";

export type SkillAssessmentResults = {
  score: number;
  completion_status?: SkillAssessmentCompletionStatus;
};

export type UserSearchHistoryContext = "skills_talentpool" | "skills_pathfinder";

export type UserSearchHistoryItem = {
  id: number;
  created_on: string;
  search_term: string;
  job_role_description?: string;
};

// Responses
export type SkillsRes = ResponseEnvelope<Skill[]>;
export type UserSkillsRes = ResponseEnvelope<[]>;

export type SkillRes = ResponseEnvelope<Skill>;
export type SkillNameSuggestionsRes = ResponseEnvelope<SkillNameSuggestion[]>;
export type AvailableSkillsRes = ResponseEnvelope<AvailableSkill[]>;
export type UsersWithSkillStatsRes = ResponseEnvelope<UserWithSkillStats[]>;
export type SingleUserWithSkillStatsRes = ResponseEnvelope<UserWithSkillStats>;
export type UserWithProgressRes = ResponseEnvelope<UserWithProgress[]>;
export type JobRoleCandidatesRes = ResponseEnvelope<JobRoleSkillsAndCandidates>;
export type JobRoleRes = ResponseEnvelope<JobRoleInfo>;
export type JobRolesSearchRes = ResponseEnvelope<JobRoleSuggestion[]>;
export type MyJobRolesRes = ResponseEnvelope<MyJobRoleType[]>;
export type MyJobRoleRes = ResponseEnvelope<MyJobRoleType>;
export type JobRolesRes = ResponseEnvelope<JobRoleInfo[]>;
export type SkillCourseRes = ResponseEnvelope<SkillCourse[]>;
export type SkillExtResourcesRes = ResponseEnvelope<ExtResource[]>;
export type UsersForSkillsRecommendationRes = ResponseEnvelope<User[]>;
export type SkillsRecommendationRes = ResponseEnvelope<SkillsRecommendation[]>;
export type SkillsUpgradesRes = ResponseEnvelope<SkillsUpgrade[]>;
export type MockSkillAssessmentRes = ResponseEnvelope<MockSkillAssessment>;
export type SkillsSettingsRes = ResponseEnvelope<SkillsSettings>;
export type SkillAssessmentResultsRes = ResponseEnvelope<SkillAssessmentResults>;
export type SkillAssessmentRes = ResponseEnvelope<SkillAssessment>;
export type SkillAssessmentStartRes = ResponseEnvelope<SkillAssessmentStartResult>;
export type SkillAssessmentQuestionSubmitRes = ResponseEnvelope<
  SkillAssessmentQuestionSubmit,
  InitializedSkillAssessmentMeta
>;
export type SkillQuestionRes = ResponseEnvelope<SkillQuestion, InitializedSkillAssessmentMeta>;
export type SkillQuestionsRes = ResponseEnvelope<SkillQuestion[]>;
export type AiJobsStatusRes = ResponseEnvelope<AiJobsStatus>;
export type AIJobsRes = ResponseEnvelope<AIJob[]>;
export type UserSearchHistoryRes = ResponseEnvelope<UserSearchHistoryItem[]>;
