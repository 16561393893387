import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const feedbackModalStyles = ({ feedbackBox }) => css`
  flex-direction: column;
  display: flex;

  .feedback {
    &-content {
      padding: 1rem 1rem 4rem;
      flex-direction: column;
      display: flex;
      gap: 1rem;

      ${mq["md"]} {
        padding: 1.5rem;
      }
    }
    &-form {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }

    &-item {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    &-message {
      color: ${feedbackBox.messageColor};
      .report-link {
        height: unset;
        text-decoration: underline;
      }
    }

    &-footer {
      padding: 1rem;
      border-top: 1px solid ${feedbackBox.borderColor};
      ${mq["md"]} {
        padding: 0.75rem 1.5rem;
      }
    }
  }
`;
