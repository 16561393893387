import {
  getNoScriptSnippet,
  getScriptSnippet,
  GTM_NO_SCRIPT_BASE,
  GTM_SCRIPT_BASE,
  GTM_SCRIPT_IDENTIFIER,
} from "@hooks/useGoogleTagManager/constants";

type GoogleTagManager = {
  initializeGTM: () => void;
  removeGTMTags: () => void;
};

const useGoogleTagManager = (gtmKey?: string, gtmIsActive?: boolean): GoogleTagManager => {
  const removeExistingTags = (): void => {
    document.querySelectorAll("script, noscript").forEach((element) => {
      const { tagName, innerHTML } = element;

      if (
        (element instanceof HTMLScriptElement &&
          (element.src.includes(GTM_SCRIPT_BASE) || innerHTML.includes(GTM_SCRIPT_IDENTIFIER))) ||
        (element instanceof HTMLElement &&
          tagName === "NOSCRIPT" &&
          innerHTML.includes(GTM_NO_SCRIPT_BASE))
      ) {
        element.remove();
      }
    });
  };

  const createOrRetrieveNoScriptTag = (): HTMLElement | Element => {
    const existingNoScript = document.querySelector("noscript[data-gtm]");
    if (!existingNoScript) {
      const noscript = document.createElement("noscript");
      noscript.innerHTML = getNoScriptSnippet(gtmKey);
      return noscript;
    }
    return existingNoScript;
  };

  const createOrRetrieveScriptTag = (): HTMLElement | Element => {
    const existingScript = document.querySelector("script[data-gtm]");
    if (!existingScript) {
      const script = document.createElement("script");
      script.setAttribute("data-gtm", "true");
      script.innerHTML = getScriptSnippet(gtmKey);
      return script;
    }
    return existingScript;
  };

  const initializeGTM = (): void => {
    if (!gtmKey || !gtmIsActive) {
      removeExistingTags();
      return;
    }
    removeExistingTags();
    document.head.insertAdjacentElement("afterbegin", createOrRetrieveScriptTag());
    document.body.insertAdjacentElement("afterbegin", createOrRetrieveNoScriptTag());
  };

  return { initializeGTM, removeGTMTags: removeExistingTags };
};

export default useGoogleTagManager;
