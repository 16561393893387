import { MassActionResultResponse, CountMassActionResponse } from "types/responses";
import HttpClient from "../HttpClient";
import { ENDPOINTS } from "../endpoints";
import { MassActionParam, MassActionType } from "@components/ReusableComponents";

export const courseMassActions = async (
  type: MassActionType,
  courseIds: Array<number>,
  data: MassActionParam,
): Promise<MassActionResultResponse> => {
  const res = await HttpClient.post(ENDPOINTS.courses.massActions.courses, {
    type: type,
    course_ids: courseIds,
    ...data,
  });

  return res.data;
};

export const courseMassActionsCount = async (
  type: MassActionType,
  courseIds: Array<number>,
  data?: MassActionParam | null,
): Promise<CountMassActionResponse> => {
  const res = await HttpClient.post(ENDPOINTS.courses.massActions.coursesCount, {
    type: type,
    course_ids: courseIds,
    ...data,
  });
  return res.data;
};

export const courseUsersMassActions = async (
  type: MassActionType,
  courseId: string,
  userIds: Array<number>,
  data?: MassActionParam,
): Promise<MassActionResultResponse> => {
  const res = await HttpClient.post(ENDPOINTS.courses.massActions.courseUsers(courseId), {
    type,
    user_ids: userIds,
    ...data,
  });

  return res.data;
};

export const courseUsersMassActionsCount = async (
  type: MassActionType,
  courseId: string,
  userIds: Array<number>,
  data?: MassActionParam,
): Promise<CountMassActionResponse> => {
  const res = await HttpClient.post(ENDPOINTS.courses.massActions.courseUsersCount(courseId), {
    type,
    user_ids: userIds,
    ...data,
  });

  return res.data;
};

export const courseStoreCourseMassActions = async (
  language: string,
  category_ids: Array<number>,
  show_catalog: boolean,
): Promise<MassActionResultResponse> => {
  const res = await HttpClient.post(ENDPOINTS.courseStore.massAcquire, {
    language,
    category_ids,
    show_catalog,
  });

  return res.data;
};

export const courseStoreCourseMassActionsCount = async (
  language: string,
  category_ids: Array<number>,
): Promise<CountMassActionResponse> => {
  const res = await HttpClient.post(ENDPOINTS.courseStore.massAcquireCount, {
    language,
    category_ids,
  });

  return res.data;
};

export const courseApprovalRequestsMassActionsCount = async (
  type: MassActionType,
  courseId: string,
  requestIds: Array<number>,
): Promise<CountMassActionResponse> => {
  const res = await HttpClient.post(
    ENDPOINTS.courses.massActions.courseApprovalRequestsCount(courseId),
    {
      type,
      request_ids: requestIds,
    },
  );

  return res.data;
};

export const courseApprovalRequestsMassActions = async (
  type: MassActionType,
  courseId: string,
  requestIds: Array<number>,
): Promise<MassActionResultResponse> => {
  const res = await HttpClient.post(
    ENDPOINTS.courses.massActions.courseApprovalRequests(courseId),
    {
      type,
      request_ids: requestIds,
    },
  );

  return res.data;
};
