import React, { FC } from "react";
import ContentLoader from "react-content-loader";

export type SkeletonAccordionProps = {
  items?: number;
};

const SkeletonAccordion: FC<SkeletonAccordionProps> = ({ items = 5 }) => (
  <>
    {Array.from({ length: items }, (_, index) => (
      <ContentLoader
        key={`accordion-item-${index}`}
        height={104}
        speed={2}
        viewBox="0 0 1000 104"
        style={{
          width: "100%",
          boxShadow: " 0px 3px 6px 0px rgb(193, 197, 200)",
          borderRadius: "5px",
          marginBottom: "16px",
        }}
      >
        <rect x="24" y="36" rx="5" ry="5" width="32" height="32" />
        <rect x="68" y="42" rx="5" ry="5" width="908" height="20" />
      </ContentLoader>
    ))}
  </>
);

export default SkeletonAccordion;
