import React, { FC, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useResponsive } from "ahooks";
import { Text, Tooltip } from "@epignosis_llc/gnosis";
import { gamificationContainer } from "./styles";
import GamificationInfo from "./GamificationInfo";
import {
  GamificationBadges,
  GamificationCertificatesSVG,
  GamificationCoursesSVG,
  GamificationLevels,
  GamificationPoints,
} from "@assets/images";
import { useConfigurationStore } from "@stores";
import { GamificationSettings } from "types/entities";
import { isLeaderBoardEnabled } from "@utils/helpers";
import { getLeaderboard } from "@api/gamification";
import queryKeys from "@constants/queryKeys";
import Leaderboard from "@views/WidgetsDashboard/components/Widgets/Leaderboard/Leaderboard";
import { useApplyTranslations } from "@hooks";

type GamificationProps = {
  isGamificationInfoOpen: boolean;
  leaderboardSelectedTab: string;
  setIsGamificationInfoOpen: (isOpen: boolean) => void;
  setLeaderboardSelectedTab: (selectedTab: string) => void;
};

const Gamification: FC<GamificationProps> = ({
  isGamificationInfoOpen,
  leaderboardSelectedTab,
  setIsGamificationInfoOpen,
}) => {
  const { t } = useApplyTranslations();
  const { sm } = useResponsive();
  const { userProfileData, gamificationSettings, userStatistics } = useConfigurationStore();
  const { points, badges, levels, leaderboard } = gamificationSettings as GamificationSettings;
  const [leaderboardEnabled, setLeaderboardEnabled] = useState(false);
  const queryClient = useQueryClient();

  const { data: leaderboardData } = useQuery(
    [queryKeys.leaderboard, gamificationSettings],
    getLeaderboard,
    {
      select: (leaderboardRes) => leaderboardRes._data,
    },
  );

  useEffect(() => {
    queryClient.invalidateQueries([queryKeys.userProfile]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    leaderboardData?.points?.user?.score,
    leaderboardData?.levels?.user?.score,
    leaderboardData?.badges?.user?.score,
  ]);

  useEffect(() => {
    if (gamificationSettings) {
      setLeaderboardEnabled(isLeaderBoardEnabled(gamificationSettings));
    }
  }, [gamificationSettings]);

  const numberOfCertificates = leaderboardData?.certificates?.user?.score ?? 0;
  const numberOfCompletedCourses = leaderboardData?.courses?.user?.score ?? 0;
  const iconHeight = sm ? 48 : 32;
  const textSize = sm ? "sm" : "xs";

  return (
    <div className="gamification-container" css={gamificationContainer}>
      {!isGamificationInfoOpen ? (
        <>
          <article className="achievements-container">
            <div className="details">
              {points?.enabled && (
                <div className="info">
                  <Tooltip content={t("general.points")}>
                    <GamificationPoints height={iconHeight} />
                  </Tooltip>
                  <Text fontSize={textSize}>{userProfileData?.points}</Text>
                </div>
              )}
              {levels?.enabled && (
                <div className="info">
                  <Tooltip content={t("general.level")}>
                    <GamificationLevels height={iconHeight} />
                  </Tooltip>
                  <Text fontSize={textSize}>{userProfileData?.level}</Text>
                </div>
              )}
              {badges?.enabled && (
                <div className="info">
                  <Tooltip content={t("general.badges")}>
                    <GamificationBadges height={iconHeight} />
                  </Tooltip>
                  <Text fontSize={textSize}>{userStatistics?.badges?.total}</Text>
                </div>
              )}
              {leaderboard?.show_courses && (
                <div className="info">
                  <Tooltip content={t("dashboard.leaderboardTabs.completedCourses")}>
                    <GamificationCoursesSVG height={iconHeight} />
                  </Tooltip>
                  <Text fontSize={textSize}>{numberOfCompletedCourses}</Text>
                </div>
              )}
              {leaderboard?.show_certifications && (
                <div className="info">
                  <Tooltip content={t("general.certificates")}>
                    <GamificationCertificatesSVG height={iconHeight} />
                  </Tooltip>
                  <Text fontSize={textSize}>{numberOfCertificates}</Text>
                </div>
              )}
            </div>
          </article>
          {leaderboardEnabled && <Leaderboard />}
        </>
      ) : (
        <GamificationInfo
          selectedTab={leaderboardSelectedTab}
          closeInfo={(): void => setIsGamificationInfoOpen(false)}
        />
      )}
    </div>
  );
};

export default Gamification;
