import { css } from "@emotion/react";

export const modalHeader = css`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const modalFooter = css`
  display: flex;
  gap: 1rem;
`;

export const previewModal = css`
  .loader-container {
    height: 30rem;
    position: relative;

    .loading-container {
      position: absolute;
      top: 50%;
    }
  }

  .pdf-container {
    height: 30rem;
    overflow-y: auto;
  }

  .img-container {
    overflow: auto;

    img {
      max-width: 100%;
      max-height: 30rem;
      margin: auto;
    }
  }

  p {
    text-align: center;
    font-style: italic;
  }
`;
