import { css } from "@emotion/react";

export const daysRemainingContainer = ({ colors }) => css`
  padding-inline-start: 0.5rem;
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
  padding-inline-end: 1rem;

  display: flex;
  .button-children {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  .button-text {
    color: ${colors.black};
    white-space: nowrap;
  }
`;

export const svgWrapper = css`
  cursor: pointer;
`;
