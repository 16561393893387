import React, { FC } from "react";
import { Drawer } from "@epignosis_llc/gnosis";
import { MobileSearch } from "@components";
import { Spotlight } from "types/entities/Spotlight";
import { i18n } from "@utils/i18n";

export type MobileSearchDrawerProps = {
  isOpen: boolean;
  isLoading: boolean;
  searchResults: Spotlight | null;
  onSearch: (inputValue: string) => void;
  onClose: () => void;
};

const MobileSearchDrawer: FC<MobileSearchDrawerProps> = ({
  isOpen,
  isLoading,
  searchResults,
  onClose,
  onSearch,
}) => {
  const isRtl = i18n.dir() === "rtl";
  const placement = isRtl ? "right" : "left";

  return (
    <Drawer placement={placement} isOpen={isOpen} width="100%" onClose={onClose}>
      <Drawer.Body>
        <MobileSearch
          isLoading={isLoading}
          searchResults={searchResults}
          onSearch={onSearch}
          onClose={onClose}
        />
      </Drawer.Body>
    </Drawer>
  );
};

export default MobileSearchDrawer;
