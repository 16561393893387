import React, { FC } from "react";
import ContentLoader from "react-content-loader";
import { i18n } from "@utils/i18n";

const SkeletonCategory: FC = () => {
  const isRTL = i18n.dir() === "rtl";
  return (
    <ContentLoader
      speed={2}
      height={35}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      rtl={isRTL}
    >
      <rect x="35" y="10" rx="5" ry="5" width="70%" height="10" />
      <rect x="3" y="5" rx="4" ry="4" width="20" height="20" />
    </ContentLoader>
  );
};

export default SkeletonCategory;
