import React, { FC } from "react";
import { pathItemStyles } from "./styles";
import PathSeparator, { PathSeparatorProps } from "./PathSeparator";
import PathContent, { PathContentProps } from "./PathContent";

export type PathItemCompoundProps = {
  Separator: FC<PathSeparatorProps>;
  Content: PathContentProps;
};

type PathItemProps = {
  children?: React.ReactNode;
};

const PathItem: FC<PathItemProps> & PathItemCompoundProps = ({ children }) => {
  return <li css={pathItemStyles}>{children}</li>;
};

PathItem.Separator = PathSeparator;
PathItem.Content = PathContent;

export default PathItem;
