// Packages or third-party libraries
import React, { FC, ReactElement, useState } from "react";
import { Grid, Input, InputError } from "@epignosis_llc/gnosis";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// Constants
import queryKeys from "@constants/queryKeys";
import { MAX_ALLOWED_FILES } from "../constants";
import { toolbarButtonsMessage } from "@components/ReusableComponents/EditableContent/constants";

// Utils
import {
  buildFileValidations,
  errorNotification,
  generalNotification,
  getFileValidations,
} from "@utils/helpers";
import { handleNewMessageErrors } from "@errors/errors";
import { createMessage, createMessagePostData, postAttachmentFile } from "@api/messages";
import { NewMessageFormValidationSchema } from "@utils/validation";

// Hooks
import { useApplyTranslations } from "@hooks";

// Components
import ActionDrawer from "@components/Drawers/ActionDrawer";
import { DragAndDropArea, Editor, FileInput } from "@components";

// Other
import { Recipient } from "types/entities";
import { DrawerSize } from "types/common";
import { resolveRecipients } from "./helpers";

type SendMessageMassActionDrawerProps = {
  isDrawerOpen: boolean;
  userIds?: number[];
  courseInstructorId?: number;
  hideAttachments?: boolean;
  headerText?: string;
  size?: DrawerSize;
  hasBackButton?: boolean;
  openDrawerFullscreen?: boolean;
  onClose: () => void;
};

const SendMessageMassActionDrawer: FC<SendMessageMassActionDrawerProps> = ({
  isDrawerOpen,
  userIds,
  courseInstructorId,
  hideAttachments,
  headerText,
  size = "md",
  hasBackButton = false,
  openDrawerFullscreen = false,
  onClose,
}) => {
  const { t } = useApplyTranslations();
  const [attachments, setAttachments] = useState<File[]>([]);
  const [droppedAttachments, setDroppedAttachments] = useState<FileList | null>(null);
  const validations = getFileValidations("message.attachments");
  const mimeTypeAndFilesizeValidations = buildFileValidations(validations);
  const recipients: Omit<Recipient, "name">[] = resolveRecipients(userIds, courseInstructorId);

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
  } = useForm<createMessagePostData>({
    defaultValues: {
      recipients: recipients,
      subject: "",
      content: "",
    },
    resolver: yupResolver(NewMessageFormValidationSchema),
    mode: "onChange",
  });

  const { mutate: createMessageMutation, status } = useMutation(
    [queryKeys.messages.create],
    (newMessage: createMessagePostData) => createMessage(newMessage),
    {
      onSuccess: () => {
        generalNotification("success", <p>{t("messages.messageSent")}</p>);
        onClose();
      },
      onError: (error: AxiosError) => {
        handleNewMessageErrors(error);
      },
    },
  );

  const { mutateAsync, status: postStatus } = useMutation(
    [queryKeys.messages.attachment],
    (file: File) => postAttachmentFile(file),
    {
      onError: (error: AxiosError) => {
        generalNotification("error", <p>{error.response?.data._errors[0].title}</p>);
      },
    },
  );

  const handleData = async (data: createMessagePostData): Promise<void> => {
    if (attachments.length) {
      const { _data: uploadedFile } = await mutateAsync(attachments[0]);
      data.attachment_hash = uploadedFile.file_hash;
    }

    createMessageMutation(data);
  };

  const handleFilesChanged = (files: File[]): void => {
    setAttachments(files);
    setDroppedAttachments(null);
  };

  const handleFilesDrop = (files: FileList): void => {
    setDroppedAttachments(files);
  };

  const handleFileError = (error: string): void => {
    errorNotification(`${error}`);
  };

  const loading = status === "loading" || postStatus === "loading";
  const actionButton = {
    isLoading: loading,
    text: t("messages.send"),
  };

  return (
    <ActionDrawer
      isOpen={isDrawerOpen}
      size={size}
      actionButton={actionButton}
      headerTitle={headerText ?? t("general.sendMessage")}
      onApply={handleSubmit(handleData)}
      onClose={onClose}
      showFooter={true}
      fullscreen={openDrawerFullscreen}
      hasBackButton={hasBackButton}
    >
      <form autoComplete="off">
        <Grid templateColumns={1} rowGap={2}>
          <div>
            <Input
              id="subject"
              label={t("messages.subject")}
              status={errors.subject ? "error" : "valid"}
              placeholder={t("messages.subjectLine")}
              {...register("subject")}
            />
            {errors.subject && <InputError>{errors.subject.message}</InputError>}
          </div>

          <div>
            <Controller
              name="content"
              control={control}
              render={({ field: { onChange, value } }): ReactElement => (
                <Editor
                  toolbarButtons={toolbarButtonsMessage}
                  id="message-body-editor"
                  model={value}
                  onChange={onChange}
                  placeholderText={t("general.typeYourMessage")}
                  label={t("messages.message")}
                  status={errors.content && "error"}
                />
              )}
            />
            {errors.content && <InputError>{errors.content.message}</InputError>}
          </div>

          {!hideAttachments && (
            <DragAndDropArea
              maxFiles={MAX_ALLOWED_FILES}
              mimeTypeAndFilesizeValidations={mimeTypeAndFilesizeValidations}
              preventDrop={attachments.length >= MAX_ALLOWED_FILES}
              onFilesDrop={handleFilesDrop}
            >
              <FileInput
                id="new-message-files"
                name="files"
                maxFiles={MAX_ALLOWED_FILES}
                mimeTypeAndFilesizeValidations={mimeTypeAndFilesizeValidations}
                selectedFiles={attachments}
                addedFiles={droppedAttachments}
                onFileError={handleFileError}
                onFilesChange={handleFilesChanged}
              />
            </DragAndDropArea>
          )}
        </Grid>
      </form>
    </ActionDrawer>
  );
};

export default SendMessageMassActionDrawer;
