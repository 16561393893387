import HttpClient from "@api/HttpClient";
import { ENDPOINTS } from "@api/endpoints";
import {
  CourseRes,
  CourseCopyFromLoginRes,
  TableCSVExportRes,
  CoursesLightRes,
} from "types/responses";
import { CourseCopyFromDomainDataLogin, CourseCopyFromDomainRequest } from "./types";
import { UserRole } from "types/entities";
import { marketoQueryParam, onboardingQueryParam } from "@constants/queryParameters";

export type PublicCourse = {
  course_id: number;

  token: {
    access_token: string;
    expires_in: number;
    refresh_token: string;
    default_role: UserRole;
    token_type: string;
  };
};

type PublicCourseRes = {
  _data: PublicCourse;
};

export const getPublicCourse = async (key: string): Promise<PublicCourseRes> => {
  const res = await HttpClient.get(ENDPOINTS.catalog.publicCourse(key));

  return res.data;
};

export const createCourse = async ({
  name,
  isFromOnboarding,
}: { name?: string; isFromOnboarding?: boolean } = {}): Promise<CourseRes> => {
  const url = isFromOnboarding
    ? `${ENDPOINTS.courses.courses}?${onboardingQueryParam}&${marketoQueryParam}`
    : ENDPOINTS.courses.courses;

  const res = await HttpClient.post(url, { name });

  return res.data;
};

export const createCourseWithMarketoTracker = async (
  shouldTrackMarketo: boolean,
): Promise<CourseRes> => {
  const url = shouldTrackMarketo
    ? `${ENDPOINTS.courses.courses}?${marketoQueryParam}`
    : ENDPOINTS.courses.courses;
  const res = await HttpClient.post(url);

  return res.data;
};

export const getCoursesTableExport = async (queryStr = ""): Promise<TableCSVExportRes> => {
  const endpoint = `${ENDPOINTS.tableExports.coursesTable}${queryStr}`;

  const res = await HttpClient.post(endpoint);

  return res.data;
};

/** *** Copy Course from other Domain endpoints *****/

export const postCourseCopyFromDomainLogin = async (
  data: CourseCopyFromDomainDataLogin,
): Promise<CourseCopyFromLoginRes> => {
  const res = await HttpClient.post(ENDPOINTS.courses.copyFromDomainLogin, data);

  return res.data;
};

export const postCourseCopyFromDomainList = async (token: string): Promise<CoursesLightRes> => {
  const res = await HttpClient.post(ENDPOINTS.courses.copyFromDomainList, { token });

  return res.data;
};

export const postCourseCopyFromDomain = async (
  data: CourseCopyFromDomainRequest,
): Promise<CourseRes> => {
  const res = await HttpClient.post(ENDPOINTS.courses.copyFromDomain, data);

  return res.data;
};
