import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["reports"] => {
  return {
    branches: {
      code: colors.secondary.base,
    },
    groups: {
      code: colors.secondary.base,
    },
  };
};
