import { css } from "@emotion/react";
import { typeScale } from "@epignosis_llc/gnosis";

export const EditableTextStyles = ({ editableText }, { fontSize }) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  word-break: break-word;
  width: 100%;

  &.heading {
    font-size: ${typeScale[fontSize]};
    font-weight: 700;
    line-height: 1.3;

    .editable-container {
      font-size: ${typeScale[fontSize]};
      font-weight: 700;
      line-height: 1.3;
    }
  }

  &.text {
    font-size: ${typeScale["sm"]};
    font-weight: 400;

    .editable-container {
      font-size: ${typeScale["sm"]};
      font-weight: 400;
    }
  }

  .editable-container {
    &[contentEditable="true"]:empty:before {
      content: attr(data-text);
      color: ${editableText.placeholderColor};
    }

    &:focus {
      outline: none;
    }

    .placeholder {
      color: ${editableText.placeholderColor};
    }
  }
`;
