import { useMutation } from "react-query";
import { WATCH_DEMO_URL } from "./constants";
import queryKeys from "@constants/queryKeys";
import { processEvent } from "@api/processEvent";

const useWatchDemo = (): { handleWatchDemoClick: () => void } => {
  const { mutate: trackWatchVideoEventMutation } = useMutation(
    [queryKeys.analytics.trackEvent],
    () => processEvent("watch_demo"),
  );

  const handleWatchDemoClick = (): void => {
    window.open(WATCH_DEMO_URL, "_blank");
    trackWatchVideoEventMutation();
  };

  return { handleWatchDemoClick };
};

export default useWatchDemo;
