import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["textWithIcon"] => {
  return {
    headingBlack: colors.black,
    headingGrey: colors.secondary.darker,
    headingWhite: colors.white,
    success: colors.green.base,
    primary: colors.primary.base,
    danger: colors.red.base,
  };
};
