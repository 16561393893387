export const REACT_PLAYER_CONFIG = {
  file: {
    attributes: {
      controlsList: "nodownload noplaybackrate",
      disablePictureInPicture: true,
      crossOrigin: "true",
    },
  },
};

export enum TabKey {
  Units = "units",
  Files = "files",
}

export const COURSE_DESCRIPTION_EDITOR_ID = "course-description-editor";
