import { Branch } from "./Branches";
import { CustomFieldWithValue, Image, Price } from "./Common";
import { Course } from "./Courses";

export type UserProfile = {
  id: number;
  login: string;
  email: string;
  name: string;
  impersonator_username?: string;
  communications_enabled: boolean;
  branding_enabled: boolean;
  skipped_communications: string | null;
  points: number | null; // Not in schema;
  surname: string;
  avatar: Image | null;
  credits?: number;
  avatarFile: File | null;
  custom_fields: CustomFieldWithValue[] | null;
  description: {
    html: string;
    text: string;
  } | null;
  level: number | null; // Not in schema
  locale: string;
  timezone: string;
  email_notifications: boolean;
  permissions: {
    administrator: string[];
    instructor: string[];
    learner: string[];
  };
  policies: UserProfilePolicies;
  main_portal_access: boolean;
  is_owner: boolean;
  impersonated: boolean;
  available_roles: UserRole[];
  available_types?: AvailableUserType[];
  deactivation_date?: string | null;
  user_type?: {
    id: number;
    name: string;
  };
  total_invoices: number;
  subscription?: Subscription;
  registration?: string;
  last_login?: string;
  type?: UserRole;
  status?: Status;
  can_delete_self?: boolean;
  has_accepted_craft_disclaimer?: boolean;
  show_onboarding: boolean;
  show_welcome_questions: boolean;
  connected_to_conference_service: boolean;
  show_new_interface_tour: boolean;
  was_created_in_trial: boolean;
  is_locked: boolean;
  should_show_end_trial_modal: boolean;
  has_seen_skills_admin_onboarding?: boolean;
  has_seen_skills_learner_onboarding?: boolean;
  show_feedback_modal: boolean;
  visible_feedback_pages: string[];
  feedback_skipped: boolean;
};

export type UserProfilePolicies = {
  can_copy_course_from_domain: boolean;
  can_create_course: boolean;
  can_create_course_policy: boolean;
  can_create_course_plan: boolean;
  can_view_courses: boolean;
  can_impersonate: boolean;
  can_reset_progress: boolean;
  can_mass_delete_courses: boolean;
  can_access_referral: boolean;
  can_view_communications: boolean;
  can_create_conference: boolean;
  can_watch_demo: boolean;
  can_enable_reverse_trial: boolean;
  can_view_open_sesame_courses: boolean;
  can_view_skills?: boolean;
  can_edit_skills_settings?: boolean;
  can_change_password?: boolean;
  ai: AIPolicies;
};

export type AIPolicies = {
  can_create_unit_with_prompt: boolean;
  can_generate_new_skill: boolean;
  can_generate_skill_thumbnail: boolean;
  can_assign_skill_courses_with_ai: boolean;
  can_suggest_skill_resources_with_ai: boolean;
  can_create_skill_questions_with_ai: boolean;
  can_use_talent_pool: boolean;
  can_use_job_pathfinder: boolean;
  can_use_editor_ai_options: boolean;
  can_generate_test_questions: boolean;
  can_edit_ai_settings: boolean;
  can_access_ai_features: boolean;
  can_enable_ai_features_in_branch_creation: boolean;
  can_toggle_ai_features_in_branch: boolean;
  editor: EditorAIPolicies;
};

export type EditorAIPolicies = {
  can_write_about: boolean;
  can_improve_writing: boolean;
  can_fix_spelling_and_grammar: boolean;
  can_make_shorter: boolean;
  can_make_longer: boolean;
  can_continue_sentence: boolean;
  can_change_tone: boolean;
  can_translate: boolean;
};

type UserPolicies = {
  policies: {
    can_delete: boolean;
    can_update: boolean;
    can_impersonate: boolean;
    can_view: boolean;
  };
};

export type UserBranch = {
  id: number;
  name: string;
  url: string;
};

export type User = UserProfile & UserPolicies;

export type AvailableUserType = {
  id: number;
  name: string;
  is_default: boolean;
};

export type Status = "active" | "inactive" | null;

export type UsersFilters = {
  branch_id?: string | null;
  group_id?: string | null;
  status?: "active" | "inactive" | null;
};
export type Group = {
  id: number;
  name: string;
  description: string;
  key_redemptions?: {
    max?: number;
    used?: number;
  };
  branch?: Branch;
  price?: Price;
  key?: string;
  max_key_redemptions?: string;
  policies: {
    can_delete: boolean;
    can_update: boolean;
    can_toggle?: boolean;
    can_add_group_courses?: boolean;
    can_remove_group_courses?: boolean;
  };
};

export type UserGroup = Group & {
  courses: {
    assigned: number;
    total: number;
  };
  policies: {
    can_remove_user: boolean;
    can_make_owner: boolean;
    can_add_group_courses: boolean;
    can_remove_group_courses: boolean;
    can_update: boolean;
    can_delete: boolean;
    can_toggle: boolean;
  };
  is_owner: boolean;
};

export type GroupUser = UserProfile & {
  is_owner: boolean;
  courses: {
    assigned: number;
    total: number;
  };
  policies: {
    can_remove_user: boolean;
    can_make_owner: boolean;
    can_add_group_courses: boolean;
    can_remove_group_courses: boolean;
    can_update: boolean;
    can_delete: boolean;
    can_toggle: boolean;
    can_view: boolean;
  };
};

export type UserRewards = {
  badges: Reward | null;
  level: Reward | null;
  global: Reward | null;
  points: Reward | null;
};

export type Reward = {
  percentage: number;
  threshold: number | null;
};

export type Certificate = {
  id: number;
  course_id: number;
  certification_id?: number;
  course_name: string;
  course_code: string;
  issued_at: string;
  expires_at: string | null;
  start_datetime?: string;
  archived: boolean;
  url: string;
  course_status: "active" | "inactive";
  policies?: {
    can_delete: boolean;
    can_download: boolean;
    can_preview: boolean;
    can_share: boolean;
  };
};

export type ValidCertificateSortingField =
  | "course_name"
  | "-course_name"
  | "issued_at"
  | "-issued_at"
  | "expires_at"
  | "-expires_at";

export type CertificateFilters = {
  dateFromIssueAt: Date | null;
  dateUntilIssueAt: Date | null;
  dateFromExpiresAt: Date | null;
  dateUntilExpiresAt: Date | null;
};

export type UserIntegrations = {
  intercom_settings: Intercom | null;
};

type Intercom = {
  app_id: string;
  user_id: string;
  user_hash: string;
};

export type UserRole = "administrator" | "instructor" | "learner";

export type Subscription = {
  description: string;
  id: number;
  price: Price;
  interval: 1 | 12;
  status: "active" | "cancelled";
  subscription_date: string;
};

export type UserCoursePolicies = {
  can_unenroll: boolean;
  can_enroll: boolean;
  can_synchronize_progress: boolean;
  can_change_user_role: boolean;
  can_preview_certificate: boolean;
  can_download_certificate: boolean;
  can_edit_expiration: boolean;
  can_update_certificate: boolean;
};

export type UserCourse = Pick<Course, "id" | "name" | "code" | "status" | "role_in_course"> & {
  completion_date: string;
  expiration_date: string;
  enrolled_date: string;
  available_roles: UserRole[];
  policies: UserCoursePolicies;
  remaining_licenses: number | null;
};

export type UsersAffectedByMassAction = {
  chunk_size: number;
  chunks: number;
  total_affected: number;
};

export enum UserRoles {
  ADMINISTRATOR = "administrator",
  INSTRUCTOR = "instructor",
  TRAINER = "trainer",
  LEARNER = "learner",
}

export enum SignUpEnum {
  DIRECT = "direct",
  MANUAL = "manual",
}
