import { useConfigurationStore } from "@stores";
import authService from "@utils/services/AuthService";
import { userHasRequiredPermissions } from "./index";

export const importExportPermissions = {
  createImport: "import_export.import",
  createExport: "import_export.export",
};

const { createImport, createExport } = importExportPermissions;
const { getState } = useConfigurationStore;

export default {
  /**
   * Permission => import_export.import
   *
   *  Allowed actions:
   *  POST import_export.import
   *
   * @returns boolean
   */
  canImport: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [createImport],
      getState().userProfileData?.permissions[userRole],
    );
  },
  /**
   * Permission => import_export.export
   *
   *  Allowed actions:
   *  GET import_export.export
   *
   * @returns boolean
   */
  canExport: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [createExport],
      getState().userProfileData?.permissions[userRole],
    );
  },
};
