export enum AvailableTabs {
  portalSettings = "portalSettings",
  usersSettings = "usersSettings",
  userTypeSettings = "userTypeSettings",
  coursesSettings = "coursesSettings",
  categoriesSettings = "categoriesSettings",
  gamificationSettings = "gamificationSettings",
  integrationSettings = "integrationSettings",
  securitySettings = "securitySettings",
  importExportSettings = "importExportSettings",
  subscription = "subscription",
  trainingMatrix = "trainingMatrix",
  timeline = "timeline",
  userReports = "userReports",
  courseReports = "courseReports",
  branchReports = "branchReports",
  groupReports = "groupReports",
  learningActivities = "learningActivities",
  customReports = "customReports",
  notificationsOverview = "overview",
  notificationsHistory = "history",
  notificationsPending = "pending",
  notificationsSystem = "system",
}
