import Color from "color";
import { PalletThemeColors, getProtectedColor } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["trainingMatrix"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);

  return {
    border: colors.secondary.lighter,
    completed: colors.green.base,
    failed: colors.red.base,
    incomplete: colors.orange.base,
    notAttempted: colors.secondary.base,
    svgColor: colors.white,
    hover: protectColorContrast(
      "darker",
      Color(colors.primary.lightest).alpha(0.25).string(),
      0.25,
    ),
    titleColor: colors.secondary.base,
    linkColor: colors.black,
  };
};
