import React, { FC } from "react";
import { Routes, Route } from "react-router-dom";

// Layouts
import {
  DashboardLayout,
  BasicLayout,
  UnitLayout,
  ExternalLayout,
  CustomHomePageLayout,
  CourseOverviewLayout,
  LayoutWrapper,
} from "@layouts";

// Components (not lazy loaded)
import RootPage from "./RootPage/RootPage";
import {
  PermissionsRoute,
  PrivateRoute,
  CmsPublicRoute,
  PublicRoute,
  CourseShare,
} from "@components";

// Permission Utils, stores, hooks
import { featureFlags } from "@config";
import useRoutePermissions from "./useRoutePermissions";
import { useConfigurationStore } from "@stores";
import { useAuth, useIsExternalCatalog } from "@hooks";

// Lazy load views
const SignIn = React.lazy(() => import("@views/SignIn/SignIn"));
const AutoLogin = React.lazy(() => import("@views/AutoLogin/AutoLogin"));
const GoToCourse = React.lazy(() => import("@views/GoToCourse/GoToCourse"));
const ActivateUser = React.lazy(() => import("@views/ActivateUser/ActivateUser"));
const Dashboard = React.lazy(() => import("@views/Dashboard/Dashboard"));
const CustomHomePage = React.lazy(() => import("@views/CustomHomePage/CustomHomePage"));
const CustomHomePageUpsell = React.lazy(() => import("@views/CustomHomePage/CustomHomePageUpsell"));
const MyCourse = React.lazy(() => import("@views/Course/MyCourse"));
const AdminCatalogCourse = React.lazy(() => import("@views/Course/Admin/AdminCatalogCourse"));
const Unit = React.lazy(() => import("@views/Unit/Unit"));
const SampleUnit = React.lazy(() => import("@views/Unit/SampleUnit"));
const UnitContinue = React.lazy(() => import("@views/Unit/UnitContinue"));
const MyCourses = React.lazy(() => import("@views/MyCourses/MyCourses"));
const Catalog = React.lazy(() => import("@views/Catalog/Catalog"));
const CourseStore = React.lazy(() => import("@views/CourseStore/CourseStore"));
const Calendar = React.lazy(() => import("@views/Calendar/Calendar"));
const SearchResults = React.lazy(() => import("@views/SearchResults/SearchResults"));
const ProfileSettings = React.lazy(() => import("@views/Settings/ProfileSettings"));
const MyFiles = React.lazy(() => import("@views/MyFiles/MyFiles"));
const MyBadges = React.lazy(() => import("@views/MyBadges/MyBadges"));
const MyProgress = React.lazy(() => import("@views/MyProgress/MyProgress"));
const Messages = React.lazy(() => import("@views/Messages/Messages"));
const Referral = React.lazy(() => import("@views/Referral/Referral"));
const NewMessage = React.lazy(() => import("@views/Messages/NewMessage"));
const MessageThread = React.lazy(() => import("@views/Messages/MessageThread"));
const Discussions = React.lazy(() => import("@views/Discussions/Discussions"));
const NewDiscussion = React.lazy(() => import("@views/Discussions/NewDiscussion"));
const EditDiscussion = React.lazy(() => import("@views/Discussions/DiscussionEdit"));
const DiscussionThread = React.lazy(() => import("@views/Discussions/DiscussionThread"));
const CatalogCourse = React.lazy(() => import("@views/Course/CatalogCourse"));
const MyGroups = React.lazy(() => import("@views/MyGroups/MyGroups"));
const CourseEdit = React.lazy(() => import("@views/CourseEdit/CourseEdit"));
const NotFound = React.lazy(() => import("@views/Errors/NotFound"));
const SignUp = React.lazy(() => import("@views/SignUp/SignUp"));
const MyPayments = React.lazy(() => import("@views/MyPayments/MyPayments"));
const Courses = React.lazy(() => import("@views/Courses/Courses"));
const UnitEdit = React.lazy(() => import("@views/UnitEdit/UnitEdit"));
const EmailVerification = React.lazy(() => import("@views/EmailVerification/EmailVerification"));
const Branches = React.lazy(() => import("@views/Branches/Branches"));
const Branch = React.lazy(() => import("@views/Branch/Branch"));
const BranchProperties = React.lazy(
  () => import("@views/Branches/components/BranchProperties/BranchProperties"),
);
const BranchCheckSSOConfig = React.lazy(
  () => import("@views/Branches/components/BranchProperties/components/BranchCheckSSOConfig"),
);
const PasswordCreate = React.lazy(() => import("@views/PasswordCreate/PasswordCreate"));
const PasswordReset = React.lazy(() => import("@views/PasswordReset/PasswordReset"));
const Groups = React.lazy(() => import("@views/Groups/Groups"));
const Group = React.lazy(() => import("@views/Group/Group"));
const ChangePassword = React.lazy(() => import("@views/ChangePassword/ChangePassword"));
const Course = React.lazy(() => import("@views/Course/Course"));
const PublicCourseNavigator = React.lazy(() => import("@views/Course/PublicCourseNavigator"));
const Users = React.lazy(() => import("@views/Users/Users"));
const User = React.lazy(() => import("@views/User/User"));
const AccountAndSettings = React.lazy(() => import("@views/AccountAndSettings/AccountAndSettings"));
const CustomizeTheming = React.lazy(() => import("@views/CustomizeTheming/CustomizeTheming"));
const CustomizeBadges = React.lazy(
  () => import("@views/AccountAndSettings/CustomizeBadges/CustomizeBadges"),
);
const CustomDomain = React.lazy(
  () => import("@views/AccountAndSettings/PortalSettings/components/CustomDomain"),
);
const UsersSettings = React.lazy(() => import("@views/User/components/UserSettings/UserSettings"));
const GroupCreate = React.lazy(() => import("@views/Groups/components/GroupCreate/GroupCreate"));
const Reports = React.lazy(() => import("@views/Reports/Reports"));
const CourseReports = React.lazy(
  () => import("@views/Reports/CourseReports/CourseReports/CourseReports"),
);
const UserReports = React.lazy(() => import("@views/Reports/UserReports/UserReports/UserReports"));
const SingleCourseReports = React.lazy(() => import("@views/Reports/CourseReports/Course/Course"));
const BranchReports = React.lazy(
  () => import("@views/Reports/BranchReports/BranchReports/BranchReports"),
);
const GroupReports = React.lazy(
  () => import("@views/Reports/GroupReports/GroupReports/GroupReports"),
);
const UnitReports = React.lazy(() => import("@views/Reports/UnitReports/UnitReports"));
const Automations = React.lazy(() => import("@views/Automations/Automations"));
const SingleUserReports = React.lazy(() => import("@views/Reports/UserReports/User/User"));
const SingleBranchReports = React.lazy(() => import("@views/Reports/BranchReports/Branch/Branch"));
const SingleUnitReports = React.lazy(() => import("@views/Reports/UnitReports/Unit/Unit"));
const SingleGroupReports = React.lazy(() => import("@views/Reports/GroupReports/Group/Group"));
const CustomReports = React.lazy(() => import("@views/Reports/CustomReports/CustomReports"));
const CustomReport = React.lazy(
  () => import("@views/Reports/CustomReports/SingleCustomReport/CustomReport"),
);
const CustomUserFields = React.lazy(
  () => import("@views/AccountAndSettings/UsersSettings/components/CustomUserFields"),
);
const Notifications = React.lazy(() => import("@views/Notifications/Notifications"));
const CheckSSOConfig = React.lazy(
  () => import("@views/AccountAndSettings/UsersSettings/components/CheckSSOConfig"),
);
const GradingHub = React.lazy(() => import("@views/Gradebook/GradingHub"));
const CourseStoreCourse = React.lazy(() => import("@views/CourseStore/Course/CourseStoreCourse"));
const OtherProvidersCourse = React.lazy(
  () => import("@views/CourseStore/Course/OtherProvidersCourse"),
);
const OpenSesameCourse = React.lazy(() => import("@views/CourseStore/OpenSesame/OpenSesameCourse"));
const CustomCourseFields = React.lazy(
  () => import("@views/AccountAndSettings/CoursesSettings/CustomCourseFields/CustomCourseFields"),
);
const SubscriptionOptions = React.lazy(() => import("@views/Subscription/SubscriptionOptions"));
const Subscription = React.lazy(() => import("@views/Subscription/Subscription"));
const OnboardingWelcome = React.lazy(
  () => import("@views/Onboarding/OnboardingWelcome/OnboardingWelcome"),
);
const OnboardingNotAvailable = React.lazy(
  () => import("@views/Onboarding/OnboardingNotAvailable/OnboardingNotAvailable"),
);
const OnboardingOptions = React.lazy(
  () => import("@views/Onboarding/OnboardingNextSteps/OnboardingNextSteps"),
);
const CustomCertificates = React.lazy(
  () => import("@views/AccountAndSettings/CoursesSettings/Certificates/CustomCertificates"),
);
const InvoicesList = React.lazy(
  () => import("@views/AccountAndSettings/EcommerceSettings/components/InvoicesList/InvoicesList"),
);
const CouponsList = React.lazy(
  () => import("@views/AccountAndSettings/EcommerceSettings/components/Coupons/CouponsList"),
);
const UserSubmissionAssignment = React.lazy(
  () => import("@views/Reports/UnitReports/Unit/Assignment/UserSubmission"),
);
const UserSubmissionSurvey = React.lazy(
  () => import("@views/Reports/UnitReports/Unit/Survey/UserSubmission"),
);
const Skills = React.lazy(() => import("@views/Skills/Skills"));
const MySkills = React.lazy(() => import("@views/Skills/MySkills/MySkills"));
const JobRole = React.lazy(() => import("@views/Skills/JobRole/JobRole"));
const MyJobRole = React.lazy(() => import("@views/Skills/MySkills/MyJobRole/MyJobRole"));
const EditSkill = React.lazy(
  () => import("@views/Skills/components/SingleSkill/components/EditSkill/EditSkill"),
);
const MySkill = React.lazy(() => import("@views/Skills/MySkills/MySkill/MySkill"));
const SkillAssessmentQuestions = React.lazy(
  () =>
    import(
      "@views/Skills/SkillAssessment/components/SkillAssessmentQuestions/SkillAssessmentQuestions"
    ),
);
const EditSkillAssessment = React.lazy(
  () =>
    import(
      "@views/Skills/components/SingleSkill/components/EditSkill/components/EditSkillAssessment/EditSkillAssessment"
    ),
);
const SkillAssessmentLayout = React.lazy(
  () => import("@views/Skills/layouts/SkillAssessmentLayout/SkillAssessmentLayout"),
);
const SkillAssessmentResults = React.lazy(
  () =>
    import(
      "@views/Skills/SkillAssessment/components/SkillAssessmentResults/SkillAssessmentResults"
    ),
);

const UserSubmissionTest = React.lazy(
  () => import("@views/Reports/UnitReports/Unit/Test/UserSubmission"),
);
const Conferences = React.lazy(() => import("@views/Conferences/Conferences"));
const RedirectExternalCatalog = React.lazy(
  () => import("@components/RedirectExternalCatalog/RedirectExternalCatalog"),
);

// Constants
import userRoles from "@constants/userRoles";
import { URLS } from "@constants/urls";
import PublicCourseRoute from "@components/CustomRoutes/PublicCourseRoute";
import { UnitTypes } from "@views/Reports/UnitReports/constants";

// Not learner roles
const notLearnerRoles = [userRoles.ADMINISTRATOR, userRoles.INSTRUCTOR];
const notAdminRoles = [userRoles.INSTRUCTOR, userRoles.LEARNER];
const administrator = [userRoles.ADMINISTRATOR];
const instructor = [userRoles.INSTRUCTOR];
const learner = [userRoles.LEARNER];

const AppRoutes: FC = () => {
  // Hooks
  const { isAuthenticated } = useAuth();
  const isExternalCatalogView = useIsExternalCatalog();
  const { permissions } = useRoutePermissions();
  const { domainSettings, userProfileData } = useConfigurationStore();
  const {
    is_sanctioned: isSanctioned,
    reached_branch_limit: reachedBranchLimit,
    cms,
    sso,
    isFreePlan,
  } = domainSettings ?? {};

  // Derived state related to state and view permissions.
  const { published: isCMSPublished } = cms ?? {};
  const { disable_direct_login: isDirectLoginDisabled } = sso ?? {};
  const showCMSPublicRoute = isCMSPublished && !isAuthenticated;
  const shouldAccessPublicCMS = showCMSPublicRoute && !isDirectLoginDisabled;
  const {
    show_onboarding: showOnboarding,
    show_welcome_questions: showWelcomeQuestions,
    is_owner: isPortalOwner,
  } = userProfileData ?? {};

  const skillsEnabled = userProfileData?.policies?.can_view_skills ?? false;

  /**
   * For routes accessible by both learners and instructors, we use `hasSkillsReadPermission()` in the
   * `canAccess` prop instead of including the skill read permission in `permissions`.
   * This ensures instructors, who don't have explicit skill read permissions, can still access skills
   * as learners.
   * `hasSkillsReadPermission()` handles both instructor and learner cases.
   */
  const hasSkillsReadPermission = permissions.hasSkillsReadPermission;

  return (
    <LayoutWrapper>
      <Routes>
        {/* Public pages */}
        <Route element={<BasicLayout />}>
          <Route
            path={URLS.root}
            element={shouldAccessPublicCMS ? <CustomHomePage isDisplaying={true} /> : <RootPage />}
          />
          {showCMSPublicRoute && (
            <Route element={<CmsPublicRoute />}>
              <Route
                path={URLS.customHomePage.public}
                element={<CustomHomePage isDisplaying={true} />}
              />
            </Route>
          )}

          <Route path={URLS.login} element={<SignIn />} />
          <Route path={URLS.gotocourse} element={<GoToCourse />} />
          <Route path={URLS.autologin} element={<AutoLogin />} />
          <Route path={URLS.activateUser} element={<ActivateUser />} />
          <Route path={URLS.signup} element={<SignUp />} />
          <Route path={URLS.emailVerification} element={<EmailVerification />} />
          <Route path={URLS.passwordReset} element={<PasswordReset />} />
          <Route path={URLS.passwordCreate} element={<PasswordCreate />} />
          <Route path={URLS.changePassword} element={<ChangePassword />} />
          <Route path={URLS.courses.courseShare} element={<CourseShare />} />
        </Route>

        {/* External view routes */}
        <Route element={<PublicRoute condition={isExternalCatalogView} />}>
          {!isAuthenticated && (
            <>
              <Route element={<ExternalLayout />}>
                <Route path={URLS.catalog.index} element={<Catalog />} />
              </Route>
              <Route element={<UnitLayout />}>
                <Route path={URLS.publicCourse.sampleUnit} element={<SampleUnit isPublic />} />
              </Route>
              <Route element={<CourseOverviewLayout />}>
                <Route path={URLS.catalog.course} element={<CatalogCourse />} />
              </Route>
            </>
          )}
        </Route>

        {/* Submissions view routes */}

        <Route element={<PublicRoute condition={!isAuthenticated} />}>
          <Route element={<ExternalLayout />}>
            {!isAuthenticated && (
              <>
                <Route
                  path={URLS.reports.userSubmissionAssignment}
                  element={<UserSubmissionAssignment />}
                />
                <Route
                  path={URLS.reports.userSubmissionSurvey}
                  element={<UserSubmissionSurvey />}
                />

                <Route path={URLS.reports.userSubmissionTest} element={<UserSubmissionTest />} />
              </>
            )}
          </Route>
        </Route>

        {/* Public course */}
        <Route element={<PermissionsRoute roles={["learner"]} permissions={[]} />}>
          <Route
            path={URLS.publicCourse.publicCourseNavigator}
            element={<PublicCourseNavigator />}
          />
        </Route>

        <Route element={<PublicCourseRoute />}>
          <Route element={<CourseOverviewLayout />}>
            <Route path={URLS.publicCourse.index} element={<MyCourse />} />
          </Route>

          <Route element={<UnitLayout />}>
            <Route path={URLS.publicCourse.unit} element={<Unit isPublic />} />
          </Route>
        </Route>

        <Route path="/external-catalog/*" element={<RedirectExternalCatalog />} />

        {/* Private pages (routes) */}
        <Route element={<PrivateRoute />}>
          {/* Dashboard layout */}
          <Route element={<DashboardLayout />}>
            <Route path={URLS.dashboard} element={<Dashboard />} />
            {isAuthenticated && (
              <>
                <Route
                  path={URLS.reports.userSubmissionAssignment}
                  element={<UserSubmissionAssignment />}
                />
                <Route
                  path={URLS.reports.userSubmissionSurvey}
                  element={<UserSubmissionSurvey />}
                />

                <Route path={URLS.reports.userSubmissionTest} element={<UserSubmissionTest />} />
              </>
            )}

            {/* User pages */}
            {/* Groups  */}
            <Route
              element={
                <PermissionsRoute
                  permissions={[permissions.profileRead, permissions.groupJoin]}
                  requireAll={false}
                />
              }
            >
              <Route path={URLS.user.groups} element={<MyGroups />} />
            </Route>
            <Route element={<PermissionsRoute permissions={[permissions.profileRead]} />}>
              <Route path={URLS.user.profile} element={<ProfileSettings />} />
              <Route path={URLS.user.files} element={<MyFiles />} />
              <Route path={URLS.user.payments} element={<MyPayments />} />
            </Route>
            <Route element={<PermissionsRoute permissions={[permissions.reportsRead]} />}>
              <Route path={URLS.user.progress} element={<MyProgress />} />
            </Route>
            <Route element={<PermissionsRoute permissions={[permissions.gamificationRead]} />}>
              <Route path={URLS.user.badges} element={<MyBadges />} />
            </Route>

            {/* Courses pages */}
            <Route
              element={<PermissionsRoute roles={learner} permissions={[permissions.coursesRead]} />}
            >
              <Route path={URLS.user.courses} element={<MyCourses />} />
            </Route>

            {/* Calendar page */}
            <Route element={<PermissionsRoute permissions={[permissions.calendarRead]} />}>
              <Route path={URLS.user.calendar} element={<Calendar />} />
            </Route>

            {/* Catalog pages */}
            {isAuthenticated && (
              <Route element={<PermissionsRoute permissions={[permissions.catalogRead]} />}>
                <Route path={URLS.catalog.index} element={<Catalog />} />
              </Route>
            )}

            {/* Course Store Pages */}
            <Route
              element={
                <PermissionsRoute
                  roles={administrator}
                  permissions={[permissions.coursesRead, permissions.courseStoreSubscription]}
                />
              }
            >
              <Route path={URLS.courseStore.index} element={<CourseStore />} />
            </Route>

            {featureFlags.skills && (
              <>
                {/* TalentSkills - DashboardLayout - Admin */}
                <Route
                  element={
                    <PermissionsRoute
                      roles={administrator}
                      permissions={[permissions.skillsRead]}
                      canAccess={skillsEnabled}
                    />
                  }
                >
                  <Route path={URLS.skills.skills} element={<Skills />} />
                  <Route path={URLS.skills.jobRole} element={<JobRole />} />
                </Route>

                {/* TalentSkills - DashboardLayout - Learner */}
                <Route
                  element={
                    <PermissionsRoute
                      roles={notAdminRoles}
                      permissions={[]}
                      canAccess={skillsEnabled && hasSkillsReadPermission()}
                    />
                  }
                >
                  <Route path={URLS.skills.mySkills} element={<MySkills />} />
                </Route>
              </>
            )}

            {/* Account and settings page */}
            <Route
              element={
                <PermissionsRoute
                  permissions={[
                    permissions.accountRead,
                    permissions.accountUpdate,
                    permissions.createExport,
                    permissions.createImport,
                    permissions.categoriesRead,
                    permissions.userTypesRead,
                  ]}
                  requireAll={false}
                />
              }
            >
              <Route
                path={URLS.accountAndSettings.accountAndSettings}
                element={<AccountAndSettings />}
              />
              <Route
                element={
                  <PermissionsRoute permissions={[permissions.customDomain]} requireAll={false} />
                }
              >
                <Route path={URLS.accountAndSettings.customDomain} element={<CustomDomain />} />
              </Route>
              <Route
                element={
                  <PermissionsRoute
                    permissions={[permissions.accountRead, permissions.accountUpdate]}
                    requireAll={true}
                  />
                }
              >
                <Route
                  path={URLS.accountAndSettings.customizeTheming}
                  element={<CustomizeTheming />}
                />
                <Route path={URLS.accountAndSettings.checkSSOConfig} element={<CheckSSOConfig />} />
              </Route>

              <Route
                element={
                  <PermissionsRoute
                    permissions={[permissions.accountRead, permissions.accountUpdate]}
                    requireAll={false}
                  />
                }
              >
                <Route
                  path={URLS.accountAndSettings.customUserFields}
                  element={<CustomUserFields />}
                />
                <Route
                  path={URLS.accountAndSettings.customCourseFields}
                  element={<CustomCourseFields />}
                />
                <Route
                  element={<PermissionsRoute permissions={[permissions.accountGamification]} />}
                >
                  <Route
                    path={URLS.accountAndSettings.customizeBadges}
                    element={<CustomizeBadges />}
                  />
                </Route>
                <Route
                  path={URLS.accountAndSettings.certificates}
                  element={<CustomCertificates />}
                />
              </Route>
            </Route>

            <Route element={<PermissionsRoute permissions={[permissions.subscriptionRead]} />}>
              <Route path={URLS.subscription.subscription} element={<Subscription />} />
              <Route
                path={URLS.subscription.subscriptionOptions}
                element={<SubscriptionOptions />}
              />
            </Route>

            <Route
              element={
                <PermissionsRoute
                  permissions={[permissions.accountUpdate, permissions.ecommerceUpdate]}
                />
              }
            >
              <Route path={URLS.accountAndSettings.invoiceList} element={<InvoicesList />} />
              <Route path={URLS.accountAndSettings.couponsList} element={<CouponsList />} />
            </Route>

            {/* Automations page */}

            <Route element={<PermissionsRoute permissions={[permissions.automationsRead]} />}>
              <Route path={URLS.automations.automations} element={<Automations />} />
            </Route>

            {/* Notifications page */}

            <Route element={<PermissionsRoute permissions={[permissions.notificationsRead]} />}>
              <Route path={URLS.notifications.notifications} element={<Notifications />} />
            </Route>

            {/* Discussions pages */}
            <Route element={<PermissionsRoute permissions={[permissions.discussionsRead]} />}>
              <Route path={URLS.user.singleDiscussion} element={<DiscussionThread />} />
              <Route path={URLS.user.editDiscussion} element={<EditDiscussion />} />
            </Route>
            <Route
              element={
                <PermissionsRoute
                  permissions={[permissions.discussionsRead, permissions.discussionsCreate]}
                />
              }
            >
              <Route path={URLS.user.newDiscussion} element={<NewDiscussion />} />
            </Route>

            {/* Referral page */}
            <Route
              element={
                <PermissionsRoute permissions={[]} canAccess={permissions.canAccessReferral} />
              }
            ></Route>
            <Route path={URLS.referral.referral} element={<Referral />} />

            {/* Messages pages */}
            <Route
              element={
                <PermissionsRoute
                  permissions={[permissions.messagesRead, permissions.messagesCreate]}
                />
              }
            >
              <Route path={URLS.user.newMessage} element={<NewMessage />} />
            </Route>
            <Route element={<PermissionsRoute permissions={[permissions.messagesRead]} />}>
              <Route path={URLS.user.inboxMessage} element={<MessageThread type="inbox" />} />
              <Route path={URLS.user.sentMessage} element={<MessageThread type="sent" />} />
            </Route>

            {/* Search pages */}
            <Route path={URLS.search.all} element={<SearchResults />} />

            <Route
              element={
                <PermissionsRoute
                  permissions={[permissions.accountRead, permissions.accountUpdate]}
                />
              }
            >
              <Route path={URLS.user.customizeTheming} element={<CustomizeTheming />} />
            </Route>

            {/* CustomHomePage-upsell*/}
            <Route
              element={
                <PermissionsRoute
                  permissions={[permissions.accountUpdate]}
                  requireAll={true}
                  canAccess={!permissions.canAccessCustomHomePageBuilder || isFreePlan}
                />
              }
            >
              <Route path={URLS.customHomePage.customHomePage} element={<CustomHomePageUpsell />} />
            </Route>
          </Route>

          {featureFlags.skills && (
            <Route
              element={
                <PermissionsRoute
                  roles={notAdminRoles}
                  permissions={[]}
                  canAccess={skillsEnabled && hasSkillsReadPermission()}
                />
              }
            >
              <Route path={URLS.skills.mySkill} element={<MySkill />} />

              <Route element={<UnitLayout />}>
                <Route element={<SkillAssessmentLayout />}>
                  <Route
                    path={URLS.skills.skillAssessmentQuestion}
                    element={<SkillAssessmentQuestions />}
                  />
                  <Route
                    path={URLS.skills.skillAssessmentResults}
                    element={<SkillAssessmentResults />}
                  />
                </Route>
              </Route>
            </Route>
          )}

          {/* Dashboard layout - noMainContentPadding */}
          <Route element={<DashboardLayout />}>
            {/* Messages page */}
            <Route element={<PermissionsRoute permissions={[permissions.messagesRead]} />}>
              <Route path={URLS.user.messages} element={<Messages />} />
            </Route>

            {/* Discussions page */}
            <Route element={<PermissionsRoute permissions={[permissions.discussionsRead]} />}>
              <Route path={URLS.user.discussions} element={<Discussions />} />
            </Route>
          </Route>

          {/* Unit pages */}
          <Route
            element={
              <PermissionsRoute
                roles={learner}
                permissions={[permissions.coursesRead, permissions.coursesUnitRead]}
              />
            }
          >
            {/* Unit layout */}
            <Route element={<UnitLayout />}>
              <Route path={URLS.user.unit} element={<Unit />} />
              <Route path={URLS.catalog.sampleUnit} element={<SampleUnit />} />
            </Route>

            <Route path={URLS.user.unitContinue} element={<UnitContinue />} />
          </Route>

          {/* Course overview pages */}
          <Route element={<CourseOverviewLayout />}>
            <Route element={<PermissionsRoute permissions={[permissions.catalogRead]} />}>
              <Route path={URLS.catalog.course} element={<CatalogCourse />} />
            </Route>
            <Route
              element={
                <PermissionsRoute
                  roles={learner}
                  permissions={[permissions.coursesRead, permissions.coursesUnitRead]}
                />
              }
            >
              <Route path={URLS.user.course} element={<MyCourse />} />
            </Route>
          </Route>

          {/** **************************/}
          {/* Admin / Instructor pages */}
          {/** **************************/}

          <Route element={<DashboardLayout />}>
            {/* Courses pages */}
            <>
              <Route
                element={
                  <PermissionsRoute
                    roles={notLearnerRoles}
                    permissions={[permissions.coursesUnionRead]}
                  />
                }
              >
                <Route path={URLS.courses.courses} element={<Courses />} />
              </Route>
            </>

            <Route
              element={
                <PermissionsRoute
                  roles={notLearnerRoles}
                  permissions={[permissions.coursesUnionRead]}
                />
              }
            >
              <Route path={URLS.courses.singleCourse} element={<Course />} />
            </Route>

            {/* Branches page */}
            <Route
              element={
                <PermissionsRoute
                  roles={notLearnerRoles}
                  permissions={[permissions.branchRead]}
                  requireAll={false}
                />
              }
            >
              <>
                <Route path={URLS.branches.branches} element={<Branches />} />

                <Route
                  element={
                    <PermissionsRoute
                      permissions={[permissions.branchCreate]}
                      canAccess={!reachedBranchLimit}
                    />
                  }
                >
                  <Route path={URLS.branches.create} element={<BranchProperties />} />
                </Route>

                <Route path={URLS.branches.branch} element={<Branch />} />

                <Route path={URLS.branches.checkSSOConfig} element={<BranchCheckSSOConfig />} />
              </>
            </Route>

            {/* Conferences page */}
            <Route
              element={
                <PermissionsRoute roles={instructor} permissions={[permissions.conferencesRead]} />
              }
            >
              <Route path={URLS.conferences.index} element={<Conferences />} />
            </Route>

            {/* Users page */}
            <>
              <Route
                element={
                  <PermissionsRoute
                    roles={notLearnerRoles}
                    permissions={[permissions.usersRead]}
                    requireAll={false}
                  />
                }
              >
                <Route path={URLS.users.users} element={<Users />} />
                <Route path={URLS.users.user} element={<User />} />
              </Route>
              <Route
                element={
                  <PermissionsRoute
                    permissions={[permissions.usersRead, permissions.usersUpdate]}
                  />
                }
              >
                <Route path={URLS.users.edit} element={<UsersSettings />} />
              </Route>
              <Route element={<PermissionsRoute permissions={[permissions.usersCreate]} />}>
                <Route path={URLS.users.create} element={<UsersSettings />} />
              </Route>
            </>

            {/* Groups pages */}
            <>
              <Route
                element={
                  <PermissionsRoute
                    roles={notLearnerRoles}
                    permissions={[permissions.groupRead]}
                    requireAll={false}
                  />
                }
              >
                <Route path={URLS.groups.groups} element={<Groups />} />
                <Route path={URLS.groups.group} element={<Group />} />
              </Route>
              <Route element={<PermissionsRoute permissions={[permissions.groupCreate]} />}>
                <Route path={URLS.groups.create} element={<GroupCreate />} />
              </Route>
            </>

            {/* Reports pages */}
            <Route
              element={
                <PermissionsRoute
                  roles={notLearnerRoles}
                  permissions={permissions.reportsMainPagePermissions}
                  requireAll={false}
                />
              }
            >
              <Route path={URLS.reports.reports} element={<Reports />} />
            </Route>

            <Route
              element={
                <PermissionsRoute
                  roles={notLearnerRoles}
                  permissions={[permissions.courseReports]}
                />
              }
            >
              <Route path={URLS.reports.courseReports} element={<CourseReports />} />
              <Route path={URLS.reports.course} element={<SingleCourseReports />} />
            </Route>

            <Route
              element={
                <PermissionsRoute roles={administrator} permissions={[permissions.userReports]} />
              }
            >
              <Route path={URLS.reports.userReports} element={<UserReports />} />
              <Route path={URLS.reports.user} element={<SingleUserReports />} />
            </Route>

            <Route
              element={
                <PermissionsRoute
                  roles={administrator}
                  permissions={[permissions.userGroupReports]}
                />
              }
            >
              <Route path={URLS.reports.groupReports} element={<GroupReports />} />
              <Route path={URLS.reports.group} element={<SingleGroupReports />} />
            </Route>

            <Route
              element={
                <PermissionsRoute roles={administrator} permissions={[permissions.branchReports]} />
              }
            >
              <Route path={URLS.reports.branchReports} element={<BranchReports />} />
              <Route path={URLS.reports.branch} element={<SingleBranchReports />} />
            </Route>

            <Route
              element={
                <PermissionsRoute
                  roles={instructor}
                  permissions={[permissions.coursesUnitUpdate]}
                />
              }
            >
              <Route path={URLS.gradingHub.gradingHub} element={<GradingHub />} />
            </Route>

            <Route
              element={
                <PermissionsRoute
                  roles={notLearnerRoles}
                  permissions={[
                    permissions.testReports,
                    permissions.surveyReports,
                    permissions.assignmentReports,
                    permissions.iltReports,
                  ]}
                  requireAll={false}
                />
              }
            >
              <Route path={URLS.reports.learningActivities} element={<UnitReports />} />
            </Route>

            <Route
              element={
                <PermissionsRoute roles={administrator} permissions={[permissions.customReports]} />
              }
            >
              <Route path={URLS.reports.customReports} element={<CustomReports />} />
              <Route
                element={
                  <PermissionsRoute
                    roles={administrator}
                    permissions={[permissions.customReports]}
                    canAccess={permissions.canAccessCustomReports}
                  />
                }
              >
                <Route path={URLS.reports.customReport} element={<CustomReport />} />
              </Route>
            </Route>

            <Route
              element={
                <PermissionsRoute
                  redirectLink={URLS.reports.learningActivities}
                  roles={notLearnerRoles}
                  permissions={[permissions.testReports]}
                />
              }
            >
              <Route
                path={URLS.reports.units.testUnit}
                element={<SingleUnitReports unitType={UnitTypes.TEST} />}
              />
            </Route>

            <Route
              element={
                <PermissionsRoute
                  redirectLink={URLS.reports.learningActivities}
                  roles={notLearnerRoles}
                  permissions={[permissions.testReports]}
                />
              }
            >
              <Route
                path={URLS.reports.units.scormUnit}
                element={<SingleUnitReports unitType={UnitTypes.SCORM} />}
              />
            </Route>

            <Route
              element={
                <PermissionsRoute
                  redirectLink={URLS.reports.learningActivities}
                  roles={notLearnerRoles}
                  permissions={[permissions.assignmentReports]}
                />
              }
            >
              <Route
                path={URLS.reports.units.assignmentUnit}
                element={<SingleUnitReports unitType={UnitTypes.ASSIGNMENT} />}
              />
            </Route>

            <Route
              element={
                <PermissionsRoute
                  redirectLink={URLS.reports.learningActivities}
                  roles={notLearnerRoles}
                  permissions={[permissions.surveyReports]}
                />
              }
            >
              <Route
                path={URLS.reports.units.surveyUnit}
                element={<SingleUnitReports unitType={UnitTypes.SURVEY} />}
              />
            </Route>

            <Route
              element={
                <PermissionsRoute
                  redirectLink={URLS.reports.learningActivities}
                  roles={notLearnerRoles}
                  permissions={[permissions.iltReports]}
                />
              }
            >
              <Route
                path={URLS.reports.units.iltUnit}
                element={<SingleUnitReports unitType={UnitTypes.ILT} />}
              />
            </Route>
          </Route>

          {/* Course overview pages */}
          <Route element={<CourseOverviewLayout />}>
            <Route
              element={
                <PermissionsRoute
                  roles={administrator}
                  permissions={[permissions.coursesRead, permissions.courseStoreSubscription]}
                />
              }
            >
              <Route path={URLS.courseStore.course} element={<CourseStoreCourse />} />
              <Route
                path={URLS.courseStore.otherProvidersCourse}
                element={<OtherProvidersCourse />}
              />
              <Route path={URLS.courseStore.openSesameCourse} element={<OpenSesameCourse />} />
            </Route>
            <Route
              element={
                <PermissionsRoute roles={notLearnerRoles} permissions={[permissions.coursesRead]} />
              }
            >
              <Route path={URLS.catalog.adminCourse} element={<AdminCatalogCourse />} />
            </Route>
          </Route>

          {featureFlags.skills && (
            <>
              {/* TalentSkills - UnitLayout - Learner */}
              <Route
                element={
                  <PermissionsRoute
                    roles={notAdminRoles}
                    permissions={[]}
                    canAccess={skillsEnabled && hasSkillsReadPermission()}
                  />
                }
              >
                <Route element={<UnitLayout />}>
                  <Route path={URLS.skills.myJobRole} element={<MyJobRole />} />
                </Route>
              </Route>

              {/* TalentSkills - UnitLayout - Admin */}
              <Route
                element={
                  <PermissionsRoute
                    roles={administrator}
                    permissions={[permissions.skillsRead]}
                    canAccess={skillsEnabled}
                  />
                }
              >
                <Route element={<UnitLayout />}>
                  <Route path={URLS.skills.editSkill} element={<EditSkill />} />
                  <Route path={URLS.skills.editSkillAssessment} element={<EditSkillAssessment />} />
                </Route>
              </Route>
            </>
          )}

          {/* Custom Home Page */}

          <>
            <Route
              element={
                <PermissionsRoute
                  permissions={[permissions.accountUpdate, permissions.branchUpdate]}
                  requireAll={false}
                />
              }
            >
              <Route element={<CustomHomePageLayout />}>
                {/* Builder/Edit mode routes */}
                <Route path={URLS.customHomePage.builder} element={<CustomHomePage />} />
                {/* Preview Routes  */}
                <Route
                  path={URLS.customHomePage.preview}
                  element={<CustomHomePage isPreview={true} />}
                />
                <Route
                  path={URLS.customHomePage.previewSlug}
                  element={<CustomHomePage isPreview={true} />}
                />
              </Route>
            </Route>
            {/* Other Private Routes  */}
            <Route path={URLS.customHomePage.privateSlug} element={<DashboardLayout />}>
              <Route
                path={URLS.customHomePage.privateSlug}
                element={<CustomHomePage isDisplaying={true} />}
              />
            </Route>
          </>

          {/* Manage course pages */}
          <Route element={<UnitLayout />}>
            <Route
              element={
                <PermissionsRoute
                  roles={notLearnerRoles}
                  permissions={[permissions.coursesRead, permissions.coursesUnitRead]}
                />
              }
            >
              <Route element={<UnitLayout />}>
                <Route path={URLS.user.unitPreview} element={<Unit isPreview />} />
              </Route>
            </Route>

            <Route
              element={
                <PermissionsRoute
                  roles={notLearnerRoles}
                  permissions={[permissions.coursesUnionRead]}
                />
              }
            >
              <Route path={URLS.courses.edit} element={<CourseEdit />} />
            </Route>

            <Route
              element={
                <PermissionsRoute
                  roles={notLearnerRoles}
                  permissions={[permissions.coursesUnionRead, permissions.coursesUnitRead]}
                />
              }
            >
              <Route path={URLS.units.edit} element={<UnitEdit />} />
            </Route>
          </Route>

          {/* 404 page */}
          <Route path="*" element={<BasicLayout />}>
            <Route path="*" element={<NotFound />} />
          </Route>

          {/* Onboarding page */}
          <Route element={<BasicLayout />}>
            <Route
              element={
                <PermissionsRoute
                  roles={administrator}
                  permissions={[permissions.accountUpdate]}
                  canAccess={isPortalOwner && showWelcomeQuestions}
                />
              }
            >
              <Route path={URLS.onboardingWelcome} element={<OnboardingWelcome />} />
            </Route>

            <Route
              element={
                <PermissionsRoute
                  roles={administrator}
                  permissions={[permissions.accountUpdate]}
                  canAccess={isPortalOwner && showOnboarding}
                />
              }
            >
              <Route path={URLS.onboardingOptions} element={<OnboardingOptions />} />
            </Route>

            <Route
              element={
                <PermissionsRoute
                  roles={administrator}
                  permissions={[permissions.accountUpdate]}
                  canAccess={isPortalOwner && isSanctioned}
                />
              }
            >
              <Route path={URLS.onboardingNotAvailable} element={<OnboardingNotAvailable />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </LayoutWrapper>
  );
};
export default AppRoutes;
