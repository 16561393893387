import { GnosisTheme } from "@epignosis_llc/gnosis";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): GnosisTheme["drawer"] => ({
  closeBtnColor: {
    base: colors.black,
    hover: colors.secondary.base,
  },
  dialog: colors.white,
});
