import { Button, Textarea } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { SparklesSVG } from "@epignosis_llc/gnosis/icons";
import ConfirmationModal from "@components/ReusableModals/ConfirmationModal";
import React, { FC, useRef, useState } from "react";
import { selectAndFocusElement } from "@utils/helpers/textElement";

export type AIPromptModalProps = {
  isOpen: boolean;
  onClose: () => void;
  header: string;
  onGenerate: (prompt: string) => void;
  placeholder?: string;
  isLoading?: boolean;
  promptMinLength?: number;
  id?: string;
};

export const AIPromptModal: FC<AIPromptModalProps> = ({
  isOpen,
  onClose,
  header,
  onGenerate,
  isLoading,
  placeholder = t("ai.promptPlaceholder"),
  promptMinLength = 2,
  id,
}) => {
  const [value, setValue] = useState("");

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const handleGenerate = (): void => {
    onGenerate(value);
    setValue(value);
  };

  return (
    <ConfirmationModal
      id={`ai-prompt-modal${id ? `-${id}` : ""}`}
      isLoading={isLoading}
      isOpen={isOpen}
      onAfterOpen={(): void => {
        selectAndFocusElement(textareaRef.current);
      }}
      onClose={(): void => {
        onClose();
      }}
      bodyTitle={
        <Textarea
          id={`body${id ? `-${id}` : ""}`}
          ref={textareaRef}
          autoFocus={true}
          placeholder={placeholder}
          value={value}
          onChange={(event): void => setValue(event.target.value)}
          onKeyDown={(event): void => {
            if (event.key === "Enter" && !event.shiftKey) {
              event.preventDefault();
              handleGenerate();
            }
          }}
        />
      }
      header={header}
      closeOnOutsideClick={true}
      footerButton={
        <Button
          disabled={value.length < promptMinLength}
          isLoading={isLoading}
          iconBefore={SparklesSVG}
          onClick={handleGenerate}
          aria-label={t("general.generate")}
        >
          {t("general.generate")}
        </Button>
      }
      confirmationButtonColor={"primary"}
    />
  );
};
