import { Column, TableProps } from "@epignosis_llc/gnosis";
import { QueryFilter } from "types/common";
import { t } from "@utils/i18n";

export const SORTING: TableProps["sorting"] = { column: "is_suggested", isDescending: true };
export const DEFAULT_FILTERS: QueryFilter[] = [];

export const getMySkillsColumns = (): Column[] => {
  return [
    {
      accessor: "name",
      cell: t("sorting.name"),
      classNames: ["name"],
      sortableHeader: true,
      headerWidth: 250,
    },
    {
      accessor: "created_on",
      cell: t("sorting.date"),
      classNames: ["date"],
      sortableHeader: true,
      sortOrder: "desc",
    },
    {
      accessor: "is_suggested",
      cell: t("skills.sorting.suggested"),
      classNames: ["suggested"],
      sortableHeader: true,
      sortOrder: "desc",
    },
  ];
};
