import React, { FC } from "react";
import { Text } from "@epignosis_llc/gnosis";
import { noteStyles } from "./styles";

type NoteProps = {
  text: string;
  className?: string;
};

const Note: FC<NoteProps> = ({ text, ...rest }) => (
  <Text fontSize="xs" as="div" css={noteStyles} {...rest}>
    {text}
  </Text>
);

export default Note;
