// Packages or third-party libraries
import React, { FC, useState } from "react";
import { useResponsive } from "ahooks";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SerializedStyles } from "@emotion/react";
import classNames from "classnames";
import { Input } from "@epignosis_llc/gnosis";
import { DurationIconSVG } from "@epignosis_llc/gnosis/icons";
import { endOfDay, isSameDay } from "date-fns";

// Styles
import { dateInput } from "./styles";

// Utils, hooks
import { getLocale } from "@utils/helpers";
import { i18n } from "@utils/i18n";
import { useApplyTranslations } from "@hooks";

export type TimeInputProps = {
  value?: Date | null;
  id?: string;
  size?: "md" | "lg";
  label?: string;
  required?: boolean;
  altLabel?: boolean;
  className?: string;
  inline?: boolean;
  timeFormat?: string;
  status?: "valid" | "error";
  disabled?: boolean;
  isReadOnly?: boolean;
  timeIntervals?: number;
  timeCaption?: string;
  tooltipContent?: string | JSX.Element;
  minDate?: Date;
  onChange: (selectedDate: Date | null) => void;
  handleInputChange?: (eventValue: string) => void;
};

const DEFAULT_TIME_FORMAT = "HH:mm";

const getPlaceholder = (timeFormat: string): string => {
  return timeFormat.toLowerCase().includes("a") ? "HH:MM AA" : "HH:MM";
};

const TimeInput: FC<TimeInputProps> = (props) => {
  const { t } = useApplyTranslations();
  const { sm } = useResponsive();

  const {
    id,
    className,
    isReadOnly = sm, // On mobile screens the "true" causes the calendar to not open
    value = new Date(),
    timeFormat = DEFAULT_TIME_FORMAT,
    disabled = false,
    timeIntervals = 15,
    timeCaption = t("general.time"),
    size = "md",
    label,
    altLabel = false,
    required = false,
    inline = false,
    status = "valid",
    tooltipContent,
    minDate,
    onChange,
    handleInputChange,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const hasLabel = Boolean(label);
  const placeholderText = getPlaceholder(timeFormat);

  // Both minTime and maxTime props required (DatePicker requirement)
  const minTime = minDate && value && isSameDay(value, minDate) ? minDate : undefined;
  const maxTime = minDate && minTime ? endOfDay(minDate) : undefined;

  const containerClassNames = classNames({
    valid: status === "valid",
    error: status === "error",
    "alt-label": hasLabel && altLabel,
    [className ?? ""]: true,
    inline,
    "time-only": true,
  });

  const isRtl = i18n.dir() === "rtl";
  const placement = isRtl ? "bottom-end" : "bottom-start";

  const onChangeDate = (date: Date): void => {
    setIsOpen(false);
    onChange(date);
  };

  const handleCalendarClose = (): void => {
    setIsOpen(false);
  };

  const handleChangeRaw = (event: React.FocusEvent<HTMLInputElement>): void => {
    if (handleInputChange) handleInputChange(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.code === "Backspace") {
      onChange(null);
    }
  };

  return (
    <div
      css={(theme): SerializedStyles => dateInput(theme, { size })}
      className={containerClassNames}
    >
      <DatePicker
        wrapperClassName="branded-datepicker"
        id={id}
        className={className}
        readOnly={isReadOnly}
        selected={value}
        placeholderText={placeholderText}
        autoComplete="off"
        dateFormat={timeFormat}
        timeFormat={timeFormat}
        disabled={disabled}
        locale={getLocale()}
        open={isOpen}
        popperPlacement={placement}
        showPopperArrow={false}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={timeIntervals}
        timeCaption={timeCaption}
        minDate={minDate}
        minTime={minTime}
        maxTime={maxTime}
        customInput={
          <Input
            id={id ?? ""}
            size={size}
            iconAfter={DurationIconSVG}
            label={label}
            tooltipContent={tooltipContent}
            containerAttrs={{ className: required ? "required" : "" }}
            data-lpignore="true"
          />
        }
        onFocus={(): void => setIsOpen(true)}
        onClickOutside={handleCalendarClose}
        onChange={onChangeDate}
        onChangeRaw={handleChangeRaw}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};
export default TimeInput;
