import { css } from "@emotion/react";

const inputHeight = {
  md: "2.5rem",
  lg: "3rem",
};

export const inputContainerBaseStyles = ({ block = true }) => css`
  ${block && "width: 100%"};
  display: inline-flex;
  flex-direction: column;

  &.alt-label {
    flex-direction: row;
    align-items: center;

    label {
      margin: 0 0.75rem 0 0;
    }
  }

  label {
    margin-bottom: 0.5rem;
  }

  &:focus-within,
  &:hover {
    .prefix-icon,
    .suffix-icon {
      z-index: 1;
    }
  }
`;

export const inputBaseStyles = ({ multiSelect }, { block, size }) => css`
  ${size && `height: ${inputHeight[size]}`};
  ${block && "width: 100%"};
  display: inline-block;
  background-color: ${multiSelect.background};
  padding: 0 0.75rem;
  border: 1px solid ${multiSelect.inputBorderColor};
  border-radius: 5px;

  &:hover,
  &:focus {
    border: 1px solid ${multiSelect.borderHoverColor};
  }

  &::placeholder {
    font-style: italic;
    color: ${multiSelect.placeholderColor};
  }
`;

export const searchInputContainer = css`
  width: 100%;
  position: relative;

  .input-wrapper {
    min-width: 250px;
  }

  input {
    padding-inline-end: 2.5rem;
  }

  .clear-button {
    position: absolute;
    inset-inline-end: 0;
    z-index: 1;

    &:hover {
      background: transparent;
    }
  }
  .prefix-icon,
  .suffix-icon {
    svg {
      height: 24px;
    }
  }
`;

export const colorPickerInput = ({ colorPicker }, { color, disabled }) => css`
  position: relative;

  .swatch {
    width: 24px;
    height: 24px;
    border-radius: 5px;
    cursor: pointer;
    z-index: 11;
    position: absolute;
    inset-inline-end: 8px;
    top: calc(22.34px + 16px);
    background-color: ${disabled ? colorPicker.disabled : color};
    cursor: ${disabled ? "not-allowed" : "initial"};

    .popover {
      position: absolute;
      border-radius: 5px;
      z-index: 11;
      inset-inline-end: 0;

      &.bottom {
        top: calc(100% + 2px);
      }

      &.top {
        bottom: calc(100% + 2px);
      }
    }
  }
`;
