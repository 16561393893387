import gamification from "./gamification";
import catalog from "./catalog";
import discussions from "./discussions";
import courses from "./courses";
import profile from "./profile";
import reports from "./reports";
import messages from "./messages";
import calendar from "./calendar";
import groups from "./groups";
import account from "./account";
import branches from "./branches";
import users from "./users";
import userTypes from "./userTypes";
import categories from "./categories";
import importExport from "./importExport";
import files from "./files";
import automations from "./automations";
import notifications from "./notifications";
import helpCenter from "./helpCenter";
import ecommerce from "./ecommerce";
import conferences from "./conferences";
import skills from "./skills";
import ai from "./ai";

export type Permissions = {
  administrator: string[];
  instructor: string[];
  learner: string[];
};

// User has the given permissions
export const userHasRequiredPermissions = (
  permissions: string[],
  userPermissions: string[] | undefined,
  requireAll = true,
): boolean => {
  if (!userPermissions) return false;
  const hasPermission = (permission: string): boolean => userPermissions.includes(permission);

  return requireAll ? permissions.every(hasPermission) : permissions.some(hasPermission);
};

export default {
  discussionsPermissions: discussions,
  profilePermissions: profile,
  reportsPermissions: reports,
  gamificationPermissions: gamification,
  coursesPermissions: courses,
  messagesPermissions: messages,
  catalogPermissions: catalog,
  calendarPermissions: calendar,
  groupPermissions: groups,
  accountPermissions: account,
  branchPermissions: branches,
  usersPermissions: users,
  userTypesPermissions: userTypes,
  categoriesPermissions: categories,
  importExportPermissions: importExport,
  filePermissions: files,
  automationsPermissions: automations,
  notificationsPermissions: notifications,
  ecommercePermissions: ecommerce,
  helpCenterPermissions: helpCenter,
  conferencesPermissions: conferences,
  skillsPermissions: skills,
  ai: ai,
};
