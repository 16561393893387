import { css } from "@emotion/react";

export const tableActionsStyles = (theme, { selectedSortingId, isRtl }) => css`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-direction: ${isRtl ? "row-reverse" : "row"};

  .sortby-text {
    text-align: center;
    white-space: nowrap;
  }

  .sorting-container {
    display: flex;
    align-item: center;
    gap: 0.25rem;
    white-space: nowrap;

    .dropdown {
      .dropdown-wrapper {
        .dropdown-list {
          .${selectedSortingId} {
            background-color: ${theme.dropdown.borderBottomColor};
          }
        }
      }
    }

    .sorting-button-nonclickable {
      pointer-events: none;
    }

    .sorting-button {
      .sorting-name {
        padding: 0 1rem;
      }

      &:hover {
        background-color: ${theme.dropdown.borderBottomColor};
        border-color: ${theme.dropdown.borderOverviewColor};
      }

      &.is-list-open {
        background-color: transparent;
        border-color: ${theme.dropdown.borderOverviewColor};
      }
    }
  }
`;
