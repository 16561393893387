import { css } from "@emotion/react";

export const nestedCategoryContainer = () => css`
  .column-category {
    display: flex;
    align-items: center;

    .overflow-category-text {
      word-break: break-word;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;
