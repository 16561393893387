import { URLS } from "@constants/urls";
import { Column, DropdownItem, TableProps } from "@epignosis_llc/gnosis";
import { t } from "@utils/i18n";
import { QueryFilter } from "types/common";
import { Pagination as IPagination } from "types/responses/index";

export const getUserColumns = (): Column[] => {
  return [
    {
      accessor: "name",
      cell: t("general.user"),
      headerWidth: 200,
    },
    {
      accessor: "completion_date",
      cell: t("general.completionDate"),
      sortOrder: "desc",
    },
    {
      accessor: "completion_status",
      cell: t("general.progressStatus"),
    },
    {
      accessor: "score",
      cell: t("general.score"),
    },
    {
      accessor: "training_time",
      cell: t("general.trainingTime"),
      sortOrder: "desc",
    },
    {
      accessor: "actions",
      cell: "",
      classNames: ["hidden"],
      sortableHeader: false,
    },
  ];
};

export const PAGINATION: IPagination = { number: 1, size: 10 };
export const DEFAULT_FILTERS: QueryFilter[] = [];
export const SORTING: TableProps["sorting"] = { column: "formatted_name", isDescending: false };

export const USERS_DEFAULT_STATE = {
  pagination: PAGINATION,
  sorting: SORTING,
  filters: DEFAULT_FILTERS,
  url: `${URLS.reports.units.unit}/users`,
};

export const PROGRESS_STATUS_TEXT = {
  completed: "general.progress.passed",
  incomplete: "general.progress.inProgress",
  not_attempted: "general.progress.notAttempted",
  failed: "general.progress.notPassed",
  pending: "general.progress.pending",
};

// Timeline tab
export const TIMELINE_PAGINATION: IPagination = { number: 1, size: 10 };
export const TIMELINE_SORTING: TableProps["sorting"] = { column: "", isDescending: false };
export const TIMELINE_DEFAULT_FILTERS: QueryFilter[] = [];

export const TIMELINE_DEFAULT_STATE = {
  pagination: TIMELINE_PAGINATION,
  sorting: TIMELINE_SORTING,
  filters: TIMELINE_DEFAULT_FILTERS,
};

export const getMassActionsOptions = (): DropdownItem[] => {
  return [
    { label: t("general.complete"), value: "complete_units" },
    { label: t("general.reset"), value: "reset_units" },
  ];
};

const PREFIX = "users.userUnits.massActions";

export const userUnitsMassActionsWordings = {
  complete_units: {
    progressHeader: `${PREFIX}.completingLearningActivity`,
    progressMessage: `${PREFIX}.completingLearningActivityCount`,
    confirmationHeader: "users.userUnits.completeLearningActivity",
    confirmationBodyTitle: `${PREFIX}.completeLearningActivityInfo`,
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
    confirmationButton: "general.complete",
    successMessage: `${PREFIX}.successComplete`,
  },
  reset_units: {
    progressHeader: `${PREFIX}.resettingLearningActivity`,
    progressMessage: `${PREFIX}.resettingLearningActivityCount`,
    confirmationHeader: "users.userUnits.resetLearningActivity",
    confirmationBodyTitle: `${PREFIX}.resetLearningActivityInfo`,
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
    confirmationButton: "general.reset",
    successMessage: `${PREFIX}.successReset`,
    confirmationButtonColor: "danger",
  },
};
