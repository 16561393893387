import React, { FC } from "react";
import { Button, Modal, Text } from "@epignosis_llc/gnosis";
import { csvExportModalBody, csvExportModalFooter } from "./styles";
import AnimatedProgressBar from "@components/ReusableComponents/AnimatedProgressBar/AnimatedProgressBar";
import { useApplyTranslations } from "@hooks";

export type ExportToCsvModalProps = {
  isOpen: boolean;
  progress: number;
  isBeingCancelled?: boolean;
  onClose: () => void;
};

const ExportToCsvModal: FC<ExportToCsvModalProps> = ({
  isOpen,
  progress,
  isBeingCancelled = true,
  onClose,
}) => {
  const { t } = useApplyTranslations();

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOutsideClick={false}>
      <Modal.Header>{t("general.saveAsCSV")}</Modal.Header>
      <Modal.Body>
        <div css={csvExportModalBody}>
          <Text fontSize="sm" as="div" className="info-text">
            {t("general.creatingTheExportFile")}
          </Text>
          <AnimatedProgressBar width={progress} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div css={csvExportModalFooter}>
          {isBeingCancelled && (
            <Text fontSize="sm" as="div" className="cancel-operation-text">
              {t("general.cancelOperation")}
            </Text>
          )}

          <Button color="secondary" onClick={onClose} aria-label={t("general.cancel")}>
            {t("general.cancel")}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ExportToCsvModal;
