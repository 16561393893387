import { Transition, Variants } from "framer-motion";

const transition = (shouldReduceMotion: boolean): Transition => ({
  duration: shouldReduceMotion ? 0 : 0.25,
  ease: "easeInOut",
});

export const variants: Variants = {
  initial: ({ md }) => {
    if (!md) {
      return { width: "auto" };
    }

    return { width: 0 };
  },
  animate: ({ md, lg, xl, shouldReduceMotion }) => {
    if (xl || lg) {
      return { transition: transition(shouldReduceMotion), width: "400px", maxWidth: "400px" };
    }

    if (md) {
      return { transition: transition(shouldReduceMotion), width: "100%", maxWidth: "400px" };
    }

    return { transition: transition(shouldReduceMotion), width: "100%", maxWidth: "100%" };
  },
  hidden: ({ md, lg, xl, shouldReduceMotion }) => {
    if (xl || lg) {
      return {
        width: "250px",
        maxWidth: "400px",
        transition: transition(shouldReduceMotion),
      };
    }

    if (md) {
      return {
        width: "100%",
        maxWidth: "250px",
        transition: transition(shouldReduceMotion),
      };
    }

    return {
      width: "100%",
      maxWidth: "100%",
      transition: transition(shouldReduceMotion),
    };
  },
};
