import { getScriptSnippet, INTERCOM_SCRIPT_BASE } from "@hooks/useIntercom/constants";

type UseIntercomParams = {
  intercomIsActive?: boolean;
  intercomKey?: string;
  intercomAppId?: string | null;
  userEmail?: string;
  intercomUserHash?: string | null;
};

type IntercomProps = {
  initializeIntercom: () => void;
  removeIntercomTags: () => void;
};

const useIntercom = ({
  intercomIsActive = false,
  intercomKey = "",
  intercomAppId = "",
  userEmail = "",
  intercomUserHash = "",
}: UseIntercomParams): IntercomProps => {
  const removeExistingTags = (): void => {
    document.querySelectorAll("script").forEach((element) => {
      const { src, innerHTML } = element;

      if (
        element instanceof HTMLScriptElement &&
        (src.includes(INTERCOM_SCRIPT_BASE) || innerHTML.includes(INTERCOM_SCRIPT_BASE)) &&
        (element.getAttribute("data-intercom-settings") === "true" ||
          element.getAttribute("data-intercom-widget") === "true")
      ) {
        element.remove();
      }
    });
  };

  const createIntercomSettingsScript = (): HTMLScriptElement | Element => {
    const existingSettingsScript = document.querySelector("script[data-intercom-settings]");
    if (!existingSettingsScript) {
      const script = document.createElement("script");
      script.setAttribute("data-intercom-settings", "true");
      script.innerHTML = getScriptSnippet(intercomAppId, userEmail, intercomUserHash);
      return script;
    }
    return existingSettingsScript;
  };

  const createIntercomWidgetScript = (): HTMLScriptElement | Element => {
    const existingWidgetScript = document.querySelector("script[data-intercom-widget]");
    if (!existingWidgetScript) {
      const script = document.createElement("script");
      script.setAttribute("data-intercom-widget", "true");
      script.async = true;
      script.src = `https://widget.intercom.io/widget/${intercomAppId}`;
      return script;
    }
    return existingWidgetScript;
  };

  const initializeIntercom = (): void => {
    if (!intercomKey || !intercomIsActive || !intercomAppId) {
      removeExistingTags();
      return;
    }
    removeExistingTags();
    document.head.insertAdjacentElement("afterbegin", createIntercomWidgetScript());
    document.head.insertAdjacentElement("afterbegin", createIntercomSettingsScript());
  };

  return { initializeIntercom, removeIntercomTags: removeExistingTags };
};

export default useIntercom;
