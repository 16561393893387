import { css } from "@emotion/react";

export const startReverseTrialButtonStyles = () => css`
  padding: 0.5rem 1.5rem;
  width: 100%;
  display: flex;

  .button-text {
    white-space: nowrap;
  }
`;
