import React, { FC, useEffect, useState } from "react";
import { Button, Loader, Text } from "@epignosis_llc/gnosis";
import { useResponsive } from "ahooks";

import { branchesStylesMobile } from "./BranchesMobile.styles";
import { ActionDrawer, SearchInput, Skeletons } from "@components";

// Hooks
import { useApplyTranslations } from "@hooks";
import { useBranches } from "../../hooks/useBranches";

type BranchesProps = {
  isOpen: boolean;
  onClose: () => void;
};

const Branches: FC<BranchesProps> = ({ isOpen, onClose }) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { t } = useApplyTranslations();
  const { md } = useResponsive();
  const {
    shouldShowMainBranch,
    shouldShowSearch,
    branchesOptions,
    switchBranchMutation,
    currentBranchId,
    branchesStatus,
    branchesError,
  } = useBranches(searchQuery);

  useEffect(() => {
    if (!md) document.body.style.setProperty("overflow", "hidden");

    return () => {
      if (!md) document.body.style.setProperty("overflow", "");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBranchClick = (branchId: string): void => {
    if (branchId !== currentBranchId?.toString()) {
      if (switchBranchMutation) {
        switchBranchMutation(branchId);
      }
    }
  };

  return (
    <ActionDrawer
      isOpen={isOpen}
      onClose={onClose}
      headerTitle={t("mainMenu.switchBranch")}
      showFooter={false}
      hasBackButton={true}
      fullscreen={true}
    >
      <div css={branchesStylesMobile}>
        {shouldShowMainBranch && (
          <div className="branches-header">
            <div className="main-branch-switch">
              <Button
                variant="link"
                onClick={(): void => switchBranchMutation && switchBranchMutation("main")}
              >
                {t("topBar.goToMainDomain")}
              </Button>
            </div>
          </div>
        )}
        <Text as="div" className="switch-label" fontSize={"sm"} weight="700">
          {t("topBar.switchBranch")}
        </Text>

        {shouldShowSearch && (
          <SearchInput
            id="branches-search"
            className="branch-search"
            placeholder="Search"
            onInputChanged={setSearchQuery}
          />
        )}
        <Skeletons.Loader status={branchesStatus} error={branchesError} loader={<Loader />}>
          <div className="branch-options">
            {branchesOptions &&
              branchesOptions?.map(({ id, label, value, icon }) => (
                <div
                  className="branch-option"
                  key={value}
                  onClick={(): void => {
                    if (id) handleBranchClick(id);
                    handleBranchClick(id);
                  }}
                >
                  <Text className="branch-option-text" key={value} as="div" fontSize={"md"}>
                    {label}
                  </Text>
                  <span className="checked-icon">{icon}</span>
                </div>
              ))}
          </div>
        </Skeletons.Loader>
      </div>
    </ActionDrawer>
  );
};

export default Branches;
