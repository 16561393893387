import React, { FC, MouseEvent } from "react";
import { IconType } from "types/common";
import ActionButton from "./ActionButton";
import { useApplyTranslations } from "@hooks";
import { FileCheckSVG } from "@epignosis_llc/gnosis/icons";

export type CompleteCourseActionProps = {
  tooltipContent?: string;
  icon?: IconType;
  iconHeight?: number;
  onClick: (e: MouseEvent) => void;
};

const CompleteCourseAction: FC<CompleteCourseActionProps> = ({
  tooltipContent,
  icon: Icon = FileCheckSVG,
  iconHeight = 32,
  onClick,
}) => {
  const { t } = useApplyTranslations();

  return (
    <ActionButton
      tooltipContent={tooltipContent ?? t("general.completeCourse")}
      onClick={onClick}
      data-testid="complete-course-action"
    >
      <Icon height={iconHeight} />
    </ActionButton>
  );
};

export default CompleteCourseAction;
