import { ENDPOINTS } from "@api/endpoints";
import { config } from "@config";
import { AIGenerateTextType } from "@components/FormElements/Editor/types";
import JobsHandler from "@utils/ai/AIJobsHandler/AIJobsHandler";
import { joinDomainWithPath } from "@utils/helpers/url";

const jobsHandler = new JobsHandler(function () {
  // Do nothing
});

export const generateTextSSE = async (
  prompt: string,
  type: AIGenerateTextType,
  update: (result: string | Error | true) => void,
  onError: (err: Error) => void,
  abortController?: AbortController,
): Promise<void> => {
  return await jobsHandler.fetchSSE<string>(
    joinDomainWithPath(config.AI_TEXT_GENERATION_API_URL, ENDPOINTS.ai.generateText),
    {
      prompt: prompt,
      type: type,
    },
    (message) => {
      if (
        message === true ||
        (typeof message == "string" && message.length) ||
        (message instanceof Error && message.message == "402")
      ) {
        return update(message);
      }

      if (message instanceof Error) {
        onError(message);
      }
    },
    abortController,
  );
};
