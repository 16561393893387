import { BranchPropertiesNames } from "@views/Branches/types";

export const IdentityInputProperties = [
  BranchPropertiesNames.BRANCH_NAME,
  BranchPropertiesNames.BRANCH_TITLE,
  BranchPropertiesNames.BRANCH_DESCRIPTION,
];

export const UserSelectProperties = [
  BranchPropertiesNames.BRANCH_DEFAULT_USER_TYPE,
  BranchPropertiesNames.BRANCH_DEFAULT_GROUP,
];

export const UserArrowProperties = [
  BranchPropertiesNames.BRANCH_SIGNUP,
  BranchPropertiesNames.BRANCH_TERMS_OF_SERVICE,
  BranchPropertiesNames.BRANCH_RESTRICT_REGISTERED_USERS,
  BranchPropertiesNames.BRANCH_RESTRICT_REGISTRATION,
  BranchPropertiesNames.BRANCH_SSO_INTEGRATION_TYPE,
];

export const filtersSearchQueryParams = {
  pagination: { number: 1, size: 10000 },
  sorting: ["name"],
};

export const signUpOptions = ["direct", "manual"];

export const DefaultSSOValues = {
  integration_type: "none",
  login_type: "classic",
  saml: {
    requires_protocol: false,
    idp: "",
    key: "",
    key_alt: "",
    saml_certificate: "",
    saml_certificate_alt: "",
    sign_in_url: "",
    sign_out_url: "",
    username: "urn:oid:1.3.6.1.4.1.5923.1.1.1.10",
    firstname: "urn:oid:2.5.4.42",
    lastname: "urn:oid:2.5.4.4",
    email: "urn:oid:0.9.2342.19200300.100.1.3",
    group: "",
    add_group_on_each_login: false,
    sign_requests: false,
    validate_requests: false,
    enable_scim2_api: false,
    scim2_api_key: null,
    idp_configuration: {
      entity_id: "",
      acs_url: "",
      single_logout_url: "",
      sp_metadata_url: "",
    },
    custom_fields: {
      fields: [],
      ignore_namespace: false,
    },
  },
  ldap: {
    server: "",
    port: 389,
    tls: false,
    bind_dn: "",
    bind_password: "",
    dn_pattern: "dc=example,dc=org",
    username: "uid",
    full_name: "displayName",
    email: "mail",
  },
  oidc: {
    client_id: "",
    client_secret: "",
    token_url: "",
    user_info_url: "",
    authorization_url: "",
    logout_url: "",
    username: "uid",
    first_name: "given_name",
    last_name: "family_name",
    email: "email",
    group: "",
    add_group_on_each_login: false,
    scope: "",
    idp_configuration: {
      authorized_redirect_url: "",
      post_logout_redirect_url: "",
    },
    custom_fields: {
      fields: [],
      ignore_namespace: false,
    },
  },
};
