import ReactFroalaEditor from "froala-editor";
import { CustomIcon, CustomQuickInsertButton } from "./types";

export const registerUploadImageQuickInsertButton = (
  callback: (editor: ReactFroalaEditor) => void,
): void => {
  ReactFroalaEditor.RegisterQuickInsertButton(CustomQuickInsertButton.UploadImageButton, {
    title: "Upload image",
    icon: CustomIcon.UploadImage,
    callback: function (this: ReactFroalaEditor) {
      callback(this);
    },
  });
};

export const registerInsertSmartTagQuickInsertButton = (
  callback: (editor: ReactFroalaEditor) => void,
): void => {
  ReactFroalaEditor.RegisterQuickInsertButton(CustomQuickInsertButton.InsertSmartTagButton, {
    title: "Smart tags",
    icon: CustomIcon.InsertSmartTag,
    callback: function (this: ReactFroalaEditor) {
      callback(this);
    },
  });
};

export const registerWriteAboutQuickInsertButton = (
  callback: (editor: ReactFroalaEditor) => void,
): void => {
  ReactFroalaEditor.RegisterQuickInsertButton(CustomQuickInsertButton.WriteAbout, {
    title: "Write about",
    icon: CustomIcon.MagicWand,
    callback: function (this: ReactFroalaEditor) {
      callback(this);
    },
  });
};
