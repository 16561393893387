import { PalletThemeColors, getProtectedColor } from "@styles/utils";
import { ThemePlusTypes } from "../../types";
import Color from "color";

export default (colors: PalletThemeColors): ThemePlusTypes["fileInput"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);
  return {
    border: colors.primary.lightest,
    borderDisabled: colors.secondary.light,
    background: Color(colors.primary.lightest).alpha(0.25).string(),
    disabledColor: colors.secondary.darker,
    titleColor: protectColorContrast("darker", colors.primary.base),
  };
};
