// Packages or third-party libraries
import React, { FC, memo, useRef, useState } from "react";
import { Document, Thumbnail } from "react-pdf";
import { Loader } from "@epignosis_llc/gnosis";
import { SerializedStyles } from "@emotion/react";

// Styles
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { PdfThumbnailStyles } from "./styles";

// Components
import { ThumbnailError } from "./components/ThumbnailError";

// Utils, hooks
import { getFilenameFromUrl } from "@utils/helpers";
import { useApplyTranslations } from "@hooks";

// Constants
import { documentOptions } from "./constants";

type PdfThumbnailProps = {
  fileUrl: string;
  pageNumber?: number;
};

const PdfThumbnail: FC<PdfThumbnailProps> = ({ fileUrl, pageNumber = 1 }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [_, setIsLoading] = useState(false);
  const { t, i18n } = useApplyTranslations();

  const fileName = getFilenameFromUrl(fileUrl);
  const isRtl = i18n.dir() === "rtl";

  const onPageLoadSuccess = (): void => {
    setIsLoading(false);
    const el = containerRef?.current;

    if (el) {
      const thumbnailEl = el.querySelector(".react-pdf__Thumbnail");
      thumbnailEl?.setAttribute("aria-label", t("a11y.pdfViewer.thumbnail", { fileName }));
    }
  };

  return (
    <div css={(theme): SerializedStyles => PdfThumbnailStyles(theme, isRtl)} ref={containerRef}>
      <Document
        file={fileUrl}
        options={documentOptions}
        renderMode="canvas"
        loading={<Loader size="md" />}
        error={<ThumbnailError />}
        onLoadSuccess={onPageLoadSuccess}
      >
        <Thumbnail pageNumber={pageNumber} />
      </Document>
    </div>
  );
};

export default memo(PdfThumbnail);
