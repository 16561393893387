import { CustomButton } from "@components/FormElements/Editor";

export const toolbarButtonsMessage = [
  "bold",
  "italic",
  "underline",
  "formatOL",
  "formatUL",
  "align",
  "indent",
  "outdent",
  "insertLink",
  "insertImage",
];

export const COURSE_FILES_DEFAULT_STATE = {
  pagination: { number: 1, size: 10000 },
  sorting: ["name"],
  filters: [{ key: "[use_case]", value: "course.image" }],
};

export const COURSE_IMAGE_UPLOAD = "course-image-upload";
export const COURSE_IMAGE_UPLOAD_CLOSE = "course-image-upload-close";
export const SMART_TAG_INSERT = "smart-tag-insert";
export const SMART_TAG_CLOSE = "smart-tag-close";

export const MORE_TEXT_BUTTONS = [
  "bold",
  "italic",
  "underline",
  "fontFamily",
  "fontSize",
  "textColor",
  "backgroundColor",
  "formatOL",
  "formatUL",
  "align",
];

export const MORE_RICH_BUTTONS = [
  "paragraphFormat",
  "lineHeight",
  "outdent",
  "indent",
  "quote",
  "insertTable",
  "insertImage",
  "insertLink",
  "insertHR",
  CustomButton.InsertSmartTagButton,
  "html",
];
