import Color from "color";
import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["discussions"] => {
  return {
    color: colors.secondary.darker,
    hover: Color(colors.primary.lightest).alpha(0.4).string(),
    linkColor: colors.black,
    border: colors.secondary.lighter,
    textColor: colors.black,
    listBackgroundColor: colors.secondary.lighter,
    boxShadowColor: colors.secondary.light,
    filledIcon: colors.orange.base,
    hoverIcon: colors.orange.light,
  };
};
