import { t } from "@utils/i18n";
import { Pagination as IPagination } from "types/responses/index";
import { Column, DropdownItem, TableProps } from "@epignosis_llc/gnosis";
import { QueryFilter } from "types/common";
import { MassActionEnum } from "@components/ReusableComponents/MassActions/types";

export enum CourseUsersFilters {
  group = "[group_id]",
  branch = "[branch_id]",
}

const PAGINATION: IPagination = { number: 1, size: 10 };
const SORTING: TableProps["sorting"] = { column: "formatted_name", isDescending: false };
const DEFAULT_FILTERS: QueryFilter[] = [];

export const DEFAULT_STATE = {
  pagination: PAGINATION,
  sorting: SORTING,
  filters: DEFAULT_FILTERS,
};

export const NAME_HEADER_WIDTH = 250;

export const getEnrollUsersInitialColumns = (): Column[] => {
  return [
    {
      accessor: "formatted_name",
      cell: t("general.user"),
      classNames: ["formatted_name"],
      maxWidth: NAME_HEADER_WIDTH,
    },
    {
      accessor: "actions",
      cell: "",
      classNames: ["hidden"],
      sortableHeader: false,
    },
  ];
};

export const getMassActionsOptions = (): DropdownItem[] => {
  return [
    {
      label: t("assignment.massActions.addToSessionHeader"),
      value: MassActionEnum.add_to_session,
    },
  ];
};

export const enrollUsersToSessionWordings = {
  add_to_session: {
    confirmationButton: "general.actions.add",
    confirmationHeader: "assignment.massActions.addToSessionHeader",
    progressHeader: "assignment.massActions.addToSessionHeader",
    progressMessage: "assignment.massActions.addProgress",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
    confirmationBodyTitle: "assignment.massActions.addToSessionWarning",
    successMessage: "assignment.massActions.addSuccess",
    prerequisiteWarningMessage: "assignment.massActions.prerequisiteWarningMessage",
  },
};
