import localStorageKeys from "@constants/localStorageKeys";
import authService from "@utils/services/AuthService";
import { parseAccessToken } from "./content";

export const getPublicAccessToken = (): string | undefined => {
  const storeKey = localStorage.getItem(localStorageKeys.PUBLIC_COURSE);
  const token = storeKey ? JSON.parse(storeKey).access_token : undefined;

  return token;
};

export const isUserLoggedInViaSSO = (): boolean => {
  const token = authService.getAccessToken();
  const decodeToken = token ? parseAccessToken(token) : null;
  return !!decodeToken?.sub.is_sso_login;
};
