import React, { FC, HTMLAttributes } from "react";
import { pathContentStyles } from "./styles";

export type PathContentProps = FC<HTMLAttributes<HTMLDivElement>>;

const PathContent: PathContentProps = ({ children, ...rest }) => (
  <div css={pathContentStyles} {...rest}>
    {children}
  </div>
);

export default PathContent;
