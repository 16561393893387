import Color from "color";
import { PalletThemeColors, getProtectedColor } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["chip"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);

  return {
    textColor: colors.black,
    success: {
      background: protectColorContrast(
        "darker",
        Color(colors.primary.lightest).alpha(0.25).string(),
        0.25,
      ),
      color: colors.primary.base,
    },
    failed: {
      background: protectColorContrast(
        "darker",
        Color(colors.red.lightest).alpha(0.25).string(),
        0.25,
      ),
      color: colors.red.base,
    },
    disabled: {
      background: colors.secondary.lighter,
    },
  };
};
