import { PalletThemeColors, getProtectedColor } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["messages"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);
  return {
    color: colors.secondary.base,
    hover: colors.primary.lighter,
    linkColor: colors.black,
    border: protectColorContrast("darker", colors.secondary.lighter),
    icon: colors.primary.base,
    errorText: colors.red.base,
  };
};
