import React, { FC, useRef, useState } from "react";
import { SerializedStyles, useTheme } from "@emotion/react";
import classNames from "classnames";
import { Button, Text } from "@epignosis_llc/gnosis";
import { DragAndDropAreaContainer } from "./styles";
import { FileValidationsObj, MimeType } from "types/entities";
import { convertBytesToSizeUnit, errorNotification, getFileType } from "@utils/helpers";
import { arrayToFileList, validateSelectedFiles } from "@utils/helpers/files";
import { useApplyTranslations } from "@hooks";

type DragAndDropAreaProps = {
  maxFiles: number;
  mimeTypeAndFilesizeValidations: FileValidationsObj;
  onFilesDrop: (selectedFiles: FileList) => void;
  attachments?: File[];
  className?: string;
  shouldValidate?: boolean;
  preventDrop?: boolean;
  showRules?: boolean;
  children?: React.ReactNode;
};

export const DragAndDropArea: FC<DragAndDropAreaProps> = ({
  maxFiles,
  mimeTypeAndFilesizeValidations,
  onFilesDrop,
  attachments = [],
  shouldValidate = false,
  preventDrop = false,
  showRules = false,
  children,
  className,
}): JSX.Element => {
  const { t } = useApplyTranslations();
  const { fileInput } = useTheme();
  const [dragging, setDragging] = useState(false);
  const counterRef = useRef(0);
  const filesLengthRef = useRef(0);
  const dropFilesHTMLClasses = classNames("drop-files", {
    "hidden visually-hidden": !dragging,
  });
  const childrenHTMLClasses = classNames("children", {
    "visually-hidden": showRules && dragging,
  });

  const groupedMimeTypes = Object.keys(mimeTypeAndFilesizeValidations).reduce((obj, currentKey) => {
    const currentValue = mimeTypeAndFilesizeValidations[currentKey];
    !obj[currentValue] ? (obj[currentValue] = [currentKey]) : obj[currentValue].push(currentKey);
    return obj;
  }, {});

  const validationText = Object.keys(groupedMimeTypes).map((fileSizeStr) => {
    const fileSize = parseInt(fileSizeStr);
    const extensions = groupedMimeTypes[fileSizeStr].map((mimeType: MimeType) =>
      getFileType(mimeType),
    );

    // Remove duplicate extensions
    const extensionsStr = [...new Set(extensions)].join(", ");

    return (
      <p key={fileSizeStr}>{` ${extensionsStr} (${convertBytesToSizeUnit(fileSize, "MB")} MB)`}</p>
    );
  });

  const handleDrag = (e: React.DragEvent<HTMLDivElement>): void => {
    e.dataTransfer.clearData();
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    if (preventDrop) return;

    // Increase counter for elements in dnd area
    counterRef.current = counterRef.current + 1;

    const items = e.dataTransfer.items;
    const files = [];

    // Get all items with file type
    for (let i = 0; i < items.length; i++) {
      if (items[i].kind === "file") {
        files.push(items[i]);
      }
    }

    if (files.length > 0) {
      filesLengthRef.current = files.length;
      setDragging(true);
    }
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    if (preventDrop) return;

    // Decrease counter for elements in dnd area
    counterRef.current = counterRef.current - 1;

    if (counterRef.current === 0) {
      setDragging(false);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleFileError = (error: string): void => {
    errorNotification(`${error}`);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    if (preventDrop) return;

    const selectedFiles = e.dataTransfer.files;

    if (selectedFiles?.length) {
      counterRef.current = 0;
      filesLengthRef.current = 0;
      setDragging(false);

      if (shouldValidate) {
        const validatedFiles = validateSelectedFiles({
          files: selectedFiles,
          selectedFiles: [],
          maxFiles,
          mimeTypeAndFilesizeValidations,
          onFileError: handleFileError,
        });

        onFilesDrop(arrayToFileList(validatedFiles));
        return;
      }

      onFilesDrop(selectedFiles);
    }
  };

  const dragAreaClassName = attachments.length === 0 ? className : "";

  return (
    <div
      css={(): SerializedStyles => DragAndDropAreaContainer({ fileInput, dragging, showRules })}
      onDrag={handleDrag}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      className={`${dragAreaClassName}${dragging ? " dragging" : ""}`}
    >
      <div className={dropFilesHTMLClasses}>
        <div className="drop-files-info">
          <Button rounded>{filesLengthRef.current}</Button>
          <Text fontSize="sm" weight="700" className="title">
            {t("validationFiles.dropFilesHere", { count: maxFiles })}
          </Text>
          <Text fontSize="sm" className="subtitle">
            {t("validationFiles.filesAllowedToAttach", { count: maxFiles })}
          </Text>

          {showRules && (
            <div className="rules">
              <Text fontSize="xs" as="div">
                {t("general.acceptedFiles")}
                {validationText}
              </Text>
            </div>
          )}
        </div>
      </div>

      <div className={childrenHTMLClasses}>{children}</div>
    </div>
  );
};
