import HttpClient from "./HttpClient";
import { ENDPOINTS } from "./endpoints";
import { CatalogCategoriesRes } from "types/responses";
// Import { getPublicBearerHeaders } from "@utils/helpers";

export const getCategories = async (
  flat = false,
  searchQuery = "",
): Promise<CatalogCategoriesRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.categories.categories}${searchQuery}`, {
    params: { flat },
    // Headers: getPublicBearerHeaders(), // TODO: uncomment when categories fixed in BE
  });
  return res.data;
};

export const getCourseStoreCategories = async (
  flat = false,
  searchQuery = "",
): Promise<CatalogCategoriesRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.courseStore.categories}${searchQuery}`, {
    params: { flat },
  });

  return res.data;
};

export const getOtherProvidersCourseStoreCategories = async (
  flat = false,
  searchQuery = "",
): Promise<CatalogCategoriesRes> => {
  const res = await HttpClient.get(
    `${ENDPOINTS.courseStore.otherProvidersCategories}${searchQuery}`,
    {
      params: { flat },
    },
  );

  return res.data;
};

export const getMyCategories = async (
  flat = false,
  searchQuery = "",
): Promise<CatalogCategoriesRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.user.categories}${searchQuery}`, {
    params: { flat },
  });

  return res.data;
};
