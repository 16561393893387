import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";
import Color from "color";

export default (colors: PalletThemeColors): ThemePlusTypes["alertBox"] => {
  return {
    info: {
      backgroundColor: Color(colors.primary.lightest).alpha(0.25).string(),
    },
    warning: {
      backgroundColor: Color(colors.orange.base).alpha(0.15).string(),
    },
    outlineButton: {
      backgroundColor: colors.white,
      hoverColor: colors.primary.light,
    },
  };
};
