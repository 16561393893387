import { DraggableLocation } from "@hello-pangea/dnd";
import { t } from "@utils/i18n";
import { SelectOption } from "types/common";
import { MyUnit, Section, UnitType } from "types/entities";
import { config, getBaseUrl } from "@config";
import { URLS } from "@constants/urls";

// Reorder a single unit
export const reorderSingleUnit = (
  list: Array<Section | MyUnit>,
  startIndex: number,
  endIndex: number,
): Array<Section | MyUnit> => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export type ReorderMultipleUnitsProps = {
  list: Array<Section | MyUnit>;
  itemsToMove: number[];
  source: DraggableLocation;
  destination: DraggableLocation;
};

export const reorderMultipleUnits = ({
  list,
  itemsToMove,
  source,
  destination,
}: ReorderMultipleUnitsProps): Array<Section | MyUnit> => {
  // Split list into 2 new arrays, one with the removed items and one with the rest
  const [reorderUnits, removed] = list.reduce(
    ([units, removed], currentUnit) =>
      !itemsToMove.includes(currentUnit.id)
        ? [[...units, currentUnit], removed]
        : [units, [...removed, currentUnit]],
    [[] as Array<Section | MyUnit>, [] as Array<Section | MyUnit>],
  );

  // Destination position is before the source position, move to destination
  if (destination.index <= source.index) {
    reorderUnits.splice(destination.index, 0, ...removed);

    // Destination position is after the source position
    // Have to find the new index to move the selected elements
  } else {
    const destinationItem = list[destination.index];
    const newDestinationIndex = reorderUnits.findIndex((unit) => unit.id === destinationItem.id);

    // Apply to the index right after the found one
    reorderUnits.splice(newDestinationIndex + 1, 0, ...removed);
  }

  return reorderUnits;
};

export const isSectionItem = (data: Section | MyUnit): data is Section => {
  return (data as Section).units !== undefined;
};

export const parentHasClassname = (targetElement: Element, className: string): boolean => {
  const nodes = [];
  let element = targetElement;

  while (element.parentElement) {
    if (element.parentElement.classList.contains(className)) {
      nodes.unshift(element.parentElement);
    }
    element = element.parentElement;
  }

  return nodes.length > 0;
};

export const mapUnitsToSelectOption = (units: MyUnit[]): SelectOption[] =>
  units.map((unit) => ({ label: unit.name, value: unit.id.toString() }) as SelectOption);

export const getOptionsTitle = (type: UnitType | null): string => {
  switch (type) {
    case "test":
    case "assignment":
    case "survey":
      return t(`unitEdit.options.title.${type}`);
    default:
      return t("unitEdit.options.title.unit");
  }
};

export const getCoursePublicUrl = (publicKey: string): string => {
  const originUrl = window.location.origin;
  const baseUrl = config.NODE_ENV != "dev" ? getBaseUrl() : "";

  return `${originUrl}${baseUrl}${URLS.publicCourse.getNavigatorUrl({ publicKey })}`;
};
