import React, { ReactNode } from "react";
import { toast, ToastOptions } from "react-toastify";
import PubSub from "pubsub-js";
import {
  WarningIconSVG,
  InfoIconSVG,
  DangerIconSVG,
  SuccessIconSVG,
} from "@epignosis_llc/gnosis/icons";

export type NotificationOptions = ToastOptions & {
  content: ReactNode;
  type?: "info" | "success" | "warning" | "error";
  duration?: number;
};

export const NOTIFICATIONS = "NOTIFICATIONS";

const ICONS = {
  info: <InfoIconSVG height={24} />,
  error: <DangerIconSVG height={24} />,
  success: <SuccessIconSVG height={24} />,
  warning: <WarningIconSVG height={24} />,
};
const notificationSubscriber = (_channel: string, options: NotificationOptions): void => {
  const { type = "info", content, duration = 2000, ...rest } = options;

  toast[type](
    <>
      <span className="prefix-icon">{ICONS[type]}</span>
      {content}
    </>,
    { hideProgressBar: true, autoClose: duration, theme: "colored", ...rest },
  );
};

export const notificationChannel = PubSub.subscribe(NOTIFICATIONS, notificationSubscriber);
