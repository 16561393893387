import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["resultsPage"] => {
  return {
    userResults: {
      color: colors.primary.base,
      hoverColor: colors.primary.lightest,
      svgColor: colors.black,
    },
  };
};
