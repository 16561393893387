import { css } from "@emotion/react";

export const modalBodyStyles = () => css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const modalFooterStyles = () => css`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;
