import Color from "color";
import { PalletThemeColors, getProtectedColor } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["timeline"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);

  return {
    iconColor: protectColorContrast("darker", colors.primary.base),
    dateColor: colors.secondary.darker,
    borderColor: colors.secondary.lighter,
    deleteActionBackground: colors.red.light,
    deleteActionText: colors.white,
    primary: {
      background: Color(colors.blue.lightest).alpha(0.25).string(),
      color: colors.blue.base,
    },
    success: {
      background: Color(colors.green.lightest).alpha(0.25).string(),
      color: colors.green.base,
    },
    danger: {
      background: Color(colors.red.lightest).alpha(0.25).string(),
      color: colors.red.base,
    },
    warning: {
      background: colors.orange.lighter,
      color: colors.orange.base,
    },
  };
};
