import React, { FC, useEffect, useState } from "react";
import { Input } from "@epignosis_llc/gnosis";
import { MagnifierSVG } from "@epignosis_llc/gnosis/icons";
import { useDebounceFn } from "ahooks";
import { searchInputContainer } from "./styles";

type SearchInputProps = React.HTMLAttributes<HTMLInputElement> & {
  id: string;
  placeholder: string;
  onInputChanged: (inputValue: string) => void;
  value?: string;
  delayBeforeSearch?: number;
  resetInput?: boolean;
  disabled?: boolean;
};

const SearchInput: FC<SearchInputProps> = ({
  id,
  placeholder,
  onInputChanged,
  value = "",
  delayBeforeSearch = 500,
  resetInput = false,
  disabled = false,
  ...rest
}) => {
  const [searchValue, setSearchValue] = useState(value);
  const { run } = useDebounceFn(
    (value) => {
      onInputChanged(value);
    },
    { wait: delayBeforeSearch },
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    setSearchValue(value);
    run(value);
  };

  useEffect(() => {
    if (value !== searchValue) setSearchValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // TODO: Since we added the above useEffect we can remove this
  // Because it does the same job but manual
  useEffect(() => {
    if (resetInput) clearSearch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetInput]);

  const clearSearch = (): void => {
    onInputChanged("");
    setSearchValue("");
  };

  return (
    <div css={searchInputContainer} {...rest}>
      <Input
        id={id}
        placeholder={placeholder}
        value={searchValue}
        onChange={handleInputChange}
        autoComplete="new-password"
        iconAfter={MagnifierSVG}
        showVerticalLine={false}
        isClearable={true}
        onClear={clearSearch}
        disabled={disabled}
      />
    </div>
  );
};

export default SearchInput;
