import React, { FC, ReactNode, useEffect, useState } from "react";
import { useResponsive } from "ahooks";
import DatePicker from "react-datepicker";
import { SerializedStyles } from "@emotion/react";
import classNames from "classnames";
import { Input } from "@epignosis_llc/gnosis";
import { CalendarSVG } from "@epignosis_llc/gnosis/icons";
import { isSameDay } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { dateInput } from "./styles";
import CustomHeader from "./components/CustomHeader";
import CustomHeaderWithHiddenYears from "./components/CustomHeaderWithHiddenYears";
import { getLocale } from "@utils/helpers";
import { i18n } from "@utils/i18n";

export type DateInputProps = {
  value?: Date | null;
  id?: string;
  size?: "md" | "lg";
  label?: string;
  required?: boolean;
  altLabel?: boolean;
  className?: string;
  inline?: boolean;
  placeholderText?: string;
  minDate?: Date;
  maxDate?: Date;
  dateFormat?: string;
  status?: "valid" | "error";
  disabled?: boolean;
  isReadOnly?: boolean;
  tooltipContent?: string | JSX.Element;
  hideYears?: boolean;
  onChange: (selectedDate: Date | null) => void;
  handleInputChange?: (eventValue: string) => void;
};

const DateInput: FC<DateInputProps> = (props) => {
  const { sm } = useResponsive();
  const {
    value = new Date(),
    id,
    size = "md",
    required = false,
    className,
    label,
    altLabel = false,
    inline = false,
    minDate,
    maxDate,
    dateFormat = "dd/MM/yyyy",
    placeholderText = dateFormat.toUpperCase(),
    disabled = false,
    status = "valid",
    isReadOnly,
    tooltipContent,
    hideYears = false,
    onChange,
    handleInputChange,
  } = props;
  const [isYearPicker, setIsYearPicker] = useState(false);
  const [isMonthPicker, setIsMonthPicker] = useState(false);
  const hasLabel = Boolean(label);
  const containerClassNames = classNames({
    valid: status === "valid",
    error: status === "error",
    "alt-label": hasLabel && altLabel,
    [className ?? ""]: true,
    inline,
  });
  const isDayView = !isMonthPicker && !isYearPicker;
  const isRtl = i18n.dir() === "rtl";
  const placement = isRtl ? "bottom-end" : "bottom-start";

  const onChangeDate = (date: Date): void => {
    // Day view
    if (isDayView) {
      onChange(date);
    }

    // Month view
    if (isMonthPicker) {
      setIsMonthPicker(false);
      setIsYearPicker(false);
    }
    // Year view
    if (isYearPicker) {
      setIsMonthPicker(true);
      setIsYearPicker(false);
    }
  };

  const handleCalendarClose = (): void => {
    setIsMonthPicker(false);
    setIsYearPicker(false);
  };

  useEffect(() => {
    if (value && maxDate && isSameDay(value, maxDate)) {
      if (maxDate.getTime() < value.getTime()) {
        onChange(maxDate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxDate]);

  const handleChangeRaw = (event: React.FocusEvent<HTMLInputElement>): void => {
    if (handleInputChange) handleInputChange(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.code === "Backspace") {
      onChange(null);
    }
    event.preventDefault();
  };

  return (
    <div
      css={(theme): SerializedStyles => dateInput(theme, { size })}
      className={containerClassNames}
    >
      <DatePicker
        wrapperClassName="branded-datepicker"
        id={id}
        className={className}
        readOnly={isReadOnly}
        selected={value}
        onChange={onChangeDate}
        customInput={
          <Input
            id={id ?? ""}
            size={size}
            iconAfter={CalendarSVG}
            label={!altLabel ? label : ""}
            tooltipContent={tooltipContent}
            containerAttrs={{ className: required ? "required" : "" }}
            data-lpignore="true"
            aria-label={label ?? ""}
          />
        }
        placeholderText={placeholderText}
        autoComplete="off"
        withPortal={!sm}
        minDate={minDate}
        maxDate={maxDate}
        dateFormat={dateFormat}
        disabled={disabled}
        locale={getLocale()}
        showYearPicker={isYearPicker}
        showMonthYearPicker={isMonthPicker}
        shouldCloseOnSelect={isDayView}
        onChangeRaw={handleChangeRaw}
        onClickOutside={handleCalendarClose}
        popperPlacement={placement}
        onKeyDown={handleKeyDown}
        showPopperArrow={false}
        renderCustomHeader={(params): ReactNode =>
          hideYears
            ? CustomHeaderWithHiddenYears({ isMonthPicker, setIsMonthPicker, ...params })
            : CustomHeader({
                isYearPicker,
                isMonthPicker,
                setIsYearPicker,
                setIsMonthPicker,
                ...params,
              })
        }
      />
    </div>
  );
};
export default DateInput;
