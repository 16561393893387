import React, { FC } from "react";
import { Button, Chip, Text } from "@epignosis_llc/gnosis";
import { useApplyTranslations } from "@hooks";
import { selectedFilesStyles } from "./styles";

type SelectedFilesProps = {
  selectedFiles: File[];
  removeFile: (index: number) => void;
  removeFiles: () => void;
};

const SelectedFiles: FC<SelectedFilesProps> = ({ selectedFiles, removeFile, removeFiles }) => {
  const { t } = useApplyTranslations();

  return (
    <div className="attachments-layer" css={selectedFilesStyles}>
      <div className="attachments-header">
        <Text fontSize="sm" weight="700">
          {t("general.selectedFiles")}
        </Text>

        {selectedFiles.length > 1 && (
          <Button color="primary" variant="link" noGutters onClick={removeFiles}>
            {t("general.clearAll")}
          </Button>
        )}
      </div>

      <div className="attachments">
        {[...selectedFiles].map((file, index) => {
          return (
            <Chip
              key={`${file.name}-${index}`}
              className="attachment-tag"
              onClose={(): void => removeFile(index)}
            >
              {file.name}
            </Chip>
          );
        })}
      </div>
    </div>
  );
};

export default SelectedFiles;
