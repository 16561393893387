import { Dispatch, useReducer } from "react";
import { QueryFilter } from "types/common";
import { Pagination } from "types/responses";
import { TableProps } from "@epignosis_llc/gnosis";
import { useUserPreferencesStore } from "@stores";
import { mapTableToSelectSorting } from "@utils/helpers";

export enum PaginatedStateActions {
  paginationPage = "PAGINATION_PAGE_CHANGE",
  paginationPageSize = "PAGINATION_PAGE_SIZE_CHANGE",
  sorting = "SORTING_CHANGE",
  filters = "FILTERS_CHANGE",
  reset = "RESET",
}

export type PaginatedState = {
  pagination: Pagination;
  sorting: TableProps["sorting"];
  filters: QueryFilter[];
  url?: string;
};

export type PaginatedStateAction =
  | { type: PaginatedStateActions.paginationPage; payload: { number: number } }
  | { type: PaginatedStateActions.paginationPageSize; payload: { size: number } }
  | { type: PaginatedStateActions.sorting; payload: { sorting: TableProps["sorting"] } }
  | { type: PaginatedStateActions.filters; payload: { filters: QueryFilter[] } }
  | { type: PaginatedStateActions.reset; payload: { state: PaginatedState } };

export const paginatedStateReducer = (
  state: PaginatedState,
  action: PaginatedStateAction,
): PaginatedState => {
  const userStore = useUserPreferencesStore;
  const { getState: getUserPreferencesState } = userStore();
  const { settings, setSettings } = getUserPreferencesState() ?? {};

  const { pagination, url } = state;
  const { type, payload } = action;

  const { paginationPage, paginationPageSize, sorting, filters, reset } = PaginatedStateActions;

  switch (type) {
    case paginationPage: {
      const newPagination = { ...pagination, number: payload.number };
      url && setSettings({ ...settings[url], pagination: newPagination }, url);
      return { ...state, pagination: newPagination };
    }
    case paginationPageSize: {
      const newPagination = { ...pagination, size: payload.size, number: 1 };
      url && setSettings({ ...settings[url], pagination: newPagination }, url);
      return { ...state, pagination: newPagination };
    }
    case sorting: {
      // Navigate users to the first page due to new search criteria
      const newPagination = { ...pagination, number: 1 };

      url &&
        setSettings(
          {
            ...settings[url],
            sorting: [mapTableToSelectSorting(payload.sorting)],
            pagination: newPagination,
          },
          url,
        );
      return { ...state, sorting: payload.sorting, pagination: newPagination };
    }
    case filters: {
      // We need to navigate user to the first page when they're searching for something
      // If we don't, we risk returning false info because of the page they might be in
      const newPagination = { ...pagination, number: 1 };

      url &&
        setSettings({ ...settings[url], filters: payload.filters, pagination: newPagination }, url);
      return { ...state, filters: payload.filters, pagination: newPagination };
    }
    case reset: {
      return payload.state;
    }
    default: {
      return state;
    }
  }
};

const usePaginatedStateReducer = (
  defaultState: PaginatedState,
): [PaginatedState, Dispatch<PaginatedStateAction>] => {
  return useReducer(paginatedStateReducer, defaultState);
};

export default usePaginatedStateReducer;
