import { css } from "@emotion/react";

export const profileSubMenuStyles = ({ colors, dropdown }, { isMobile }) => css`
  // Items of the sub-menu container
  display: none;
  position: ${isMobile ? "fixed" : "absolute"};
  width: ${isMobile ? "100%" : "auto"};
  top: ${isMobile ? "0" : "auto"};
  inset-inline-end: 0;
  padding-top: ${isMobile ? "0" : "0.5rem"};
  z-index: 100;

  .sub-menu-content {
    min-width: 15.125rem;
    max-width: ${isMobile ? "none" : "20rem"};
    max-height: ${isMobile ? "none" : "31rem"};
    height: ${isMobile ? "100vh" : "auto"};
    padding: 1rem;
    border-radius: 0.3125rem;
    box-shadow: 0 0.1875rem 0.375rem ${dropdown.boxShadowColor};
    background: ${dropdown.backgroundColor};
    overflow-y: auto;
    overflow-x: hidden;

    // Targets the last div of the sub-menu content to remove the margin-bottom of the last link which is always the logout button.
    .user-pages:last-of-type a {
      margin-bottom: 0;
    }
  }

  .user-details-container {
    padding-bottom: 0.75rem;

    & + .divider {
      margin-top: 0;
    }
  }

  // Horizontal line divider
  .divider {
    height: 0.0625rem;
    margin: 0.75rem -1rem;
    background: ${colors.secondary.light};
  }
`;
