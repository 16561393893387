import { css } from "@emotion/react";

export const notificationStyles = (theme) => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 0;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: 370px;
  font-family: "Mulish", sans-serif;
  font-size: 14px;

  box-shadow:
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.06);

  .loading-containerloader {
    width: auto;
    margin-left: 16px;
  }

  .text {
    flex-grow: 1;
    padding: 0 16px;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${theme.colors.black};
  }

  .line {
    margin-left: 12px;
  }

  .stopButton {
    box-sizing: content-box;
    padding: 4px 12px;
    width: 62px;
    margin-right: 8px;
  }

  .closeButton {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    padding: 0;
  }
`;
