// Security related helpers.
export const sanitizeHTML = (input: string): string => {
  // Step 1: Parse the input HTML string into a DOM Document
  const parser = new DOMParser();
  const doc = parser.parseFromString(input, "text/html");

  // Step 2: Define unsafe elements and attributes to remove
  const UNSAFE_TAGS = ["script", "iframe", "object", "embed", "link", "style"];
  const UNSAFE_ATTRIBUTES = ["onerror", "onload", "onclick", "onmouseover", "onfocus"];
  const UNSAFE_URL_SCHEMES = ["javascript:", "data:", "vbscript:"]; // Unsafe URL schemes

  // Step 3: Remove all unsafe tags from the DOM
  UNSAFE_TAGS.forEach((tag) => {
    const elements = doc.getElementsByTagName(tag);
    while (elements.length > 0) {
      elements[0].parentNode?.removeChild(elements[0]);
    }
  });

  // Step 4: Remove unsafe attributes and sanitize href/src/xlink:href
  const allElements = doc.getElementsByTagName("*");
  for (let i = 0; i < allElements.length; i++) {
    const element = allElements[i];

    // Step 4.1: Sanitize 'href', 'src', 'xlink:href' attributes
    ["href", "src", "xlink:href"].forEach((attr) => {
      if (element.hasAttribute(attr)) {
        let url = element.getAttribute(attr) || "";

        // Decode any potentially encoded characters (like &NewLine;)
        url = decodeURIComponent(url);

        // Remove any newlines or suspicious characters that could be used to obfuscate URLs
        url = url.replace(/[\s\r\n]+/g, "").toLowerCase();

        // If the cleaned URL starts with any unsafe schemes, remove the attribute
        if (UNSAFE_URL_SCHEMES.some((scheme) => url.startsWith(scheme))) {
          element.removeAttribute(attr);
        }
      }
    });

    // Step 4.2: Remove unsafe attributes like 'onerror', 'onclick', etc.
    for (let j = element.attributes.length - 1; j >= 0; j--) {
      const attr = element.attributes[j].name;
      if (UNSAFE_ATTRIBUTES.includes(attr)) {
        element.removeAttribute(attr);
      }
    }
  }

  // Step 5: Return the sanitized HTML as a string
  return doc.body.innerHTML;
};
