import { t } from "@utils/i18n";
import StringSchema from "yup/lib/string";
import { AnyObject } from "yup/lib/types";
import { yup } from "./validation";
import { USERNAME_REGEX } from "@constants/validation";

export const ProfileValidationSchema = yup.object().shape({
  name: yup.string().trim().required().max(50),
  surname: yup.string().trim().required().max(50),
  email: yup.string().trim().email().required().max(150),
  login: yup
    .string()
    .trim()
    .required()
    .matches(USERNAME_REGEX, () => t("signUp.validationMessages.invalidUsername"))
    .max(150),
  description: yup.string().max(800),
  user_type_id: yup.number().required(),
  credits: yup
    .string()
    .matches(/^[0-9]*(.[^,]\d{1,3})?$/, () => t("errors.groups.priceIsNotValid")),
  password: yup.string().trim().max(30),
});

export const MandatoryCustomFieldSchema = yup.string().trim().required();

export const MaxLengthCustomFieldSchema = (
  maxLength: number,
): StringSchema<string | undefined, AnyObject> => yup.string().trim().max(maxLength);

export const PasswordValidationSchema = yup.object().shape({
  current_password: yup.string().trim().required().max(30),
  password: yup.string().trim().required().max(30),
  confirm_password: yup
    .string()
    .trim()
    .required()
    .max(30)
    .oneOf([yup.ref("password"), null], () => t("validationMessages.passwordsShouldMatch")),
});
