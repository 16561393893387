import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getUnenrolledCourseUsers } from "../api";
import {
  CourseUsersFilters,
  getEnrollUsersInitialColumns,
  DEFAULT_STATE,
  getMassActionsOptions,
} from "./constants";
import { courseUsersTableContainer } from "../styles";
import {
  applyQueryFilter,
  getFetchingStatus,
  getFilterDropdownOptions,
  getFormattedUserName,
  mapTableToSelectSorting,
} from "@utils/helpers";
import { ActionsContainer, CustomTable, CustomTableActions, EnrollAction } from "@components";
import { buildPaginatedSearchQuery } from "@utils/helpers/url";
import queryKeys from "@constants/queryKeys";
import usePaginatedStateReducer, {
  PaginatedState,
  PaginatedStateActions,
} from "@hooks/usePaginatedStateReducer";
import { getGroups } from "@api/group";
import { getUserBranches } from "@api/user";
import { useConfigurationStore } from "@stores";
import authService from "@utils/services/AuthService";
import { buildEmptyStateProps, emptyState } from "@components/CustomTable";
import { DropdownItem, Row, TableHandlers, Text } from "@epignosis_llc/gnosis";
import { useResponsive } from "ahooks";
import { enrollUserToCourse } from "@views/User/components/Courses/api";
import { AxiosError } from "axios";
import { handleUserCoursesErrors } from "@errors/errors";
import permissions from "@utils/permissions";
import { hasPermissionsChanged } from "@utils/helpers/filters";
import { CountMassActionResponse, MassActionResultResponse } from "types/responses";
import { courseUsersMassActionsCount, courseUsersMassActions } from "@api/massActions/courses";
import { MassActionType } from "@components/ReusableComponents/";
import { MassActionsProps } from "@components/CustomTable/types";
import { EnrollUsersTableProps } from "../types";
import { staleTimeConfig } from "@config";

const EnrollUsersTable: FC<EnrollUsersTableProps> = ({
  handleInvalidateQuery,
  canMassEnrollUsers,
}) => {
  const { courseId } = useParams() as { courseId: string };
  const massActionsOptions = getMassActionsOptions();
  const [coursesState, coursesDispatch] = usePaginatedStateReducer(DEFAULT_STATE);
  const { pagination, sorting: tableSorting, filters } = coursesState;
  const [resetInput, setResetInput] = useState(false);
  const queryClient = useQueryClient();
  const userId = useConfigurationStore((state) => state.userProfileData?.id);
  const [hoveredRow, setHoveredRow] = useState<Row | null>(null);
  const { sm } = useResponsive();
  const { canReadBranches } = permissions.branchPermissions;
  const allowReadBranches = canReadBranches();

  // Map table's sorting to query's sorting
  const sorting = tableSorting?.column ? [mapTableToSelectSorting(tableSorting)] : [];
  const searchQuery = buildPaginatedSearchQuery({ pagination, sorting, filters });
  const groupsBranchesQuery = buildPaginatedSearchQuery({
    pagination: { number: 1, size: 10000 },
    sorting: ["name"],
  });

  // Permissions
  const { domainSettings } = useConfigurationStore();
  const { main_portal: isMainPortal } = domainSettings ?? {};
  const isAdmin = authService.isAdministrator();
  const canAdminAccessBranches = isAdmin && Boolean(isMainPortal);

  // Mass actions
  const tableRef = React.useRef<TableHandlers>(null);
  const [userIds, setUserIds] = useState<number[]>([]);
  const allowMassActions = canMassEnrollUsers && userIds.length > 0;

  const handleRestoreDefault = (): void => {
    coursesDispatch({
      type: PaginatedStateActions.filters,
      payload: { filters: DEFAULT_STATE.filters },
    });
    setResetInput(true);
  };

  const {
    status: courseUsersStatus,
    data: users,
    error: courseUsersError,
  } = useQuery(
    [queryKeys.courses.unenrolledCourseUsers, courseId, searchQuery],
    () => getUnenrolledCourseUsers(courseId, searchQuery),
    {
      select: (users) => ({
        data: users._data,
        pagination: users._meta?.pagination,
      }),
      staleTime: staleTimeConfig[queryKeys.courses.unenrolledCourseUsers],
    },
  );

  const { mutate: enrollUserToCourseMutation } = useMutation(
    (userId: string) => enrollUserToCourse(courseId, userId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          queryKeys.courses.unenrolledCourseUsers,
          courseId,
          searchQuery,
        ]);
        queryClient.invalidateQueries([queryKeys.courses.enrollmentRequests]);
      },
      onError: (error: AxiosError) => {
        handleUserCoursesErrors(error);
      },
      onSettled: () => {
        handleInvalidateQuery();
      },
    },
  );

  const handleEnrollUser = (userId: string): void => {
    enrollUserToCourseMutation(userId);
  };

  const cachedGroupsData = queryClient.getQueryData([queryKeys.allGroups]) ?? null;
  const { data: groups = [] } = useQuery(
    [queryKeys.allGroups],
    () => getGroups(groupsBranchesQuery),
    {
      select: (groups) => groups._data,
      enabled: isAdmin && !cachedGroupsData,
    },
  );

  const cachedBranchesData = queryClient.getQueryData([queryKeys.myBranches]) ?? null;
  const { data: branches = [] } = useQuery(
    [queryKeys.myBranches],
    () => getUserBranches(groupsBranchesQuery, userId ? userId.toString() : ""),
    {
      select: (branches) => branches._data,
      enabled: canAdminAccessBranches && !cachedBranchesData,
    },
  );

  const status = getFetchingStatus([courseUsersStatus]);
  const error = courseUsersError;
  const tableStatus = { status, error };

  const activeFilters = useMemo(() => {
    return Object.keys(filters).filter((key) => filters[key] !== null);
  }, [filters]);

  const handleSortingChanged = (sorting: PaginatedState["sorting"]): void => {
    coursesDispatch({ type: PaginatedStateActions.sorting, payload: { sorting } });
  };

  const handlePaginationPageChange = (value: number): void => {
    coursesDispatch({ type: PaginatedStateActions.paginationPage, payload: { number: value } });
  };

  const handlePaginationPageSizeChange = (value: number): void => {
    coursesDispatch({ type: PaginatedStateActions.paginationPageSize, payload: { size: value } });
  };

  const handleSearchChanged = (searchValue: string): void => {
    setResetInput(false);
    cleanState();
    const filter = { key: "[keyword][like]", value: searchValue };
    const newFilters = applyQueryFilter({ filters, filter });
    coursesDispatch({ type: PaginatedStateActions.filters, payload: { filters: newFilters } });
  };

  const handleFiltersChanged = ({ category, value }: DropdownItem): void => {
    cleanState();

    // Shouldn't have selected both branch and group filter
    const currentFilters = filters.filter((item) => {
      if (category === CourseUsersFilters.branch) {
        return item.key !== CourseUsersFilters.group;
      } else if (category === CourseUsersFilters.group) {
        return item.key !== CourseUsersFilters.branch;
      } else {
        return true;
      }
    });

    const filter = { key: category as string, value: value as string };
    const newFilters = applyQueryFilter({ filters: currentFilters, filter });
    coursesDispatch({ type: PaginatedStateActions.filters, payload: { filters: newFilters } });
  };

  const handleFilterRemove = ({ category, value }: DropdownItem): void => {
    cleanState();

    const newFilters = filters.filter((item) => !(category === item.key && value === item.value));
    coursesDispatch({ type: PaginatedStateActions.filters, payload: { filters: newFilters } });
  };

  const emptyStateProps = buildEmptyStateProps({
    filters: activeFilters,
    tableType: "users",
    isDrawer: true,
  });

  const rows =
    users?.data?.map((user) => {
      const { id, name, surname, login } = user;
      const isHovered = hoveredRow?.id === user.id && sm;
      const userId = id.toString();
      const formattedUserName = getFormattedUserName({ name, surname, login });

      return {
        formatted_name: (): JSX.Element => (
          <Text fontSize="sm" as="div" className="has-overflow">
            {formattedUserName}
          </Text>
        ),
        actions: (): JSX.Element => {
          const actions = [
            <EnrollAction
              key="enroll-user-action"
              onClick={(): void => handleEnrollUser(userId)}
            />,
          ];

          return <ActionsContainer actions={actions} isHovered={isHovered} />;
        },
        id,
      };
    }) ?? [];

  const handleRowHover = (row: Row | null): void => {
    setHoveredRow(row);
  };

  const countRequest = useCallback(
    (type: MassActionType): Promise<CountMassActionResponse> => {
      return courseUsersMassActionsCount(type, courseId, userIds);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(userIds)],
  );

  const massActionRequest = useCallback(
    (type: MassActionType): Promise<MassActionResultResponse> => {
      return courseUsersMassActions(type, courseId, userIds);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(userIds)],
  );

  const cleanState = (): void => {
    setUserIds([]);
    tableRef.current?.resetSelected();
  };

  const handleInvalidateQueryMassActions = (): void => {
    queryClient.invalidateQueries([queryKeys.courses.unenrolledCourseUsers, courseId, searchQuery]);
    handleInvalidateQuery();
  };

  const handleRowSelect = (rows: Row[]): void => {
    const selectedRows = rows.map((row) => Number(row.id));
    setUserIds(selectedRows);
  };

  const tableData = {
    rows,
    columns: getEnrollUsersInitialColumns(),
    emptyState: emptyState(emptyStateProps),
    sorting: tableSorting,
    onSortingChanged: handleSortingChanged,
    onHoveredRowChange: handleRowHover,
    selectable: true,
    autohide: true,
    onRowSelect: handleRowSelect,
  };

  const paginationData = {
    paginationRes: users?.pagination,
    paginationState: {
      number: pagination.number,
      size: pagination.size,
    },
    onPageChange: handlePaginationPageChange,
    onPageSizeChange: handlePaginationPageSizeChange,
  };

  const massActionsProps = {
    allowMassActions,
    massActionsOptions,
    originTableName: "enrollCourseUsers",
    countRequest,
    massActionRequest,
    cleanState,
    handleInvalidateQueryMassActions,
    selectedRows: userIds.length,
  } as MassActionsProps;

  // Restore to default filters if permissions changes while you have a filter applied
  useEffect(() => {
    // Check which permission changed and if the user has a filter applied related to this permission
    hasPermissionsChanged(filters, handleRestoreDefault, allowReadBranches);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowReadBranches]);

  const shouldSetFixPlacement = userIds.length > 0;

  return (
    <>
      <CustomTableActions
        filterDropdownOptions={getFilterDropdownOptions({ branches, groups })}
        selectedFilters={filters}
        resetInput={resetInput}
        onSearchChange={handleSearchChanged}
        onFilterSelect={handleFiltersChanged}
        onFilterRemove={handleFilterRemove}
        hideFilters={(!branches.length && !groups.length) || !isAdmin}
        tableHasData={Boolean(users?.data.length)}
        massActionsProps={massActionsProps}
        fixPlacement={shouldSetFixPlacement}
      />

      <div css={courseUsersTableContainer}>
        <CustomTable
          tableProps={tableData}
          tableStatus={tableStatus}
          paginationProps={paginationData}
          tableRef={tableRef}
        />
      </div>
    </>
  );
};

export default EnrollUsersTable;
