import { ENDPOINTS } from "./endpoints";
import HttpClient from "./HttpClient";
import {
  BundlesRes,
  CatalogCategoriesRes,
  CatalogPurchaseRes,
  CatalogSettingsRes,
  CatalogSubscribeRes,
  CourseFilesRes,
  CourseRes,
  CoursesRes,
  MyUnitRes,
  UnitsRes,
} from "types/responses";
import { CatalogPurchase, CatalogSubscription } from "types/entities/Catalog";
import { EnrollmentPostData } from "types/entities/Courses";

export const getBundles = async (branchId?: string): Promise<BundlesRes> => {
  const branchQuery = branchId ? `?filter[branch_id][eq]=${branchId}` : "";
  const res = await HttpClient.get(`${ENDPOINTS.catalog.bundles}${branchQuery}`);

  return res.data;
};

export const getCatalogCourseUnits = async (courseId: string): Promise<UnitsRes> => {
  const res = await HttpClient.get(ENDPOINTS.courses.catalogCourseUnits(courseId));

  return res.data;
};

export const getCatalogCourse = async (courseId: string): Promise<CourseRes> => {
  const res = await HttpClient.get(ENDPOINTS.catalog.course(courseId));

  return res.data;
};

export const getCatalogCourseFiles = async (
  courseId: string,
  queryStr = "",
): Promise<CourseFilesRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.catalog.courseFiles(courseId)}${queryStr}`);

  return res.data;
};

export const getCatalogCategories = async (
  queryStr = "",
  branchId?: string,
): Promise<CatalogCategoriesRes> => {
  const branchQuery = branchId ? `&filter[branch_id][eq]=${branchId}` : "";
  const res = await HttpClient.get(`${ENDPOINTS.catalog.categories}${queryStr}${branchQuery}`);

  return res.data;
};

export const getCatalog = async (queryStr = "", branchId?: string): Promise<CoursesRes> => {
  const branchQuery = branchId ? `&filter[branch_id][eq]=${branchId}` : "";
  const res = await HttpClient.get(`${ENDPOINTS.catalog.catalog}${queryStr}${branchQuery}`);

  return res.data;
};

export const getCatalogSettings = async (): Promise<CatalogSettingsRes> => {
  const res = await HttpClient.get(ENDPOINTS.catalog.settings);

  return res.data;
};

export const getSampleUnit = async (unitId: string): Promise<MyUnitRes> => {
  const res = await HttpClient.get(ENDPOINTS.catalog.sampleUnit(unitId));

  return res.data;
};

export const postCatalogPurchase = async (data: CatalogPurchase): Promise<CatalogPurchaseRes> => {
  const res = await HttpClient.post(ENDPOINTS.catalog.purchase, data);

  return res.data;
};

export const postCatalogSubscribe = async (
  data: CatalogSubscription,
): Promise<CatalogSubscribeRes> => {
  const res = await HttpClient.post(ENDPOINTS.catalog.subscribe, data);

  return res.data;
};

export const postEnrollment = async (data: EnrollmentPostData): Promise<void> => {
  await HttpClient.post(ENDPOINTS.catalog.enrollment, data);
};

export const postEnrollmentApproval = async (courseId: string): Promise<void> => {
  await HttpClient.post(ENDPOINTS.catalog.enrollmentApproval(courseId));
};

export const deleteEnrollmentApproval = async (courseId: string): Promise<void> => {
  await HttpClient.delete(ENDPOINTS.catalog.enrollmentApproval(courseId));
};
