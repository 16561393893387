import { USERNAME_REGEX } from "@constants/validation";
import { yup } from "./validation";

export const SignInFormValidationSchema = yup.object().shape({
  username: yup.string().trim().required(),
  password: yup.string().trim().required(),
});

export const LdapSignInFormValidationSchema = yup.object().shape({
  username: yup.string().trim().required().matches(USERNAME_REGEX),
  password: yup.string().trim().required(),
});
