import { userHasRequiredPermissions } from "./index";
import { useConfigurationStore } from "@stores";
import authService from "@utils/services/AuthService";

// Map with all available permissions
export const groupPermissions = {
  group_key: "group.join",
  read: "group.read",
  reports: "reports.group",
  create: "group.create",
  update: "group.update",
  delete: "group.delete",
};

const { group_key, read, reports, create, update, delete: deleteGroup } = groupPermissions;
const { getState } = useConfigurationStore;

export default {
  /**
   * Permission => group.join
   *
   *  Allowed actions:
   *   PUT joinGroup
   *
   * @returns boolean
   */
  canJoinAGroup: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [group_key],
      getState().userProfileData?.permissions[userRole],
    );
  },
  canReadGroups: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([read], getState().userProfileData?.permissions[userRole]);
  },

  canReadReportGroups: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([reports], getState().userProfileData?.permissions[userRole]);
  },

  canCreateGroups: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([create], getState().userProfileData?.permissions[userRole]);
  },

  canUpdateGroups: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([update], getState().userProfileData?.permissions[userRole]);
  },

  canDeleteGroups: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [deleteGroup],
      getState().userProfileData?.permissions[userRole],
    );
  },
};
