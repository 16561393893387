import React, { ReactElement } from "react";
import { skipLinkStyles } from "./SkipLink.styles";
import { useApplyTranslations } from "@hooks";

const SkipLink = ({ focusId, text }: { focusId: string; text?: string }): ReactElement => {
  const { t } = useApplyTranslations();
  const contentText = text || t("a11y.skipLink");

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    e.preventDefault();
    const targetElement = document.getElementById(focusId);
    if (targetElement) {
      targetElement.setAttribute("tabindex", "-1");
      targetElement.focus();
    }
  };

  return (
    <a
      css={skipLinkStyles}
      href={`#${focusId}`}
      className="skip-link"
      onClick={handleClick}
      data-testid="skip-link"
      aria-label={`Skip to ${text}`}
      tabIndex={0}
    >
      {contentText}
    </a>
  );
};

export default SkipLink;
