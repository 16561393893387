import HttpClient from "./HttpClient";
import { ENDPOINTS } from "./endpoints";
import { AnnouncementsRes } from "types/responses";

export const getAnnouncements = async (): Promise<AnnouncementsRes> => {
  const res = await HttpClient.get(ENDPOINTS.announcements.announcements);

  return res.data;
};

export const putAnnouncementsClose = async (): Promise<void> => {
  await HttpClient.put(ENDPOINTS.announcements.close);
};
