import { css } from "@emotion/react";

export const skipLinkStyles = ({ skipLink }) => css`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${skipLink.background};
  color: ${skipLink.color};
  padding: 0.5rem 1rem;
  transform: translateY(-100%);
  transition: transform 0.3s;
  z-index: 100;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);

  &:hover {
    color: ${skipLink.color};
  }

  &:focus {
    clip: auto; /* Remove the clipping */
    clip-path: none;
    overflow: visible;
    transform: translateY(0);
    outline: 2px solid ${skipLink.color};
  }
`;
