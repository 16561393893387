import Color from "color";
import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["terms"] => {
  return {
    background: Color(colors.primary.lightest).alpha(0.25).string(),
    contentBackground: colors.white,
  };
};
