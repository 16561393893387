import React from "react";
import {
  OpenedMessageSVG,
  SettingsSVG,
  FolderSVG,
  MyCoursesSVG,
  BadgeIconSVG,
  ChatCloudsSVG,
  CalendarSVG,
  CatalogSVG,
  GroupsSVG,
  SquareAddSVG,
  AllUsersSVG,
  BranchIconSVG,
  CategoriesSVG,
  CertificateWithViewboxSVG,
  MyProgressSVG,
  TimelineSVG,
  NotificationSVG,
  AutomationsIconSVG,
  ReportsSVG,
  ImportExportIconSVG,
  GearIconSVG,
  CourseReportsSVG,
  UserReportsSVG,
  BranchReportsSVG,
  GroupReportsSVG,
  LearningActivitiesSVG,
  CustomReportsSVG,
  UserTypeIconSVG,
  ImportDataSVG,
  BillingSVG,
  BrainSVG,
} from "@epignosis_llc/gnosis/icons";
import { URLS } from "@constants/urls";
import { useConfigurationStore } from "@stores";
import permissionsUtils, { userHasRequiredPermissions } from "@utils/permissions";
import { messagesPermissions } from "@utils/permissions/messages";
import { profilePermissions } from "@utils/permissions/profile";
import { reportsPermissions } from "@utils/permissions/reports";
import { coursesPermissions } from "@utils/permissions/courses";
import { discussionsPermissions } from "@utils/permissions/discussions";
import { catalogPermissions } from "@utils/permissions/catalog";
import { gamificationPermissions } from "@utils/permissions/gamification";
import { SystemOption } from "types/entities/Spotlight";
import { calendarPermissions } from "@utils/permissions/calendar";
import authService from "@utils/services/AuthService";
import { usersPermissions } from "@utils/permissions/users";
import { categoriesPermissions } from "@utils/permissions/categories";
import { groupPermissions } from "@utils/permissions/groups";
import { branchPermissions } from "@utils/permissions/branches";
import userRoles from "@constants/userRoles";
import { i18n, t } from "@utils/i18n";
import { notificationsPermissions } from "@utils/permissions/notifications";
import { automationsPermissions } from "@utils/permissions/automations";
import { importExportPermissions } from "@utils/permissions/importExport";
import { accountPermissions } from "@utils/permissions/account";
import queryParameters from "@constants/queryParameters";
import { usersTypesPermissions } from "@utils/permissions/userTypes";
import { featureFlags } from "@config";
import { ecommercePermissions } from "@utils/permissions/ecommerce";
import { MyProgressActiveTab } from "@views/MyProgress/types";
import { skillsPermissions } from "@utils/permissions/skills";
import { SKILLS_SETTINGS_URL } from "@views/Skills/constants";

const { read: messagesRead, create: messagesCreate } = messagesPermissions;
const { read: profileRead } = profilePermissions;
const { read: coursesRead, create: coursesCreate, union_read: unionRead } = coursesPermissions;
const { read: discussionsRead, create: discussionsCreate } = discussionsPermissions;
const { read: catalogRead } = catalogPermissions;
const { read: gamificationRead, update: updateGamification } = gamificationPermissions;
const { read: calendarRead, create: calendarCreate } = calendarPermissions;
const { update: ecommerceUpdate } = ecommercePermissions;
const { getState } = useConfigurationStore;
const { read: usersRead, create: usersCreate } = usersPermissions;
const { read: categoriesRead, create: categoryCreate } = categoriesPermissions;
const { read: groupRead, create: groupCreate } = groupPermissions;
const { read: branchRead, create: branchCreate } = branchPermissions;
const { read: notificationsRead, create: notificationsCreate } = notificationsPermissions;
const { read: automationRead, create: automationCreate } = automationsPermissions;
const { read: automationsRead } = automationsPermissions;
const { createImport, createExport } = importExportPermissions;
const {
  read: accountRead,
  update: accountUpdate,
  subscription: accountSubscription,
} = accountPermissions;
const { read: userTypesRead, create: userTypesCreate } = usersTypesPermissions;
const { accountSkills: skillsSettingsRead } = skillsPermissions;
const {
  read: reportsRead,
  courseReports,
  userReports,
  testReports,
  surveyReports,
  assignmentReports,
  iltReports,
  groupReports: userGroupReports,
  branchReports,
  timeline,
  customReports,
} = reportsPermissions;

const hasReachedBranchLimit = Boolean(getState().domainSettings?.reached_branch_limit);

export type SystemOptions = {
  [key: string]: SystemOption;
};

// When translations are initialized, get the translated data.
let resources = {};
i18n.store.on("added", function (lng: string) {
  resources = { [lng]: { translation: i18n.getDataByLanguage(lng) } };
});

const getTranslations = (key: string): string[] =>
  Object.keys(resources ?? {}).reduce((array, lng) => {
    array.push(t(`systemOptions.${key}`, { lng }).toLowerCase());
    return array;
  }, [] as string[]);

// Apply profile changes
const getSystemOptions = (userRole: string): SystemOptions => {
  const isAdmin = userRole === userRoles.ADMINISTRATOR;

  const { canAccessSkills: hasSkillsReadPermission } = permissionsUtils.skillsPermissions;

  return {
    inboxMessages: {
      id: "inboxMessages",
      searchArray: getTranslations("inboxMessages"),
      permissions: [messagesRead],
      redirectUrl: URLS.user.messages,
      icon: <OpenedMessageSVG height={32} />,
    },
    myProfile: {
      id: "myProfile",
      searchArray: getTranslations("myProfile"),
      permissions: [profileRead],
      redirectUrl: URLS.user.profile,
      icon: <SettingsSVG height={32} />,
    },
    myFiles: {
      id: "myFiles",
      searchArray: getTranslations("myFiles"),
      permissions: [profileRead],
      redirectUrl: URLS.user.files,
      icon: <FolderSVG height={32} />,
      isEnabled: () => userRole !== userRoles.ADMINISTRATOR,
    },
    myCertificates: {
      id: "myCertificates",
      searchArray: getTranslations("myCertificates"),
      permissions: [reportsRead],
      redirectUrl: URLS.user.progress,
      redirectionState: { activeTab: MyProgressActiveTab.Certificates },
      icon: <CertificateWithViewboxSVG height={32} />,
      isEnabled: () => userRole === userRoles.LEARNER,
    },
    myProgress: {
      id: "myProgress",
      searchArray: getTranslations("myProgress"),
      permissions: [reportsRead],
      redirectUrl: URLS.user.progress,
      icon: <MyProgressSVG height={32} />,
    },
    myGroups: {
      id: "myGroups",
      searchArray: getTranslations("myGroups"),
      permissions: [profileRead],
      redirectUrl: URLS.user.groups,
      icon: <GroupsSVG height={32} />,
    },
    myCourses: {
      id: "myCourses",
      searchArray: getTranslations("myCourses"),
      permissions: [coursesRead],
      redirectUrl: URLS.user.courses, // On MainSearch.tsx we handle the url for adm and instr
      icon: <MyCoursesSVG height={32} />,
    },
    myBadges: {
      id: "myBadges",
      searchArray: getTranslations("myBadges"),
      permissions: [gamificationRead],
      isEnabled: () => Boolean(getState().gamificationSettings?.badges?.enabled),
      redirectUrl: URLS.user.badges,
      icon: <BadgeIconSVG height={32} />,
    },
    discussions: {
      id: "discussions",
      searchArray: getTranslations("discussions"),
      permissions: [discussionsRead],
      redirectUrl: URLS.user.discussions,
      icon: <ChatCloudsSVG height={32} />,
    },
    calendar: {
      id: "calendar",
      searchArray: getTranslations("calendar"),
      permissions: [calendarRead],
      redirectUrl: URLS.user.calendar,
      icon: <CalendarSVG height={32} />,
    },
    catalog: {
      id: "catalog",
      searchArray: getTranslations("catalog"),
      permissions: [catalogRead],
      redirectUrl: URLS.catalog.index,
      icon: <CatalogSVG height={32} />,
    },
    allUsers: {
      id: "allUsers",
      searchArray: getTranslations("allUsers"),
      permissions: [usersRead],
      redirectUrl: URLS.users.users,
      icon: <AllUsersSVG height={32} />,
    },
    allCourses: {
      id: "allCourses",
      searchArray: getTranslations("allCourses"),
      permissions: [unionRead],
      redirectUrl: URLS.courses.courses,
      icon: <MyCoursesSVG height={32} />,
    },
    categories: {
      id: "categories",
      searchArray: getTranslations("categories"),
      permissions: [categoriesRead],
      redirectUrl: `${URLS.accountAndSettings.accountAndSettings}?tab=categoriesSettings`,
      icon: <CategoriesSVG height={32} />,
    },
    groups: {
      id: "groups",
      searchArray: getTranslations("groups"),
      permissions: [groupRead],
      redirectUrl: URLS.groups.groups,
      icon: <GroupsSVG height={32} />,
    },
    branches: {
      id: "branches",
      searchArray: getTranslations("branches"),
      permissions: [branchRead],
      redirectUrl: URLS.branches.branches,
      icon: <BranchIconSVG height={32} />,
    },
    notifications: {
      id: "notifications",
      searchArray: getTranslations("notifications"),
      permissions: [notificationsRead],
      redirectUrl: URLS.notifications.notifications,
      icon: <NotificationSVG height={32} />,
    },
    import: {
      id: "import",
      searchArray: getTranslations("import"),
      permissions: [createImport],
      redirectUrl: `${URLS.accountAndSettings.accountAndSettings}?tab=importExportSettings`,
      icon: <ImportDataSVG height={32} />,
    },
    export: {
      id: "export",
      searchArray: getTranslations("export"),
      permissions: [createExport],
      redirectUrl: `${URLS.accountAndSettings.accountAndSettings}?tab=importExportSettings`,
      icon: <ImportExportIconSVG height={32} />,
    },
    reports: {
      id: "reports",
      searchArray: getTranslations("reports"),
      permissions: [
        courseReports,
        userReports,
        testReports,
        surveyReports,
        assignmentReports,
        iltReports,
        userGroupReports,
        branchReports,
        customReports,
        timeline,
      ],
      redirectUrl: URLS.reports.reports,
      icon: <ReportsSVG height={32} />,
      requireAllPermissions: false,
      isEnabled: () => userRole !== userRoles.LEARNER,
    },
    userSettings: {
      id: "userSettings",
      searchArray: getTranslations("userSettings"),
      permissions: [accountRead],
      redirectUrl: `${URLS.accountAndSettings.accountAndSettings}?tab=usersSettings`,
      icon: <GearIconSVG height={32} />,
    },
    themesSettings: {
      id: "themesSettings",
      searchArray: getTranslations("themesSettings"),
      permissions: [accountRead, accountUpdate],
      redirectUrl: URLS.accountAndSettings.customizeTheming,
      icon: <GearIconSVG height={32} />,
    },
    certificationSettings: {
      id: "certificationSettings",
      searchArray: getTranslations("certificationSettings"),
      permissions: [accountRead, accountUpdate],
      redirectUrl: URLS.accountAndSettings.certificates,
      icon: <GearIconSVG height={32} />,
    },
    gamificationSettings: {
      id: "gamificationSettings",
      searchArray: getTranslations("gamificationSettings"),
      permissions: [accountRead, updateGamification],
      redirectUrl: `${URLS.accountAndSettings.accountAndSettings}?tab=gamificationSettings`,
      icon: <GearIconSVG height={32} />,
    },
    ecommerceSettings: {
      id: "eCommerceSettings",
      searchArray: getTranslations("eCommerceSettings"),
      permissions: [accountRead, ecommerceUpdate],
      redirectUrl: `${URLS.accountAndSettings.accountAndSettings}?tab=ecommerceSettings`,
      icon: <GearIconSVG height={32} />,
    },
    skills: {
      id: "skills",
      searchArray: getTranslations("skills"),
      permissions: [],
      redirectUrl: isAdmin ? URLS.skills.skills : URLS.skills.mySkills,
      icon: <BrainSVG height={32} />,
      isEnabled: () => featureFlags.skills && hasSkillsReadPermission(),
    },
    skillsSettings: {
      id: "skillsSettings",
      searchArray: getTranslations("skillsSettings"),
      permissions: [skillsSettingsRead],
      redirectUrl: SKILLS_SETTINGS_URL,
      icon: <GearIconSVG height={32} />,
      isEnabled: () => featureFlags.skills,
    },
    domainSettings: {
      id: "domainSettings",
      searchArray: getTranslations("domainSettings"),
      permissions: [accountRead],
      redirectUrl: `${URLS.accountAndSettings.accountAndSettings}?tab=portalSettings`,
      icon: <GearIconSVG height={32} />,
    },
    subscriptionSettings: {
      id: "subscriptionSettings",
      searchArray: getTranslations("subscriptionSettings"),
      permissions: [accountSubscription],
      redirectUrl: URLS.subscription.subscription,
      icon: <GearIconSVG height={32} />,
      isEnabled: () => userRole === userRoles.ADMINISTRATOR,
    },
    billingHistory: {
      id: "billingHistory",
      searchArray: getTranslations("billingHistory"),
      permissions: [accountSubscription],
      redirectUrl: URLS.subscription.subscriptionOptions,
      icon: <BillingSVG height={32} />,
      isEnabled: () => userRole === userRoles.ADMINISTRATOR,
    },
    addUser: {
      id: "addUser",
      searchArray: getTranslations("addUser"),
      permissions: [usersCreate],
      redirectUrl: URLS.users.create,
      icon: <SquareAddSVG height={32} />,
    },
    addCourse: {
      id: "addCourse",
      searchArray: getTranslations("addCourse"),
      permissions: [coursesCreate],
      redirectUrl: "",
      icon: <SquareAddSVG height={32} />,
    },
    addCategory: {
      id: "addCategory",
      searchArray: getTranslations("addCategory"),
      permissions: [categoryCreate],
      redirectUrl: `${URLS.accountAndSettings.accountAndSettings}?tab=categoriesSettings`,
      icon: <SquareAddSVG height={32} />,
    },
    addGroup: {
      id: "addGroup",
      searchArray: getTranslations("addGroup"),
      permissions: [groupCreate],
      redirectUrl: URLS.groups.create,
      icon: <SquareAddSVG height={32} />,
    },
    addBranch: {
      id: "addBranch",
      searchArray: getTranslations("addBranch"),
      permissions: [branchCreate],
      redirectUrl: URLS.branches.create,
      icon: <SquareAddSVG height={32} />,
      isEnabled: () => !hasReachedBranchLimit,
    },
    addNotification: {
      id: "addNotification",
      searchArray: getTranslations("addNotification"),
      permissions: [notificationsRead, notificationsCreate],
      redirectUrl: `${URLS.notifications.notifications}?show=${queryParameters.addNotification}`,
      icon: <SquareAddSVG height={32} />,
    },
    addAutomation: {
      id: "addAutomation",
      searchArray: getTranslations("addAutomation"),
      permissions: [automationCreate, automationRead],
      redirectUrl: `${URLS.automations.automations}?show=${queryParameters.addAutomation}`,
      icon: <SquareAddSVG height={32} />,
    },
    automations: {
      id: "automations",
      searchArray: getTranslations("automations"),
      permissions: [automationsRead],
      redirectUrl: URLS.automations.automations,
      icon: <AutomationsIconSVG height={32} />,
    },
    addUserType: {
      id: "addUserType",
      searchArray: getTranslations("addUserType"),
      permissions: [userTypesRead, userTypesCreate],
      redirectUrl: `${URLS.accountAndSettings.accountAndSettings}?tab=userTypeSettings&show=${queryParameters.addUserType}`,
      icon: <SquareAddSVG height={32} />,
    },
    userTypes: {
      id: "userTypes",
      searchArray: getTranslations("userTypes"),
      permissions: [userTypesRead],
      redirectUrl: `${URLS.accountAndSettings.accountAndSettings}?tab=userTypeSettings`,
      icon: <UserTypeIconSVG height={32} />,
    },
    // Infographic: {
    //   Id: "infographic",
    //   SearchArray: getTranslations("infographic"),
    //   Permissions: [],
    //   RedirectUrl: "",
    //   Icon: <InfographicSVG height={32} />,
    // },
    userReports: {
      id: "userReports",
      searchArray: getTranslations("userReports"),
      permissions: [userReports],
      redirectUrl: URLS.reports.userReports,
      icon: <UserReportsSVG height={32} />,
    },
    courseReports: {
      id: "courseReports",
      searchArray: getTranslations("courseReports"),
      permissions: [courseReports],
      redirectUrl: URLS.reports.courseReports,
      icon: <CourseReportsSVG height={32} />,
    },
    branchReports: {
      id: "branchReports",
      searchArray: getTranslations("branchReports"),
      permissions: [branchReports],
      redirectUrl: URLS.reports.branchReports,
      icon: <BranchReportsSVG height={32} />,
    },
    groupReports: {
      id: "groupReports",
      searchArray: getTranslations("groupReports"),
      permissions: [userGroupReports],
      redirectUrl: URLS.reports.groupReports,
      icon: <GroupReportsSVG height={32} />,
    },
    learningActivitiesReports: {
      id: "learningActivitiesReports",
      searchArray: getTranslations("learningActivitiesReports"),
      permissions: [testReports, surveyReports, assignmentReports, iltReports],
      requireAllPermissions: false,
      redirectUrl: URLS.reports.learningActivities,
      icon: <LearningActivitiesSVG height={32} />,
    },
    customReports: {
      id: "customReports",
      searchArray: getTranslations("customReports"),
      permissions: [customReports],
      redirectUrl: URLS.reports.customReports,
      icon: <CustomReportsSVG height={32} />,
    },
    timeline: {
      id: "timeline",
      searchArray: getTranslations("timeline"),
      permissions: [timeline],
      redirectUrl: `${URLS.reports.reports}?tab=timeline`,
      icon: <TimelineSVG height={32} />,
      isEnabled: () => userRole !== userRoles.LEARNER,
    },
    // MyTimeline: {
    //   Id: "myTimeline",
    //   SearchArray: getTranslations("myTimeline"),
    //   Permissions: [],
    //   RedirectUrl: "",
    //   Icon: <TimelineSVG height={32} />,
    // },
    // MyInfographic: {
    //   Id: "myInfographic",
    //   SearchArray: getTranslations("myInfographic"),
    //   Permissions: [],
    //   RedirectUrl: "",
    //   Icon: <InfographicSVG height={32} />,
    // },
    addDiscussion: {
      id: "addDiscussion",
      searchArray: getTranslations("addDiscussion"),
      permissions: [discussionsRead, discussionsCreate],
      redirectUrl: URLS.user.newDiscussion,
      icon: <SquareAddSVG height={32} />,
    },
    addEvent: {
      id: "addEvent",
      searchArray: getTranslations("addEvent"),
      permissions: [calendarCreate, calendarRead],
      redirectUrl: `${URLS.user.calendar}?show=${queryParameters.addCalendarEvent}`,
      icon: <SquareAddSVG height={32} />,
    },
    // Conferences: {
    //   Id: "conferences",
    //   SearchArray: getTranslations("conferences"),
    //   Permissions: [],
    //   RedirectUrl: "",
    //   Icon: "",
    // },
    // AddConference: {
    //   Id: "addConference",
    //   SearchArray: getTranslations("addConference"),
    //   Permissions: [],
    //   RedirectUrl: "",
    //   Icon: <SquareAddSVG height={32} />,
    // },
    sendMessage: {
      id: "sendMessage",
      searchArray: getTranslations("sendMessage"),
      permissions: [messagesCreate],
      redirectUrl: URLS.user.newMessage,
      icon: <OpenedMessageSVG height={32} />,
    },
  };
};

export const getSystemResults = (inputValue: string): SystemOption[] => {
  const userRole = authService.getDefaultRole();
  const userPermissions = getState().userProfileData?.permissions[userRole] ?? [];
  const systemOptions = getSystemOptions(userRole);

  const systemOptionsKeys = Object.keys(systemOptions).filter((key) => {
    const option = systemOptions[key];
    const requireAllPermissions = option.requireAllPermissions ?? true;

    // User don't have permission to see this option
    if (option.permissions.length > 0) {
      if (!userHasRequiredPermissions(option.permissions, userPermissions, requireAllPermissions))
        return false;
    }

    // User can't see this option because of isEnabled special case
    if (option?.isEnabled) {
      if (!option.isEnabled()) return false;
    }

    // Check if input string exists in the option search array
    return option.searchArray.some((item: string) => item.includes(inputValue.toLowerCase()));
  });

  return systemOptionsKeys.map((key) => systemOptions[key]);
};
