import { userHasRequiredPermissions } from "./index";
import { useConfigurationStore } from "@stores";
import authService from "@utils/services/AuthService";

// Map with all the available permissions
export const calendarPermissions = {
  read: "calendar.read",
  create: "calendar.create",
};

const { read, create } = calendarPermissions;
const { getState } = useConfigurationStore;

export default {
  /**
   * Permission => calendar.read
   *
   * Allowed actions:
   *  GET calendar.events, calendar.export
   *
   * @returns boolean
   */
  canAccessCalendar: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([read], getState().userProfileData?.permissions[userRole]);
  },

  canCreateEvent: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([create], getState().userProfileData?.permissions[userRole]);
  },
};
