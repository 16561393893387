import { css } from "@emotion/react";

export const PdfViewerStyles = (_theme, isRtl) => css`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;

  &:hover {
    .page-controls {
      opacity: 1;
    }
  }

  .container {
    flex: 1;
    height: 100%;
    width: 100%;
    overflow: hidden;

    .document-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-block: 3px;
      padding-inline: 3px 12px;
      height: 100%;
      width: 100%;
      overflow: auto;

      &.max-height {
        padding-block: 0;
        padding-inline: 0;

        .react-pdf__Page {
          margin-bottom: 0 !important;
        }
      }

      .loading-container {
        position: absolute;
        top: 50%;
        inset-inline-start: 50%;
        transform: translateX(${isRtl ? "50%" : "-50%"}) translateY(-50%);
      }

      .react-pdf__Document {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .react-pdf__Page {
        margin-bottom: 8px;
        box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);

        &:last-of-type {
          margin-bottom: 3px;
        }

        .react-pdf__Page__textContent,
        .react-pdf__Page__annotations,
        .react-pdf__Page__canvas {
          max-width: 100%;
        }
      }
    }
  }
`;

export const PdfThumbnailStyles = (_theme, isRtl) => css`
  height: 100%;
  width: 100%;

  .react-pdf__Document,
  .react-pdf__Thumbnail__page {
    height: 100%;
    width: 100%;
  }

  .react-pdf__Thumbnail__page__canvas {
    margin: auto;
    max-height: 100%;
    max-width: 100%;
    height: fit-content !important;
    object-fit: contain;
  }

  .loading-container {
    position: absolute;
    top: 50%;
    inset-inline-start: 50%;
    transform: translateX(${isRtl ? "50%" : "-50%"}) translateY(-50%);
  }
`;
