import { css } from "@emotion/react";

export const shareOptionStyles = ({ courseEdit }) => css`
  .copy-link-container {
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap: 0.5rem;
  }

  .note {
    color: ${courseEdit.note};
  }
`;
