import { CourseProgress, UserRole } from "types/entities";

type CourseUserRole = Exclude<UserRole, "administrator">;

export type CourseUser = {
  id: number;
  name: string;
  surname: string;
  login: string;
  role_in_course: CourseUserRole;
  available_roles_in_course: CourseUserRole[];
  enrolled_date: string;
  completion_date: string | null;
  completion_status: CourseProgress["completion_status"];
  policies: UserPolicies;
  expiration_date: string | null;
  completion_percentage: number | null;
  is_active: boolean;
  has_certificate: boolean;
  download_certificate_url: string | null;
};

export type UnenrolledUser = {
  id: number;
  name: string;
  surname: string;
  login: string;
  session: {
    id: number;
    name: string;
  } | null;
};

export type UserPolicies = {
  can_change_user_role: boolean;
  can_download_certificate: boolean;
  can_preview_certificate: boolean;
  can_synchronize_progress: boolean;
  can_unenroll: boolean;
  can_edit_expiration: boolean;
  can_view: boolean;
  can_reset_progress: boolean;
  can_update_certificate: boolean;
  can_complete: boolean;
};

export type EnrollUsersTableProps = {
  handleInvalidateQuery: () => void;
  canMassEnrollUsers: boolean;
};

export type EnrollmentRequest = {
  id: number;
  name: string;
  surname: string;
  login: string;
  request_date: string;
};

export enum RequestResponse {
  Approved = "approved",
  Declined = "declined",
}
