/**
 * Helper function to select and focus a text-like element.
 * Works for HTMLTextAreaElement and HTMLInputElement.
 * @param element - The element to focus and select.
 */
export function selectAndFocusElement(
  element: HTMLInputElement | HTMLTextAreaElement | null,
): void {
  if (!element) {
    return;
  }

  const value = element.value;

  // Select the entire text content
  element.setSelectionRange(0, value.length, "forward");

  // Scroll to ensure the caret is visible
  element.focus();
}
