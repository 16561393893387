import React, { FC, useState } from "react";
import { Button, Text, Tooltip } from "@epignosis_llc/gnosis";
import { CheckSolidSVG, CopySVG } from "@epignosis_llc/gnosis/icons";
import { CopyToClipboardButtonContainer, CopyToClipboardButtonSettingsContainer } from "./styles";
import classNames from "classnames";
import { useApplyTranslations } from "@hooks";

type CopyToClipboardButtonProps = {
  textToCopy: string;
  tooltipPlacement?: "top" | "bottom" | "right" | "left";
  buttonVariant?: "solid" | "outline" | "ghost" | "link";
  buttonColor?: "primary" | "secondary" | "danger" | "success";
  clipboardText?: string;
  disableTooltip?: boolean;
  onClick?: () => void;
  preventDefault?: boolean;
  buttonText?: string;
  shouldHideIcon?: boolean;
  className?: string;
};

const CopyToClipboardButton: FC<CopyToClipboardButtonProps> = ({
  textToCopy: copyText,
  clipboardText,
  tooltipPlacement = "top",
  buttonVariant = "link",
  preventDefault = false,
  buttonColor = "secondary",
  disableTooltip = false,
  onClick,
  buttonText,
  shouldHideIcon,
  className,
  ...rest
}) => {
  const { t } = useApplyTranslations();
  const [showTooltip, setShowTooltip] = useState(false);
  const cssContainer = clipboardText
    ? CopyToClipboardButtonSettingsContainer
    : CopyToClipboardButtonContainer;

  const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    preventDefault && event.preventDefault();
    navigator.clipboard.writeText(copyText);
    setShowTooltip(true);
    setTimeout(() => setShowTooltip(false), 1000);
  };

  return (
    <div
      css={cssContainer}
      {...rest}
      className={classNames("copy-to-clipboard-button", className)}
      onClick={onClick}
    >
      <Tooltip
        content="Copied!"
        disabled={!showTooltip || disableTooltip}
        visible={showTooltip}
        placement={tooltipPlacement}
        parentProps={{ "aria-label": t("a11y.general.elementCopiedToClipboard") }}
      >
        <div onClick={handleClick} className="copy-to-clipboard-container">
          <Button
            color={showTooltip ? "success" : buttonColor}
            noGutters
            variant={buttonVariant}
            aria-label={t("a11y.general.elementCopiedToClipboard")}
          >
            {!shouldHideIcon &&
              (showTooltip ? <CheckSolidSVG height={16} /> : <CopySVG height={16} />)}
            {buttonText && <Text fontSize="sm">{buttonText}</Text>}
          </Button>
          {clipboardText && <Text fontSize="sm">{clipboardText}</Text>}
        </div>
      </Tooltip>
    </div>
  );
};

export default CopyToClipboardButton;
