import React, { Dispatch, FC, SetStateAction } from "react";
import { Button, Result, Text } from "@epignosis_llc/gnosis";

import { knowledgeBaseContainer } from "../styles";

import { SearchInput, Skeletons } from "@components";
import { useApplyTranslations } from "@hooks";
import { NoResults } from "@assets/images";

import { fullTrim } from "@components/Drawers/HelpCenter/helpers";
import { HelpContent } from "../constants";
import { Article } from "../types";

type KnowledgeBaseProps = {
  articles?: Article[];
  status: "idle" | "error" | "loading" | "success";
  headerSubtitle?: string;
  error?: unknown;
  handleSearchChange?: (searchValue: string) => void;
  setHelpContent?: Dispatch<SetStateAction<string | null>>;
  setSelectedArticle?: Dispatch<SetStateAction<Article | undefined>>;
};

const KnowledgeBase: FC<KnowledgeBaseProps> = ({
  articles,
  status,
  headerSubtitle,
  error,
  handleSearchChange,
  setHelpContent,
  setSelectedArticle,
}) => {
  const { t } = useApplyTranslations();

  const skeletonCount = 7;
  const skeletonNumbers = Array.from({ length: skeletonCount }, (_, index) => index + 1);
  const isArticlesEmpty = articles?.length === 0;

  return (
    <div css={knowledgeBaseContainer}>
      {handleSearchChange && (
        <SearchInput
          id="sender-search"
          placeholder={t("helpCenter.searchKB")}
          onInputChanged={handleSearchChange}
        />
      )}
      {headerSubtitle && (
        <Text fontSize="lg" weight="700">
          {headerSubtitle}
        </Text>
      )}
      <Skeletons.Loader
        status={status}
        error={error}
        loader={
          <div className="skeleton-wrapper">
            {skeletonNumbers.map((n) => (
              <Skeletons.ListItem key={n} />
            ))}
          </div>
        }
      >
        {isArticlesEmpty ? (
          <Result
            className="no-articles-result"
            title={t("helpCenter.noArticles")}
            icon={NoResults}
          />
        ) : (
          articles?.map((article, index) => (
            <div key={article.id}>
              <Button
                variant="link"
                onClick={(): void => {
                  setHelpContent && setHelpContent(HelpContent.article);
                  setSelectedArticle && setSelectedArticle(article);
                }}
                data-testid={`help-menu-button-${index}`}
                className="help-menu-button"
                noGutters
              >
                {article.title}
              </Button>
              <Text
                as="div"
                fontSize="md"
                dangerouslySetInnerHTML={{ __html: fullTrim(article.snippet) }}
              />
            </div>
          ))
        )}
      </Skeletons.Loader>
    </div>
  );
};

export default KnowledgeBase;
