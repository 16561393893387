import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["reorderColumns"] => {
  return {
    boxShadowColor: colors.secondary.light,
    typeColor: colors.secondary.darker,
    iconColor: colors.primary.light,
    backgroundColor: colors.secondary.lighter,
  };
};
