import { PalletThemeColors } from "@styles/utils";
import Color from "color";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["courseCard"] => {
  return {
    cardHeader: {
      infoBtn: {
        color: colors.black,
        background: colors.white,
        labelBackground: colors.primary.darkest,
        labelColor: colors.white,
      },
    },
    cardBody: {
      code: colors.secondary.darker,
    },
    cardFooter: {
      borderColor: colors.secondary.lighter,
      color: colors.primary.base,
      background: colors.secondary.lighter,
      aboutToExpireBackground: Color(colors.red.light).alpha(0.15).string(),
      expired: colors.red.base,
      textColor: colors.white,
      price: {
        currentPriceBackground: "#b9d7f4", // TODO: Find colors from design system
        previousPriceBackground: "#dcebfa",
      },
    },
    progressBar: {
      background: colors.secondary.lighter,
      progressBackground: colors.green.base,
      color: colors.white,
      percentageColor: colors.green.darker,
    },
  };
};
