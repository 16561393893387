import React, { FC, useState } from "react";
import { t } from "@utils/i18n";
import { signUpFormContainer } from "./styles";
import { useSearchQuery } from "@hooks";
import useConfigurationStore from "@stores/configuration";
import SignUpForm from "@components/SignUp/SignUpForm";
import SocialSignUpLink from "@components/SignUp/SocialSignUp/SocialSignUpLink";
import Divider from "@components/ReusableComponents/Divider/Divider";
import PendingActivation from "@views/PendingActivation/PendingActivation";

type SearchQueryFilters = {
  action?: "email_verification" | "admin_activation" | "complete_signup";
};

type SignUpFormContainerProps = { isPublic?: boolean };

const SignUpFormContainer: FC<SignUpFormContainerProps> = ({ isPublic = false }) => {
  const { action, ...restQueryData } = useSearchQuery() as SearchQueryFilters;
  const { domainSettings } = useConfigurationStore();
  const { social_login } = domainSettings ?? {};
  const hasSocialLogin = social_login?.length !== 0;
  const [registrationType, setRegistrationType] = useState("");
  const isAdminActivation =
    registrationType === "admin_activation" || action === "admin_activation";
  const isValidQuery = action === "email_verification" || action === "admin_activation";

  const updateRegistrationType = (type: string): void => {
    setRegistrationType(type);
  };

  const prefilledData =
    action === "complete_signup"
      ? {
          email: restQueryData["email"] ?? "",
          name: restQueryData["name"] ?? "",
          surname: restQueryData["surname"] ?? "",
          login: restQueryData["login"] ?? "",
          socialServiceType: restQueryData["social_service_type"] ?? null,
          sessionData: restQueryData["session_data"] ?? "",
        }
      : undefined;

  return (
    <div css={signUpFormContainer} className="signup-form-container">
      {registrationType === "admin_activation" ||
      registrationType === "email_verification" ||
      isValidQuery ? (
        <PendingActivation activation_type={isAdminActivation ? "admin" : "email"} />
      ) : (
        <>
          <SignUpForm
            setRegistrationType={updateRegistrationType}
            isPublic={isPublic}
            prefilledData={prefilledData}
          />
          {hasSocialLogin && prefilledData === undefined && (
            <>
              <Divider className="form-container" text={t("signUp.or")} />
              <div className="signUp-link-container">
                <SocialSignUpLink />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default SignUpFormContainer;
