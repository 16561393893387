import yup from "./yup-extended";
import { t } from "@utils/i18n";

yup.setLocale({
  array: {
    min: ({ min }) => t("validationMessages.requiredField", { number: min }),
  },
  mixed: {
    required: () => t("validationMessages.requiredField"),
    notType: () => t("validationMessages.validField"),
  },
  number: {
    max: ({ max }) => t("validationMessages.maxNumber", { number: max }),
    min: ({ min }) => t("validationMessages.minNumber", { number: min }),
    integer: () => t("validationMessages.integer"),
  },
  string: {
    max: ({ max }) => t("validationMessages.maxCharacters", { number: max }),
    email: () => t("profileSettings.validationMessages.emailFormat", { count: 1 }),
    matches: () => t("validationMessages.validField"),
    uuid: () => t("validationMessages.uuid"),
  },
});

export { yup };
