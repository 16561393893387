import { css } from "@emotion/react";

export const userRoleSwitchStyles = css`
  .role-switcher {
    padding-left: 0.5rem;

    label {
      width: 100%;
    }

    div {
      padding-bottom: 0.5rem;
    }
  }
`;
