import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { handleEnrollmentErrors } from "@errors";
import { generalNotification } from "@utils/helpers";
import { postEnrollment } from "@api/catalog";
import { useParams } from "react-router-dom";
import { EnrollmentPostData } from "types/entities/Courses";
import localStorageKeys from "@constants/localStorageKeys";
import { useApplyTranslations } from "@hooks";

type UseEnrollmentMutationType = {
  isLoading: boolean;
  enrollmentMutation: () => void;
};

export type UnitRouterParams = {
  courseId: string;
  unitId: string;
};

export const useEnrollmentMutation = (urlId?: string): UseEnrollmentMutationType => {
  const { t } = useApplyTranslations();
  const { courseId } = useParams() as UnitRouterParams;
  const queryClient = useQueryClient();

  const data = {
    id: parseInt(urlId ?? courseId),
    type: "course",
  } as EnrollmentPostData;

  const { mutate: enrollmentMutation, isLoading } = useMutation(() => postEnrollment(data), {
    onSuccess: () => {
      generalNotification("success", <p>{t("courseCatalog.youEnrolledSuccessfully")}</p>);
      queryClient.invalidateQueries(["course", courseId.toString()]);
    },
    onError: (error: AxiosError) => {
      const errorId = error.response?.data?._errors?.[0]?.id;

      if (errorId === "forbidden.terms_not_accepted") {
        const dataObject = {
          pending: true,
          courseId: urlId ?? courseId,
        };

        localStorage.setItem(
          localStorageKeys.PENDING_CATALOG_ENROLLMENT,
          JSON.stringify(dataObject),
        );
      }

      handleEnrollmentErrors(error);
    },
  });

  return {
    enrollmentMutation,
    isLoading,
  };
};

export default useEnrollmentMutation;
