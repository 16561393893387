import { useCallback } from "react";
import useUIStore from "@stores/ui";
import localStorageKeys from "@constants/localStorageKeys";
import shallow from "zustand/shallow";

const SESSION_DURATION = 10 * 60 * 1000; // 10 minutes

interface ConsentSession {
  loginTime: number | null;
  showConsentModal: boolean;
  clearLoginTime: () => void;
  initializeSession: () => void;
}

const initializeSessionLogic = (): void => {
  const {
    consentDialogSession: { loginTime, timerId, showConsentModal },
  } = useUIStore.getState();

  if (loginTime !== null) return;

  let storedTime = localStorage.getItem(localStorageKeys.CONSENT_MODAL_TIMER);

  if (!storedTime) {
    const now = Date.now().toString();
    localStorage.setItem(localStorageKeys.CONSENT_MODAL_TIMER, now);
    storedTime = now;
  }

  const loginTimestamp = Number(storedTime);
  const currentTime = Date.now();
  const elapsed = currentTime - loginTimestamp;

  if (elapsed >= SESSION_DURATION) {
    if (!showConsentModal) {
      useUIStore.setState((state) => ({
        consentDialogSession: { ...state.consentDialogSession, showConsentModal: true },
      }));
    }
    if (timerId) {
      clearInterval(timerId);
    }
    return;
  }

  if (timerId) {
    clearInterval(timerId);
  }

  const newTimerId = setInterval(() => {
    const timeElapsed = Date.now() - loginTimestamp;
    // eslint-disable-next-line no-console
    console.log("time left", timeElapsed);

    if (timeElapsed >= SESSION_DURATION) {
      useUIStore.setState((state) => ({
        consentDialogSession: {
          ...state.consentDialogSession,
          showConsentModal: true,
          timerId: null,
        },
      }));
      clearInterval(newTimerId);
    }
  }, 1000);

  useUIStore.setState((state) => ({
    consentDialogSession: {
      ...state.consentDialogSession,
      loginTime: loginTimestamp,
      timerId: newTimerId,
    },
  }));
};

export const useConsentDialogSession = (): ConsentSession => {
  const { loginTime, showConsentModal, timerId } = useUIStore(
    (state) => ({
      loginTime: state.consentDialogSession.loginTime,
      showConsentModal: state.consentDialogSession.showConsentModal,
      timerId: state.consentDialogSession.timerId,
    }),
    shallow,
  );

  const initializeSession = useCallback(() => {
    initializeSessionLogic();
  }, []);

  const clearLoginTime = useCallback((): void => {
    localStorage.removeItem(localStorageKeys.CONSENT_MODAL_TIMER);
    if (timerId) {
      clearInterval(timerId);
    }
    useUIStore.setState(() => ({
      consentDialogSession: { loginTime: null, timerId: null, showConsentModal: false },
    }));
  }, [timerId]);

  return { loginTime, showConsentModal, clearLoginTime, initializeSession };
};
