import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const helpContainer = css`
  padding: 1rem;

  .nav-wrapper {
    margin-bottom: 1.5rem;
  }

  ${mq["md"]} {
    padding: 1.5rem 2rem;
  }
`;

export const knowledgeBaseContainer = css`
  display: inline-flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;

  .no-articles-result {
    width: 100%;
  }

  .help-menu-button {
    padding: 0;
    text-align: left;

    .btn-text {
      font-size: 1rem;
      font-weight: 700;
    }
  }

  .input-wrapper {
    max-width: 20.25rem;
  }
`;

export const contactSupportContainer = css`
  .support-service {
    gap: 1rem;
  }
`;
