import { useConfigurationStore } from "@stores";
import { useMemo } from "react";
import { LeaderboardStatistics, Leaderboard } from "types/entities";

type LeaderboardTabsData = { title: string; data: Leaderboard | null }[];

const useLeaderboardState = (
  rawLeaderboardData: LeaderboardStatistics | undefined,
): LeaderboardTabsData => {
  const { gamificationSettings } = useConfigurationStore();
  const { badges, points, levels, leaderboard } = gamificationSettings ?? {};
  const {
    show_badges: showBadges,
    show_certifications: showCertificates,
    show_courses: showCourses,
    show_levels: showLevels,
    show_points: showPoints,
  } = leaderboard ?? {};

  const permissionsArray = useMemo(
    () => ({
      badges: badges?.enabled && showBadges,
      points: points?.enabled && showPoints,
      levels: levels?.enabled && showLevels,
      courses: showCourses,
      certificates: showCertificates,
    }),
    [badges, showBadges, points, showPoints, levels, showLevels, showCertificates, showCourses],
  );

  const leaderboardTabs = useMemo(() => {
    if (rawLeaderboardData) {
      return Object.entries(rawLeaderboardData)
        .map((tabData) => ({
          title: tabData[0],
          data: tabData[1],
        }))
        .filter(({ data, title }) => data !== null && permissionsArray[title]);
    }

    return [];
  }, [rawLeaderboardData, permissionsArray]);

  return leaderboardTabs;
};

export default useLeaderboardState;
