import React, { ReactElement } from "react";
import { MultiValueRemoveProps, components } from "react-select";
import { CloseSolidSVG } from "@epignosis_llc/gnosis/icons";
import { Option } from "@components/FormElements/AutocompleteInput/AutocompleteInput";

const CustomMultiValueRemove = (props: MultiValueRemoveProps<Option>): ReactElement => {
  return (
    <components.MultiValueRemove {...props}>
      <CloseSolidSVG className="multi-value-remove" height={16} />
    </components.MultiValueRemove>
  );
};

export default CustomMultiValueRemove;
