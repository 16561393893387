import { HandledError } from "@errors";

export const skillRecommendationErrors: HandledError[] = [
  {
    status: 404,
    id: "not_found.recommendation_not_found",
    errorMsg: "errors.skills.recommendationNotFound",
  },

  // TODO: Handle more errors
];

export const skillMassActionsErrors = [
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },

  // TODO: Handle more errors
];

export const skillUpdateErrors: HandledError[] = [
  {
    status: 404,
    id: "not_found.skill_not_found",
    errorMsg: "skills.errors.skillNotAvailable",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },

  // TODO: Handle more errors
];

export const skillAssessmentResultErrors: HandledError[] = [
  {
    status: 403,
    id: "forbidden.skill_assessment_not_completed",
    errorMsg: "skills.errors.assessment.results.incompleteAssessment",
  },
  {
    status: 404,
    id: "not_found.skill_assessment_attempt_not_found",
    errorMsg: "skills.errors.assessment.results.incompleteAssessment",
  },
];

export const skillAssessmentQuestionErrors: HandledError[] = [
  {
    status: 403,
    id: "forbidden.skill_assessment_not_started",
    errorMsg: "skills.errors.assessment.submit.nonStartedAssessment",
  },
  {
    status: 403,
    id: "forbidden.can_not_submit_skill_question",
    errorMsg: "skills.errors.assessment.submit.cannotSubmitQuestion",
  },
  {
    status: 403,
    id: "forbidden.skill_assessment_already_completed",
    errorMsg: "skills.errors.assessment.submit.alreadyCompletedAssessment",
  },
  {
    status: 403,
    id: "forbidden.skill_assessment_all_questions_already_submitted",
    errorMsg: "skills.errors.assessment.submit.allQuestionAlreadySubmitted",
  },
  {
    status: 404,
    id: "not_found.skill_question_not_found",
    errorMsg: "skills.errors.assessment.submit.notFoundQuestion",
  },
  {
    status: 403,
    id: "forbidden.skill_assessment_question_traversal_not_allowed",
    errorMsg: "skills.errors.assessment.question.traversalNotAllowed",
  },
];

export const skillAssessmentStartErrors: HandledError[] = [];

export const skillAssessmentCompleteErrors: HandledError[] = [
  {
    status: 403,
    id: "forbidden.skill_assessment_not_started",
    errorMsg: "skills.errors.assessment.submit.nonStartedAssessment",
  },
  {
    status: 403,
    id: "forbidden.skill_assessment_already_completed",
    errorMsg: "skills.errors.assessment.submit.alreadyCompletedAssessment",
  },
];

export const skillAssignmentErrors: HandledError[] = [
  {
    status: 400,
    id: "bad_request",
    errorMsg: "skills.errors.assingnmentFailed",
  },
  {
    status: 400,
    id: "bad_request.skill_assignment_failed_nothing_to_assign",
    errorMsg: "skills.errors.assignmentFailedNoSkillsSelected",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
];

export const skillUnassignmentErrors: HandledError[] = [
  {
    status: 400,
    id: "bad_request",
    errorMsg: "skills.errors.unassingnmentFailed",
  },
  {
    status: 400,
    id: "bad_request.skill_unassignment_failed_nothing_to_assign",
    errorMsg: "skills.errors.unassignmentFailedNotSkillsSelected",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
];
