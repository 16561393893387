import React, { FC, MouseEvent } from "react";
import ActionButton from "./ActionButton";
import { IconType } from "types/common";
import { UserMinusSVG } from "@epignosis_llc/gnosis/icons";
import { useApplyTranslations } from "@hooks";

export type UnenrollActionProps = {
  tooltipContent?: string;
  icon?: IconType;
  iconHeight?: number;
  disabled?: boolean;
  onClick: (e: MouseEvent) => void;
};

const UnenrollAction: FC<UnenrollActionProps> = ({
  tooltipContent,
  icon: Icon = UserMinusSVG,
  iconHeight = 32,
  disabled = false,
  onClick,
}) => {
  const { t } = useApplyTranslations();

  return (
    <ActionButton
      tooltipContent={tooltipContent ?? t("general.unenroll")}
      onClick={onClick}
      data-testid="unenroll-action"
      disabled={disabled}
    >
      <Icon height={iconHeight} />
    </ActionButton>
  );
};

export default UnenrollAction;
