import React, { FC } from "react";
import { articleContentStyles } from "./styles";
import { convertPatternToBreaks } from "../helpers";

type ArticleContentProps = {
  content?: string;
};
const ArticleContent: FC<ArticleContentProps> = ({ content }) => {
  return (
    <>
      {content && (
        <div
          css={articleContentStyles}
          dangerouslySetInnerHTML={{ __html: convertPatternToBreaks(content) }}
        />
      )}
    </>
  );
};

export default ArticleContent;
