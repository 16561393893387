import { AxiosError } from "axios";
import { HandledError, handledApiError } from "./errors";

export const handleAssignmentErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, assignmentErrors, false, "", handleError);
};

export const assignmentErrors: HandledError[] = [
  {
    status: 400,
    id: "bad_request",
    errorMsg: "errors.general.statusUpdateFailed",
  },
  {
    status: 401,
    id: "access_denied",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 404,
    id: "not_found.unit_not_found",
    errorMsg: "errors.units.unitNotFound",
  },
  {
    status: 404,
    id: "not_found.user_not_found",
    errorMsg: "users.errors.notFound",
  },
  {
    status: 409,
    id: "conflict.expired_submission",
    errorMsg: "errors.units.expiredSubmission",
  },
];
