// Packages or third-party libraries
import React, { FC } from "react";

// Components
import { ActionDrawer } from "@components";
import CollectMorePoints from "./CollectMorePoints";

// Hooks
import { useApplyTranslations } from "@hooks";

// Constants
import { footerButtonTranslations } from "./constants";
import { GamificationInfoDrawerProps } from "./types";

const GamificationInfoDrawer: FC<GamificationInfoDrawerProps> = ({
  currentTab,
  isOpen,
  onClose,
}) => {
  const { t } = useApplyTranslations();

  return (
    <ActionDrawer
      isOpen={isOpen}
      headerTitle={t(footerButtonTranslations[currentTab])}
      onClose={onClose}
      size="md"
      hasBackButton
      showFooter={false}
    >
      <CollectMorePoints isPoints={currentTab === "points"} />
    </ActionDrawer>
  );
};

export default GamificationInfoDrawer;
