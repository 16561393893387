import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["modal"] => {
  return {
    iconHover: colors.primary.base,
    color: colors.black,
    border: colors.secondary.lighter,
  };
};
