import React, { FC, useState } from "react";
import { useDebounceFn, useResponsive } from "ahooks";
import { useMutation } from "react-query";
import { Button } from "@epignosis_llc/gnosis";
import { MagnifierSVG } from "@epignosis_llc/gnosis/icons";
import { MainSearch, MobileSearchDrawer } from "@components";
import { getSpotlight } from "@api/search";
import queryKeys from "@constants/queryKeys";
import { SearchResults, SystemOption } from "types/entities";
import { getSystemResults } from "@utils/helpers/systemOptions";

const SEARCH_LIMIT = 8;

const SEARCH_EMPTY_STATE = {
  users: [],
  courses: [],
  files: [],
  categories: [],
  groups: [],
  branches: [],
  units: [],
  skills: [],
  total: {
    users: 0,
    courses: 0,
    files: 0,
    categories: 0,
    groups: 0,
    branches: 0,
    units: 0,
    skills: 0,
  },
  options: [],
};

const SpotlightSearch: FC = () => {
  const { md } = useResponsive();
  const [searchResults, setSearchResults] = useState<SearchResults | null>(null);
  const [systemOptionsResults, setSystemOptionsResults] = useState<SystemOption[]>([]);
  const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);

  const { run: runGetSpotlight } = useDebounceFn(
    async (inputValue: string) => {
      const trimmedValue = inputValue.trim();

      if (trimmedValue.length >= 2) {
        const systemResults = getSystemResults(trimmedValue);
        setSystemOptionsResults(systemResults);

        // If found system option less than the search limit make API request to get more results
        if (systemResults.length < SEARCH_LIMIT) {
          getSpotlightMutation(trimmedValue);
        } else {
          setSearchResults({
            ...SEARCH_EMPTY_STATE,
            options: systemResults.slice(0, 8),
          });
        }
      } else {
        setSearchResults(null);
        setSystemOptionsResults([]);
      }
    },
    { wait: 200 },
  );

  const { mutate: getSpotlightMutation, status: getSpotlightStatus } = useMutation(
    [queryKeys.search.spotlight],
    (searchValue: string) =>
      getSpotlight(
        `?limit=${SEARCH_LIMIT - systemOptionsResults.length}&search_term=${encodeURIComponent(
          searchValue,
        )}`,
      ),
    {
      onSuccess: (response) => {
        setSearchResults(
          systemOptionsResults.length > 0
            ? { ...{ options: systemOptionsResults }, ...response?._data }
            : { ...response?._data },
        );
      },
    },
  );

  const closeMobileSearch = (): void => {
    setIsMobileSearchOpen(false);
  };

  return (
    <>
      {md ? (
        <MainSearch
          searchResults={searchResults}
          isLoading={getSpotlightStatus === "loading"}
          onSearch={runGetSpotlight}
        />
      ) : (
        <>
          <MobileSearchDrawer
            isOpen={isMobileSearchOpen}
            isLoading={getSpotlightStatus === "loading"}
            onClose={closeMobileSearch}
            searchResults={searchResults}
            onSearch={runGetSpotlight}
          />

          <Button
            className="mobile-search-btn"
            onClick={(): void => setIsMobileSearchOpen((state) => !state)}
            variant="ghost"
            noGutters
          >
            <MagnifierSVG height={32} />
          </Button>
        </>
      )}
    </>
  );
};

export default SpotlightSearch;
