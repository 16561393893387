import { css } from "@emotion/react";

export const link = ({ customLink }, { color }) => css`
  color: ${customLink[color].color};
  text-decoration: none;

  &:hover {
    color: ${customLink[color].hoverColor};
  }

  &.readonly {
    color: inherit;
    cursor: not-allowed;
  }

  &.underlined {
    text-decoration: underline;
  }
`;
