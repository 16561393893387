import { getProtectedColor, PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["reverseTrial"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);

  return {
    trialModal: {
      tickColor: protectColorContrast("darkest", colors.primary.base),
    },
  };
};
