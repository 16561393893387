import { css } from "@emotion/react";

export const CopyToClipboardButtonContainer = css`
  position: relative;
  width: 40px;
`;

export const CopyToClipboardButtonSettingsContainer = ({ copyToClipboard }) => css`
  div {
    max-width: 180px;

    .copy-to-clipboard-container {
      display: flex;
      border-radius: 5px;
      max-width: 180px;
      align-items: center;
      gap: 10px;
      color: ${copyToClipboard.hoverColor};
      cursor: pointer;

      &:hover {
        background-color: ${copyToClipboard.wrapperHoverColor};
      }

      button {
        color: ${copyToClipboard.hoverColor} !important;
      }
    }
  }
`;
