import { css } from "@emotion/react";

export const divider = ({ formElements }) => css`
  .container {
    display: flex;
    align-items: center;
  }

  .line {
    background-color: ${formElements.input.background};
    height: 1px;
    width: 100%;
  }
`;

export const verticalDividerStyles = ({ formElements }, { height }) => css`
  .line {
    background-color: ${formElements.input.background};
    height: ${height}px;
    width: 1px;
  }
`;
