import { userHasRequiredPermissions } from "./index";
import { useConfigurationStore } from "@stores";
import authService from "@utils/services/AuthService";

export const gamificationPermissions = {
  read: "gamification.read",
  accountGamification: "account.gamification",
  reportsGamification: "reports.user",
  update: "account.gamification",
};

const { read, update, accountGamification, reportsGamification } = gamificationPermissions;
const { getState } = useConfigurationStore;

export default {
  /**
   * Permission => gamification.read
   *
   * Allowed actions:
   * GET gamification.settings, gamification.leaderboard, user.badges, user.rewards
   *
   * @returns boolean
   */

  canAccessGamification: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [read, accountGamification, reportsGamification],
      getState().userProfileData?.permissions[userRole],
      false, // At least one of the permissions is required
    );
  },

  canUpdateGamification: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([update], getState().userProfileData?.permissions[userRole]);
  },
};
