import HttpClient from "@api/HttpClient";
import { ENDPOINTS } from "@api/endpoints";
import { createFeedbackTicketPostData, FeedbackTicketRes } from "./types";

export const createFeedbackTicket = async (
  ticket: createFeedbackTicketPostData,
): Promise<FeedbackTicketRes> => {
  const res = await HttpClient.post(ENDPOINTS.help.feedback, ticket);

  return res.data;
};
