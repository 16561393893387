import React, { FC } from "react";
import { Button } from "@epignosis_llc/gnosis";
import { useConfigurationStore, useUIStore } from "@stores";
import { SSOIconSVG, CredentialsSVG } from "@epignosis_llc/gnosis/icons";
import { useApplyTranslations } from "@hooks";

const LoginType: FC = () => {
  const { t } = useApplyTranslations();
  const { getLoginType, setLoginType } = useUIStore();
  const loginType = getLoginType();
  const { domainSettings } = useConfigurationStore();
  const integrationType = domainSettings?.sso.integration_type ?? "";

  const handleClick = (): void => {
    loginType === "ldap" ? setLoginType("direct") : setLoginType("ldap");
  };

  return (
    <section className="login-type-container">
      {integrationType !== "" && (
        <Button
          as="button"
          color="primary"
          iconBefore={loginType === "ldap" ? CredentialsSVG : SSOIconSVG}
          className="sign-in-type"
          onClick={handleClick}
          variant="outline"
        >
          {loginType === "ldap" ? t("signIn.loginWithCredentials") : t("signIn.loginWithLdap")}
        </Button>
      )}
    </section>
  );
};

export default LoginType;
