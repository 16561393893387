import { css } from "@emotion/react";

export const timeOptionsStyles = css`
  .time-type-button {
    width: 100%;
  }

  .time-note {
    margin-bottom: -0.5rem;
  }
`;
