import React, { FC } from "react";
import { NonPreviewableSVG } from "@epignosis_llc/gnosis/icons";
import { useApplyTranslations } from "@hooks";

export const ThumbnailError: FC = () => {
  const { t } = useApplyTranslations();

  return (
    <div className="no-thumbnail-cover" aria-label={t("a11y.general.noThumbnail")}>
      <NonPreviewableSVG height={40} />
    </div>
  );
};
