import { SerializedStyles } from "@emotion/react";
import React, { FC } from "react";
import { massActionsCountContainer } from "./styles";

type MassActionsCountProps = {
  count: number;
  isDropdownOpen?: boolean;
  variant?: "solid" | "outline";
};

const MassActionsCount: FC<MassActionsCountProps> = ({
  count,
  isDropdownOpen = false,
  variant = "outline",
}) => {
  return (
    <div
      css={(theme): SerializedStyles => massActionsCountContainer(theme, isDropdownOpen, variant)}
    >
      <span className="mass-action-count">{count}</span>
    </div>
  );
};

export default MassActionsCount;
