import { css } from "@emotion/react";

export const branchesMenuStyles = ({ button }, { shouldShowSearch }) => {
  return css`
    .branches-dropdown-header {
      padding: ${shouldShowSearch ? "0" : "1rem 1rem 0"};

      .main-branch-switch {
        padding: 0.25rem 0;
      }
    }

    .dropdown-button {
      // Colors are taken from the ghost button since we want the same colors (hover) on active.
      &.is-active {
        background: ${button.primary.ghost.background};
        border-radius: 0.3125rem;

        svg {
          color: ${button.primary.ghost.hoverColor};
        }
      }
    }
  `;
};
