import React, {
  Dispatch,
  forwardRef,
  ForwardRefRenderFunction,
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input, InputError, Textarea } from "@epignosis_llc/gnosis";

import { supportFormContainer } from "./styles";

import { DragAndDropArea, FileInput } from "@components";

import {
  buildFileValidations,
  errorNotification,
  generalNotification,
  getFileValidations,
} from "@utils/helpers";
import { TicketFormValidationSchema } from "@utils/validation/contactSupportSchemas";
import { useApplyTranslations } from "@hooks";
import queryKeys from "@constants/queryKeys";
import {
  createAccountManager,
  createTicket,
  postAccountManagerAttachmentFile,
  postTicketAttachmentFile,
} from "@components/Drawers/HelpCenter/api";

import { CANCELLATION_SURVEY_PREFIX } from "@views/Subscription/constants";
import { createTicketPostData, SupportFormData } from "@components/Drawers/HelpCenter/types";
import { TabHandle } from "@components/ReusableDrawers/types";
import { handleUploadFilesErrors } from "@errors/errors";

export type SupportFormProps = {
  setIsButtonDisabled: Dispatch<SetStateAction<boolean>>;
  setSubjectValue?: Dispatch<SetStateAction<string>>;
  type: "ticket" | "accountManager" | "support";
  onSubmitSuccess?: () => void;
  onValidChange: (isValid: boolean) => void;
  onLoadingChange: (isLoading: boolean) => void;
  onSubmissionSuccess: () => void;
};

const MAX_ALLOWED_FILES = 1;

const SupportForm: ForwardRefRenderFunction<TabHandle, SupportFormProps> = (
  {
    setIsButtonDisabled,
    setSubjectValue,
    type,
    onValidChange,
    onLoadingChange,
    onSubmissionSuccess,
  },
  ref,
) => {
  const { t } = useApplyTranslations();
  const [droppedAttachments, setDroppedAttachments] = useState<FileList | null>(null);
  const [attachments, setAttachments] = useState<File[]>([]);
  const validations = getFileValidations("ticket.attachments");
  const mimeTypeAndFilesizeValidations = buildFileValidations(validations);
  const ticketType = type === "ticket";
  const isSupportTicket = type === "support";

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    watch,
  } = useForm<SupportFormData>({
    mode: "onChange",
    resolver: yupResolver(TicketFormValidationSchema),
    context: { type },
    defaultValues: {
      subject: isSupportTicket ? t(`${CANCELLATION_SURVEY_PREFIX}.cancelSubscription`) : "",
      body: "",
    },
  });

  const watchSubject = watch("subject");
  const watchBody = watch("body");

  useEffect(() => {
    setIsButtonDisabled((isSupportTicket ? false : !watchSubject) || !watchBody);
    setSubjectValue && setSubjectValue(watchSubject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchSubject, watchBody]);

  useImperativeHandle(ref, () => ({
    handleApply: handleSubmit(handleData),
  }));

  useEffect(() => {
    onValidChange(isValid);
  }, [isValid, onValidChange]);

  const handleFileError = (error: string): void => {
    errorNotification(`${error}`);
  };

  const handleFilesChanged = (files: File[]): void => {
    setAttachments(files);
    setDroppedAttachments(null);
  };

  const handleFilesDrop = (files: FileList): void => {
    setDroppedAttachments(files);
  };

  const { mutate: createTicketMutation, isLoading: createTicketMutationLoading } = useMutation(
    [queryKeys.help.ticket.create],
    (ticket: createTicketPostData) => createTicket(ticket, true),
    {
      onSuccess: () => {
        onSubmissionSuccess();
      },
      onError: (error: AxiosError) => {
        handleUploadFilesErrors(error);
      },
    },
  );

  const {
    mutateAsync: mutateTicketAttachmentAsync,
    isLoading: postTicketAttachmentFileMutationLoading,
  } = useMutation(
    [queryKeys.help.ticket.attachment],
    (file: File) => postTicketAttachmentFile(file),
    {
      onError: (error: AxiosError) => {
        generalNotification("error", error.response?.data._errors[0].title);
      },
    },
  );

  const { mutate: createAccountManagerMutation, isLoading: createAccountManagerMutationLoading } =
    useMutation(
      [queryKeys.help.accountManager.create],
      (ticket: createTicketPostData) => createAccountManager(ticket),
      {
        onSuccess: () => {
          onSubmissionSuccess();
        },
        onError: (error: AxiosError) => {
          handleUploadFilesErrors(error);
        },
      },
    );

  const {
    mutateAsync: mutateAccountManagerAttachmentAsync,
    isLoading: postAccountManagerAttachmentFileMutationLoading,
  } = useMutation(
    [queryKeys.help.accountManager.attachment],
    (file: File) => postAccountManagerAttachmentFile(file),
    {
      onError: (error: AxiosError) => {
        generalNotification("error", error.response?.data._errors[0].title);
      },
    },
  );

  const handleData = async (data: createTicketPostData): Promise<void> => {
    if (attachments.length) {
      const { _data: uploadedFile } =
        ticketType || isSupportTicket
          ? await mutateTicketAttachmentAsync(attachments[0])
          : await mutateAccountManagerAttachmentAsync(attachments[0]);
      data.attachment_hash = uploadedFile.file_hash;
    }

    if (ticketType || isSupportTicket) {
      createTicketMutation(data);
    } else {
      createAccountManagerMutation(data);
    }
  };

  const getTextareaProps = useCallback(() => {
    switch (type) {
      case "accountManager":
        return {
          label: t("helpCenter.typeYourMessage"),
          placeholder: `${t("helpCenter.typeYourMessageInfo")}\n\n${t(
            "helpCenter.typeYourMessageRemember",
          )}`,
        };
      case "support":
        return {
          label: t(`${CANCELLATION_SURVEY_PREFIX}.describeTheIssue`),
          placeholder: t(`${CANCELLATION_SURVEY_PREFIX}.textareaPlaceholder`),
        };
      case "ticket":
      default:
        return {
          label: t("helpCenter.describeYourIssue"),
          placeholder: t("helpCenter.describeYourIssueInfo"),
        };
    }
  }, [type, t]);

  useEffect(() => {
    const loading =
      createTicketMutationLoading ||
      postTicketAttachmentFileMutationLoading ||
      createAccountManagerMutationLoading ||
      postAccountManagerAttachmentFileMutationLoading;
    onLoadingChange(loading);
  }, [
    createTicketMutationLoading,
    postTicketAttachmentFileMutationLoading,
    createAccountManagerMutationLoading,
    postAccountManagerAttachmentFileMutationLoading,
    onLoadingChange,
  ]);

  return (
    <form
      autoComplete="off"
      css={supportFormContainer(isSupportTicket)}
      onSubmit={handleSubmit(handleData)}
    >
      {!isSupportTicket && (
        <div className="input-field-wrapper">
          <div className="input-container">
            <Input
              {...register("subject")}
              id="subject"
              label={t("helpCenter.howCanWeHelp")}
              placeholder={t("general.subject")}
              status={errors.subject ? "error" : "valid"}
            />
            {errors.subject?.message && <InputError>{errors?.subject?.message}</InputError>}
          </div>
        </div>
      )}

      <div className="input-field-wrapper">
        <div className="input-container">
          <Textarea
            {...register("body")}
            id="body"
            label={getTextareaProps().label}
            placeholder={getTextareaProps().placeholder}
            status={errors.body ? "error" : "valid"}
          />
          {errors.body?.message && <InputError>{errors?.body?.message}</InputError>}
        </div>
      </div>

      <DragAndDropArea
        maxFiles={MAX_ALLOWED_FILES}
        mimeTypeAndFilesizeValidations={mimeTypeAndFilesizeValidations}
        preventDrop={attachments.length >= MAX_ALLOWED_FILES}
        onFilesDrop={handleFilesDrop}
      >
        <FileInput
          id="support-files"
          name="files"
          maxFiles={MAX_ALLOWED_FILES}
          mimeTypeAndFilesizeValidations={mimeTypeAndFilesizeValidations}
          selectedFiles={attachments}
          addedFiles={droppedAttachments}
          onFileError={handleFileError}
          onFilesChange={handleFilesChanged}
        />
      </DragAndDropArea>
    </form>
  );
};

export default forwardRef(SupportForm);
