import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";
import { typeScale } from "@epignosis_llc/gnosis";

export const iltTitleContainerStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;

  ${mq["lg"]} {
    flex-direction: row;
    align-items: center;
  }
`;

export const iltButtonsStyles = css`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

export const iltTitleStyles = ({ units: { ilt } }, { isPast }) => css`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .icon {
    min-width: 32px;
  }

  .text {
    color: ${isPast ? ilt.textColor : "initial"};
    word-break: break-word;
  }

  .has-started {
    display: flex;
    gap: 0.25rem;
    padding: 0.125rem 0.5rem;
    font-size: 8px;
    font-weight: 700;

    .has-started-icon {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: ${ilt.startedColor};
    }
  }
`;

export const iltSessionsContainerStyles = css`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  ${mq["lg"]} {
    gap: 2rem;
  }
`;

export const iltSessionStyles = () => css`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  ${mq["lg"]} {
    gap: 1rem;
  }

  .session-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
  }

  .session-container {
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1;

    ${mq["lg"]} {
      flex-direction: row;
      align-items: center;
      gap: 1rem;
    }
  }
`;

export const iltSessionDescriptionStyles = (_theme, { showMore }) => css`
  .description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: ${showMore ? "block" : "-webkit-box"};
    -webkit-line-clamp: 7;
    line-clamp: 7;
    -webkit-box-orient: vertical;
    word-break: break-word;

    ${mq["lg"]} {
      display: block;
    }
  }
`;

export const iltSessionDetailsStyles = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
  }

  .webinar-location-container {
    display: flex;
    align-items: center;
  }

  .children {
    display: flex;
    justify-content: end;
  }
`;

export const iltSessionDateStyles = ({ units: { ilt } }) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${mq["lg"]} {
    width: auto;
  }

  .date {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
    padding: 0;

    &.is-past {
      color: ${ilt.textColor};
    }

    ${mq["lg"]} {
      flex-direction: column;
      align-items: center;
      gap: 0;
      padding: 0 0.5rem;

      .day {
        order: 1;
      }

      .year {
        order: 2;
        font-size: ${typeScale.xs};
      }
    }
  }
`;

export const iltInfoItemStyles = ({ units: { ilt } }) => css`
  display: flex;
  flex-direction: column;

  .label {
    color: ${ilt.textColor};
    text-transform: uppercase;
  }

  .content {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }
`;

export const iltSessionActionsStyles = css`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
