import { css } from "@emotion/react";

export const socialSignIn = () => css`
  .social-button {
    padding: 0 0 0 1rem;
    width: 100%;
    margin-bottom: 0.5rem;

    span {
      width: 50%;
    }

    &.facebook svg {
      height: 24px;
      width: 30px;
    }
  }
`;
