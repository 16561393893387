import { css } from "@emotion/react";

export const upsellStateStyles = () => css`
  .empty-state-container {
    gap: 2rem;

    .empty-state {
      &-footer {
        margin-top: 0 !important;
        border-top: none;
        padding: 0;
      }
    }
  }
`;
