import { css } from "@emotion/react";
import { GlobalStyleVariables } from "@styles";
const { unitsMaxWidth } = GlobalStyleVariables;

export const errorResultContainer = css`
  width: 100%;
  max-width: ${unitsMaxWidth};
  margin: auto;

  img,
  svg {
    width: 100%;
    max-width: 40%;
    margin: auto;
    height: auto;
  }

  .body {
    padding: 0 1rem;
  }
`;
