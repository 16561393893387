import React, { FC } from "react";
import { unitPageLayoutContentBody } from "../styles";
import { SerializedStyles } from "@emotion/react";

export type ContentBodyProps = {
  noPadding?: boolean;
  children?: React.ReactNode;
};

const ContentBody: FC<ContentBodyProps> = ({ noPadding = false, children }) => {
  return (
    <div
      id="unit-page-layout-content-body"
      css={(): SerializedStyles => unitPageLayoutContentBody(noPadding)}
    >
      {children}
    </div>
  );
};

export default ContentBody;
