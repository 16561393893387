// Packages or third-party libraries
import React, { FC } from "react";
import { useQuery } from "react-query";
import { Text } from "@epignosis_llc/gnosis";

// Styles
import { userBadgesDrawerContainer } from "./styles";

// Components
import Skeletons from "@components/Skeleton/Skeleton";
import UserItem from "@components/UserStats/UserItem";
import BadgeCategory from "@components/UserStats/BadgeCategory";
import { NoBadgesSVG } from "@assets/images";

// Hooks
import { useApplyTranslations } from "@hooks";

// Utils
import permissions from "@utils/permissions";

// Constants
import queryKeys from "@constants/queryKeys";
import { badgeCategories } from "./constants";
import { MAX_BADGES } from "@layouts/DashboardLayout/components/TopBarMenu/constants";

// Others
import { getUserBadges } from "@api/app";
import { useConfigurationStore } from "@stores";
import { BadgesInfoProps } from "./types";

const BadgesInfo: FC<BadgesInfoProps> = ({ userId, leaderboardUser, index }) => {
  const { t } = useApplyTranslations();
  const { gamificationSettings } = useConfigurationStore();
  const { badges } = gamificationSettings ?? {};
  const { canAccessGamification } = permissions.gamificationPermissions;
  const badgesQuery = `?filter[type]=awarded&sort=criteria&page[size]=${MAX_BADGES}`;
  const shouldQueryRun = canAccessGamification() && badges?.enabled;

  const {
    data: badgesData,
    status: badgesStatus,
    error: badgesError,
  } = useQuery([queryKeys.users.badges, userId], () => getUserBadges(userId, badgesQuery), {
    select: (res) => res._data,
    enabled: shouldQueryRun,
  });

  const EmptyState = (): React.JSX.Element => (
    <div className="empty-state-container">
      <NoBadgesSVG />
      <Text fontSize="md" weight="700">
        {t("rewards.drawer.badgesEmptyState")}
      </Text>
    </div>
  );

  return (
    <div css={userBadgesDrawerContainer}>
      <Skeletons.Loader status={badgesStatus} error={badgesError}>
        {badgesData && badgesData.length ? (
          <>
            <span className="user-item-container">
              <UserItem
                leaderboardUser={leaderboardUser}
                index={index}
                isGamificationDrawer={true}
              />
            </span>
            <div className="badges-container">
              {badgeCategories.map((badge) => (
                <BadgeCategory key={badge} title={badge} badges={badgesData} />
              ))}
            </div>
          </>
        ) : (
          <EmptyState />
        )}
      </Skeletons.Loader>
    </div>
  );
};

export default BadgesInfo;
