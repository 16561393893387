import authService from "@utils/services/AuthService";
import { AxiosRequestHeaders } from "axios";
import { getPublicAccessToken } from "./tokens";

type beforeUnloadRequestProps = {
  method: string;
  url: string;
  body?: string;
};

// Used to send a request on beforeunload event, for the edge cases where the user leaves unexpectedly the current page (tab close, URL change etc...).
export const beforeUnloadRequest = async ({
  method,
  url,
  body,
}: beforeUnloadRequestProps): Promise<void> => {
  const contentType = "application/json";
  const authorization = `Bearer ${authService.getAccessToken()}`;
  const currentRole = authService.getDefaultRole();
  const isFirefox = navigator.userAgent.toLocaleLowerCase().includes("firefox");
  const isFetchSupported = "fetch" in window;

  // SUPPORT FOR FIREFOX
  // FIREFOX supports fetch but only XMLHttpRequest works on the beforeunload
  if (isFirefox) {
    const xhr = new XMLHttpRequest();
    xhr.open(method, url, false);
    xhr.setRequestHeader("Content-Type", contentType);
    xhr.setRequestHeader("Authorization", authorization);
    xhr.setRequestHeader("Current-Role", currentRole);
    xhr.send(body);
  } else {
    // SUPPORT FOR CHROME, SAFARI, EDGE
    if (isFetchSupported) {
      await fetch(url, {
        method,
        body: body || undefined,
        headers: {
          "Content-Type": contentType,
          Authorization: authorization,
          "Current-Role": currentRole,
        },
        keepalive: true,
      });
    }
  }
};

export const getPublicBearerHeaders = (): AxiosRequestHeaders | undefined => {
  const isAuthenticated = Boolean(authService.getAccessToken());
  const headers: AxiosRequestHeaders = {};

  const token = getPublicAccessToken();

  if (isAuthenticated || !token) return undefined; // If the user is authenticated dont return the headers

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return headers;
};

// Used to determine if the request should be retried based on the error code and the failure count
const errorRetries = {
  403: false,
  404: false,
  500: 1,
};

export const getErrorRetries = (failureCount: number, code: number): boolean => {
  const retryAttempts = errorRetries[code] ? errorRetries[code] : failureCount < 3;

  return retryAttempts;
};
