import React, { FC, ReactNode } from "react";
import { MainStyles } from "./styles";

export type MainProps = FC<{
  children: ReactNode;
}>;

const Main: MainProps = ({ children }) => <div css={MainStyles}>{children}</div>;

export default Main;
