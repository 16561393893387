import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["toggleButtonsView"] => {
  return {
    backgroundColor: colors.white,
    borderBottomColor: colors.secondary.lighter,
    textColor: colors.black,
  };
};
