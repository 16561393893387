import { Recipient } from "types/entities";

// We can either pass userIds as prop, which will create an array with multiple users as recipients
// Or pass courseInstructorId, which will create a single array with the course instructors of a specific course as recipient
// If none of the above is passed, the recipients will be set to an empty array
export const resolveRecipients = (
  usersIds?: number[],
  courseInstructorId?: number,
): Omit<Recipient, "name">[] => {
  if (usersIds) {
    return usersIds.map((id) => ({
      id,
      type: "user",
    }));
  }

  if (courseInstructorId) {
    return [
      {
        id: courseInstructorId,
        type: "instructor",
      },
    ];
  }

  return [];
};
