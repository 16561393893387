import { css } from "@emotion/react";

export const LoaderContainer = css`
  .loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 9rem;

    &.is-fullscreen {
      margin-top: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
