import React, { FC } from "react";
import { Button, Text } from "@epignosis_llc/gnosis";
import { URLS } from "@constants/urls";
import { useUIStore } from "@stores";
import { useNavigate } from "react-router-dom";
import { useApplyTranslations } from "@hooks";

export type SignInOrUpTextProps = {
  isLoginSelected: boolean;
  isModalOpen?: boolean;
};

const SignInOrUpText: FC<SignInOrUpTextProps> = ({ isLoginSelected, isModalOpen = false }) => {
  const { t } = useApplyTranslations();
  const { showSignUpModal, showSignInModal, setShowSignUpModal, setShowSignInModal } = useUIStore();
  const navigate = useNavigate();

  // If the sign in or the sign up modal are open, render the sign up or sign in, on a modal else navigate on the views
  const handleSignUpLink = (): void => {
    if (showSignInModal) {
      setShowSignInModal(false);
      setShowSignUpModal(true);
    } else navigate(URLS.signup);
  };

  const handleSignInLink = (): void => {
    if (showSignUpModal) {
      setShowSignUpModal(false);
      setShowSignInModal(true);
    } else navigate(URLS.login);
  };

  return (
    <section className="sign-in-or-up-link">
      {isLoginSelected ? (
        <>
          {!isModalOpen && (
            <Text fontSize="sm" as="div">
              {t("signIn.dontHaveAccount")}
              <Button variant="link" noGutters onClick={handleSignUpLink} type="button">
                {t("signIn.signUpHere")}
              </Button>
            </Text>
          )}
        </>
      ) : (
        <Text fontSize="sm" as="div">
          {t("signUp.alreadyHasAccount")}
          <Button variant="link" noGutters onClick={handleSignInLink} type="button">
            {t("signUp.loginExclamation")}
          </Button>
        </Text>
      )}
    </section>
  );
};

export default SignInOrUpText;
