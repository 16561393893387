import { ENDPOINTS } from "@api/endpoints";
import { BranchSwitchRes, UserGroupsRes } from "types/responses";
import HttpClient from "@api/HttpClient";
import authService from "@utils/services/AuthService";
import { InvoiceRes } from "@views/MyPayments/types";

const formContentType = {
  "Content-Type": "multipart/form-data",
};

export const getAvailableGroups = async (userId: string, queryStr = ""): Promise<UserGroupsRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.users.availableGroups(userId)}${queryStr}`);
  return res.data;
};

export const getUserInvoices = async (userId: string, queryStr = ""): Promise<InvoiceRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.users.payments(userId)}${queryStr}`);
  return res.data;
};

export const addUser = async (groupId: string, userId: string): Promise<void> => {
  const res = await HttpClient.put(`${ENDPOINTS.groups.userAction(groupId, userId)}`);
  return res.data;
};

export const postUnlockUser = async (userId: string): Promise<void> => {
  const res = await HttpClient.put(ENDPOINTS.accountAndSettings.usersSettings.unlockUser(userId));
  return res.data;
};

export const userImpersonation = async (
  userId: string,
  branchId?: string,
): Promise<BranchSwitchRes> => {
  const refreshToken = authService.getRefreshToken() as string;
  const bodyFormData = new FormData();
  bodyFormData.append("grant_type", "refresh_token");
  bodyFormData.append("client_id", "TalentPlus");
  bodyFormData.append("client_secret", "secret");
  branchId && bodyFormData.append("branch_id", branchId);
  bodyFormData.append("refresh_token", refreshToken);

  const res = await HttpClient.post(ENDPOINTS.users.impersonation(userId), bodyFormData, {
    headers: formContentType,
  });

  return res.data;
};

export const stopUserImpersonation = async (): Promise<BranchSwitchRes> => {
  const refreshToken = authService.getRefreshToken() as string;
  const bodyFormData = new FormData();
  bodyFormData.append("grant_type", "refresh_token");
  bodyFormData.append("client_id", "TalentPlus");
  bodyFormData.append("client_secret", "secret");
  bodyFormData.append("refresh_token", refreshToken);

  const res = await HttpClient.post(ENDPOINTS.users.stopImpersonation, bodyFormData, {
    headers: formContentType,
  });

  return res.data;
};
