import { css } from "@emotion/react";

export const EditorStyles = ({ editor }, { required, isFocused, hasError }) => {
  const { background, borderColor, focusColor, errorColor, placeholderColor, hoverColor } = editor;
  const border = hasError ? errorColor : isFocused ? focusColor : borderColor;
  const borderStyle = `${isFocused ? 2 : 1}px solid ${border} !important`;

  return css`
    label {
      margin-inline-start: 0.5rem;

      ${required && `&::after { content: " *"; color: ${editor.errorColor}}`}
    }

    /* Override default styles */

    /* toolbar styles */
    .fr-toolbar.fr-top {
      background: ${background};
      border: ${borderStyle};
      border-bottom: none !important;
      border-radius: 5px 5px 0 0;
    }

    .fr.ai-dropdown {
      border: none !important;

      ::after {
        border: none !important;
      }

      ::before {
        border: none !important;
      }
    }

    //same for before after
    .fr-command.fr-btn.fr.ai-dropdown::before,
    .fr-command.fr-btn.fr.ai-dropdown::after {
      border: none !important;
    }

    /* view styles */
    .fr-box.fr-basic {
      .fr-wrapper {
        border: ${borderStyle};
        border-top: none !important;
        border-bottom: none !important;

        &.show-placeholder .fr-placeholder {
          color: ${placeholderColor};
        }
      }

      .fr-second-toolbar {
        border: ${borderStyle};
        border-top: none !important;
        border-radius: 0 0 5px 5px;
      }
    }

    .fr-box.fr-inline {
      border: 1px solid transparent;
      border-radius: 5px;

      .fr-wrapper {
        &.show-placeholder .fr-placeholder {
          color: ${placeholderColor};
        }
      }

      &.fr-code-view {
        padding: 1.75rem 0.75rem 0.75rem;
        border-color: ${focusColor};

        .fr-command.html-switch {
          padding: 12px 8px;
          top: 0.5rem;
          inset-inline-end: 0.5rem;
          border-radius: 5px;

          &:hover {
            background: ${hoverColor};
          }

          i {
            font-size: 1rem;
          }
        }
      }
    }
  `;
};

export const AIDropdownListStyles = (position) => {
  return css`
    position: absolute;
    top: ${position.y}px;
    left: ${position.x}px;
    z-index: 10000;
    .ai-dropdown {
      width: 250px;
      font-family: Arial, sans-serif;
      font-size: 14px;
      color: #333;
      background: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 5px;
    }
    /* Style for the.ai-dropdown list */
    .ai-dropdown-list {
      padding: 8px 0;
      max-height: 400px;
      overflow-y: auto;
      list-style-type: none;
      margin: 0;
    }

    /* Style for each.ai-dropdown item */
    .ai-dropdown-item {
      display: flex;
      align-items: center;
      padding: 2px 12px;
      cursor: pointer;
    }

    /* Style for the icons inside the items */
    .icon {
      font-size: 16px;
    }

    .aiSubmenuButton {
      padding: 0;
      outline: none;
      position: relative;
      width: 100%;
      border: none;
      background-color: inherit;
      font-family: "Mulish", sans-serif;
      font-size: 14px;
      text-align: left;
      transition: 0.3s;

      &.locked {
        cursor: default !important;
      }

      .content {
        border-radius: 5px;
        height: 40px;
        width: 100%;
        padding: 8px 12px;
        display: inline-flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
      }

      .outline-content {
        border: 1px solid rgba(217, 217, 217, 0.2);
      }

      .title {
        display: inline-flex;
        align-items: center;
        flex-grow: 1;
      }

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: contain;
        vertical-align: middle;
      }

      :hover .content {
        background: #74b0ea40;
        transition: 0.3s;
      }
    }
  `;
};
