import { css } from "@emotion/react";

export const tableContainer = css`
  table tbody tr td {
    padding: 0rem 1.5rem;
  }

  .empty-state-container {
    button {
      padding: 0 !important;
    }

    svg {
      height: 100%;
    }
  }

  .table-actions-container {
    position: relative;

    .hover-actions {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      background: inherit;
      opacity: 0;
    }

    .actions-icon {
      position: absolute;
      inset-inline-end: 0;
      top: 0;
      opacity: 0;
    }

    .is-visible {
      opacity: 1;
    }

    &:focus-within {
      .hover-actions {
        opacity: 1;
      }
    }
  }

  .table-footer-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;

    .pagination-wrapper {
      margin: 0;
    }

    .pagination-section {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    .export-to-csv-btn {
      color: black;
    }
  }
`;

export const emptyStateContainer = css`
  .body {
    margin-top: 0;
  }
`;

export const tableSelectStyles = () => css`
  // This is the React-select control container that should have transparent background and on hover it should be white.
  // This functionality might be moved to the design system in the future if we need this coloring in another table.
  .control-md {
    background: transparent;

    &:hover {
      background-color: #fff;
    }

    &:not(&:hover) {
      border: 1px solid transparent;
    }
  }

  .select-input-wrapper {
    background: none !important;
  }

  .table-select {
    border: none;
    cursor: pointer;
  }
`;
