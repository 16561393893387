import { palletColorsGenerator } from "../utils";
import { COLORS } from "../constants";
import { getGnosisTheme } from "./gnosis";
import { getPlusTheme } from "./plus";

export const THEME_COLOURS = {
  ...COLORS,
  primary: "#2CD1F8",
};

const colors = palletColorsGenerator(THEME_COLOURS);
const defaultTurquoise = { ...getGnosisTheme(colors), ...getPlusTheme(colors) };

export default defaultTurquoise;
