import { generateTimestamp } from "@utils/helpers/general";

const CONTEXT_TIMESTAMP_KEY = "context_timestamp";
const CONTEXT_ID_KEY = "context_id";

export const generateRandomId = (): string => {
  // Create a random byte array of 8 bytes.
  const array = new Uint8Array(8);
  window.crypto.getRandomValues(array);

  // Convert the byte array to a Base64 string
  const contextId = btoa(String.fromCharCode(...Array.from(array)))
    .replace(/\+/g, "") // Replace + with '' (URL-safe)
    .replace(/\//g, "") // Replace / with '' (URL-safe)
    .replace(/=+$/, ""); // Remove any trailing '='

  return contextId;
};

export const hasTwoHoursPassed = (timestamp: number): boolean => {
  const currentTime = Math.floor(Date.now() / 1000); // Get current time in seconds
  return currentTime - timestamp >= 7200; // Check if 2 hours have passed
};

class LogTraceIdService {
  // Generate trace identifier context ID and its timestamp for later validation.
  generateContextId(): void {
    localStorage.setItem(CONTEXT_TIMESTAMP_KEY, generateTimestamp().toString());
    localStorage.setItem(CONTEXT_ID_KEY, generateRandomId());
  }

  generateXRequestId(): string {
    const contextId = this.getContextId();
    const traceId = generateRandomId();
    const xRequestId = `00-${contextId}-${traceId}`;

    return xRequestId;
  }

  getContextId(): string | null {
    return localStorage.getItem(CONTEXT_ID_KEY);
  }

  getContextIdTimeStamp(): string | null {
    return localStorage.getItem(CONTEXT_TIMESTAMP_KEY);
  }

  isContextIdExpired(): boolean | null {
    const timestamp = this.getContextIdTimeStamp() ?? "";
    if (!timestamp) return false; // Consider it valid if no timestamp

    return hasTwoHoursPassed(parseInt(timestamp, 10));
  }
}

const logTraceIdService = new LogTraceIdService();

export default logTraceIdService;
