import React, { FC, useState } from "react";
import { DragAndDropArea, FileInput } from "@components";
import { buildFileValidations, errorNotification, getFileValidations } from "@utils/helpers";
import { UseMutateFunction } from "react-query";
import { fileUploadAreaStyles } from "./styles";
import { IconType } from "types/common";
import {
  DEFAULT_VALIDATION_TYPES,
  ValidationType,
  getExtraValidExtensions,
  getFilesValidation,
} from "./helpers";
import { usePostCourseFileProps } from "@views/CourseEdit/hooks";
import { useApplyTranslations } from "@hooks";
import { FileValidationsObj } from "types/entities";

const MAX_ALLOWED_FILES = 100;

type FileUploadAreaProps = {
  id?: string;
  title?: string;
  subtitle?: string;
  maxFiles?: number;
  icon?: IconType;
  isLoading?: boolean;
  attachments?: File[];
  postFileMutation?: UseMutateFunction<unknown, unknown, usePostCourseFileProps, unknown>;
  validationTypes?: ValidationType[];
  showRules?: boolean;
  isFileInputReadonly?: boolean;
  hideBorder?: boolean;
  handleFilesUpload?: (files: File[]) => void;
  children?: React.ReactNode;
};

const FileUploadArea: FC<FileUploadAreaProps> = (props): JSX.Element => {
  const { t } = useApplyTranslations();
  const {
    id = "upload-course-file",
    title,
    maxFiles = MAX_ALLOWED_FILES,
    subtitle,
    icon,
    isLoading,
    attachments = [],
    handleFilesUpload,
    postFileMutation,
    validationTypes = DEFAULT_VALIDATION_TYPES,
    showRules = false,
    isFileInputReadonly = false,
    hideBorder = false,
    children,
  } = props;
  const [droppedAttachments, setDroppedAttachments] = useState<FileList | null>(null);
  const [uploadingFilesCount, setUploadingFilesCount] = useState<number>(0);
  let mimeTypeAndFilesizeValidations: FileValidationsObj;
  let extraValidExtensions: string[] = [];

  if (typeof validationTypes === "string") {
    const validations = getFileValidations("message.attachments");
    mimeTypeAndFilesizeValidations = buildFileValidations(validations);
  } else {
    mimeTypeAndFilesizeValidations = getFilesValidation(validationTypes);
    extraValidExtensions = getExtraValidExtensions(validationTypes);
  }

  const handleFilesChanged = (files: File[]): void => {
    setDroppedAttachments(null);
    setUploadingFilesCount(files.length);

    files.forEach((file, index) => {
      // Checking the last file to be uploaded so we can toggleDrawer on success.
      const isLastFile = index + 1 === files.length;
      postFileMutation && postFileMutation({ file, isLastFile });
    });
  };

  const onFilesChange = (files: File[]): void => {
    if (handleFilesUpload) {
      setDroppedAttachments(null);
      handleFilesUpload(files);
    } else {
      handleFilesChanged(files);
    }
  };
  const handleFilesDrop = (files: FileList): void => {
    setDroppedAttachments(files);
  };

  const handleFileError = (error: string): void => {
    errorNotification(`${error}`);
  };

  return (
    <span css={fileUploadAreaStyles}>
      <DragAndDropArea
        className="upload-prompt-area"
        maxFiles={maxFiles}
        attachments={attachments}
        mimeTypeAndFilesizeValidations={mimeTypeAndFilesizeValidations}
        preventDrop={attachments.length >= maxFiles || isLoading}
        showRules={showRules}
        onFilesDrop={handleFilesDrop}
      >
        <FileInput
          id={id}
          name="files"
          maxFiles={maxFiles}
          mimeTypeAndFilesizeValidations={mimeTypeAndFilesizeValidations}
          acceptedFileExtensions={extraValidExtensions}
          selectedFiles={attachments}
          addedFiles={droppedAttachments}
          isLoading={isLoading}
          isLoadingText={t("general.uploadingFile", { count: uploadingFilesCount })}
          icon={icon}
          title={title}
          subtitle={subtitle}
          isReadonly={isFileInputReadonly}
          hideBorder={hideBorder}
          onFileError={handleFileError}
          onFilesChange={onFilesChange}
        >
          {children}
        </FileInput>
      </DragAndDropArea>
    </span>
  );
};

export default FileUploadArea;
