import { configResponsive } from "ahooks";
import { Map } from "types/common";

// Screen
export type ScreenSizes = {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
  xxl: number;
  "3xl": number;
};

export const breakpoints: ScreenSizes = {
  xs: 320,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
  "3xl": 1920,
} as const;

export const mq: Map<string> = Object.keys(breakpoints).reduce(
  (acc, breakpointName) => ({
    ...acc,
    [breakpointName]: `@media screen and (min-width: ${breakpoints[breakpointName]}px)`,
  }),
  {},
);

// Configures useResponsive hook
configResponsive(breakpoints);
