export const getPadding = (
  isQuickActions: boolean,
  isReportPage: boolean,
  isInsideAchievementsWidget: boolean,
): string => {
  if (isReportPage && !isInsideAchievementsWidget) {
    return "unset";
  } else if (isQuickActions || isInsideAchievementsWidget) {
    return "0.45rem 1rem";
  } else if (!isReportPage && !isInsideAchievementsWidget) {
    return "0.625rem 0rem";
  } else {
    return "unset";
  }
};

export const getViewBox = (id: string, iconHeight: number): string | undefined => {
  const viewBoxMap = {
    completed_units: `-8 -8 ${iconHeight} ${iconHeight}`,
    units_in_progress: `-1 0 ${iconHeight} ${iconHeight}`,
    units_not_passed: `-5 -3 ${iconHeight} ${iconHeight}`,
    courses_not_passed: `-4 -4 ${iconHeight} ${iconHeight}`,
    learners_not_passed: `-4 -4 ${iconHeight} ${iconHeight}`,
  };

  return viewBoxMap[id] || undefined;
};
