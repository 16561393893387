import React, { FC, memo } from "react";
import { t } from "@utils/i18n";
import { ProgressBar, Text } from "@epignosis_llc/gnosis";
import { CourseProgress } from "types/entities";
import { SerializedStyles } from "@emotion/react";
import { EmptyCell } from "@components";
import { courseCompletionProgressBar } from "./styles";

export type ProgressStatusCellProps = {
  isInstructorInCourse?: boolean;
  completionStatus: CourseProgress["completion_status"];
  completionPercentage: number;
};

const ProgressStatusCell: FC<ProgressStatusCellProps> = ({
  isInstructorInCourse,
  completionStatus,
  completionPercentage = 0,
}) => {
  if (isInstructorInCourse) return <EmptyCell />;

  const isFailure = completionStatus === "failed";
  const displayedText = resolveDisplayedText(completionStatus, completionPercentage);

  return (
    <div className="progress-cell">
      <Text fontSize="sm" weight="400" className="no-wrap">
        {displayedText}
      </Text>
      <ProgressBar
        percent={!isFailure ? completionPercentage : 0}
        rounded={true}
        css={(theme): SerializedStyles =>
          courseCompletionProgressBar(theme, {
            failed: isFailure,
          })
        }
      />
    </div>
  );
};

export default memo(ProgressStatusCell);

const resolveDisplayedText = (
  completionStatus: CourseProgress["completion_status"],
  completionPercentage: number | null,
): string => {
  switch (completionStatus) {
    case "not_attempted":
      return t("general.progress.notStarted");
    case "incomplete":
      return `${completionPercentage}%`;
    case "completed":
      return t("general.progress.completed");
    case "failed":
      return t("general.progress.notPassed");
  }
};
