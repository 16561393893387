import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAIJobPolling } from "@hooks/useAIJobPolling";
import { generateCourseImage, getCourseAIJobs } from "@views/CourseEdit/api";
import { AIJobFilters } from "@views/CourseEdit/constants";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { generalNotification } from "@utils/helpers";
import { queryKeys } from "@constants/index";
import { Course } from "types/entities";
import { t } from "i18next";
import { performEditorAIAction } from "@utils/helpers/ai";
import { COURSE_DESCRIPTION_EDITOR_ID } from "@components/CourseOverview/constants";
import { useConfigurationStore } from "@stores";
import { AIGenerateTextType } from "@components/FormElements/Editor/types";
import { CourseEditAIState } from "./types";

const CourseEditAIContext = createContext<CourseEditAIState | undefined>(undefined);

type CourseEditAIProviderProps = {
  course: Course;
} & PropsWithChildren;

/**
 * A Context Provider responsible for the state of AI Course-editing capabilities.
 */
export const CourseEditAIProvider: FC<CourseEditAIProviderProps> = ({ children, course }) => {
  const [isImagePromptOpen, setIsImagePromptOpen] = useState(false);
  const [isAIImageGenerationAvailable, setIsAIImageGenerationAvailable] = useState(false);
  const [isAIWriteAboutAvailable, setIsAIDescriptionAvailable] = useState(false);
  const [isImageGenerationStarting, setIsImageGenerationStarting] = useState(false);
  const [isImageWorking, setIsImageWorking] = useState(false);
  const [isDescriptionWorking, setIsDescriptionWorking] = useState(false);

  const { userProfileData } = useConfigurationStore();

  const queryClient = useQueryClient();

  const courseId = course.id.toString();

  const showImageGenerationErrorNotification = (error?: AxiosError): void => {
    if (error?.response?.status === 409) {
      generalNotification("warning", <p>{t("ai.errors.imageGenerationAlreadyInProgress")}</p>);
      return;
    }

    generalNotification("error", <p>{t("ai.errors.ImageGenerationFailed")}</p>);
  };

  // Poll for AI jobs.
  const { course_image: courseImageJob, startPolling } = useAIJobPolling(
    true,
    (signal) => getCourseAIJobs(courseId, signal),
    AIJobFilters,
    (error) => {
      if (isImageWorking) {
        showImageGenerationErrorNotification(error);
      }
      setIsImageWorking(false);
    },
  );

  // Update permissions.
  useEffect(() => {
    setIsAIImageGenerationAvailable(course.policies?.can_generate_image_with_ai ?? false);
    setIsAIDescriptionAvailable(userProfileData?.policies?.ai?.editor?.can_write_about ?? false);
  }, [
    course.policies?.can_generate_image_with_ai,
    userProfileData?.policies?.ai?.editor?.can_write_about,
  ]);

  // Update state based on AI Job statuses.
  useEffect(() => {
    if (courseImageJob?.status === "working") {
      setIsImageGenerationStarting(false);
      setIsImageWorking(true);
      return;
    }

    setIsImageWorking(false);

    if (courseImageJob?.status === "completed") {
      queryClient.invalidateQueries([queryKeys.myCourse, courseId]);
    }
  }, [queryClient, courseId, courseImageJob, setIsImageWorking]);

  // Start image generation.
  const { mutate: generateCourseImageMutation } = useMutation(
    (aiPrompt: string) => generateCourseImage(courseId, aiPrompt),
    {
      onError: (error: AxiosError) => {
        setIsImageGenerationStarting(false);
        setIsImageWorking(false);
        showImageGenerationErrorNotification(error);
      },
      onSuccess: () => {
        setIsImageWorking(true);
        setIsImageGenerationStarting(false);
        setIsImagePromptOpen(false);
        startPolling();
      },
    },
  );

  const generateImage = useCallback(
    (prompt: string) => {
      if (isImageWorking || isImageGenerationStarting) {
        return;
      }

      setIsImageGenerationStarting(true);
      generateCourseImageMutation(prompt);
    },
    [isImageWorking, isImageGenerationStarting, generateCourseImageMutation],
  );

  const generateDescription = useCallback(
    (prompt: string): void => {
      if (isDescriptionWorking) {
        return;
      }

      const actionType: AIGenerateTextType = {
        action: "completion",
        args: {
          clearEditor: true,
        },
      };

      performEditorAIAction(COURSE_DESCRIPTION_EDITOR_ID, actionType, prompt);
    },
    [isDescriptionWorking],
  );

  const generateImageAndDescription = useCallback(
    (prompt: string) => {
      generateImage(prompt);
      generateDescription(prompt);
    },
    [generateImage, generateDescription],
  );

  const state: CourseEditAIState = useMemo(
    () => ({
      isAIImageGenerationAvailable,
      isAIDescriptionAvailable: isAIWriteAboutAvailable,
      isImagePromptOpen,
      setIsImagePromptOpen,
      isImageGenerationStarting,
      isImageWorking,
      setIsImageWorking,
      isDescriptionWorking,
      setIsDescriptionWorking,
      generateImage,
      generateImageAndDescription,
    }),
    [
      isAIImageGenerationAvailable,
      isAIWriteAboutAvailable,
      isImagePromptOpen,
      isImageGenerationStarting,
      isImageWorking,
      isDescriptionWorking,
      generateImage,
      generateImageAndDescription,
    ],
  );

  return <CourseEditAIContext.Provider value={state}>{children}</CourseEditAIContext.Provider>;
};

export const useCourseEditAI = (): CourseEditAIState => {
  const context = useContext(CourseEditAIContext);
  if (!context) {
    throw new Error("useCourseEditAI must be used within CourseEditAIProvider");
  }

  return context;
};
