import { getTask, deleteTask } from "@api/tableExports";
import queryKeys from "@constants/queryKeys";
import { calculateExponentialBackoff, generalNotification } from "@utils/helpers";
import { UseMutateFunction, UseMutationResult, useMutation } from "react-query";
import { UseDeleteTaskMutationParams } from "./types";
import { getCSVFile } from "@utils/helpers/exportToCsv";
import { Dispatch, SetStateAction, useRef } from "react";
import { t } from "@utils/i18n";
import { TableCSVExportRes } from "types/responses";

const TWENTY_SECONDS = 20000;
type TaskMutationType = UseMutateFunction<TableCSVExportRes, unknown, string, unknown>;

// *** Reusable mutation hooks about getTask and deleteTask for TableCSVExport *** //

export const useGetTaskMutation = (
  setIsExportModalOpen: Dispatch<SetStateAction<boolean>>,
  setExportProgress: Dispatch<SetStateAction<number>>,
): {
  getTaskMutation: TaskMutationType;
  resetRefs: () => void;
} => {
  const pingCountRef = useRef<number>(1);
  const delayRef = useRef<number>(0);
  const lastUpdateRef = useRef<number>(0);
  const previousProgressRef = useRef<number>(0);

  const resetRefs = (): void => {
    pingCountRef.current = 1;
    delayRef.current = 0;
    lastUpdateRef.current = 0;
    previousProgressRef.current = 0;
  };

  const handleFailedTask = (): void => {
    setExportProgress(0);
    setIsExportModalOpen(false);
    resetRefs();
    generalNotification("error", t("general.somethingWentWrongPleaseTryAgain"));
  };

  const { mutate: getTaskMutation } = useMutation(
    [queryKeys.tableExports.task],
    (taskId: string) => getTask(taskId),
    {
      onSuccess: (res) => {
        const { id, status, progress, download_url } = res._data;
        setExportProgress(progress);

        if (status === "completed" && download_url) {
          getCSVFile(download_url);

          resetRefs();

          setTimeout(() => {
            setExportProgress(0);
            setIsExportModalOpen(false);
          }, 2000);
        }

        if (status === "failed") handleFailedTask();

        // If status is processing or created
        if (status !== "completed" && status !== "cancelled" && status !== "failed") {
          // This is for the initialization only
          if (pingCountRef.current === 1) lastUpdateRef.current = new Date().getTime();

          // If the progress changes set the last update variable to the current time
          if (progress !== previousProgressRef.current) {
            const newDate = new Date();
            lastUpdateRef.current = newDate.getTime();
          }

          const exponentialDelay = calculateExponentialBackoff(pingCountRef.current);
          pingCountRef.current++;
          delayRef.current = exponentialDelay;

          setTimeout(() => {
            const newDate = new Date();

            // Check if progress is not changing in a span of 20 seconds

            if (newDate.getTime() - lastUpdateRef.current > TWENTY_SECONDS) {
              handleFailedTask();
            } else {
              // Set the previous progress
              previousProgressRef.current = progress;

              getTaskMutation(id);
            }
          }, delayRef.current);
        }
      },
    },
  );

  return { getTaskMutation, resetRefs };
};

export const useDeleteTaskMutation = ({
  setIsExportModalOpen,
  setExportProgress,
  resetRefs,
}: UseDeleteTaskMutationParams): UseMutationResult<TableCSVExportRes, unknown, string, unknown> => {
  return useMutation([queryKeys.tableExports.task], (taskId: string) => deleteTask(taskId), {
    onSuccess: () => {
      setExportProgress(0);
    },
    onSettled: () => {
      resetRefs();
      setIsExportModalOpen(false);
    },
  });
};
