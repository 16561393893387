import { t } from "@utils/i18n";
import { yup } from "./validation";

export const OnboardingInitialStepsSchemas = {
  step1: yup.object().shape({
    usage_types: yup.array().of(yup.string()).min(1).max(3).required(),
  }),
  step2: yup.object().shape({
    company_size: yup.string().trim().required(),
  }),
  step3: yup.object().shape({
    industry: yup
      .string()
      .trim()
      .required(() => t("validationMessages.requiredField")),
    department: yup
      .string()
      .trim()
      .required(() => t("validationMessages.requiredField")),
  }),
  step4: yup.object().shape({
    address: yup.object().shape({
      country: yup.string().trim().required(),
      city: yup
        .string()
        .trim()
        .max(50, () => t("errors.general.valueTooLong", { count: 50 }))
        .required(),
      home_address: yup
        .string()
        .trim()
        .max(50, () => t("errors.general.valueTooLong", { count: 50 }))
        .required(),
      zip: yup
        .string()
        .trim()
        .max(50, () => t("errors.general.valueTooLong", { count: 50 }))
        .required(),
    }),
  }),
};

export const OnboardingAISchemas = yup.object({
  aiInput: yup
    .string()
    .required(() => t("validationMessages.requiredField"))
    .min(5, () => t("errors.general.valueTooShort", { count: 5 }))
    .max(1000, () => t("errors.general.valueTooLong", { count: 1000 })),
});
