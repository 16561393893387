// Packages or third-party libraries
import React, { FC, useEffect } from "react";
import { Tabs } from "@epignosis_llc/gnosis";

// Styles
import { leaderBoardContainer } from "./styles";

// Components
import RewardsTab from "./RewardsTab";
import { Skeletons, UserStats } from "@components";

// Hooks
import { useApplyTranslations } from "@hooks";

// Utils
import { sortLeaders } from "@utils/helpers";

// Constants
import { TAB_TITLES } from "./constants";

// Other imports
import { Leaderboard as LeaderboardType } from "types/entities";
import { LeaderboardTableProps } from "./types";
import { SerializedStyles } from "@emotion/react";

const LeaderboardTable: FC<LeaderboardTableProps> = ({
  selectedTab,
  leaderboardTabs,
  rewardsTab,
  gamificationError,
  gamificationStatus,
  globalDiscount,
  setSelectedTab,
}) => {
  const { t } = useApplyTranslations();

  const renderTabs = (): {
    title: string;
    content: React.JSX.Element;
  }[] => {
    const tempLeaderboardTabs = leaderboardTabs.map(({ title, data }) => {
      return {
        title: t(TAB_TITLES[title]),
        content: (
          <UserStats
            title={title}
            stats={sortLeaders(data as LeaderboardType)}
            isGamificationDrawer={true}
          />
        ),
      };
    });

    const tempRewardsTab = {
      title: t(TAB_TITLES[rewardsTab.title]),
      content: <RewardsTab userRewards={rewardsTab.data} global={globalDiscount ?? 0} />,
    };

    return [...tempLeaderboardTabs, tempRewardsTab];
  };

  // Reset active tab when gamification settings change, to not point in a hidden tab
  useEffect(() => {
    if (selectedTab === leaderboardTabs.length) {
      setSelectedTab(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaderboardTabs.length]);

  return (
    <div
      css={(): SerializedStyles => leaderBoardContainer({ isGamificationDrawer: true })}
      className="gamification-container"
    >
      <Skeletons.Loader status={gamificationStatus} error={gamificationError}>
        <div className="content-container">
          {leaderboardTabs.length > 0 && (
            <Tabs
              selectedTab={selectedTab}
              className="leaderboard-tabs"
              onChangeTab={setSelectedTab}
              tabs={renderTabs()}
            />
          )}
        </div>
      </Skeletons.Loader>
    </div>
  );
};

export default LeaderboardTable;
