import { css } from "@emotion/react";

export const textWithIconContainer = (
  { textWithIcon, icon },
  { iconColor, descriptionColor, noGap },
) => css`
  display: flex;
  gap: ${noGap ? "0" : "0.5rem"};
  align-items: center;

  .icon {
    display: flex;
    align-items: center;

    svg {
      color: ${textWithIcon[iconColor]};
    }
  }

  .text-with-icon-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .heading {
      &-black {
        color: ${textWithIcon.headingBlack};
      }

      &-grey {
        color: ${textWithIcon.headingGrey};
      }

      &-white {
        color: ${textWithIcon.headingWhite};
      }
    }

    .description-container {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      color: ${textWithIcon[descriptionColor]};

      .tooltip-content {
        display: flex;
        color: ${icon.primary};
      }
    }
  }
`;
