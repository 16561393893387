import { yup } from "./validation";
import { t } from "@utils/i18n";

export const NewMessageFormValidationSchema = yup.object().shape({
  recipients: yup.array().min(1),
  subject: yup.string().trim().required().max(150),
  content: yup.string().trim().max(65000),
});

export const MessageReplyFormValidationSchema = yup.object().shape({
  recipients: yup.array().min(1),
  content: yup.string().trim().max(65000),
});

export const MessageForwardFormValidationSchema = yup.object().shape({
  recipients: yup.array().min(1),
  content: yup.string().trim().max(65000),
});

export const CustomReportNewMessageFormValidationSchema = yup.object().shape({
  subject: yup.string().trim().required().max(150),
  body: yup
    .string()
    .trim()
    .required()
    .test(
      "maxHtmlStrippedLength",
      () => t("validationMessages.maxCharacters", { number: 65000 }),
      (value) => {
        const plainText = value?.replace(/<[^>]*>/g, "").replace(/"&nbsp;"/g, "") || "";
        return plainText.length <= 65000;
      },
    ),
});
