import { css } from "@emotion/react";
import { distanceFromTooltip, fixTooltipHeight } from "@views/Skills/styles";

export const header = () => css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .skill-filters-button {
    margin-left: 0;
  }

  .assignSkillsButton {
    margin-left: auto;
  }
`;

export const actionsCell = ({ skills }) => css`
  display: flex;
  align-items: center;

  .item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .divider {
    padding: 0 0.75rem;
    color: ${skills.separator};
  }
`;

export const talbleHeaderWithInfoTooltipStyles = ({ icon }) => css`
  display: flex;
  gap: ${distanceFromTooltip};
  align-items: center;

  ${fixTooltipHeight()}

  // Using an id to override inherited styles
  #tooltip-icon {
    position: unset;
    top: unset;
    transform: unset;

    cursor: help;
    color: ${icon.primary};
  }
`;
