import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const feedbackBoxStyles = ({ colors, feedbackBox }) => css`
  --expandButtonWidth: -24.0625rem;
  --boxBorderRadius: 0.3125rem;
  position: fixed;
  top: 60%;
  inset-inline-end: var(--expandButtonWidth);
  transform: translateY(-50%);
  z-index: 1000;
  transition: inset-inline-end 0.5s ease-in-out;
  width: 100%;
  height: fit-content;
  border-radius: var(--boxBorderRadius);
  box-shadow: 0 0.1875rem 0.375rem 0 ${feedbackBox.boxShadow};

  &.show-modal {
    inset-inline-end: 0;
  }

  .feedback-box-content {
    height: 100vh;
    background: ${colors.white};

    ${mq["md"]} {
      border-radius: 0 var(--boxBorderRadius) 0;
      height: auto;
      position: relative;
      background: ${colors.white};
      z-index: 10;
    }
  }

  ${mq["md"]} {
    top: 50%;
    inset-inline-end: var(--expandButtonWidth);
    max-width: 24.0625rem;
    inset-inline-start: auto;
    bottom: 20%;
    background: ${colors.white};
    padding: 0;
  }

  .feedback-box-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${feedbackBox.borderColor};
    background: ${feedbackBox.background};
    padding: 1rem 1.5rem;
    justify-content: space-between;

    ${mq["md"]} {
      border-radius: var(--boxBorderRadius) 0 0;
    }
    .feedback-box-title {
      display: flex;
      align-items: center;
      .prefix-icon {
        flex-shrink: 0;
      }
    }

    .close {
      &-button {
        color: ${colors.white};
        display: inline-block;
        position: relative;
        min-width: inherit;
        width: auto;
        height: auto;
        top: auto;
        inset-inline-start: auto;
        transform: translateX(0);
        padding: 0;
        cursor: pointer;
        border-radius: 0;
        background: none;
      }

      &-icon {
        path {
          fill: currentColor;
        }
      }
    }
  }
`;

export const overlayStyles = ({ feedbackBox }) => css`
  position: fixed;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  background-color: ${feedbackBox.overlayBackground};
  z-index: 999;

  ${mq["md"]} {
    display: none;
  }
`;

export const floatedButtonStyles = ({ colors, feedbackBox }) => css`
  .floated-feedback-button {
    border-radius: 0.3125rem 0.3125rem 0 0;
    position: absolute;
    inset-inline-start: -86px;
    top: 40%;
    transform: translateY(-50%) rotate(-90deg);
    background-color: ${feedbackBox.background};
    color: ${colors.white};
    transition: inset-inline-start 0.2s ease-in-out;

    ${mq["md"]} {
      inset-inline-start: -76px;
      top: 50%;
    }

    .icon {
      transform: rotate(-90deg);
    }

    :hover {
      background-color: ${feedbackBox.background};
    }

    &.hover-state:hover {
      padding-bottom: 1rem;
      inset-inline-start: -90px;
      background: ${feedbackBox.background};
      height: 4rem;
    }
  }
`;
