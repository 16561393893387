import React, { FC, ReactNode } from "react";
import Lottie from "react-lottie";
import { Heading, Text } from "@epignosis_llc/gnosis";

import { feedbackResultStyles } from "./FeedbackResult.styles";

import failureAnimationData from "@assets/animated/feedback-mechanism-error.json";
import successAnimationData from "@assets/animated/feedback-mechanism-success.json";
import { useApplyTranslations } from "@hooks";

type FeedbackResultProps = {
  status?: "success" | "error";
  children?: ReactNode;
};

const FeedbackResult: FC<FeedbackResultProps> = ({ status, children }) => {
  const { t } = useApplyTranslations();

  if (!status) {
    return <>{children}</>;
  }

  const feedbackConfig = {
    success: {
      title: t("general.thankYou"),
      description: t("feedbackMechanism.successFeedbackMessage"),
      animationData: successAnimationData,
    },
    error: {
      title: t("errors.general.somethingWentWrong"),
      description: t("feedbackMechanism.somethingWentWrongMessage"),
      animationData: failureAnimationData,
    },
  };

  const animationConfig = feedbackConfig[status];
  const { title, description, animationData } = animationConfig;

  const lottieOptions = {
    animationData: animationData,
    loop: 2,
    autoplay: true,
  };

  return (
    <div css={feedbackResultStyles}>
      <Lottie options={lottieOptions} height={250} width={250} />
      <div className="feedback-result-body">
        <Heading as="h2" data-testid="feedback-result-title">
          {title}
        </Heading>
        <Text fontSize="md" as="p" data-testid="feedback-result-info">
          {description}
        </Text>
      </div>
    </div>
  );
};

export default FeedbackResult;
