import React, { FC } from "react";
import { contentStyles } from "./styles";

export type ContentProps = {
  children: React.ReactNode;
};

const Content: FC<ContentProps> = ({ children }) => (
  <div css={contentStyles}>
    <div className="content-container">{children}</div>
  </div>
);

export default Content;
