import { css } from "@emotion/react";
import { typeScale } from "@epignosis_llc/gnosis";
import { mq } from "@utils/breakpoints";

const CONTAINER_MAX_WIDTH = 1032;

export const courseContentStyles = css`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 1;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    flex: 1;
  }

  .description-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
  }
`;

export const mobileCourseContentStyles = ({ courseOverview }) => css`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  .course-information-wrapper {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .progress-wrapper {
    padding: 0 0.5rem;
    text-align: center;

    .progress-info-tag {
      background: 0;
      padding: 0;
    }
  }

  .expiration-wrapper {
    text-align: center;

    .expiration-info {
      background: none;
      padding: 0;
    }
  }

  .rating-wrapper {
    display: flex;
    justify-content: center;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
  }

  .description-container {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
  }

  .instructor-text-info {
    text-align: center;
    text-decoration: underline;
  }

  .mobile-footer {
    display: flex;
    align-items: center;
    bottom: 0;
    position: sticky;
    top: 0;
    z-index: 11;
    max-height: 72px;
    height: 72px;
    background: white;
    border-top: 1px solid ${courseOverview.footerBorder};
    padding: 1rem;
  }
`;

export const customFieldsStyles = ({ courseOverview }, { canEditOptions }) => css`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  &.is-mobile-view {
    overflow-x: auto;
    white-space: nowrap;
    flex-wrap: nowrap;
  }

  .custom-field-tag {
    cursor: ${canEditOptions ? "pointer" : "default"};
    background-color: ${courseOverview.customFieldTag.background};
    color: ${courseOverview.textColor};
    transition: background-color 0.2s ease-in;

    &:hover {
      background-color: ${canEditOptions
        ? courseOverview.customFieldTag.hoverBackground
        : courseOverview.customFieldTag.background};
    }
  }
`;

export const descriptionStyles = (_, { showMore, customLineClamp }) => css`
  display: flex;
  flex-direction: column;
  align-items: start;

  .description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: ${showMore ? "block" : "-webkit-box"};
    -webkit-line-clamp: ${customLineClamp};
    line-clamp: ${customLineClamp};
    -webkit-box-orient: vertical;
    word-break: break-word;
  }
`;

export const certificateStyles = ({ courseOverview, courseEdit }) => css`
  position: relative;

  &:before {
    content: "";
    width: 100%;
    height: 82px;
    background-color: ${courseOverview.certificate.background};
    position: absolute;
    top: 35%;
    transform: translateY(-50%);
    z-index: 1;
    border-inline-start: 1px solid ${courseOverview.borderLeft};

    ${mq["sm"]} {
      height: 112px;
      top: 50%;
    }
  }

  .certificate-container {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    max-width: ${CONTAINER_MAX_WIDTH}px;
    display: flex;
    margin: auto;
    padding: 1rem;
    text-align: center;

    ${mq["sm"]} {
      flex-direction: row;
      gap: 2rem;
      text-align: start;
    }

    .certificate-text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 0.5rem;
      z-index: 2;

      a {
        text-decoration: underline;
      }
    }

    .certificate-image-container {
      z-index: 10;

      .certificate-image {
        svg {
          z-index: 2;
          box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
          border-radius: 5px;
        }
      }
    }

    .upload-overlay {
      width: 262px;
      height: 185px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
      color: white;
      background-color: ${courseEdit.courseCertificateOverlayBackground};
      transition: opacity 0.2s linear;
      opacity: 0;
      border-radius: 5px;

      &:hover {
        opacity: 1;
      }

      button {
        width: 74px;
        height: 74px;
        cursor: pointer;
      }
    }
  }
`;

export const tabsSectionStyles = () => css`
  width: 100%;

  .tabs-section {
    position: relative;
  }
`;

export const completionTextStyles = ({ courseOverview }, { canEditOptions }) => css`
  position: absolute;
  top: 1.6rem;
  inset-inline-end: 1rem;
  z-index: 1;
  color: ${courseOverview.disabled};
  cursor: ${canEditOptions ? "pointer" : "default"};

  &:hover {
    color: ${canEditOptions ? courseOverview.completionTextHoverColor : courseOverview.disabled};
  }
`;

export const emptyResultsStyles = css`
  height: 9rem;

  .body {
    margin-top: 1rem;

    h2 {
      font-size: ${typeScale["sm"]};

      ${mq["sm"]} {
        font-size: ${typeScale["md"]};
      }
    }
  }
`;

export const courseTabStyles = css`
  padding-top: 1rem;

  .upload-prompt-area.dragging {
    min-height: 15rem;
  }

  .upload-loader {
    margin-top: 1rem;
  }
`;

export const prerequisitesStyles = ({ courseOverview }, { canEditOptions, hideBorder }) => css`
  background: ${courseOverview.prerequisites.background};
  border-inline-start: ${hideBorder ? "none" : `1px solid ${courseOverview.borderLeft}`};

  .prerequisites-container {
    max-width: ${CONTAINER_MAX_WIDTH}px;
    margin: 0 auto;
    padding: 2rem 1rem 1rem;

    .title-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 0.25rem;

      .section-title {
        width: fit-content;
        margin-bottom: 0.25rem;
        cursor: ${canEditOptions ? "pointer" : "default"};

        &:hover {
          color: ${canEditOptions
            ? courseOverview.prerequisites.textHoverColor
            : courseOverview.prerequisites.textColor};
        }
      }

      .section-subtitle {
        color: ${courseOverview.disabled};
      }
    }

    .rule-sets-container {
      margin-top: 2rem;
      margin-bottom: -2rem;

      .prerequisite-path-item {
        top: calc(-2rem + 0.5rem);
        margin-bottom: 0.5rem;

        a {
          &:hover {
            color: initial;
          }

          &:not(.readonly) .prerequisite-content:hover {
            background-color: ${courseOverview.prerequisites.hover};
          }
        }

        .prerequisite-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 0.5rem;
          padding: 1rem;
          border-radius: 5px;

          .icon {
            line-height: 0;
          }
        }
      }

      .divider {
        position: relative;
        top: -1.25rem;
      }
    }
  }
`;

export const footerStyles = css`
  width: 100%;

  .footer-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;

    .footer-container-end {
      display: flex;
      align-items: center;
      gap: 1rem;

      a {
        line-height: 0;
      }
    }
  }
`;
