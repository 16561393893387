import { Tag } from "@epignosis_llc/gnosis";
import React, { FC } from "react";
import { betaTagStyles } from "./styles";
import { Interpolation, Theme } from "@emotion/react";
import { useApplyTranslations } from "@hooks";

type BetaTagProps = {
  addtionalStyles?: Interpolation<Theme>;
};

export const BetaTag: FC<BetaTagProps> = ({ addtionalStyles }) => {
  const { t } = useApplyTranslations();
  return (
    <Tag className="beta-tag" css={[betaTagStyles, addtionalStyles]}>
      {t("skills.beta").toUpperCase()}
    </Tag>
  );
};
