// TYPE ASSERTIONS
export const assertIsString = (value: unknown): asserts value is string => {
  if (typeof value !== "string") {
    throw new Error("Value is not a string");
  }
};

export const assertIsNumber = (value: unknown): asserts value is number => {
  if (typeof value !== "number") {
    throw new Error("Value is not a number");
  }
};

export const assertIsBoolean = (value: unknown): asserts value is boolean => {
  if (typeof value !== "boolean") {
    throw new Error("Value is not a boolean");
  }
};

export const assertIsArray = (value: unknown): asserts value is unknown[] => {
  if (!Array.isArray(value)) {
    throw new Error("Value is not an array");
  }
};

export const assertIsObject = (value: unknown): asserts value is Record<string, unknown> => {
  if (typeof value !== "object" || value === null || Array.isArray(value)) {
    throw new Error("Value is not an object");
  }
};

export const assertIsFunction = (
  value: unknown,
): asserts value is (...args: unknown[]) => unknown => {
  if (typeof value !== "function") {
    throw new Error("Value is not a function");
  }
};

export const assertIsDefined = <T>(value: T): asserts value is NonNullable<T> => {
  if (value === undefined || value === null) {
    throw new Error(`Value is not defined`);
  }
};

export const assertIsDefinedAndNotNull = <T>(
  value: T,
): asserts value is Exclude<T, null | undefined> => {
  if (value === undefined) {
    throw new Error(`Value is not defined`);
  }
};

// TYPE GUARDS
export const isString = (value: unknown): value is string => typeof value === "string";

export const isNumber = (value: unknown): value is number => typeof value === "number";

export const isBoolean = (value: unknown): value is boolean => typeof value === "boolean";

export const isArray = (value: unknown): value is unknown[] => Array.isArray(value);

export const isObject = (value: unknown): value is Record<string, unknown> =>
  typeof value === "object" && value !== null && !Array.isArray(value);

export const isFunction = (value: unknown): value is (...args: unknown[]) => unknown =>
  typeof value === "function";

export const isDefined = <T>(value: T): value is NonNullable<T> =>
  value !== undefined && value !== null;

export const isDefinedAndNotNull = <T>(value: T): value is Exclude<T, null | undefined> =>
  value !== undefined;
