import React, { FC, useState } from "react";
import { useApplyTranslations } from "@hooks";
import {
  formatSelectedDate,
  getDomainDateFormat,
  timeZonedDate,
  calculateCompletionDate,
} from "@utils/helpers";
import { CourseUser } from "@views/Course/CourseUsers/types";
import { completeCourse } from "@api/courses";
import { handleUserCoursesErrors } from "@errors/errors";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import { ConfirmationModal, DateInput } from "@components";
import { useConfigurationStore } from "@stores";

export type CompleteCourseModalProps = {
  selectedUser: CourseUser;
  courseId: string;
  handleInvalidateQuery: () => void;
  setIsCompleteCourseModalOpen: (open: boolean) => void;
  isCompleteCourseModalOpen: boolean;
};

const CompleteCourseModal: FC<CompleteCourseModalProps> = ({
  selectedUser,
  courseId,
  handleInvalidateQuery,
  setIsCompleteCourseModalOpen,
  isCompleteCourseModalOpen,
}) => {
  const { t } = useApplyTranslations();
  const { userProfileData } = useConfigurationStore();
  const timezone = userProfileData?.timezone ?? "UTC";
  const dateFormat = getDomainDateFormat();
  const { id, enrolled_date } = selectedUser;
  const [enrollmentDate, setEnrollmentDate] = useState<string>(enrolled_date);
  const completion_date = calculateCompletionDate(selectedUser, enrolled_date, timezone);
  const [completionDate, setCompletionDate] = useState<string | null>(completion_date);
  const userId = id.toString();

  const { mutate: completeCourseMutation } = useMutation(
    () => completeCourse(courseId.toString(), userId, enrollmentDate, completionDate),
    {
      onError: (error: AxiosError) => {
        handleUserCoursesErrors(error);
      },
      onSettled: () => {
        handleInvalidateQuery();
      },
    },
  );

  const handleCompleteCourse = (): void => {
    completeCourseMutation();
    setIsCompleteCourseModalOpen(false);
  };

  return (
    <ConfirmationModal
      id={id}
      header={t("general.completeCourse")}
      isOpen={isCompleteCourseModalOpen}
      bodyTitle={
        <>
          <DateInput
            id="enrollment-date"
            value={enrollmentDate ? timeZonedDate(enrollmentDate, timezone) : null}
            dateFormat={dateFormat}
            maxDate={completionDate ? timeZonedDate(completionDate, timezone) : undefined}
            label={t("general.enrollmentDate")}
            onChange={(selectedDate: Date | null): void =>
              setEnrollmentDate(formatSelectedDate(selectedDate, timezone) ?? "")
            }
          />
          <DateInput
            id="completion-date"
            dateFormat={dateFormat}
            value={completionDate ? timeZonedDate(completionDate, timezone) : null}
            minDate={enrollmentDate ? timeZonedDate(enrollmentDate, timezone) : undefined}
            label={t("general.completionDate")}
            onChange={(selectedDate: Date | null): void =>
              setCompletionDate(formatSelectedDate(selectedDate, timezone))
            }
          />
        </>
      }
      footerButton={t("general.save")}
      confirmationButtonColor="primary"
      onConfirm={handleCompleteCourse}
      onClose={(): void => setIsCompleteCourseModalOpen(false)}
      closeOnOutsideClick={true}
    />
  );
};

export default CompleteCourseModal;
