import { Column, TableProps } from "@epignosis_llc/gnosis";
import { t } from "@utils/i18n";
import { QueryFilter } from "types/common";
import { Pagination as IPagination } from "types/responses/index";

// Sorting and pagination
export enum CourseGroupsFilters {
  branch = "[branch_id]",
}

// Table data
export const getCourseGroupsColumns = (): Column[] => {
  return [
    {
      accessor: "name",
      cell: t("general.groups_one"),
      classNames: ["name"],
      maxWidth: 200,
    },
  ];
};

export const actionsColumn = {
  accessor: "actions",
  cell: "",
  classNames: ["hidden"],
  sortableHeader: false,
};

export const getAvailableUsersColumns = (): Column[] => {
  return [
    {
      accessor: "name",
      cell: t("general.groups_one"),
      classNames: ["name"],
      maxWidth: 100,
    },
    {
      accessor: "actions",
      cell: "",
      classNames: ["hidden"],
      sortableHeader: false,
    },
  ];
};

export const filtersSearchQueryParams = {
  pagination: { number: 1, size: 10000 },
  sorting: ["name"],
};

export const PAGINATION: IPagination = { number: 1, size: 10 };
export const DEFAULT_FILTERS: QueryFilter[] = [];
export const SORTING: TableProps["sorting"] = { column: "name", isDescending: false };
