import { PalletThemeColors, getProtectedColor } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["quickActionsWidget"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);

  return {
    activeBackgroundColor: colors.primary.base,
    backgroundColor: protectColorContrast("darker", colors.primary.light),
    hoverColor: protectColorContrast("black", colors.white),
  };
};
