import Color from "color";
import { COLORS } from "./constants";

export type Colors = Record<string, string>;

export type ThemeColors = {
  base: string;
  lighter: string;
  lightest: string;
  light: string;
  dark: string;
  darker: string;
  darkest: string;
};

export type PalletThemeColors = {
  primary: ThemeColors;
  secondary: ThemeColors;
  green: ThemeColors;
  orange: ThemeColors;
  red: ThemeColors;
  blue: ThemeColors;
  black: string;
  white: string;
};

export type FontFamily = "Mulish" | "Fredoka One";

export type TypographyLevels = keyof TypeScaleSizes;

export type Typography = Readonly<{
  baseFontSize: number;
  lineHeight: number;
  sizeRatio: number;
  levels: {
    base: string;
    up: TypographyLevels[];
    down: TypographyLevels[];
  };
}>;

type TypeScaleSizes = {
  "2xs": number;
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
  "2xl": number;
  "3xl": number;
  "4xl": number;
};

export const palletGenerator = (base: string): ThemeColors => ({
  base,
  lightest: Color(base).lighten(0.7).hex(),
  lighter: Color(base).lighten(0.5).hex(),
  light: Color(base).lighten(0.2).hex(),
  dark: Color(base).darken(0.2).hex(),
  darker: Color(base).darken(0.4).hex(),
  darkest: Color(base).darken(0.6).hex(),
});

export const getContrastColor = (hexColor: string): string => {
  return Color(hexColor).isLight() ? COLORS.black : COLORS.white;
};

export const isShadeOfWhite = (color: string): boolean => {
  const isShadeOfWhite = getContrastColor(color);
  // If getContrastColor returns black it means the shade is white.
  return isShadeOfWhite === COLORS.black;
};

// A closure that protects the contrast of the color.
export const getProtectedColor = (
  primary: string,
  colors: PalletThemeColors,
): ((state: string, base: string, opacity?: number) => string) => {
  const isLightTheme = isShadeOfWhite(primary);
  const getColor = (shade: string, opacity: number): string =>
    Color(colors.primary[shade]).alpha(opacity).string();

  return (state, base, opacity = 100): string => {
    if (!isLightTheme) return base;

    switch (state) {
      case "darker": // Mostly for backgrounds
        return getColor("darker", opacity);
      case "darkest": //  Mostly for hover colors
        return getColor("darkest", opacity);
      case "black":
        return colors.black;
      default:
        return base;
    }
  };
};

export const palletColorsGenerator = (colors: Colors): PalletThemeColors => ({
  primary: palletGenerator(colors.primary),
  secondary: palletGenerator(colors.secondary),
  green: palletGenerator(colors.green),
  orange: palletGenerator(colors.orange),
  red: palletGenerator(colors.red),
  blue: palletGenerator(colors.blue),
  black: colors.black,
  white: colors.white,
});
