import {
  OptionsType,
  OtherOptionSettings,
  PlanBasicInformation,
  PlanName,
  PlanState,
} from "./types";
import { t } from "@utils/i18n";
import { Column } from "@epignosis_llc/gnosis";

const OPTIONS_PREFIX = "billingAndSubscription.optionsPage";
export const CANCELLATION_SURVEY_PREFIX = "billingAndSubscription.cancellationSurvey";
const PLANS_PREFIX = "billingAndSubscription.plansPage";

export const CUSTOM_PLAN_CREDITS = 1000000;

export const ATHENS_DST_START_TIME = 5;
export const ATHENS_DST_END_TIME = 10;
export const ATHENS_NON_DST_START_TIME = 6;
export const ATHENS_NON_DST_END_TIME = 11;
export const ATLANTA_DST_START_TIME = 2;
export const ATLANTA_DST_END_TIME = 10;
export const ATLANTA_NON_DST_START_TIME = 3;
export const ATLANTA_NON_DST_END_TIME = 11;

export enum CancellationModalView {
  LANDING = "landing",
  SURVEY = "survey",
  FREE_PLAN = "downgrade",
  SUPPORT = "support",
}

export const timezones = {
  athens: "Europe/Athens",
  atlanta: "America/New_York",
};

export const getOtherOptionsSettings = (): OtherOptionSettings[] => [
  {
    title: t(`${OPTIONS_PREFIX}.cancellation`),
    description: t(`${OPTIONS_PREFIX}.cancellationInfo`),
    buttonText: t("billingAndSubscription.cancellationSurvey.cancelSubscription"),
    action: "cancel",
  },
  {
    title: t(`${OPTIONS_PREFIX}.transitionToFree`),
    description: t(`${OPTIONS_PREFIX}.transitionToFreeInfo`),
    buttonText: t("billingAndSubscription.cancellationSurvey.switchToFree"),
    action: "switch",
  },
];

export const getLandingPageOptions = (): OptionsType => [
  {
    question: `${CANCELLATION_SURVEY_PREFIX}.contactSupportQuestion`,
    action: CancellationModalView.SUPPORT,
    buttonLabel: `${CANCELLATION_SURVEY_PREFIX}.contactSupportButton`,
  },
  {
    question: `${CANCELLATION_SURVEY_PREFIX}.switchToFreePlanQuestion`,
    action: CancellationModalView.FREE_PLAN,
    buttonLabel: `${CANCELLATION_SURVEY_PREFIX}.switchToFreePlanButton`,
  },
  {
    question: `${CANCELLATION_SURVEY_PREFIX}.cancelSubscriptionOptionQuestion`,
    action: CancellationModalView.SURVEY,
    buttonLabel: `${CANCELLATION_SURVEY_PREFIX}.cancelSubscription`,
  },
];

export const getInvoicesColumns = (): Column[] => [
  {
    accessor: "period",
    cell: t("general.period"),
    sortableHeader: false,
  },
  {
    accessor: "plan",
    cell: t("general.plan"),
    headerWidth: 200,
    sortableHeader: false,
  },
  {
    accessor: "amount",
    cell: t("general.amount"),
    sortOrder: "desc",
    sortableHeader: false,
  },
  {
    accessor: "actions",
    cell: "",
    classNames: ["hidden"],
    sortableHeader: false,
  },
];

export const USER_VALUES_PER_PLAN: Record<Exclude<PlanName, "enterprise">, number[]> = {
  core: [40, 70, 100, 150, 200, 300, 400, 500, 600, 800, 1000],
  grow: [70, 100, 150, 200, 300, 400, 500, 600, 800, 1000],
  pro: [100, 150, 200, 300, 400, 500, 600, 800, 1000],
};

export const PLAN_NAMES = ["core", "grow", "pro"];

export const FREE_PLAN_NAME = "Free";
export const FREE_PLAN_KEY = "free";

export const REVERSE_TRIAL_PLAN_KEY = "reverse-trial";

export const defaultState: Record<PlanName, PlanState> = {
  core: {
    userNumber: USER_VALUES_PER_PLAN.core[0],
    interval: 12,
    hasFlex: false,
    hasLibrary: false,
  },
  grow: {
    userNumber: USER_VALUES_PER_PLAN.grow[0],
    interval: 12,
    hasFlex: false,
    hasLibrary: false,
  },
  pro: {
    userNumber: USER_VALUES_PER_PLAN.pro[0],
    interval: 12,
    hasFlex: false,
    hasLibrary: false,
  },
  enterprise: { userNumber: 100, interval: 12, hasFlex: false, hasLibrary: false },
};

export const withSoonTag = ["linkedin_learning"];
export const hiddenFeatures = ["talentlibrary"];

export const getBasicPlanInfo = (): { [key: string]: PlanBasicInformation } => {
  return {
    core: {
      id: "core",
      name: "Core",
      description: t(`${PLANS_PREFIX}.descriptions.core`),
      maxUsers: 100,
    },
    grow: {
      id: "grow",
      name: "Grow",
      description: t(`${PLANS_PREFIX}.descriptions.grow`),
      maxUsers: 500,
    },
    pro: {
      id: "pro",
      name: "Pro",
      description: t(`${PLANS_PREFIX}.descriptions.pro`),
      maxUsers: 1000,
    },
    enterprise: {
      id: "enterprise",
      name: "Enterprise",
      description: t(`${PLANS_PREFIX}.descriptions.enterprise`),
      maxUsers: null,
    },
  };
};

export const defaultSelectLabelRanges = {
  [`core-${defaultState.core.userNumber}`]: `1-${defaultState.core.userNumber}`,
  [`grow-${defaultState.grow.userNumber}`]: `1-${defaultState.grow.userNumber}`,
  [`pro-${defaultState.pro.userNumber}`]: `1-${defaultState.pro.userNumber}`,
};

export const EXTRA_FEATURES: { [key: string]: string[] } = {
  free: ["unlimited_email_support"],
  core: ["unlimited_courses", "unlimited_email_support"],
  grow: ["unlimited_courses", "unlimited_email_support"],
  pro: ["unlimited_courses", "unlimited_email_support"],
  enterprise: ["unlimited_courses", "unlimited_email_support"],
};

export const EXCLUDED_FROM_REGULAR_MONTHLY_PLANS: string[] = [
  "consultation_call",
  "onboarding",
  "customer_success_manager",
];

export const EXTRA_FEATURES_ON_PLAN_CARD: { [key: string]: string[] } = {
  free: ["unlimited_email_support"],
  core: ["unlimited_courses"],
  grow: [],
  pro: [],
  enterprise_placeholder: [
    "unlimited_branches",
    "talentcraft_ai_replenishing",
    "account_manager",
    "phone_support",
  ],
  enterprise: [],
};

export const EXTRA_FEATURES_ALL_FEATURES_DRAWER: { [key: string]: string[] } = {
  core: ["unlimited_courses", "unlimited_email_support"],
  grow: [],
  pro: [],
  enterprise: ["unlimited_branches", "talentcraft_ai_replenishing", "phone_support"],
};

export const LABELS_ORDER: { [key: string]: string[] } = {
  reverseTrial: [],
  free: ["unlimited_email_support"],
  core: ["talentcraft_ai_once_off", "unlimited_courses", "custom_homepage", "sso", "custom_domain"],
  grow: ["talentcraft_ai_replenishing", "unlimited_courses", "custom_reports"],
  pro: [
    "talentcraft_ai_replenishing",
    "unlimited_courses",
    "white_label",
    "automated_actions",
    "live_chat",
    "priority_email_support",
  ],
  enterprise: [
    "unlimited_branches",
    "talentcraft_ai_replenishing",
    "unlimited_courses",
    "phone_support",
  ],
};

export const FLEX_INFO =
  "https://help.talentlms.com/hc/en-us/articles/9652402239516-How-TalentLMS-subscription-plans-work";
export const LIBRARY_INFO = "https://www.talentlms.com/library/";
export const BRANCHES_INFO =
  "https://help.talentlms.com/hc/en-us/articles/360014656833-How-to-work-with-branches";
export const TALENT_LIBRARY_TEXT = "TalentLibrary"; // Not translatable
export const TALENT_CRAFT_AI_TEXT = "TalentCraft AI"; // Not translatable
export const TALENT_CRAFT_AI_TEXT_PREMIUM = "TalentCraft AI premium"; // Not translatable
export const FLEX_TEXT = "Flex"; // Not translatable
export const SKILLS_TEXT = "Skills"; // Not translatable
export const SKILLS_INFO = "https://www.talentlms.com/skills";
