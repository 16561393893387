import { t } from "@utils/i18n";
import yup from "./yup-extended";

export const DomainNameSettingsSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .matches(/^[a-z0-9]{3,50}$/, () => t("accountAndSettings.portalSettings.domainNamePattern"))
    .required(),
});
