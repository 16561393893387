// Packages or third-party libraries
import React, { FC } from "react";

// Styles
import { pointsContainer } from "./styles";

// Components
import ListItem from "./ListItem";

// Hooks & Utils
import { useConfigurationStore } from "@stores";
import { filterValidEntries } from "./helpers";
import { CollectMorePointsProps } from "./types";

const CollectMorePoints: FC<CollectMorePointsProps> = ({ isPoints }) => {
  const { gamificationSettings } = useConfigurationStore();
  const { points, levels } = gamificationSettings ?? {};

  const type = isPoints ? "points" : "levels";
  const data = isPoints ? points : levels;

  const items = filterValidEntries(data, type);

  return (
    <div css={pointsContainer}>
      <ul>
        {items.map(({ key, value, translationKey }) => (
          <ListItem key={key} value={value} translationKey={translationKey} type={type} />
        ))}
      </ul>
    </div>
  );
};

export default CollectMorePoints;
