import { GnosisTheme } from "@epignosis_llc/gnosis";
import { PalletThemeColors, getProtectedColor } from "@styles/utils";
import Color from "color";

export default (colors: PalletThemeColors): GnosisTheme["table"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);

  return {
    borderBottomColor: colors.secondary.base,
    rowBackgroundColor: colors.secondary.lighter,
    rowHoverColor: protectColorContrast(
      "darkest",
      Color(colors.primary.lightest).alpha(0.25).string(),
      0.25,
    ),
  };
};
