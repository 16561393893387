import { ArrowLeftSVG, ArrowRightSVG } from "@epignosis_llc/gnosis/icons";
import { i18n } from "@utils/i18n";
import { IconType } from "types/common";

const useRtlArrowIcons = (): IconType => {
  const isRtl = i18n.dir() === "rtl";

  return isRtl ? ArrowRightSVG : ArrowLeftSVG;
};

export default useRtlArrowIcons;
