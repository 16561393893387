import Color from "color";
import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const topNavContainer = ({ unitTopNav, gamification, button }, isFailed) => css`
  .nav-content {
    height: 4.5rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${unitTopNav.background};
    padding: 0rem 1rem;
    box-shadow: 0px 3px 6px ${unitTopNav.shadowColor};
    z-index: 1;
    transition: all 0.2s ease;
    position: relative;

    h1 {
      flex: 1;
      margin-inline-start: 0.5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 400;
      text-align: center;

      ${mq["md"]} {
        margin-inline-start: 0;
      }
    }

    .right-container {
      display: flex;
      align-items: center;

      .is-pressed {
        color: ${button[isFailed ? "danger" : "success"].ghost.color};
        background-color: ${Color(button[isFailed ? "danger" : "success"].ghost.background)
          .alpha(0.16)
          .string()};
      }
    }

    .gamification-icon {
      color: ${gamification.color};
    }
  }

  .announcements {
    padding-inline-end: 3rem;

    .close-button {
      inset-inline-end: 1rem;
    }
  }
`;

export const drawerBody = css`
  .nav-handle {
    min-height: auto;

    .nav-item {
      border: 0;
    }
  }
`;
