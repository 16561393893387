import React, { Modal } from "@epignosis_llc/gnosis";
import { FC } from "react";
import { signUpModalBodyContainer } from "./styles";
import { useUIStore } from "@stores";
import SignUpFormContainer from "@views/SignUp/SignUpFormContainer";
import localStorageKeys from "@constants/localStorageKeys";

const SignUpPopupModal: FC = () => {
  const { showSignUpModal, setShowSignUpModal } = useUIStore();

  const handleClose = (): void => {
    setShowSignUpModal(false);

    // Reset local storage
    localStorage.removeItem(localStorageKeys.EXTERNAL_SIGNIN_SIGNUP);
  };

  return (
    <Modal isOpen={showSignUpModal} onClose={handleClose}>
      <Modal.Body css={signUpModalBodyContainer}>
        <SignUpFormContainer />
      </Modal.Body>
    </Modal>
  );
};

export default SignUpPopupModal;
