import { userHasRequiredPermissions } from "./index";
import { useConfigurationStore } from "@stores";
import authService from "@utils/services/AuthService";

// Map with all the available permissions
export const profilePermissions = {
  read: "profile.read",
  fields_update: "profile.field.update",
  password_update: "profile.password.update",
};

const { read, fields_update, password_update } = profilePermissions;
const { getState } = useConfigurationStore;

export default {
  /**
   * Permission => profile.read
   * profile.read is required in order to have all the other profile related permissions
   *
   * Allowed actions:
   *  GET profile, branches, groups, files
   *
   * @returns boolean
   */
  canAccessProfile: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([read], getState().userProfileData?.permissions[userRole]);
  },
  /**
   * Permission => profile.field.update
   *
   * Allowed actions:
   *  PATCH updateProfile
   *
   * @returns boolean
   */
  canUpdateProfileFields: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [read, fields_update],
      getState().userProfileData?.permissions[userRole],
    );
  },
  /**
   * Permission => profile.password.update
   *
   * Allowed actions:
   *  POST password
   *
   * @returns boolean
   */
  canUpdatePassword: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [read, password_update],
      getState().userProfileData?.permissions[userRole],
    );
  },
};
