import { css } from "@emotion/react";

export const roleCellContainer = (isTable) => css`
  // This is the React-select control container that should have transparent background and on hover it should be white.
  // This functionality might be moved to the design system in the future if we need this coloring in another table.
  .control-md {
    background: transparent;

    &:hover {
      background-color: #fff;
    }

    &:not(&:hover) {
      border: 1px solid transparent;
    }
  }

  .select-input-wrapper {
    ${isTable && `background: none !important`};
  }

  .user-role-text {
    padding: 0 0.75rem;
    cursor: default;
  }

  .user-role-select {
    border: none;
    cursor: pointer;
  }
`;
