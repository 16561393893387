import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["pressedRectangle"] => {
  return {
    background: colors.white,
    color: colors.primary.base,
    border: colors.secondary.lighter,
    borderHoverColor: colors.primary.base,
    borderPressedColor: colors.primary.base,
  };
};
