import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { Button, Modal, Text } from "@epignosis_llc/gnosis";
import { useApplyTranslations } from "@hooks";

import { modalBodyStyles, modalFooterStyles } from "./TrialEndedModal.styles";

import queryKeys from "@constants/queryKeys";
import { dismissEndModal } from "@api/reverseTrial";
import { generalNotification } from "@utils/helpers";

import { BOOK_DEMO_URL } from "../constants";
import { URLS } from "@constants/urls";
import { images } from "@constants/assets";

type TrialEndedModalProps = {
  isOpen: boolean;
  toggleModal: () => void;
};

export const TrialEndedModal: FC<TrialEndedModalProps> = ({ isOpen, toggleModal }) => {
  const { t } = useApplyTranslations();
  const navigate = useNavigate();

  const handleOnClose = (): void => {
    dismissTrialEndedModalMutation();
    toggleModal();
  };

  const handleOnUpgradeNow = (): void => {
    handleOnClose();
    navigate(URLS.subscription.subscription);
  };

  const handleBookADemo = (): void => {
    window.open(BOOK_DEMO_URL, "_blank");
  };

  const { mutate: dismissTrialEndedModalMutation } = useMutation(
    [queryKeys.reverseTrial.dismissEndModal],
    () => dismissEndModal(),
    {
      onError: () => {
        generalNotification("error", <p>{t("errors.general.genericIssue")}</p>);
      },
    },
  );

  return (
    <Modal
      data-testid="reverse-trial-ended-modal"
      isOpen={isOpen}
      onClose={handleOnClose}
      closeOnOutsideClick={false}
    >
      <Modal.Header>
        <Text fontSize="xl" weight="700">
          {t("reverseTrial.endOfTrialHeader")}
        </Text>
      </Modal.Header>
      <Modal.Body css={modalBodyStyles}>
        <img src={images.reverseTrial.dashboardEnded} alt={t("reverseTrial.trialEndedImgAlt")} />
        <Text
          fontSize="md"
          dangerouslySetInnerHTML={{ __html: t("reverseTrial.endOfTrialBody") }}
        />
      </Modal.Body>
      <Modal.Footer css={modalFooterStyles}>
        <Button
          data-testid="reverse-trial-modal-upgrade-now-button"
          onClick={handleOnUpgradeNow}
          variant="solid"
        >
          {t("general.upgradeNow")}
        </Button>
        <Button
          data-testid="reverse-trial-modal-get-a-demo-button"
          color="secondary"
          variant="solid"
          onClick={handleBookADemo}
        >
          {t("reverseTrial.getADemo")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
