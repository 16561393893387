import React, { FC } from "react";
import { SerializedStyles } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { ChatCloudsSVG } from "@epignosis_llc/gnosis/icons";
import { useQuery } from "react-query";
import { Button, Tooltip } from "@epignosis_llc/gnosis";

// Styles
import { DiscussionsMenuStyles } from "./DiscussionsMenu.styles";

// API, Permissions, hooks
import permissions from "@utils/permissions";
import { useApplyTranslations } from "@hooks";
import { getActiveDiscussions } from "@api/discussions";

// Constants
import { URLS } from "@constants/urls";
import queryKeys from "@constants/queryKeys";

const BranchesMenu: FC = () => {
  const navigate = useNavigate();
  const { t } = useApplyTranslations();
  // Set permissions
  const { canAccessDiscussions } = permissions.discussionsPermissions;

  // Get total unread discussions
  const { data } = useQuery(queryKeys.discussions.active, getActiveDiscussions, {
    enabled: canAccessDiscussions(),
  });

  return (
    <div css={(theme): SerializedStyles => DiscussionsMenuStyles(theme)}>
      <Tooltip content={t("mainMenu.discussions")}>
        <Button
          title={t("mainMenu.discussions")}
          data-testid="topbar-discussions"
          variant="ghost"
          className="discussions action-btn"
          color="primary"
          noGutters
          onClick={(): void => navigate(URLS.user.discussions)}
          aria-label={t("mainMenu.discussions")}
        >
          {data && data._data.total > 0 && <div className="unread-message" />}
          <ChatCloudsSVG height={32} />
        </Button>
      </Tooltip>
    </div>
  );
};

export default BranchesMenu;
