import { css } from "@emotion/react";

export const fileUploadAreaStyles = css`
  .upload-prompt-area {
    margin: 1rem 0;
  }

  .loading-container {
    position: initial;
    inset-inline-start: auto;
    transform: translate(0, 0);
  }
`;

export const ActionDrawerContainer = ({ uploadArea }) => css`
  .upload-prompt-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dashed ${uploadArea.uploadFile.borderColor};
    margin: 1rem 0;
    height: 13rem;

    .upload-prompt {
      padding: 1rem;
      display: flex;
      justify-content: center;
      width: 100%;
      align-items: center;
      flex-direction: column;
      text-align: center;

      .upload-prompt-container {
        display: flex;
        direction: row;
        gap: 0.5rem;
        width: 100%;
        align-items: end;

        .upload-button {
          min-width: auto;
        }
      }

      .subtitle {
        color: ${uploadArea.grayBase};
        margin-top: 1.5rem;
        width: 100%;
        font-size: 12px;
        font-style: italic;

        .link {
          text-decoration: underline;
        }
      }

      .add-content-icon {
        svg {
          fill: ${uploadArea.uploadFile.textColor};
          color: ${uploadArea.uploadFile.textColor};
        }
      }

      .add-files {
        color: ${uploadArea.uploadFile.textColor};

        &:hover {
          text-decoration: underline;
        }
      }

      .add-files-subtitle {
        color: ${uploadArea.grayBase};
      }
    }

    .loading-wrapper {
      height: 4rem;
    }

    /* style for upload option inside file input */
    .file-input,
    .label-container,
    .children-wrapper {
      width: 100%;
      height: 100%;
    }

    .children-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .file-input {
      cursor: pointer;
    }
  }
`;
