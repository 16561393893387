// Packages or third-party libraries
import React, { FC, Fragment, useEffect, useRef } from "react";
import classNames from "classnames";
import { groupBy, pipe, values, reverse, toString } from "ramda";
import { SerializedStyles } from "@emotion/react";

// Styles
import { userStatsItem } from "./styles";

// Components
import UserItem from "./UserItem";

// Utils, storage, etc.
import { useConfigurationStore } from "@stores";

// Other imports
import { LeaderboardUser } from "types/entities";

export type UserStatsProps = {
  stats: LeaderboardUser[];
  isGamificationDrawer?: boolean;
  hasFooter?: boolean;
  title?: string;
};

const groupByScore = pipe(
  groupBy((user: LeaderboardUser) => toString(user.score)), // Group users by their score
  values, // Get arrays of users from the record
  (userGroups) => reverse(userGroups), // Explicitly reverse the arrays of users
);

const userClassNames = (id: number, userId: number | undefined): string =>
  classNames({
    "list-wrapper": true,
    "user-background": id === userId,
  });

const UserStats: FC<UserStatsProps> = ({
  stats,
  isGamificationDrawer,
  title,
  hasFooter = false,
}) => {
  const { userProfileData } = useConfigurationStore();
  const leadersGroupsByScore = groupByScore(stats);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parentElementRef: any = useRef(null);
  const currentUserRef = useRef(null);

  useEffect(() => {
    if (isGamificationDrawer) {
      const gamificationDrawer = document.querySelector("#gamification-drawer");

      const parentElement =
        gamificationDrawer &&
        gamificationDrawer.querySelectorAll('[class*="gamification-container"]');

      if (parentElement) {
        parentElementRef.current = parentElement[0];
        const parentContainer = parentElement[0]?.parentElement;
        if (parentContainer) {
          parentContainer.style.overflowY = "hidden";
        }
      }
    }
  }, [isGamificationDrawer]);

  return (
    <ol
      css={(theme): SerializedStyles => userStatsItem(theme, { isGamificationDrawer, hasFooter })}
      tabIndex={0}
    >
      {leadersGroupsByScore
        .filter((leaderGroup): leaderGroup is LeaderboardUser[] => leaderGroup !== undefined) // Filters out undefined groups
        .map((leaderGroup, index) =>
          leaderGroup.map((leaderboardUser) => {
            const { id, name } = leaderboardUser;

            return (
              <Fragment key={`${id}-${name}`}>
                <li
                  ref={userProfileData?.id === id ? currentUserRef : null}
                  className={userClassNames(id, userProfileData?.id)}
                >
                  <UserItem
                    title={title}
                    leaderboardUser={leaderboardUser}
                    index={index}
                    isGamificationDrawer={isGamificationDrawer}
                  />
                </li>
              </Fragment>
            );
          }),
        )}
    </ol>
  );
};

export default UserStats;
