import { userHasRequiredPermissions } from "./index";
import authService from "@utils/services/AuthService";
import { useConfigurationStore } from "@stores";

// Map with all the available permissions
export const branchPermissions = {
  read: "branch.read",
  reports: "reports.branch",
  create: "branch.create",
  update: "branch.update",
};

const { read, reports, create, update } = branchPermissions;
const { getState } = useConfigurationStore;

export default {
  /**
   * Permission => user.read
   * user.read is required in order to have all the other user related permissions
   *
   *  Allowed actions:
   *  GET user.users
   *
   * @returns boolean
   */
  canReadBranches: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([read], getState().userProfileData?.permissions[userRole]);
  },

  canReadReportBranches: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([reports], getState().userProfileData?.permissions[userRole]);
  },

  canCreateBranches: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([create], getState().userProfileData?.permissions[userRole]);
  },

  canUpdateBranches: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([update], getState().userProfileData?.permissions[userRole]);
  },
};
