import { ENDPOINTS } from "@api/endpoints";
import HttpClient from "@api/HttpClient";

export const dismissOnboardingTour = async (): Promise<void> => {
  const res = await HttpClient.post(ENDPOINTS.onboardingSettings.dismissTour);

  return res.data;
};

export const restartOnboardingTour = async (): Promise<void> => {
  const res = await HttpClient.post(ENDPOINTS.onboardingSettings.restartTour);

  return res.data;
};
