import React, { FC, ReactNode, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import useZendeskService from "@hooks/useZendeskService";
import { Loader } from "@epignosis_llc/gnosis";
import { AxiosError } from "axios";
import { IntercomProvider } from "react-use-intercom";
import { useNavigate } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";

import { Toaster } from "@components";
import AutologinLoader from "@components/Autologin/AutologinLoader";
import { InternalServerError, InactiveBranchError } from "@views/Errors";

import { useConfigurationStore, useUIStore } from "@stores";
import { useAuth, useGetGamificationSettings, useGetUserProfile } from "@hooks";
import { decodeUrlSafeBase64, languageChange, setActiveTheme } from "@utils/helpers";
import permissions from "@utils/permissions";
import authService from "@utils/services/AuthService";

import { getCatalogSettings } from "@api/catalog";
import { getAnnouncements } from "@api/announcements";
import { getUserStatistics, getUserIntegrations } from "@api/user";
import {
  getDomainSettings,
  getDomainTerms,
  getSSOAuthUrls,
  getSocialDomainSettings,
} from "@api/app";
import { getSkillsSettings } from "@views/Skills/api";

import { userRoles } from "@constants/index";
import { integrations, staleTimeConfig } from "@config";
import queryKeys from "@constants/queryKeys";
import { URLS } from "@constants/urls";
import localStorageKeys from "@constants/localStorageKeys";
import logTraceIdService from "@utils/services/LogTraceIdService";
import { LogDeviceMetricsService } from "@utils/services/LogDeviceMetricsService";
import { calculate90DaysPassed } from "@views/WidgetsDashboard/components/ConsentDialogBox/utils";
import { useConsentDialogSession } from "@views/WidgetsDashboard/components/ConsentDialogBox/useConsentDialogSession";

type LayoutWrapperProps = {
  children: ReactNode;
};

const isBranchInactive = (errors: unknown[]): boolean => {
  return errors.some((error) => {
    const errorResponse = (error as AxiosError)?.response;
    const responseStatus = errorResponse?.status;
    const errorId = errorResponse?.data?._errors?.[0]?.id;

    return responseStatus === 403 && errorId === "forbidden.branch_inactive";
  });
};

const LayoutWrapper: FC<LayoutWrapperProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();

  const queryClient = useQueryClient();
  const {
    userProfileData,
    domainSettings,
    domainTerms,
    userIntegrations,
    setDomainSettings,
    setDomainTerms,
    setUserProfile,
    setGamificationSettings,
    setSsoDomainSettings,
    setSocialDomainSettings,
    setUserStatistics,
    setCatalogSettings,
    setAnnouncements,
    setUserIntegrations,
    setSkillsSettings,
    requiredCustomFields,
  } = useConfigurationStore();

  const { showRedirectLoading, setLiveChatVisible, setPhoneSupportVisible } = useUIStore();
  const { initializeSession, clearLoginTime } = useConsentDialogSession();

  const {
    locale: domainSettingsLocale,
    external_catalog: isExternalCatalog,
    is_sanctioned: isSanctioned,
  } = domainSettings ?? {};

  const {
    show_onboarding: showOnboarding,
    show_welcome_questions: showWelcomeQuestions,
    skipped_communications: lastSkippedTimestamp,
    is_owner: isPortalOwner,
  } = userProfileData ?? {};

  const has90DaysPassed = lastSkippedTimestamp ? calculate90DaysPassed(lastSkippedTimestamp) : null;

  const { isAdministrator, isInstructor } = authService;
  const rolePasses = (isAdministrator || isInstructor) && !isPortalOwner;

  const { integration_type = null, single_logout_url: singleLogoutRedirectURL = "" } =
    domainSettings?.sso ?? {};
  const { canAccessGamification } = permissions.gamificationPermissions;
  const { canAccessProfile } = permissions.profilePermissions;
  const { mutate: getGamificationSettingsMutation } = useGetGamificationSettings();
  const { isEditMode, isAddWidgetMode } = useConfigurationStore();
  const { intercom_settings = null } = userIntegrations ?? {};
  const localStorageLocale = localStorage.getItem(localStorageKeys.LANGUAGE_LOCALE);
  const isCatalogEnabled = Boolean(isExternalCatalog) || isAuthenticated;

  const navigate = useNavigate();
  const { javascript_integrations: jsIntegrationsSettings, main_portal: isMainPortal = false } =
    useConfigurationStore((state) => state.domainSettings) || {};

  const isAuthenticatedRequestsEnabled =
    !isAuthenticated || !domainTerms ? false : domainTerms.terms === null;

  const { chat_support: liveChatInPlan, phone_support: phoneSupportInPlan } =
    domainSettings?.features_allowed_in_plan ?? {};
  const userRole = authService.getDefaultRole();
  const isAdmin = userRole === userRoles.ADMINISTRATOR;

  const { zendesk } = jsIntegrationsSettings ?? {};
  const { is_active: isZendeskActive = false, key: zendeskKey = "" } = zendesk ?? {};

  const handleZendeskLoaded = (): void => {
    setLiveChatVisible(false);
    setPhoneSupportVisible(false);
  };

  const shouldInitMessengerZendesk = isMainPortal && isZendeskActive && !isAdmin && isAuthenticated;

  const shouldInitZendesk = isAuthenticated && (liveChatInPlan || phoneSupportInPlan);

  const selectedZendeskKey = shouldInitMessengerZendesk ? zendeskKey : integrations.ZENDESK_API_KEY;

  useZendeskService({
    zendeskKey: selectedZendeskKey,
    shouldInitializeZendesk: shouldInitZendesk,
    shouldInitializeMessengerZendesk: shouldInitMessengerZendesk,
    defer: true,
    onLoaded: handleZendeskLoaded,
  });

  // Get domain settings
  const {
    status: domainSettingsStatus,
    error: domainSettingsError,
    refetch,
  } = useQuery(queryKeys.domainSettings, getDomainSettings, {
    onSuccess: (res) => {
      setDomainSettings(res._data);
      const { theme } = res._data;
      setActiveTheme(theme);

      // If the domain is paused, logout and redirect to login
      if (res?._data.paused) {
        authService.removeTokens();
        authService.removeRole();
        navigate(URLS.login);
      }
    },
    cacheTime: 0,
    staleTime: staleTimeConfig[queryKeys.domainSettings],
  });

  // Get announcements (if we are authenticated, we need to refetch for internal announcements)
  useQuery([queryKeys.announcements.announcements, isAuthenticated], getAnnouncements, {
    onSuccess: (res) => {
      setAnnouncements(res._data);
    },
    cacheTime: 0,
  });

  useEffect(() => {
    const contextId = logTraceIdService.getContextId();
    const isContextIdExpired = logTraceIdService.isContextIdExpired();
    const shouldGenerateContextId =
      (!isAuthenticated && !contextId) || (!isAuthenticated && isContextIdExpired);

    if (isAuthenticated) {
      refetch();
      // Log device metrics
      LogDeviceMetricsService.logDeviceMetrics(["device"]);
    }
    // Generate trace identifier context ID.
    if (shouldGenerateContextId) logTraceIdService.generateContextId();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const isSSOenabled = ["saml", "oidc"].some((type) => type === integration_type);

  // Get saml and oidc sso domain settings
  useQuery(queryKeys.ssoDomainSettings, () => getSSOAuthUrls(), {
    enabled: isSSOenabled,

    onSuccess: (res) => {
      const logoutUrl = singleLogoutRedirectURL
        ? decodeUrlSafeBase64(singleLogoutRedirectURL)
        : res._data.logout_url;

      // If we log in from SSO (okta for example) the key is not set in the first login
      localStorage.setItem(localStorageKeys.SSO_LOGOUT_URL, logoutUrl);
      setSsoDomainSettings(res._data);
    },
  });

  // Get social media domain login/signup options
  useQuery(queryKeys.socialDomainSettings, getSocialDomainSettings, {
    enabled: domainSettings?.social_login.length !== 0,
    onSuccess: (res) => setSocialDomainSettings(res._data),
  });

  // Get user profile data
  const { status: userProfileStatus, error: userProfileError } = useGetUserProfile({
    enabled: !(isEditMode || isAddWidgetMode) && isAuthenticated,
    cacheTime: staleTimeConfig[queryKeys.userProfile],
    onSuccess: (res) => {
      setUserProfile(res._data);

      isAuthenticated && canAccessGamification()
        ? getGamificationSettingsMutation()
        : setGamificationSettings(null);
    },
  });

  const isImpersonationEnabled = userProfileData?.impersonated;

  // Get domain terms
  const { status: domainTermsStatus, error: domainTermsError } = useQuery(
    queryKeys.domainTerms.terms,
    getDomainTerms,
    {
      cacheTime: 0,
      enabled: isAuthenticated && Boolean(!isImpersonationEnabled),
      onSuccess: (res) => {
        setDomainTerms(res._data);

        if (res._data.terms) {
          queryClient.cancelQueries();
        }
      },
    },
  );

  // Get user statistics
  useQuery([queryKeys.userStatistics], () => getUserStatistics(), {
    enabled: isAuthenticatedRequestsEnabled,
    onSuccess: (res) => setUserStatistics(res._data),
  });

  // Get catalog settings
  useQuery([queryKeys.catalogSettings], () => getCatalogSettings(), {
    onSuccess: (res) => setCatalogSettings(res._data),
    cacheTime: 0,
    enabled: isCatalogEnabled,
  });

  // Get integration settings
  useQuery([queryKeys.userIntegrations], getUserIntegrations, {
    enabled: isAuthenticated,
    onSuccess: (res) => setUserIntegrations(res._data),
  });

  // Get skills settings
  useQuery([queryKeys.skills.settings.getSettings], getSkillsSettings, {
    enabled: isAuthenticated && permissions.skillsPermissions.canGetSkillSettings(),
    onSuccess: (res) => setSkillsSettings(res._data),
  });

  useEffect(() => {
    // If the domain settings locale is set and the user has not selected a locale, set the domain settings locale
    // If the user has selected a locale, set the user's locale

    if (domainSettingsLocale && !localStorageLocale) {
      languageChange(domainSettingsLocale);
    } else if (localStorageLocale) {
      languageChange(localStorageLocale);
    }
  }, [domainSettingsLocale, localStorageLocale]);

  useEffect(() => {
    if (userProfileData) {
      // When logged in branch and branch locale is set to a specific locale set domain locale, else set user's locale
      const locale =
        domainSettings?.user_selected_locale === false
          ? domainSettings.locale
          : userProfileData.locale;
      languageChange(locale);

      const localStorageLocale = localStorage.getItem(localStorageKeys.LANGUAGE_LOCALE);

      // Used in public routes and non react helpers
      if (localStorageLocale !== locale) {
        localStorage.setItem(localStorageKeys.LANGUAGE_LOCALE, locale);
      }
    }
  }, [domainSettings?.locale, domainSettings?.user_selected_locale, userProfileData]);

  useEffect(() => {
    const shouldNavigateToOnboarding =
      !isSanctioned &&
      !showWelcomeQuestions &&
      showOnboarding &&
      !requiredCustomFields &&
      location.pathname !== URLS.user.profile &&
      location.pathname !== URLS.onboardingOptions &&
      location.pathname !== URLS.onboardingNotAvailable;

    if (shouldNavigateToOnboarding) {
      navigate(URLS.onboardingOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOnboarding]);

  useEffect(() => {
    // this check is identical to shouldShowConsent in ConsentDialogBox.tsx
    // with the addition of isAuthenticated
    const shouldShowConsent =
      isAuthenticated &&
      rolePasses &&
      (has90DaysPassed || has90DaysPassed === null) &&
      canAccessProfile();

    if (shouldShowConsent) {
      initializeSession();
    } else {
      clearLoginTime();
    }
  }, [
    canAccessProfile,
    clearLoginTime,
    has90DaysPassed,
    initializeSession,
    isAuthenticated,
    rolePasses,
  ]);

  // Required request are fetching data
  const isLoading = [domainSettingsStatus, domainTermsStatus, userProfileStatus].some(
    (status) => status === "loading",
  );
  if (isLoading) return <Loader fullScreen />;

  // Branch is inactive
  if (isBranchInactive([domainSettingsError, domainTermsError, userProfileError]))
    return <InactiveBranchError />;

  // An error occurred
  if (domainSettingsError || domainTermsError || userProfileError) {
    // Check for invalid domain => redirect to given URL
    if (domainSettingsError) {
      const domainSettingsAxiosError = domainSettingsError as AxiosError;
      const domainSettingsErrorId = domainSettingsAxiosError?.response?.data._errors[0]?.id;

      if (
        domainSettingsErrorId === "service_unavailable.invalid_domain" ||
        domainSettingsErrorId === "not_found.invalid_domain"
      ) {
        const redirectionURL = `https://${domainSettingsAxiosError?.response?.data._meta?.redirect_url}`;
        window.location.replace(redirectionURL);

        return <Loader fullScreen />;
      }
    }

    return <InternalServerError />;
  }

  // Redirecting to core
  if (showRedirectLoading) return <AutologinLoader isFullScreen={true} />;

  return (
    <IntercomProvider appId={intercom_settings?.app_id ?? ""}>
      <Toaster />
      {children}
    </IntercomProvider>
  );
};

export default LayoutWrapper;
