export type Price = {
  amount: number;
  currency: string;
};

export type Image = {
  default: string;
  xl: string | null;
  lg: string | null;
  md: string | null;
  sm: string | null;
};

export type Video = {
  url: string;
  type: string;
};

export type CustomField = {
  id: number;
  name: string;
  type: CustomFieldType;
  visible_on_reports?: boolean;
  mandatory?: boolean;
  max_length?: number | null;
  checked?: boolean | null;
  dropdown_items?: CustomFieldDropdownItem[] | null;
  selective_availability?: number[];
  policies?: {
    can_delete: boolean;
    can_update: boolean;
  };
  enable_selective_availability?: boolean;
};

export type CustomFieldWithValue = Pick<CustomField, "id" | "name" | "type"> & {
  value: string;
};

export type FormCustomFields = { [key: string]: string };

export type CustomFieldType = "text" | "dropdown" | "checkbox" | "date";

export type CustomFieldDropdownItem = {
  item: string;
  default: boolean;
};

export type RichText = {
  html: string;
  text: string;
};

export type AccessToken = {
  aud: string;
  exp: number;
  iat: number;
  jti: string;
  nbf: number;
  scopes: string[];
  sub: {
    user_id: number;
    domain_id: number;
    branch_id: number;
    created_at: number;
    is_sso_login: boolean;
  };
};

export type Tag = {
  id: number;
  name: string;
};

export type SmartTag = {
  label: string;
  value: string;
};

export type TabObject = {
  title: JSX.Element | string;
  content?: JSX.Element | string;
  id?: string;
  key?: string;
};

export type CountMassAction = {
  total: number;
  chunks: number;
  deactivating_prerequisites?: boolean;
  will_reach_plan_limit?: boolean;
  deleting_courses_with_linked_units?: boolean;
  total_unique_courses?: number;
  total_unique_users?: number;
  will_reach_capacity?: boolean;
};

export type AvailableTabsType = {
  info: TabObject;
  permission: boolean;
};

export type Policies = {
  [key: string]: boolean;
};

export enum SearchFilters {
  KEYWORD = "[keyword][like]",
  NAME = "[name][like]",
  DESCRIPTION = "[description][like]",
  SKILL = "[skill_name][like]",
}
