import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const pagination = () => css`
  display: flex;
  justify-content: center;

  ${mq["md"]} {
    justify-content: flex-end;
  }

  margin-top: 2rem;
`;
