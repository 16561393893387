export const isElementVisible = (child: Element, parent: Element): boolean => {
  const childRect = child.getBoundingClientRect();
  const parentRect = parent.getBoundingClientRect();

  // Check if child is fully or partially within the parent
  const isVisible =
    childRect.top < parentRect.bottom &&
    childRect.bottom > parentRect.top &&
    childRect.left < parentRect.right &&
    childRect.right > parentRect.left;

  return isVisible;
};

export const getVisibleHeight = (child: Element, parent: Element): number => {
  const { bottom: childBottom, top: childTop } = child.getBoundingClientRect();
  const { bottom: parentBottom, top: parentTop } = parent.getBoundingClientRect();

  return Math.max(0, Math.min(childBottom, parentBottom) - Math.max(childTop, parentTop));
};

export const mostVisiblePageReducer = (
  prevPage: Element,
  currentPage: Element,
  documentContainer: Element,
): Element => {
  const prevHeight = getVisibleHeight(prevPage, documentContainer);
  const currentHeight = getVisibleHeight(currentPage, documentContainer);

  return prevHeight > currentHeight ? prevPage : currentPage;
};
