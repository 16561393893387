import { css } from "@emotion/react";

export const upgradeMenuItemStyles = ({ colors }) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;

  .days-left-text {
    color: ${colors.secondary.darker};
  }
`;
