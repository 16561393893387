import React, { FC, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { LazyMotion, domAnimation } from "framer-motion";
import classNames from "classnames";
import { Loader } from "@epignosis_llc/gnosis";
import { externalLayoutContainer, externalLayoutContent } from "./styles";
import ExternalHeader from "./components/ExternalHeader";

type ExternalLayoutProps = {
  noMainContentPadding?: boolean;
};

const ExternalLayout: FC<ExternalLayoutProps> = ({ noMainContentPadding = false }) => {
  const contentContainerClassNames = classNames({ "no-padding": noMainContentPadding });

  return (
    <div css={externalLayoutContainer}>
      <div className="main-content-container">
        <ExternalHeader />
        <LazyMotion features={domAnimation} strict>
          <Suspense fallback={<Loader fullScreen />}>
            <div
              id="scroll-container"
              css={externalLayoutContent}
              className={contentContainerClassNames}
            >
              <main className="main-content-wrapper" role="main">
                <Outlet />
              </main>
            </div>
          </Suspense>
        </LazyMotion>
      </div>
    </div>
  );
};

export default ExternalLayout;
