// React and third-party imports
import React, { FC, useEffect, useState } from "react";
import { Grid } from "@epignosis_llc/gnosis";

// Components
import DateInput from "@components/FormElements/DateInput/DateInput";

// Hooks & Utilities
import { getDomainDateFormat, formatDateWithEndOfTheDayTime } from "@utils/helpers";
import { useApplyTranslations } from "@hooks";
import { useConfigurationStore } from "@stores";

type SetExpirationMassActionModalBodyProps = {
  handleExpirationDate: (date: string) => void;
};

const SetExpirationMassActionModalBody: FC<SetExpirationMassActionModalBodyProps> = ({
  handleExpirationDate,
}) => {
  const { t } = useApplyTranslations();

  const { userProfileData } = useConfigurationStore();
  const timezone = userProfileData?.timezone ?? "UTC";

  const [expirationDate, setExpirationDate] = useState<Date | null>(
    new Date(new Date().setHours(23, 59, 59, 999)),
  );

  const dateFormat = getDomainDateFormat();

  useEffect(() => {
    const formatted = formatDateWithEndOfTheDayTime(expirationDate, timezone);
    if (!formatted) return;

    handleExpirationDate(formatted);
  }, [expirationDate, handleExpirationDate, timezone]);

  return (
    <Grid templateColumns={1} rowGap={1}>
      <Grid.Item>
        <DateInput
          id="completion-date"
          value={expirationDate}
          dateFormat={dateFormat}
          label={t("general.expirationDate")}
          minDate={new Date()}
          onChange={(selectedDate: Date | null): void => {
            if (!selectedDate) return;

            const formatted = formatDateWithEndOfTheDayTime(selectedDate, timezone);
            if (!formatted) return;

            setExpirationDate(selectedDate);
            handleExpirationDate(formatted);
          }}
        />
      </Grid.Item>
    </Grid>
  );
};

export default SetExpirationMassActionModalBody;
