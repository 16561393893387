import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useApplyTranslations } from "@hooks";
import { Button } from "@epignosis_llc/gnosis";
import { URLS } from "@constants/urls";
import { upgradeNowMenuButtonStyles } from "./UpgradeNowMenuButton.styles";

type UpgradeNowButtonProps = {
  onActionComplete: () => void;
};

const UpgradeNowMenuButton: FC<UpgradeNowButtonProps> = ({ onActionComplete }) => {
  const navigate = useNavigate();
  const { t } = useApplyTranslations();

  const handleClick = (): void => {
    navigate(URLS.subscription.subscription);
    onActionComplete();
  };

  return (
    <div css={upgradeNowMenuButtonStyles}>
      <Button variant="solid" onClick={handleClick}>
        {t("general.upgradeNow")}
      </Button>
    </div>
  );
};

export default UpgradeNowMenuButton;
