import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["certificateTemplateCard"] => {
  return {
    defaultBackground: colors.white,
    selectedBackground: colors.primary.base,
    borderColor: colors.secondary.lighter,
    textColor: colors.white,
  };
};
