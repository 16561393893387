import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { SerializedStyles } from "@emotion/react";
import { AxiosError } from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input, InputError, Button, Heading, FormError, Text } from "@epignosis_llc/gnosis";
import { IconPreviewSVG, HideIconSVG } from "@epignosis_llc/gnosis/icons";

import { loginForm } from "./styles";

import SignInOrUpText from "@components/ReusableComponents/SignInOrUpText/SignInOrUpText";
import UserBranches from "./components/UserBranches";

import { useApplyTranslations, useEnrollmentMutation, useLogout, useSearchQuery } from "@hooks";
import { closeAndHideZendeskWidget } from "@hooks/useZendeskService";
import authService from "@utils/services/AuthService";
import { SignInFormValidationSchema } from "@utils/validation";
import logTraceIdService from "@utils/services/LogTraceIdService";
import { showGamificationNotification } from "@utils/helpers";
import { useConfigurationStore, useUIStore } from "@stores";
import { AuthenticationExpireSVG } from "@assets/images";
import { signin, LoginPostData, autologinToBranch } from "@api/app";

import { URLS } from "@constants/urls";
import localStorageKeys from "@constants/localStorageKeys";
import queryKeys from "@constants/queryKeys";
import userRoles from "@constants/userRoles";

import { HandledError, handleSignInErrors } from "@errors";
import { handleAutologinBranchErrors } from "@errors/errors";
import { SignInSearchQueryFilters } from "types/common";

type SignInFormProps = {
  // Form opens on modal
  showOnModal?: boolean;
};

const SignInForm: FC<SignInFormProps> = ({ showOnModal = false }) => {
  const { t } = useApplyTranslations();
  const { handleLogOut } = useLogout();

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { userProfileData, domainSettings } = useConfigurationStore();

  const { login } = userProfileData ?? {};
  const {
    setShowSessionExpirationModal,
    setLoginType,
    setLiveChatVisible,
    setPhoneSupportVisible,
  } = useUIStore();

  const { redirect } = useSearchQuery() as SignInSearchQueryFilters;

  const signup = domainSettings?.signup;
  const isSignUpEnabled = signup?.enabled;
  const { impersonator_username: impersonatorUsername, impersonated: isImpersonated } =
    userProfileData ?? {};
  const [loginData, setLoginData] = useState<LoginPostData | null>(null);

  const noUserImpersonated = Boolean(!impersonatorUsername) && isImpersonated;

  const [signinError, setSigninError] = useState<string | JSX.Element>("");
  const [showPassword, setShowPassword] = useState(false);

  const { enrollmentMutation } = useEnrollmentMutation();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<LoginPostData>({
    mode: "onChange",
    resolver: yupResolver(SignInFormValidationSchema),
    defaultValues: {
      // Set username if form opens on modal
      username: !showOnModal
        ? ""
        : impersonatorUsername && isImpersonated
          ? impersonatorUsername
          : login,
      password: "",
    },
  });

  const storageKey = localStorage.getItem(localStorageKeys.EXTERNAL_SIGNIN_SIGNUP);
  const externalEnrollment = storageKey ? JSON.parse(storageKey).enrollment === true : false;
  const externalId = storageKey ? JSON.parse(storageKey).courseId : "";
  const plainRedirectUrl = storageKey ? JSON.parse(storageKey).redirectUrl : "";
  const activeBundle = storageKey ? JSON.parse(storageKey).activeBundle : "";
  const [isRestrictedBranchError, setIsRestrictedBranchError] = useState(false);

  const { mutate: signInMutation, isLoading: signInLoading } = useMutation(
    [queryKeys.signin],
    (data: LoginPostData) => signin(data),
    {
      onSuccess: (res) => {
        const authData = res;
        logTraceIdService.generateContextId();
        authService.setTokens(authData);
        authService.setDefaultRole(authData.default_role);
        setLoginType("direct");
        queryClient.invalidateQueries([queryKeys.catalogSettings]);
        const userRole = authService.getDefaultRole();

        // If the sign-in is performed from external catalog
        if (storageKey) {
          if (externalEnrollment && userRole === userRoles.LEARNER) {
            enrollmentMutation();
          } else if (
            [userRoles.ADMINISTRATOR, userRoles.INSTRUCTOR].includes(userRole) &&
            typeof externalId === "string"
          ) {
            // Redirect to admin course overview page
            navigate(URLS.catalog.adminCourseOverviewLink({ courseId: externalId }));
            return;
          }

          if (plainRedirectUrl) {
            // If login or sign-up is performed from public header
            navigate(plainRedirectUrl);
          } else {
            // Redirect to course details page
            if (externalId) {
              // Check for payment

              externalEnrollment
                ? navigate(URLS.catalog.createCourseLink({ courseId: externalId }))
                : navigate(URLS.catalog.createCourseLink({ courseId: externalId }), {
                    state: { isPayment: true },
                  });
            } else {
              // Navigate on catalog and check for active bundle
              activeBundle
                ? navigate(URLS.catalog.index, { state: { activeBundle } })
                : navigate(URLS.catalog.index);
            }
          }

          localStorage.removeItem(localStorageKeys.EXTERNAL_SIGNIN_SIGNUP);
        } else {
          if (!showOnModal) {
            // Delete stored settings for showing announcements
            localStorage.removeItem(localStorageKeys.ANNOUNCEMENTS);

            // Redirect to pathname or to dashboard
            navigate(redirect ? decodeURIComponent(redirect) : URLS.dashboard);
          } else {
            // Hide sign in modal
            setShowSessionExpirationModal(false);
            queryClient.clear();
          }
        }

        const gamification = authData?._meta?.gamification;
        if (gamification) {
          showGamificationNotification(gamification);
        }
      },
      onError: (error: AxiosError) => {
        const handleError = (foundError: HandledError | null, axiosError: AxiosError): void => {
          if (foundError?.errorMsg) {
            setIsRestrictedBranchError(false);
            if (foundError?.id === "forbidden.user_required_to_update_password") {
              const updatePasswordToken: string =
                axiosError?.response?.data._meta.update_password_token;
              if (updatePasswordToken) {
                localStorage.setItem(localStorageKeys.UPDATE_PASSWORD_TOKEN, updatePasswordToken);
                navigate(URLS.changePassword);
              }
            } else if (
              foundError?.id === "forbidden.plus_is_disabled" ||
              foundError?.id === "forbidden.user_not_allowed_to_access_plus"
            ) {
              const coreUrl = window.location.origin;
              window.location.replace(coreUrl);
            } else if (
              foundError?.id === "forbidden.cannot_login_to_main_portal" ||
              foundError?.id === "forbidden.user_not_member_in_branch"
            ) {
              setIsRestrictedBranchError(true);
              setSigninError(
                <UserBranches
                  branches={axiosError?.response?.data._meta.branches}
                  canLoginMainPortal={Boolean(axiosError?.response?.data._meta.main_portal_access)}
                  errorMessage={t("signIn.validationMessages.restrictedToBranch")}
                  handleAutologin={handleAutologin}
                />,
              );
            } else {
              setSigninError(t(foundError.errorMsg));
            }
          }
        };
        handleSignInErrors(error, true, handleError);
      },
    },
  );

  const { mutate: autologinToBranchMutation } = useMutation(
    [queryKeys.branches.loginBranches],
    (branchId: string) => autologinToBranch(branchId, loginData),
    {
      onSuccess: (res) => {
        const response = res._data;
        const { token, domain } = response;

        const url = redirect
          ? `https://${domain}/plus${URLS.autologin}?token=${token}&redirect_path=${redirect}`
          : `https://${domain}/plus${URLS.autologin}?token=${token}`;

        window.location.replace(url);
      },

      onError: (error: AxiosError) => {
        handleAutologinBranchErrors(error, false);
        navigate(URLS.login);
      },
    },
  );

  const handleAutologin = (_userId: string, branchId: string): void => {
    autologinToBranchMutation(branchId);
  };

  const handleLogin = (data: LoginPostData): void => {
    setLoginData(data);
    signInMutation(data);
  };

  const handleUserLogOut = (): void => {
    handleLogOut();

    // Hide sign in modal
    setShowSessionExpirationModal(false);
  };

  useEffect(() => {
    if (showOnModal) {
      closeAndHideZendeskWidget();
      setLiveChatVisible(false);
      setPhoneSupportVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOnModal]);

  return (
    <form
      css={(theme): SerializedStyles =>
        loginForm(theme, { showOnModal }, { isRestrictedBranchError })
      }
      onSubmit={handleSubmit(handleLogin)}
      autoComplete="off"
    >
      {!showOnModal ? (
        <Heading size="2xl" className={!isSignUpEnabled ? "reset-heading" : ""}>
          {t("signIn.login")}
        </Heading>
      ) : (
        <div className="modal-title-container">
          <Heading size="md">{t("signIn.justToBeSafe")}</Heading>
        </div>
      )}
      {isSignUpEnabled && <SignInOrUpText isModalOpen={showOnModal} isLoginSelected={true} />}

      <section className="form-content">
        {showOnModal && (
          <div className="svg-text-wrapper">
            <AuthenticationExpireSVG className="content-svg" />
            <Text fontSize="md" className="password-text">
              {!noUserImpersonated
                ? t("signIn.enterYourPassword")
                : t("signIn.youCanNoLongerLogin")}
            </Text>
          </div>
        )}

        {/* Show username when form opens on modal or the user login is not set */}
        {(!showOnModal || !login) && (
          <div className="form-item">
            <Input
              {...register("username")}
              id="username"
              data-testid="username"
              label={t("signIn.usernameOrEmail")}
              size="lg"
              status={errors.username ? "error" : "valid"}
            />
            {errors.username && (
              <InputError data-testid="username-error">{errors.username.message}</InputError>
            )}
          </div>
        )}

        {!noUserImpersonated && (
          <div className="form-item">
            <Input
              {...register("password")}
              id="password"
              data-testid="password"
              type={showPassword ? "text" : "password"}
              label={t("signIn.password")}
              size={showOnModal ? "md" : "lg"}
              status={errors.password ? "error" : "valid"}
              iconAfter={showPassword ? HideIconSVG : IconPreviewSVG}
              onIconClick={(): void => {
                setShowPassword((v) => !v);
              }}
            />
            {errors.password && <InputError>{errors.password.message}</InputError>}
          </div>
        )}

        {Boolean(signinError) && (
          <div className="login-error-wrapper">
            <FormError className="branches-error-container">
              <p className="login-error-container">{signinError}</p>
            </FormError>
          </div>
        )}
      </section>

      {showOnModal ? (
        <section className="footer-container">
          <div className="btns-wrapper">
            <Button color="secondary" type="button" onClick={handleUserLogOut}>
              {t("general.logOut")}
            </Button>
            {!noUserImpersonated && (
              <Button className="login-btn" type="submit" isLoading={signInLoading}>
                {t("signIn.login")}
              </Button>
            )}
          </div>
        </section>
      ) : (
        <Button type="submit" block isLoading={signInLoading} className="sign-in-btn">
          {t("signIn.login")}
        </Button>
      )}
    </form>
  );
};

export default SignInForm;
