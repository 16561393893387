import { css } from "@emotion/react";

export const unitLayoutContainer = () => css`
  height: 100%;

  /* used only in course edit page */
  .course-edit-main-content-container {
    position: relative;
    height: 100vh;
    width: 100%;
  }

  .main-content-container {
    position: relative;
    height: calc(100vh - 4.5rem);
    width: 100%;

    .top-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
`;
