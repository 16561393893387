import { getContrastColor, getProtectedColor, PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";
import Color from "color";

export default (colors: PalletThemeColors): ThemePlusTypes["onboardingIntro"] => {
  const protectColorContrast = getProtectedColor(colors.primary.light, colors);
  const hoverBackgroundColor = protectColorContrast("darker", colors.primary.light);
  const hoverTextColor = getContrastColor(hoverBackgroundColor);

  return {
    backButton: colors.secondary.darker,
    badgeText: colors.secondary.lighter,
    border: colors.secondary.lighter,
    createWithAI: {
      inputBackground: colors.white,
      pillBackground: Color(colors.primary.lightest).alpha(0.25).string(),
      pillBackgroundHover: Color(colors.primary.light).alpha(0.25).string(),
    },
    descriptionLight: colors.secondary.darker,
    introBackground: colors.secondary.lighter,
    nextStepsOptions: {
      background: colors.secondary.lighter,
      backgroundHover: hoverBackgroundColor,
      backgroundSecondary: colors.white,
      colorHover: hoverTextColor,
      iconColor: colors.secondary.base,
      disabledColor: colors.secondary.dark,
    },
    option: {
      borderSelected: colors.primary.base,
      backgroundSelected: Color(colors.primary.lightest).alpha(0.25).string(),
      borderDeactivated: colors.secondary.light,
    },
    stepBackgroundActive: colors.primary.lightest,
    stepBackgroundDefault: colors.secondary.light,
    stepBackgroundPrevious: colors.primary.base,
  };
};
