import { yup } from "@utils/validation";

export const EditCourseContentValidation = yup.object().shape({
  name: yup.string().max(100),
  description: yup.string().trim().nullable().max(5000),
});

export const SharedNotificationValidationSchema = yup.object().shape({
  emails: yup.array().of(yup.string().email()).min(1).required(),
  subject: yup.string().required(),
  message: yup.string().required(),
});

export const cloneUnitValidationSchema = yup.object().shape({
  unitId: yup.string().required(),
  targetCourseId: yup.string().required(),
});

export const linkUnitValidationSchema = yup.object().shape({
  unitId: yup.string().required(),
  targetCourseId: yup.string().required(),
});
