import { css } from "@emotion/react";

export const feedbackResultStyles = () => css`
  display: flex;
  flex-direction: column;
  height: 29rem;
  align-items: center;
  padding: 1.5rem;
  gap: 0.25rem;

  .feedback-result-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0.75rem;
  }
`;
