import { AxiosResponse } from "axios";
import { ENDPOINTS } from "@api/endpoints";
import HttpClient from "@api/HttpClient";

export const postUserMetrics = async (
  metrics: {
    i: string;
    v: string;
  } | null,
): Promise<AxiosResponse> => {
  return await HttpClient.post(ENDPOINTS.logDeviceMetrics, metrics);
};
