import React, { FC } from "react";
import { SerializedStyles } from "@emotion/react";
import { Heading, Text, Tooltip } from "@epignosis_llc/gnosis";
import { textWithIconContainer } from "./styles";
import { InfoButtonSVG } from "@epignosis_llc/gnosis/icons";
import { TextWithIconProps } from "./types";

const TextWithIcon: FC<TextWithIconProps> = ({
  id,
  icon,
  iconAfter,
  iconColor,
  iconTooltip,
  label,
  labelTag = "h2",
  labelSize = "md",
  labelColor = "black",
  description,
  descriptionSize = "md",
  descriptionColor,
  tooltipContent,
  boldDescription = false,
  noGap = false,
  ...rest
}) => {
  const iconBefore = icon && !iconAfter;

  return (
    <div
      css={(theme): SerializedStyles =>
        textWithIconContainer(theme, { iconColor, descriptionColor, noGap })
      }
      {...rest}
    >
      {iconBefore && (
        <Tooltip
          role="tooltip"
          as="span"
          content={iconTooltip}
          aria-describedby="tooltip-content"
          disabled={!iconTooltip}
        >
          <span className="icon">{icon}</span>
        </Tooltip>
      )}
      <div className="text-with-icon-content">
        {label && (
          <Heading
            as={labelTag}
            size={labelSize}
            data-testid={`${id ? id + "-label" : "label"}`}
            className={`heading-${labelColor}`}
          >
            {label}
          </Heading>
        )}
        {description && (
          <div className="description-container">
            <Text
              as="span"
              fontSize={descriptionSize}
              weight={boldDescription ? "700" : "400"}
              data-testid={`${id ? id + "-description" : "description"}`}
            >
              {description}
            </Text>
            {tooltipContent && (
              <div data-testid={`${id ? id + "-tooltip" : "tooltip"}`}>
                <Tooltip
                  role="tooltip"
                  as="span"
                  content={tooltipContent}
                  aria-describedby="tooltip-content"
                >
                  <InfoButtonSVG
                    id="tooltip-content"
                    aria-label={tooltipContent}
                    className="tooltip-content"
                    height={16}
                    tabIndex="0"
                  />
                </Tooltip>
              </div>
            )}
          </div>
        )}
      </div>

      {iconAfter && (
        <Tooltip
          role="tooltip"
          as="span"
          content={iconTooltip}
          aria-describedby="tooltip-content"
          disabled={!iconTooltip}
        >
          <span className="icon">{iconAfter}</span>
        </Tooltip>
      )}
    </div>
  );
};

export default TextWithIcon;
