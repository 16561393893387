import { postUserMetrics } from "./api";
import { debugLog, encryptData } from "./helpers";
import { enabledMetrics, keyBase64, metrics } from "./config";
import { isDefinedAndNotNull } from "types/assertions";

class LogDeviceMetricsService {
  // Sends metrics to the server
  private async sendMetrics(metrics: Record<string, unknown>): Promise<void> {
    if (!isDefinedAndNotNull(metrics)) return;

    const encryptedMetrics = await this.encryptMetrics(metrics);

    if (encryptedMetrics) {
      debugLog(`Sending metrics to the server: ${JSON.stringify(encryptedMetrics)}`);
      try {
        await postUserMetrics(encryptedMetrics);
      } catch (error) {
        debugLog(`Error sending metrics: ${error}`);
      }
    }
  }

  // Encrypts the metrics
  private async encryptMetrics(
    metrics: Record<string, unknown>,
  ): Promise<{ i: string; v: string } | null> {
    try {
      const encryptedData = await encryptData(metrics, keyBase64);
      return encryptedData.payload;
    } catch (error) {
      debugLog(`Encryption failed: ${error}`);
      return null;
    }
  }

  // Logs device metrics
  logDeviceMetrics(type: string[], customMetrics?: Record<string, unknown>): void {
    if (type.length === 0) return;

    const preDefinedMetrics = type.reduce((acc, metric) => {
      if (enabledMetrics[metric] && typeof metrics[metric] === "function") {
        debugLog(`Logging ${metric} metrics...`);
        acc[metric] = metrics[metric]();
      }
      return acc;
    }, {});

    if (Object.keys(preDefinedMetrics).length === 0) return;

    this.sendMetrics({ ...preDefinedMetrics, ...customMetrics });
  }
}

const logDeviceMetricsService = new LogDeviceMetricsService();
export default logDeviceMetricsService;
