import { QueryFilter } from "types/common";
import { Pagination as IPagination } from "types/responses/index";
import { t } from "@utils/i18n";
import { Column, DropdownItem } from "@epignosis_llc/gnosis";
import { useConfigurationStore } from "@stores";
import { URLS } from "@constants/urls";

// Table data
export const getUsersColumns = (): Column[] => {
  return [
    {
      accessor: "formatted_name",
      cell: t("users.user"),
      classNames: ["user"],
      headerWidth: 200,
    },
    {
      accessor: "email",
      cell: t("general.email"),
      classNames: ["email"],
      maxWidth: 200,
    },
    {
      accessor: "type",
      cell: t("general.type"),
      classNames: ["type"],
      maxWidth: 200,
    },
    {
      accessor: "registration",
      cell: t("users.registration"),
      classNames: ["registration"],
      sortOrder: "desc" as Column["sortOrder"],
    },
    {
      accessor: "last_login",
      cell: t("users.lastLogin"),
      classNames: ["last_login"],
      sortOrder: "desc" as Column["sortOrder"],
    },
  ];
};

export const actionsColumn = {
  accessor: "actions",
  cell: "",
  classNames: ["hidden"],
  sortableHeader: false,
};

export const SORTING = { column: "formatted_name", isDescending: false };

export const PAGINATION: IPagination = { number: 1, size: 10 };
export const DEFAULT_FILTERS: QueryFilter[] = [];

type MassActionsOptionsWithPermissionsReturnType = (DropdownItem & { permission?: boolean })[];

// Render the dropdown items depending on the permissions of each one
export const massActionsOptionsWithPermissions = (
  allowUpdateUsers: boolean,
  allowUsersDelete: boolean,
  allowBranchUpdate: boolean,
  allowGroupUpdate: boolean,
  allowSendMessage: boolean,
): MassActionsOptionsWithPermissionsReturnType => {
  const { getState } = useConfigurationStore;
  const { domainSettings } = getState() ?? {};
  const { main_portal: isMainPortal } = domainSettings ?? {};

  const allowGroupMassActions = allowGroupUpdate && isMainPortal;

  return [
    { label: t("general.activate"), value: "activate", permission: allowUpdateUsers },
    { label: t("general.deactivate"), value: "deactivate", permission: allowUpdateUsers },
    { label: t("general.delete"), value: "delete", permission: allowUsersDelete },
    {
      label: t("branches.courses.addToBranch"),
      value: "add_to_branches",
      permission: allowBranchUpdate,
    },
    {
      label: t("branches.courses.removeFromBranch"),
      value: "remove_from_branches",
      permission: allowBranchUpdate,
    },
    { label: t("courses.addToGroup"), value: "add_to_groups", permission: allowGroupMassActions },
    {
      label: t("courses.removeFromGroup"),
      value: "remove_from_groups",
      permission: allowGroupMassActions,
    },
    { label: t("general.sendMessage"), value: "send_message", permission: allowSendMessage },
  ];
};

export const getImportUserItem = (icon: JSX.Element): DropdownItem[] => {
  return [
    {
      icon: icon,
      label: t("users.importUsers"),
    },
  ];
};

const SELECTED_TAB = "importExportSettings";
export const backLink = `${URLS.accountAndSettings.accountAndSettings}?tab=${SELECTED_TAB}`;

export const usersMassActionsWordings = {
  activate: {
    progressHeader: "general.activate",
    progressMessage: "users.massActions.activatingUsers",
    confirmationBodyTitle: "users.massActions.activateUsers",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
    confirmationHeader: "general.activate",
    confirmationButton: "general.activate",
    successMessage: "users.massActions.activateUsersSuccess",
  },
  deactivate: {
    progressHeader: "general.deactivate",
    progressMessage: "users.massActions.deactivatingUsers",
    confirmationHeader: "general.deactivate",
    confirmationButton: "general.deactivate",
    successMessage: "users.massActions.deactivateUsersSuccess",
    confirmationBodyTitle: "users.massActions.deactivateUsers",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
  delete: {
    progressHeader: "general.delete",
    progressMessage: "users.massActions.deletingUsers",
    confirmationHeader: "general.delete",
    confirmationButton: "general.delete",
    confirmationButtonColor: "danger",
    successMessage: "users.massActions.deleteUsersSuccess",
    confirmationBodyTitle: "users.massActions.deleteUsers",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
  add_to_branches: {
    progressHeader: "branches.courses.addToBranch",
    progressMessage: "users.massActions.addingUsersToBranches",
    confirmationHeader: "branches.courses.addToBranch",
    confirmationButton: "general.apply",
    successMessage: "users.massActions.addToBranchesSuccess",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
  remove_from_branches: {
    progressHeader: "users.massActions.removeUsersFromBranches",
    progressMessage: "users.massActions.removingUsersFromBranches",
    confirmationHeader: "users.massActions.removeUsersFromBranches",
    confirmationButton: "general.apply",
    successMessage: "users.massActions.removeFromBranchesSuccess",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
  add_to_groups: {
    progressHeader: "courses.addToGroup",
    progressMessage: "users.massActions.addingUsersToGroups",
    confirmationHeader: "courses.addToGroup",
    confirmationButton: "general.apply",
    successMessage: "users.massActions.addToGroupsSuccess",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
  remove_from_groups: {
    progressHeader: "users.massActions.removeUsersFromGroups",
    progressMessage: "users.massActions.removingUsersFromGroups",
    confirmationHeader: "users.massActions.removeUsersFromGroups",
    confirmationButton: "general.apply",
    successMessage: "users.massActions.removeFromGroupsSuccess",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
};

export const MAX_ALLOWED_FILES = 1;
