import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["accordion"] => {
  return {
    backgroundColor: colors.white,
    borderColor: colors.secondary.light,
    disabledColor: colors.secondary.base,
    dividerColor: colors.secondary.lighter,
    shadowColor: colors.secondary.light,
    statusColor: {
      valid: colors.green.base,
      invalid: colors.red.base,
    },
    textColor: colors.black,
  };
};
