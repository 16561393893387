import React, { ReactNode } from "react";
import { components, GroupBase, GroupHeadingProps } from "react-select";
import classNames from "classnames";
import { Avatar, Button, Text } from "@epignosis_llc/gnosis";
import { GroupsSVG, BranchIconSVG, CategoriesSVG } from "@epignosis_llc/gnosis/icons";
import { images } from "@constants/assets";
import {
  FileType,
  SpotlightBranch,
  SpotlightCategory,
  SpotlightCourse,
  SpotlightFile,
  SpotlightGroup,
  SpotlightUnit,
  SpotlightUser,
  SystemOption,
} from "types/entities";
import { GroupedOption, Option } from "@components/MainSearch/types";
import { getHighlightedText, truncate, capitalize } from "@utils/helpers";
import { t } from "@utils/i18n";
import { SearchNoResults } from "@assets/images";
import HighlightedText from "@components/MainSearch/HighlightedText";
import { FileTag, UserAvatar } from "@components/ReusableComponents";
import { getFormattedUserName } from "./view";
import { COLORS } from "@styles";
import { SpotlightSkill } from "types/entities/Spotlight";

export const GroupHeading = (props: GroupHeadingProps<Option>): JSX.Element => {
  if (props.data.label === "detailed-results") {
    return <></>;
  }
  return <components.GroupHeading {...props} />;
};

const optionContainer = (isResultsLink: boolean): string =>
  classNames({
    "spotlight-result": true,
    "detailed-results-link": isResultsLink,
  });

export const mapSystemOptionToOption = (data: SystemOption): Option => {
  const { id, icon } = data;

  return {
    value: id,
    label: t(`systemOptions.${id}`),
    avatar: icon,
    type: "options",
  };
};

export const mapSpotlightCourseToOption = (data: SpotlightCourse): Option => {
  const { id, name, code, category, cover_image } = data;

  return {
    value: id.toString(),
    label: name,
    subtitle: `${code ? `${code} / ` : ""} ${category ? truncate(category.name, 25) : ""} `,
    avatar: cover_image?.default ?? images.cover,
    type: "courses",
  };
};

export const mapSpotlightUnitToOption = (data: SpotlightUnit): Option => {
  const { id, name, course } = data;

  const obj: Option = {
    value: id.toString(),
    label: name,
    subtitle: course.name,
    avatar: course.cover_image?.default ?? images.cover,
    type: "units",
  };

  return obj;
};

export const mapSpotlightFileToOption = (data: SpotlightFile): Option => {
  const { id, name, type } = data;

  const obj: Option = {
    value: id.toString(),
    label: name,
    avatar: type,
    type: "files",
  };

  return obj;
};

export const mapSpotlightUserToOption = (data: SpotlightUser): Option => {
  const { id, name, surname, avatar, login, email } = data;

  const obj: Option = {
    value: id.toString(),
    label: getFormattedUserName({ name, surname, login }),
    subtitle: email,
    avatar: <UserAvatar avatar={avatar} imageSize="sm" avatarSize="sm" name={name} />,
    type: "users",
  };

  return obj;
};

export const mapSpotlightBranchToOption = (data: SpotlightBranch): Option => {
  const { id, name, description } = data;

  const obj: Option = {
    value: id.toString(),
    label: name,
    subtitle: description.text,
    avatar: (
      <Avatar bgColor={COLORS.primary} size={"sm"} className="spotlight-avatar">
        <BranchIconSVG height={32} />
      </Avatar>
    ),
    type: "branches",
  };

  return obj;
};

export const mapSpotlightCategoriesToOption = (data: SpotlightCategory): Option => {
  const { id, name } = data;

  const obj: Option = {
    value: id.toString(),
    label: name,
    avatar: (
      <Avatar bgColor={COLORS.primary} size={"sm"} className="spotlight-avatar">
        <CategoriesSVG height={32} />
      </Avatar>
    ),
    type: "categories",
  };

  return obj;
};

export const mapSpotlightGroupToOption = (data: SpotlightGroup): Option => {
  const { id, name, description } = data;

  const obj: Option = {
    value: id.toString(),
    label: name,
    subtitle: description.text,
    avatar: (
      <Avatar bgColor={COLORS.primary} size={"sm"} className="spotlight-avatar">
        <GroupsSVG height={32} />
      </Avatar>
    ),
    type: "groups",
  };

  return obj;
};

export const mapSpotlightSkillToOption = (data: SpotlightSkill): Option => {
  const { id, name, cover_image } = data;

  return {
    value: id.toString(),
    label: name,
    avatar: cover_image?.default ?? images.cover,
    type: "skills",
  };
};

export const formatSearchResults = (
  array: GroupedOption[],
  result: {
    label: string;
    options: Array<
      | SystemOption
      | SpotlightCourse
      | SpotlightUnit
      | SpotlightFile
      | SpotlightUser
      | SpotlightBranch
      | SpotlightGroup
      | SpotlightCategory
      | SpotlightSkill
    >;
    type: string;
  },
): GroupedOption[] => {
  // TODO: Investigate creating a re-usable fn for all the mapping fns
  if (result.type === "options") {
    array.push({
      ...result,
      options: result.options.map((option) => mapSystemOptionToOption(option as SystemOption)),
    });
  }

  if (result.type === "courses") {
    array.push({
      ...result,
      options: result.options.map((option) =>
        mapSpotlightCourseToOption(option as SpotlightCourse),
      ),
    });
  }

  if (result.type === "units") {
    array.push({
      ...result,
      options: result.options.map((option) => mapSpotlightUnitToOption(option as SpotlightUnit)),
    });
  }

  if (result.type === "files") {
    array.push({
      ...result,
      options: result.options.map((option) => mapSpotlightFileToOption(option as SpotlightFile)),
    });
  }

  if (result.type === "users") {
    array.push({
      ...result,
      options: result.options.map((option) => mapSpotlightUserToOption(option as SpotlightUser)),
    });
  }

  if (result.type === "branches") {
    array.push({
      ...result,
      options: result.options.map((option) =>
        mapSpotlightBranchToOption(option as SpotlightBranch),
      ),
    });
  }

  if (result.type === "groups") {
    array.push({
      ...result,
      options: result.options.map((option) => mapSpotlightGroupToOption(option as SpotlightGroup)),
    });
  }

  if (result.type === "categories") {
    array.push({
      ...result,
      options: result.options.map((option) =>
        mapSpotlightCategoriesToOption(option as SpotlightCategory),
      ),
    });
  }

  if (result.type === "skills") {
    array.push({
      ...result,
      options: result.options.map((option) => mapSpotlightSkillToOption(option as SpotlightSkill)),
    });
  }

  return array;
};

export const formatOptionLabel = (option: Option, inputValue: string): JSX.Element => {
  const { label, subtitle, avatar, type } = option;
  const optionLabel = getHighlightedText(label, inputValue);
  const optionSubtitle =
    typeof optionLabel === "object" ? subtitle : getHighlightedText(subtitle ?? "", inputValue);
  const isResultsLink = type === "detailed-results";

  return (
    <div className={optionContainer(isResultsLink)}>
      {isResultsLink ? (
        <Button variant="link" noGutters>
          {label}
        </Button>
      ) : (
        <>
          {avatar && (
            <>
              {option.type === "files" ? (
                <FileTag type={avatar as FileType} size="md" fontSize="xs" />
              ) : (
                <>
                  {typeof avatar === "string" ? (
                    <img src={avatar} alt="image" className="spotlight-image" />
                  ) : (
                    <div className="spotlight-option-icon">
                      <Button color="secondary" rounded>
                        {avatar}
                      </Button>
                    </div>
                  )}
                </>
              )}
            </>
          )}
          <div>
            <Text fontSize="xs" as="div" className="title">
              {typeof optionLabel === "string" ? (
                optionLabel
              ) : (
                <HighlightedText text={optionLabel} />
              )}
            </Text>
            {subtitle && optionSubtitle && (
              <Text fontSize="xs" as="div" className="subtitle">
                {typeof optionSubtitle === "string" ? (
                  optionSubtitle
                ) : (
                  <HighlightedText text={optionSubtitle} />
                )}
              </Text>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export const formatGroupLabel = (group: GroupBase<Option>): JSX.Element => {
  const { label } = group;
  return (
    <Text fontSize="sm" as="div" weight="700">
      {label ? capitalize(label) : ""}
    </Text>
  );
};

export const noOptionsMessage = (inputValue: string): ReactNode => {
  if (inputValue.length <= 2) {
    return <Text fontSize="sm">{t("messages.pleaseEnterMoreCharacters")}</Text>;
  }

  return (
    <div className="no-results-container">
      <SearchNoResults />
      <Text fontSize="sm" weight="700" className="title">
        {t("spotlight.noResults")}
      </Text>
      <Text fontSize="sm" className="subtitle">
        {t("spotlight.tryAgain")}
      </Text>
    </div>
  );
};

export const translationsList = {
  users: "general.users",
  courses: "general.courses",
  files: "general.files",
  categories: "general.categories",
  groups: "general.groups_other",
  branches: "branches.branches",
  units: "general.units",
  skills: "skills.skills",
};
