export type ToolbarButton = {
  [key: string]: {
    buttons: string[];
    align?: string;
    buttonsVisible?: number;
  };
};

export type InsertCustomOptions = {
  [key: string]: string;
};

export type AIEditorOptions = {
  aiEnabled: boolean;
  disabledActions?: AIGenerateTextActionType[];
  forceEnableWriteAbout?: boolean;
};

export type AIGenerateTextType = {
  action: AIGenerateTextActionType;
  args?: AIGenerateTextArgumentsType;
  callback?: (selectedText: string) => void;
};

export const AllAIGenerateTextActions = [
  "completion",
  "improve_writing",
  "fix_spelling_grammar",
  "make_shorter",
  "make_longer",
  "continue_sentence",
  "change_tone",
  "translate",
] as const;

export type AIGenerateTextActionType = (typeof AllAIGenerateTextActions)[number];

export type AIGenerateTextArgumentsType = AIGenerateTextBaseArgumentsType &
  AIGenerateTextTranslateArgumentsType &
  AIGenerateTextChangeToneArgumentsType;

type AIGenerateTextBaseArgumentsType = {
  clearEditor?: boolean;
};

type AIGenerateTextTranslateArgumentsType = {
  language?: {
    name: string;
    isoCode: string;
  };
};

type AIGenerateTextChangeToneArgumentsType = {
  tone?: ToneOfText;
};

type ToneOfText = "professional" | "casual" | "straightforward" | "confident" | "friendly";
