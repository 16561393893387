import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useApplyTranslations } from "@hooks";
import { Button, Text } from "@epignosis_llc/gnosis";
import { URLS } from "@constants/urls";
import { upgradeMenuItemStyles } from "./UpgradeMenuItem.styles";

type UpgradeMenuItemProps = {
  trialDaysLeft: number;
  onActionComplete: () => void;
};

const UpgradeMenuItem: FC<UpgradeMenuItemProps> = ({ trialDaysLeft, onActionComplete }) => {
  const navigate = useNavigate();
  const { t } = useApplyTranslations();

  const handleClick = (): void => {
    navigate(URLS.subscription.subscription);
    onActionComplete();
  };

  return (
    <div css={upgradeMenuItemStyles}>
      <Button variant="solid" onClick={handleClick}>
        {t("general.upgradeNow")}
      </Button>
      <Text className="days-left-text" fontSize="sm">
        {trialDaysLeft === 1
          ? t("reverseTrial.dayRemaining", { day: trialDaysLeft })
          : t("reverseTrial.daysRemaining", { days: trialDaysLeft })}
      </Text>
    </div>
  );
};

export default UpgradeMenuItem;
