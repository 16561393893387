import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const MainMenuDrawerContainer = ({ actionDrawer, icon }) => css`
  --mainHeaderHeight: 72px;
  top: var(--mainHeaderHeight);
  height: calc(100% - var(--mainHeaderHeight));
  display: none;

  ${mq["md"]} {
    display: block;
  }

  header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${actionDrawer.border};
    justify-content: space-between;

    .back-title-container {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .back-button {
        padding: 0;
        min-width: auto;
        height: auto;
      }
    }

    .header-container {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .header-title {
        font-size: 1.375rem;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-all;
        cursor: default;
      }

      .header-link {
        line-height: 0;
      }

      .link-icon {
        color: ${icon.primary};
      }
    }

    .close-button {
      padding: 0;
    }
  }

  footer {
    padding: 1rem 2rem;
    border-top: 1px solid ${actionDrawer.border};
  }

  .action-drawer-footer {
    display: flex;
    justify-content: space-between;

    .button {
      &-right {
        margin: 0 0.5rem;
      }

      &-left {
        margin: 0 0.5rem 0 0;
      }
    }
  }

  .help-footer-buttons {
    display: flex;
    justify-content: space-between;

    svg {
      height: 1.125rem;
    }
  }
`;
