import { config } from "@config";

const getBaseUrl = (): "/" | "/plus" => {
  switch (config.NODE_ENV) {
    case "prod":
    case "staging":
      return "/plus";
    default:
      return "/";
  }
};
export default getBaseUrl;
