const appendRedirectParam = (url: string, redirectParam: string): string => {
  // Extract success_url value from the URL
  const regex = /success_url:([^,]+)/;
  const match = url.match(regex);
  const originalValue = match ? match[1] : null;

  if (!originalValue) {
    return url;
  }

  // Replace URL - safe base64 characters back to regular base64 characters
  let base64String = originalValue.replace(/-/g, "+").replace(/_/g, "/");

  // Adjust base64 string length to be a multiple of 4
  while (base64String.length % 4 !== 0) {
    base64String += "=";
  }

  const decodedUrl = atob(base64String);
  const successUrl = new URL(decodedUrl);
  const params = new URLSearchParams(successUrl.search);

  params.delete("token");
  params.set("redirect_path", redirectParam);
  params.append("token", "");
  successUrl.search = params.toString();

  // Encode a string to base64 URL-safe format
  let base64Encoded = btoa(successUrl.href);

  // Replace '+' with '-' and '/' with '_'
  base64Encoded = base64Encoded.replace(/\+/g, "-").replace(/\//g, "_");

  // Remove '=' padding
  base64Encoded = base64Encoded.replace(/=+$/, "");

  const updatedUrl = url.replace(`success_url:${originalValue}`, `success_url:${base64Encoded}`);

  return updatedUrl;
};

export default appendRedirectParam;
