import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["reminderWidget"] => {
  return {
    color: colors.black,
    hover: colors.primary.base,
    backgroundColor: colors.secondary.lighter,
  };
};
