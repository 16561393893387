import React, { FC } from "react";
import { Result } from "@epignosis_llc/gnosis";
import { errorResultContainer } from "./styles";
import { images } from "@constants/assets";
import { IconType } from "types/common";
import { useApplyTranslations } from "@hooks";

type ErrorResultProps = {
  errorImg?: string | IconType;
  title?: string;
};

const ErrorResult: FC<ErrorResultProps> = ({ errorImg = images.errors.fetchData, title }) => {
  const { t } = useApplyTranslations();
  return (
    <div css={errorResultContainer}>
      <Result icon={errorImg} title={title ?? t("general.somethingWentWrongEmptyState")} />
    </div>
  );
};

export default ErrorResult;
