import Color from "color";
import { PalletThemeColors, isShadeOfWhite } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["announcements"] => {
  const isLightTheme = isShadeOfWhite(colors.primary.base);

  return {
    internal: {
      background: isLightTheme
        ? colors.primary.base
        : Color(colors.primary.lightest).alpha(0.25).string(),
    },
    external: {
      background: colors.white,
    },
    admin: {
      background: isLightTheme
        ? colors.primary.base
        : Color(colors.primary.lightest).alpha(0.25).string(),
    },
    color: colors.black,
    borderColor: colors.secondary.base,
  };
};
